import useQuestionnaireCreateRouteConfig from "../../../../routes/configs/questionnaires/QuestionnaireCreateRouteConfig";
import useQuestionnaireEditRouteConfig from "../../../../routes/configs/questionnaires/QuestionnaireEditRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function QuestionnairesDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("questionnaires");

  //Set the datatable fetch info
  config.setFetchRequest("questionnaires");

  //Set default order
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //Set the datatable comuns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("questionnaire", useQuestionnaireEditRouteConfig()),
    useDeleteRowActionConfig("questionnaire"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "questionnaire",
      useQuestionnaireCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default QuestionnairesDataTable;
