import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { StyledAltIconButton } from "../../../../../styles/ButtonStyles";
import { StyledFormCard } from "../main/MainFormStyles";
import {
  StyledHeaderActions,
  StyledSettingsCardBody,
  StyledSettingsCardHeader,
} from "./SettingsFormStyles";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBorderTopLeft,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import HtmlSizeInput from "../../../../../components/input/html/size/HtmlSizeInput";
import InputWrapper from "../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../components/select/main/Select";
import { StyledInput } from "../../../../../styles/InputStyles";
import HtmlColorInput from "../../../../../components/input/html/color/HtmlColorInput";
import HtmlMultiSizeInput from "../../../../../components/input/html/size/multi/HtmlMultiSizeInput";

function SettingsFormView({
  item,
  settings,
  images,
  onDelete,
  onCancel,
  onSettingChange,
}) {
  const { translate } = useLanguageContext();

  /**
   * This method will render the given setting
   * @param {string} setting
   * @returns {JSX.Element}
   */
  function renderSetting(setting) {
    switch (setting) {
      case "width":
      case "height":
      case "maxWidth":
      case "maxHeight":
      case "minWidth":
      case "minHeight":
      case "gap":
        return (
          <HtmlSizeInput
            value={item.style[setting]}
            onChange={(val) => onSettingChange(setting, val)}
            types={getSizeTypes(setting)}
          />
        );

      case "flex":
        return (
          <StyledInput
            type="number"
            value={item.style[setting]}
            placeholder="auto"
            onChange={(e) => onSettingChange(setting, e.target.value)}
          />
        );

      case "flexDirection":
        return (
          <Select
            value={
              item.style[setting] ??
              (item.style.display === "flex" ? "row" : "column")
            }
            options={[
              { value: "row", label: translate("eva.main.layout.values.row") },
              {
                value: "column",
                label: translate("eva.main.layout.values.column"),
              },
            ]}
            onChange={(val) => onSettingChange(setting, val)}
          />
        );

      case "justifyContent":
        return (
          <Select
            value={item.style[setting] ?? "flex-start"}
            options={[
              {
                value: "flex-start",
                label: translate(`eva.main.layout.values.flex-start`),
              },
              {
                value: "center",
                label: translate(`eva.main.layout.values.center`),
              },
              {
                value: "flex-end",
                label: translate(`eva.main.layout.values.flex-end`),
              },
              {
                value: "space-between",
                label: translate(`eva.main.layout.values.space-between`),
              },
              {
                value: "space-around",
                label: translate(`eva.main.layout.values.space-around`),
              },
              {
                value: "space-evenly",
                label: translate(`eva.main.layout.values.space-evenly`),
              },
            ]}
            onChange={(value) => onSettingChange(setting, value)}
          />
        );

      case "alignItems":
        return (
          <Select
            value={item.style[setting] ?? "stretch"}
            options={[
              {
                value: "flex-start",
                label: translate(`eva.main.layout.values.flex-start`),
              },
              {
                value: "center",
                label: translate(`eva.main.layout.values.center`),
              },
              {
                value: "flex-end",
                label: translate(`eva.main.layout.values.flex-end`),
              },
              {
                value: "stretch",
                label: translate(`eva.main.layout.values.stretch`),
              },
            ]}
            onChange={(value) => onSettingChange(setting, value)}
          />
        );

      case "image":
        return (
          <Select
            value={item.image?.id}
            withNullOption={true}
            options={images.map((image) => ({
              value: image.id,
              label: image.name,
            }))}
            onChange={(value) =>
              onSettingChange(
                setting,
                images.find((i) => i.id === value),
                false
              )
            }
          />
        );

      case "backgroundColor":
      case "color":
        return (
          <HtmlColorInput
            value={item.style[setting]}
            onChange={(value) => onSettingChange(setting, value)}
            useOpacityInput={
              setting === "backgroundColor" && item.type !== "page"
            }
            useVisabilityInput={setting === "color" && item.type !== "page"}
          />
        );

      case "borderRadius":
        return (
          <HtmlMultiSizeInput
            icon={faBorderTopLeft}
            rotateIcon={true}
            inputs={[
              {
                value: item.style.borderTopLeftRadius,
                onChange: (val) => onSettingChange("borderTopLeftRadius", val),
              },
              {
                value: item.style.borderTopRightRadius,
                onChange: (val) => onSettingChange("borderTopRightRadius", val),
              },
              {
                value: item.style.borderBottomRightRadius,
                onChange: (val) =>
                  onSettingChange("borderBottomRightRadius", val),
              },
              {
                value: item.style.borderBottomLeftRadius,
                onChange: (val) =>
                  onSettingChange("borderBottomLeftRadius", val),
              },
            ]}
          />
        );

      case "margin":
        return (
          <HtmlMultiSizeInput
            icon={faArrowRightToBracket}
            iconRotation={-90}
            rotateIcon={true}
            types={["px", "%", "auto"]}
            inputs={[
              {
                value: item.style.marginTop,
                onChange: (val) => onSettingChange("marginTop", val),
              },
              {
                value: item.style.marginRight,
                onChange: (val) => onSettingChange("marginRight", val),
              },
              {
                value: item.style.marginBottom,
                onChange: (val) => onSettingChange("marginBottom", val),
              },
              {
                value: item.style.marginLeft,
                onChange: (val) => onSettingChange("marginLeft", val),
              },
            ]}
          />
        );

      case "padding":
        return (
          <HtmlMultiSizeInput
            icon={faArrowRightFromBracket}
            iconRotation={90}
            rotateIcon={true}
            inputs={[
              {
                value: item.style.paddingTop,
                onChange: (val) => onSettingChange("paddingTop", val),
              },
              {
                value: item.style.paddingRight,
                onChange: (val) => onSettingChange("paddingRight", val),
              },
              {
                value: item.style.paddingBottom,
                onChange: (val) => onSettingChange("paddingBottom", val),
              },
              {
                value: item.style.paddingLeft,
                onChange: (val) => onSettingChange("paddingLeft", val),
              },
            ]}
          />
        );

      case "boxShadow":
        return (
          <Select
            value={item.style.boxShadow ? true : false}
            options={[
              { value: true, label: translate("eva.main.general.yes") },
              { value: false, label: translate("eva.main.general.no") },
            ]}
            onChange={(value) =>
              onSettingChange(
                "boxShadow",
                value ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : null
              )
            }
          />
        );

      default:
        return null;
    }
  }

  /**
   * This method will return the size types for the given setting
   * @param {string} setting
   * @returns {string[]}
   */
  function getSizeTypes(setting) {
    if (item.type === "page" || setting === "gap") {
      return ["px"];
    }
    return ["px", "%"];
  }

  return (
    item && (
      <StyledFormCard>
        <StyledSettingsCardHeader>
          {/* Header */}
          <h2>
            {translate("eva.main.entities.states.edit", [
              {
                key: "entities",
                value: translate(`eva.main.layout.types.${item.type}`),
              },
            ])}
          </h2>

          <StyledHeaderActions>
            {/* Delete button */}
            {item.type !== "page" && (
              <StyledAltIconButton
                onClick={onDelete}
                title={translate("eva.main.entities.states.delete", [
                  {
                    key: "entities",
                    value: translate(`eva.main.layout.types.${item.type}`),
                  },
                ])}
              >
                <FontAwesomeIcon icon={faTrash} />
              </StyledAltIconButton>
            )}

            {/* Close button */}
            <StyledAltIconButton
              onClick={onCancel}
              title={translate("eva.main.general.cancel")}
            >
              <FontAwesomeIcon icon={faXmark} />
            </StyledAltIconButton>
          </StyledHeaderActions>
        </StyledSettingsCardHeader>

        <StyledSettingsCardBody>
          {/* Settings */}
          {settings.map((setting) => (
            <InputWrapper
              key={setting + item.id}
              label={translate(`eva.main.layout.attributes.${setting}`)}
            >
              {renderSetting(setting)}
            </InputWrapper>
          ))}
        </StyledSettingsCardBody>
      </StyledFormCard>
    )
  );
}

export default SettingsFormView;
