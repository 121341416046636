import { useNavigate, useParams } from "react-router-dom";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import HeaderActionConfig from "../HeaderActionConfig";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

/**
 * @param {string} entity for example: "location"
 * @param {RouteConfig} routeConfig  for example: useLocationCreateRouteConfig()
 * @returns {HeaderActionConfig}
 */
function useCreateHeaderActionConfig(entity, routeConfig) {
  const { translate } = useLanguageContext();
  const navigate = useNavigate();
  const params = useParams();

  /**
   * This method will return the disabled state of the header action
   * @returns {boolean}
   */
  function getDisabled() {
    if (routeConfig.validator) {
      return routeConfig.validator.validate() !== true;
    }
    return false;
  }

  return new HeaderActionConfig()
    .setIcon(faPlus)
    .setCallback(() => navigate(routeConfig.getPathWithParams(params)))
    .setDisabled(getDisabled())
    .setTitle(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate(`eva.main.entities.${entity}`),
        },
      ])
    );
}

export default useCreateHeaderActionConfig;
