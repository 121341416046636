import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import TinyMessageEditor from "../../../components/tinyEditor/message/TinyMessageEditor";
import EntityTranslationContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import { useTheme } from "styled-components";

function MessageTemplatePageView({
  messageTemplateData,
  messageController,
  translationsController,
  onNameChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="message_template_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"message_template_page_submit"}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={messageTemplateData.name}
              onChange={onNameChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="message_template_page_fetch">
        <EntityTranslationContainer
          controller={translationsController}
          style={{ marginTop: theme.spacing.large }}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.message")}
            error={translationsController.getTranslationError(
              "message",
              submitError
            )}
          >
            <TinyMessageEditor controller={messageController} />
          </InputWrapper>
        </EntityTranslationContainer>
      </OverlayLoader>
    </>
  );
}

export default MessageTemplatePageView;
