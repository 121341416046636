import { useLanguageContext } from "../../../context/LanguageContext";
import LayoutPage from "../../../pages/layouts/layout/LayoutPage";
import { useLayoutCreateRouteValidator } from "../../validators/layouts/LayoutCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useLayoutsRouteConfig from "./LayoutsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useLayoutCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/layouts/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.layout"),
        },
      ])
    )
    .setPage(<LayoutPage />)
    .setValidator(useLayoutCreateRouteValidator())
    .setParent(useLayoutsRouteConfig());
}

export default useLayoutCreateRouteConfig;
