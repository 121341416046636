import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import usePermissionMiddleware from "../../../middlewares/PermissionMiddleware";
import useRoleTypeMiddleware from "../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../RouteValidator";

/**
 * @returns {{ validate: (user) => boolean|{ to: string, replace: boolean }}}
 */
export function useUserEditRouteValidator() {
  const middlewaresValidation = useMiddlewares([
    useAuthMiddleware(),
    useRoleTypeMiddleware(["superadmin", "user"], "or"),
    usePermissionMiddleware(["users.update", "users.update.self"], "or"),
  ]);

  return useRouteValidator((user) => {
    //The default superadmin user cannot be edited
    if (user && user.email === "superadmin@evasolutions.eu") {
      return false;
    }

    return middlewaresValidation;
  });
}
