import { faFilePdf, faImage, faVideo } from "@fortawesome/free-solid-svg-icons";

class FileHelper {
  /**
   * This method will simulate a file selection and return the selected file(s)
   * @param {string} accept
   * @param {bool} multiple
   * @returns {Promise<File|File[]>}
   */
  static select(accept, multiple = false) {
    return new Promise((resolve, reject) => {
      // Create a hidden input
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.style.display = "none";
      fileInput.accept = accept;
      fileInput.multiple = multiple;

      // Add the onChange event to the hidden input for resolving the file
      fileInput.addEventListener("change", (event) => {
        const selectedFiles = multiple
          ? event.target.files
          : event.target.files[0];
        resolve(selectedFiles);
      });

      // Make sure to resolve with null when the selection gets canceled
      fileInput.addEventListener("cancel", () => {
        resolve(multiple ? [] : null);
      });

      // Add the hidden input to the dom
      document.body.appendChild(fileInput);

      // Simulate a click on the input and remove it afterwards
      fileInput.click();
      fileInput.remove();
    });
  }

  /**
   * This method will return the extension of the given file path
   * @param {string|File} path
   */
  static extension(path) {
    if (path instanceof File) {
      path = path.name;
    }
    return path ? path.split(".").pop() : null;
  }

  /**
   * This method will return the icon for the given extension
   * @param {string} extension
   * @returns {IconDefinition}
   */
  static extensionIcon(extension) {
    switch (extension) {
      case "pdf":
        return faFilePdf;
      case "jpg":
      case "jpeg":
      case "png":
        return faImage;
      case "mp4":
        return faVideo;
      default:
        return null;
    }
  }

  /**
   * This method will return the icon for the given file path
   * @param {string} path
   * @returns {IconDefinition|null}
   */
  static pathIcon(path) {
    return FileHelper.extensionIcon(FileHelper.extension(path));
  }

  /**
   * This method will return the type for the given file path
   * @param {string} path
   * @returns {string}
   */
  static pathType(path) {
    switch (FileHelper.extension(path)) {
      case "pdf":
        return "attachment";
      case "mp4":
        return "video";
      case "jpg":
      case "jpeg":
      case "png":
        return "image";
      default:
        return null;
    }
  }

  /**
   * This method will return the api path for the given path
   * @param {string} path
   * @param {bool} download
   * @returns {string}
   */
  static apiPath(path, download = false) {
    if (!path || typeof path !== "string" || path.startsWith("http")) {
      return path;
    }

    return download
      ? `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/download/${path}`
      : `${process.env.REACT_APP_API_HOST}/storage/` +
          path.replace("public/", "");
  }

  /**
   * This method will download the given file with the given filename
   * @param {Blob} file
   * @param {string} filename
   */
  static download(file, filename) {
    if (!(file instanceof Blob)) {
      return;
    }

    const link = document.createElement("a");

    // Determine and set the filename
    link.setAttribute("download", filename);

    //Determine and set the link href location
    const url = URL.createObjectURL(file);
    link.href = url;

    //Fire an imaginary click event on the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default FileHelper;
