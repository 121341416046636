import { useState } from "react";
import { useParams } from "react-router-dom";
import EmployeePageView from "./EmployeePageView";
import { useAuthContext } from "../../../context/AuthContext";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function EmployeePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { employee } = useParams();
  const authContext = useAuthContext();
  const [allergens, setAllergens] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    email: "",
    present: false,
    allergens: [],
    department_id: null,
    location_id: authContext.auth.location,
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    fetchAllergens();
    fetchDepartments();

    if (employee) {
      fetchEmployeeData();
    }
  });

  /**
   * This method will fetch the current departments from the API
   */
  async function fetchDepartments() {
    await callEvaApi(
      new EvaApiCall("departments")
        .setLoadingGroup("employee_page_fetch")
        .setOnSuccess((response) => {
          setDepartments(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Fetch the allergens from the API
   */
  async function fetchAllergens() {
    await callEvaApi(
      new EvaApiCall("canteen/allergens")
        .setLoadingGroup("employee_page_fetch")
        .setOnSuccess((response) => {
          setAllergens(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the employee data from the API
   */
  async function fetchEmployeeData() {
    await callEvaApi(
      new EvaApiCall(`employees/${employee}`)
        .setLoadingGroup("employee_page_fetch")
        .setOnSuccess((response) => {
          setEmployeeData({
            name: response.data.data.name,
            email: response.data.data.email,
            present: response.data.data.present,
            allergens: response.data.data.allergens,
            location_id: response.data.data.location,
            department_id: response.data.data.department,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the location id on input change
   * @param {any} value
   */
  function onLocationIdChange(value) {
    setEmployeeData({ ...employeeData, location_id: value });
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setEmployeeData({ ...employeeData, name: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onEmailChange(e) {
    setEmployeeData({ ...employeeData, email: e.target.value });
  }

  /**
   * This method will update the present on input change
   * @param {any} value
   */
  function onPresentChange(value) {
    setEmployeeData({ ...employeeData, present: value });
  }

  /**
   * This method will update the allergens on input change
   * @param {array} values
   */
  function onAllergensChange(values) {
    setEmployeeData({
      ...employeeData,
      allergens: values,
    });
  }

  /**
   * This method will update the department on input change
   * @param {any} value
   */
  function onDepartmentChange(value) {
    setEmployeeData({ ...employeeData, department_id: value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(employee ? `employees/${employee}` : "employees")
        .setLoadingGroup("employee_page_save")
        .setMethod(employee ? "PUT" : "POST")
        .setData(employeeData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <EmployeePageView
      employee={employee}
      employeeData={employeeData}
      allergens={allergens}
      departments={departments}
      onLocationIdChange={onLocationIdChange}
      onNameChange={onNameChange}
      onEmailChange={onEmailChange}
      onPresentChange={onPresentChange}
      onAllergensChange={onAllergensChange}
      onDepartmentChange={onDepartmentChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default EmployeePage;
