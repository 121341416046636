import { useAuthContext } from "../../../../context/AuthContext";
import useMiddlewares from "../../../../middlewares/Middleware";
import usePermissionMiddleware from "../../../../middlewares/PermissionMiddleware";
import useRoleTypeMiddleware from "../../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../../RouteValidator";
import { useOrderRouteValidator } from "./OrderRouteValidator";

/**
 * @returns {{ validate: (order) => boolean|{ to: string, replace: boolean }}}
 */
export function useOrderEditRouteValidator() {
  const authContext = useAuthContext();
  const orderValidator = useOrderRouteValidator();
  const middlewaresValidation = useMiddlewares([
    usePermissionMiddleware("canteen.dishes.create"),
    useRoleTypeMiddleware(["superadmin", "user"], "or"),
  ]);

  return useRouteValidator((order) => {
    //Use the default order validator
    const orderValidation = orderValidator.validate(order);
    if (orderValidation !== true) {
      return orderValidation;
    }

    //Validate if the user has permission to edit the order
    if (order) {
      const orderEmployeeId =
        typeof order.employee === "object" ? order.employee.id : order.employee;
      if (
        (orderEmployeeId !== authContext.auth.user.employee &&
          !authContext.hasPermission("canteen.orders.update")) ||
        (orderEmployeeId === authContext.auth.user.employee &&
          !authContext.hasPermission("canteen.orders.update.self") &&
          !authContext.hasPermission("canteen.orders.update"))
      ) {
        return false;
      }
    }

    //validate the middlewares
    return middlewaresValidation;
  });
}
