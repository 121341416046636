import { useLanguageContext } from "../../../../context/LanguageContext";
import OrdersPage from "../../../../pages/canteen/orders/index/OrdersPage";
import { useOrdersRouteValidator } from "../../../validators/canteen/orders/OrdersRouteValidator";
import { RouteConfig } from "../../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useOrdersRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/orders")
    .setLabel(translate("eva.main.entities.orders"))
    .setValidator(useOrdersRouteValidator())
    .setPage(<OrdersPage />);
}

export default useOrdersRouteConfig;
