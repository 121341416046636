import { useRef, useState } from "react";
import TinyEditorView from "./TinyEditorView";
import useCustomEffect from "../../hooks/useCustomEffect";

function TinyEditor({ controller }) {
  return <TinyEditorView controller={controller} />;
}

export default TinyEditor;

export function useTinyEditor(value, onChange) {
  const editorRef = useRef(null);
  const changeFromEditor = useRef(false);
  const [initialized, setInitialized] = useState(false);

  /**
   * UseEffect for updating the editor content when the value changes
   */
  useCustomEffect(() => {
    if (initialized && !changeFromEditor.current) {
      const cursorPosition = getCursorPosition();
      editorRef.current.setContent(value ?? "");
      setCursorPosition(cursorPosition);
    }

    changeFromEditor.current = false;
  }, [value, initialized]);

  /**
   * This method is called when the editor content changes
   * @param {string} content
   * @param {object} editor
   */
  function onEditorChange(content, editor) {
    if (onChange) {
      changeFromEditor.current = true;
      onChange(editor.getContent());
    }
  }

  /**
   * This method returns the current cursor position
   * @returns {object}
   */
  function getCursorPosition() {
    return editorRef.current.selection.getBookmark(2);
  }

  /**
   * This method sets the cursor position
   * @param {object} cursorPosition
   */
  function setCursorPosition(cursorPosition) {
    editorRef.current.selection.moveToBookmark(cursorPosition);
  }

  /**
   * This method inserts the content at the current cursor position
   * @param {string} content
   */
  function insertAtCursor(content) {
    editorRef.current.execCommand("mceInsertContent", false, content);
  }

  /**
   * This method initializes the editor
   * @param {object} editor
   */
  function initialize(editor) {
    editorRef.current = editor;
    setInitialized(true);
  }

  return {
    editorRef,
    initialized,
    onEditorChange,
    insertAtCursor,
    initialize,
  };
}
