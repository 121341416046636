import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilePreview from "../../../../../../components/filePreview/FilePreview";
import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import FileHelper from "../../../../../../helpers/FileHelper";
import { StyledFlexLayout } from "../../../../../../styles/LayoutStyles";
import StepLayout from "../layout/StepLayout";
import { faImage } from "@fortawesome/free-solid-svg-icons";

function QuestionnaireStepView({
  step,
  questionnaire,
  visitorData,
  setPageConfig,
  processStep,
  previousStep,
  onQuestionChange,
}) {
  const { translateEntity } = useLanguageContext();

  function renderQuestion(question) {
    const visitorDataQuestion = visitorData.questions?.find(
      (q) => q.id === question.id
    );

    return (
      <InputWrapper
        key={question.id}
        label={translateEntity(question, "question")}
      >
        <StyledFlexLayout style={{ alignItems: "center" }}>
          {question.image && (
            <FilePreview path={FileHelper.apiPath(question.image)}>
              <FontAwesomeIcon icon={faImage} size="2xl" />
            </FilePreview>
          )}

          <Select
            value={visitorDataQuestion?.value}
            onChange={(val) => onQuestionChange(question, val)}
            options={question.answers.map((answer) => ({
              value: answer.id,
              label: translateEntity(answer, "answer"),
            }))}
          />
        </StyledFlexLayout>
      </InputWrapper>
    );
  }

  return (
    <StepLayout
      step={step}
      visitorData={visitorData}
      setPageConfig={setPageConfig}
      processStep={processStep}
      previousStep={previousStep}
    >
      <StyledFlexLayout style={{ flexDirection: "column" }}>
        {questionnaire.questions.map((question) => renderQuestion(question))}
      </StyledFlexLayout>
    </StepLayout>
  );
}

export default QuestionnaireStepView;
