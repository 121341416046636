import { useState } from "react";
import { useHelpTextContext } from "../../../../context/HelpTextContext";
import HelpSelectView from "./HelpSelectView";

function HelpSelect({ disabled }) {
  const helpTextContext = useHelpTextContext();
  const [helpText, setHelpText] = useState(null);
  const helpTexts = helpTextContext.getCurrentHelpTexts();

  /**
   * Handle the change of the select
   * @param value
   */
  function onChange(value) {
    setHelpText(helpTexts.find((text) => text.id === value));
  }

  return (
    helpTexts.length > 0 && (
      <HelpSelectView
        helpText={helpText}
        options={helpTexts.map((ht) => ({ value: ht.id, label: ht.title }))}
        onChange={onChange}
        disabled={disabled}
      />
    )
  );
}

export default HelpSelect;
