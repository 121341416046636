import styled from "styled-components";

export const StyledOverlayLoader = styled.div`
  position: relative;
`;

export const StyledOverlayLoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    `rgba(${props.theme.colors.overlay.primary}, 0.5)`};
  border-radius: ${(props) => props.theme.radius.default};
  z-index: 1000;
`;
