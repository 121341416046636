import { createGlobalStyle } from "styled-components";

const GenericStyles = createGlobalStyle`
  *,
  ::before,
  ::after {
    border: 0 solid transparent;
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
    color: inherit;
    padding: 0;
    margin: 0;
  }

  html {
    font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji";
  }

  body {
    margin: 0;
    background-color: ${(props) => props.theme.colors.primary.dark};
    color: ${(props) => props.theme.colors.on.primary};
    font-size: ${(props) => props.theme.font.size.default};
  }

  hr {
    height: 1px;
    background-color: ${(props) => props.theme.colors.primary.lightest};
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  h1, h2, h3 {
    font-weight: bold;
  }

  h1 {
    font-size: ${(props) => props.theme.font.size.xl};
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.lg};
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
      monospace;
    font-size: 1em;
  }

  table {
    border-collapse: collapse;
  }

  small {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  sub,
  sup {
    font-size: 75%;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
    cursor: pointer;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    appearance: button;
    -moz-appearance: button;
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  summary {
    display: list-item;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: ${(props) => props.theme.spacing.large};
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: ${(props) => props.theme.spacing.large};
  }
  ul ul, ol ul {
    list-style-type: circle;
  }
  ul ul ul, ol ol ul, ul ol ul, ol ul ul {
    list-style-type: square;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
  }

  textarea {
    resize: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.primary.lightest};
  }
  ::-webkit-scrollbar-thumb {
    border: 1px solid ${(props) => props.theme.colors.primary.lightest};
    background: ${(props) => props.theme.colors.primary.darkest};
  }




  /* ADD THIS BLOCK TO ENABLE DRAG CURSOR FUNCTIONALITY */
  .dragging, .dragging * {
    cursor: grabbing !important;
  }
`;

export default GenericStyles;
