import { useAuthContext } from "../context/AuthContext";

function useLocationMiddleware() {
  const authContext = useAuthContext();

  /**
   * This method will validate the middleware
   * @returns {boolean}
   */
  function validate() {
    return (
      authContext.auth.user?.company.locations.length <= 0 ||
      authContext.auth.location !== null
    );
  }

  /**
   * This method return the redirect instructions on fail
   * @returns {{ to: string, replace: boolean }}
   */
  function redirect() {
    return { to: "/dashboard", replace: true };
  }

  //return the middleware
  return { validate, redirect };
}

export default useLocationMiddleware;
