import { useRef, useState } from "react";
import PdfViewerView from "./PdfViewerView";
import useCustomEffect from "../../hooks/useCustomEffect";

function PdfViewer({ url }) {
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(null);
  const containerRef = useRef(null);

  /**
   * This effect will update the page width when the window is resized
   */
  useCustomEffect(() => {
    updateWidth();

    function updateWidth() {
      if (containerRef.current) {
        setPageWidth(containerRef.current.offsetWidth);
      }
    }

    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  });

  /**
   * This method will be called when the document is loaded
   * @param {object} param0
   */
  function onDocumentLoaded({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <PdfViewerView
      url={url}
      onDocumentLoaded={onDocumentLoaded}
      numPages={numPages}
      containerRef={containerRef}
      pageWidth={pageWidth}
    />
  );
}

export default PdfViewer;
