import { useLanguageContext } from "../../../../../../context/LanguageContext";
import FileHelper from "../../../../../../helpers/FileHelper";
import { StyledPreviewImage } from "../../../../../../styles/ImageStyles";
import FilePreview from "../../../../../filePreview/FilePreview";
import ColumnConfig from "../ColumnConfig";

/**
 * @param {string} key default value is "image"
 * @param {function(row) : any} data default value is (row) => row[key]
 * @returns {ColumnConfig}
 */
function useImageColumnConfig(key = "image", data = (row) => row[key]) {
  const { translate } = useLanguageContext();

  return new ColumnConfig()
    .setKey(key)
    .setData(
      (row) =>
        data(row) && (
          <FilePreview path={data(row)}>
            <StyledPreviewImage
              src={FileHelper.apiPath(data(row))}
              alt={translate("eva.main.validation.attributes.image")}
            />
          </FilePreview>
        )
    )
    .setLabel(translate("eva.main.validation.attributes.image"))
    .setLabelHeaderHidden(true)
    .setHeaderStyle({ width: "0px" })
    .setOrderable(false)
    .setSearchable(false);
}

export default useImageColumnConfig;
