import HiddenLoaderView from "./HiddenLoaderView";

function HiddenLoader({ groups, iconSize, children, containerStyle }) {
  return (
    <HiddenLoaderView
      groups={groups}
      iconSize={iconSize}
      containerStyle={containerStyle}
    >
      {children}
    </HiddenLoaderView>
  );
}

export default HiddenLoader;
