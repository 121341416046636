import { useLanguageContext } from "../../../../context/LanguageContext";
import TypePage from "../../../../pages/calamities/type/TypePage";
import { useTypeEditRouteValidator } from "../../../validators/calamities/types/TypeEditRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useCalamitiesRouteConfig from "../CalamitiesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCalamityTypeEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/calamities/types/:type/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.calamity_type"),
        },
      ])
    )
    .setValidator(useTypeEditRouteValidator())
    .setPage(<TypePage />)
    .setParent(useCalamitiesRouteConfig());
}

export default useCalamityTypeEditRouteConfig;
