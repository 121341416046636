import { useLanguageContext } from "../../../context/LanguageContext";
import ContactPage from "../../../pages/contacts/contact/ContactPage";
import { useContactCreateRouteValidator } from "../../validators/contacts/ContactCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useContactsRouteConfig from "./ContactsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useContactCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/contacts/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.contact"),
        },
      ])
    )
    .setValidator(useContactCreateRouteValidator())
    .setPage(<ContactPage />)
    .setParent(useContactsRouteConfig());
}

export default useContactCreateRouteConfig;
