import FrontOfficePageConfig from "../../FrontOfficePageConfig";
import StartPageView from "./StartPageView";

function StartPage({ setPageConfig }) {
  return <StartPageView setPageConfig={setPageConfig} />;
}

export const StartPageConfig = new FrontOfficePageConfig(StartPage, "start");

export default StartPage;
