import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";

function FlowPageHouseruleFormView({
  flowData,
  houserules,
  onHouseruleChange,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <>
      <InputWrapper
        label={translate("eva.main.entities.houserule")}
        error={EVAApiHelper.getResponseError(
          `steps.${flowData.selectedStep}.file_id`,
          submitError
        )}
        compactError={true}
        required
      >
        <Select
          value={flowData.steps[flowData.selectedStep].file_id}
          options={houserules.map((houserule) => ({
            value: houserule.id,
            label: houserule.name,
          }))}
          onChange={onHouseruleChange}
        />
      </InputWrapper>
    </>
  );
}

export default FlowPageHouseruleFormView;
