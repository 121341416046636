import { useAuthContext } from "../../../context/AuthContext";
import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useCompanyTypeMiddleware from "../../../middlewares/CompanyTypeMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import useRoleTypeMiddleware from "../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../RouteValidator";

/**
 * @returns {{ validate: (employee) => boolean|{ to: string, replace: boolean }}}
 */
export function useEmployeeEditRouteValidator() {
  const authContext = useAuthContext();
  const middlewaresValidation = useMiddlewares([
    useAuthMiddleware(),
    useRoleTypeMiddleware(["superadmin", "user"], "or"),
    useCompanyTypeMiddleware("default"),
  ]);

  return useRouteValidator((employee) => {
    //Check if the user is allowed to edit other employees
    if (
      employee &&
      parseInt(employee.id) !== authContext.auth.user.employee &&
      !authContext.hasPermission("employees.update")
    ) {
      return false;
    }

    //Check if the user is allowed to edit himself
    if (
      employee &&
      parseInt(employee.id) === authContext.auth.user.employee &&
      !authContext.hasPermission("employees.update.self")
    ) {
      return false;
    }

    return middlewaresValidation;
  });
}
