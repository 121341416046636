import ZoomPanEditor from "../zoomPanEditor/ZoomPanEditor";
import FlowChartEdge from "./edge/FlowChartEdge";
import FlowChartNode from "./node/FlowChartNode";

function FlowChartView({
  zoomPanController,
  nodes,
  nodeRefs,
  edges,
  onNodeRefInit,
  onNodeRefClear,
}) {
  return (
    <ZoomPanEditor controller={zoomPanController}>
      {nodes?.map((node) => (
        <FlowChartNode
          key={node.id}
          node={node}
          onRefInit={onNodeRefInit}
          onRefClear={onNodeRefClear}
        />
      ))}

      {edges?.map((edge, index) => (
        <FlowChartEdge
          key={edge.from + edge.to + index}
          edge={edge}
          nodeRefs={nodeRefs}
          zoomPanController={zoomPanController}
        />
      ))}
    </ZoomPanEditor>
  );
}

export default FlowChartView;
