import { useLanguageContext } from "../../../context/LanguageContext";
import AnnouncementsPage from "../../../pages/announcements/index/AnnouncementsPage";
import useAnnouncementsRouteValidator from "../../validators/AnnouncementsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useAnnouncementsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/announcements")
    .setLabel(translate("eva.main.entities.announcements"))
    .setValidator(useAnnouncementsRouteValidator())
    .setPage(<AnnouncementsPage />);
}

export default useAnnouncementsRouteConfig;
