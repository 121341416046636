import { useLanguageContext } from "../../../context/LanguageContext";
import TerminalPage from "../../../pages/terminals/terminal/TerminalPage";
import { useTerminalCreateRouteValidator } from "../../validators/terminals/TerminalCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useTerminalsRouteConfig from "./TerminalsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useTerminalCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/terminals/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.terminal"),
        }
      ])
    )
    .setValidator(useTerminalCreateRouteValidator())
    .setPage(<TerminalPage />)
    .setParent(useTerminalsRouteConfig());
}

export default useTerminalCreateRouteConfig;