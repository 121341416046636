export const pageDefaultSettings = {
  overflow: "hidden",
  backgroundColor: "rgb(255, 255, 255)",
  color: "rgb(0, 0, 0)",
  width: "600px",
  height: "800px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

export const boxDefaultSettings = {
  overflow: "hidden",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  display: "flex",
  gap: "10px",
  flex: 1,
  maxWidth: "100%",
  maxHeight: "100%",
};

export const subboxDefaultSettings = {
  ...boxDefaultSettings,
  flexDirection: "column",
};

export const contentDefaultSettings = {
  width: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  overflow: "auto",
};

export const languageDefaultSettings = {
  maxWidth: "100%",
  maxHeight: "100%",
};

export const imageDefaultSettings = {
  maxWidth: "100%",
  maxHeight: "100%",
  width: "100px",
  height: "100px",
  overflow: "hidden",
};

/**
 * This method will return the default layout settings for the given type
 * @param {string} type
 * @returns {object}
 */
export function getDefaultLayoutSettings(type) {
  switch (type) {
    case "page":
      return pageDefaultSettings;
    case "box":
      return boxDefaultSettings;
    case "subbox":
      return subboxDefaultSettings;
    case "content":
      return contentDefaultSettings;
    case "language":
      return languageDefaultSettings;
    case "image":
      return imageDefaultSettings;
    default:
      return {};
  }
}
