import { useTerminalContext } from "../../../../context/TerminalContext";
import FrontOfficePageConfig from "../../FrontOfficePageConfig";
import ButtonPageView from "./ButtonPageView";

function ButtonPage({ pageConfig, setPageConfig }) {
  const { terminal } = useTerminalContext();
  const button = terminal?.buttons?.find(
    (button) => parseInt(button.id) === parseInt(pageConfig.data?.buttonId)
  );
  const layout = button?.layout || terminal?.layout;

  return (
    <ButtonPageView
      button={button}
      layout={layout}
      setPageConfig={setPageConfig}
    />
  );
}

export const ButtonPageConfig = new FrontOfficePageConfig(ButtonPage, "button");

export default ButtonPage;
