class RowActionConfig {
  constructor() {
    this.icon = (row, dataTable) => null;
    this.title = (row, dataTable) => "";
    this.callback = (row, dataTable) => {};
    this.loadingGroups = (row, dataTable) => [];
    this.disabled = (row, dataTable) => false;
    this.hidden = (row, dataTable) => false;
  }

  /**
   * @param {IconDefinition | function(row, controller) : IconDefinition} icon
   * @returns {this}
   */
  setIcon(icon) {
    this.icon = typeof icon === "function" ? icon : () => icon;
    return this;
  }

  /**
   * @param {string | function(row, dataTable) : string} title
   * @returns {this}
   */
  setTitle(title) {
    this.title = typeof title === "function" ? title : () => title;
    return this;
  }

  /**
   * @param {function(row, dataTable)} callback
   * @returns {this}
   */
  setCallback(callback) {
    this.callback = callback;
    return this;
  }

  /**
   * @param {string[] | function(row, dataTable) : string[]} loadingGroups
   * @returns {this}
   */
  setLoadingGroups(loadingGroups) {
    this.loadingGroups =
      typeof loadingGroups === "function" ? loadingGroups : () => loadingGroups;
    return this;
  }

  /**
   * @param {boolean | function(row, dataTable) : boolean} disabled
   * @returns {this}
   */
  setDisabled(disabled) {
    this.disabled = typeof disabled === "function" ? disabled : () => disabled;
    return this;
  }

  /**
   * @param {boolean | function(row, dataTable) : boolean} hidden
   * @returns {this}
   */
  setHidden(hidden) {
    this.hidden = typeof hidden === "function" ? hidden : () => hidden;
    return this;
  }
}

export default RowActionConfig;
