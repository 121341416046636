import { useLanguageContext } from "../../../../context/LanguageContext";
import TypePage from "../../../../pages/calamities/type/TypePage";
import { useTypeCreateRouteValidator } from "../../../validators/calamities/types/TypeCreateRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useCalamitiesRouteConfig from "../CalamitiesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCalamityTypeCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/calamities/types/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.calamity_type"),
        },
      ])
    )
    .setValidator(useTypeCreateRouteValidator())
    .setPage(<TypePage />)
    .setParent(useCalamitiesRouteConfig());
}

export default useCalamityTypeCreateRouteConfig;
