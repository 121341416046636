import Select from "../../../main/Select";

function LanguageFormSelectView({ options, value, onChange, disabled }) {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      isDisabled={disabled}
    />
  );
}

export default LanguageFormSelectView;
