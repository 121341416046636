import useHelpTextEditRouteConfig from "../../../routes/configs/helpTexts/HelpTextEditRouteConfig";
import useHelpTextCreateRouteConfig from "../../../routes/configs/helpTexts/HelpTextCreateRouteConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function HelpTextsDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("help_texts");

  //Set the datatable fetch info
  config.setFetchRequest("help_texts");
  config.setFetchParams({ with_help_text_language_data: true });

  //Set the datatable order by
  config.setOrderBy("title");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("title").setLockActive(true),
    useBasicColumnConfig("code"),
    useBasicColumnConfig("path"),
    useBasicColumnConfig("description"),
    useBasicColumnConfig("language.label"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("help_text", useHelpTextEditRouteConfig()),
    useDeleteRowActionConfig("help_text"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("help_text", useHelpTextCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default HelpTextsDataTable;
