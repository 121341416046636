import { useLanguageContext } from "../../../context/LanguageContext";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../context/AuthContext";
import HtmlHelper from "../../../helpers/HtmlHelper";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import HeaderActionConfig from "../main/config/headerAction/HeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function AuditTrailsDataTable() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig(
    `audit_trails_${authContext.auth?.user?.company?.type}`
  );

  //Set the datatable fetch request
  config.setFetchRequest("audit_trails");

  //Set the datatable order by
  config.setOrderBy("created_at");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location_name")
      .setLabel(translate("eva.main.validation.attributes.location_id"))
      .setHidden(authContext.auth?.user?.company?.type === "superadmin"),
    useBasicColumnConfig("user_name").setLabel(
      translate("eva.main.validation.attributes.user_id")
    ),
    useBasicColumnConfig("user_ip"),
    useBasicColumnConfig("action"),
    useBasicColumnConfig("table"),
    useBasicColumnConfig("record_name").setLabel(
      translate("eva.main.validation.attributes.record_id")
    ),
    useBasicColumnConfig("details").setData(
      (row) => HtmlHelper.jsonToHtmlString(JSON.parse(row.details)),
      true
    ),
    useBasicColumnConfig("created_at").setUseFromToDateTime(true),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    new HeaderActionConfig()
      .setIcon(faFileExport)
      .setCallback(handleExport)
      .setTitle(
        translate("eva.main.entities.states.export", [
          {
            key: "entities",
            value: translate("eva.main.entities.audit_trails"),
          },
        ])
      )
      .setLoadingGroups("audit_trails_datatable_export"),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This method exports the audit trails datatable
   */
  async function handleExport() {
    callEvaApi(
      new EvaApiCall("audit_trails/export")
        .setLoadingGroup("audit_trails_datatable_export")
        .setParams(dataTable.filters)
        .setDownloadAs("audit_trails")
    );
  }

  return <DataTable controller={dataTable} />;
}

export default AuditTrailsDataTable;
