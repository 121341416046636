import { useLanguageContext } from "../../../context/LanguageContext";
import FlowPage from "../../../pages/flows/flow/FlowPage";
import { useFlowCreateRouteValidator } from "../../validators/flows/FlowCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useFlowsRouteConfig from "./FlowsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFlowCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/flows/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.flow"),
        },
      ])
    )
    .setValidator(useFlowCreateRouteValidator())
    .setPage(<FlowPage />)
    .setParent(useFlowsRouteConfig());
}

export default useFlowCreateRouteConfig;
