import { useLanguageContext } from "../../../context/LanguageContext";
import RolePage from "../../../pages/roles/role/RolePage";
import { useRoleEditRouteValidator } from "../../validators/roles/RolesEditValidator";
import { RouteConfig } from "../RouteConfig";
import useRolesRouteConfig from "./RolesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useRoleEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/roles/:role/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.role"),
        },
      ])
    )
    .setValidator(useRoleEditRouteValidator())
    .setPage(<RolePage />)
    .setParent(useRolesRouteConfig());
}

export default useRoleEditRouteConfig;
