import styled from "styled-components";

export const StyledDataTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledDataTableHeader = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.large};
  align-items: end;
  justify-content: space-between;
`;

export const StyledHeaderLeft = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: end;
  flex-wrap: wrap;
`;

export const StyledHeader = styled.h2`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.default};
`;

export const StyledFromToDateTime = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledHeaderRight = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: end;
`;

export const StyledSettingsWrapper = styled.div`
  position: relative;
`;

export const StyledSettings = styled.div`
  position: absolute;
  z-index: 1;
  top: ${(props) =>
    `calc(${props.theme.container.size.normal} + ${props.theme.spacing.normal})`};
  right: 0;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.default};
  border: ${(props) => `1px solid ${props.theme.colors.primary.lightest}`};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledSettingsItem = styled.p`
  background-color: ${(props) => props.theme.colors.primary.normal};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.default};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  white-space: nowrap;
  color: ${(props) =>
    `rgba(${props.theme.colors.on.primaryRGB}, ${
      props.$active ? "1" : "0.33"
    })`};
`;

export const StyledTableWrapper = styled.div`
  border-radius: ${(props) => props.theme.radius.default};
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${(props) => props.theme.colors.on.primary};
`;

export const StyledTr = styled.tr`
  thead & {
    background-color: ${(props) => props.theme.colors.primary.darkest};
  }

  tbody & {
    &:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.primary.normal};
    }
    &:nth-child(even) {
      background-color: ${(props) => props.theme.colors.primary.light};
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.primary.darkest};
    }
  }
`;

export const StyledTh = styled.th`
  text-align: left;
  border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
  border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};
  white-space: nowrap;
  color: ${(props) =>
    props.$highlight
      ? props.theme.colors.accent.normal
      : props.theme.colors.on.primary};

  &:last-child {
    border-right-width: 0px;
  }
  &:first-child {
    border-left-width: 0px;
  }
`;

export const StyledThContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
`;

export const StyledThActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;
`;

export const StyledTd = styled.td`
  text-align: left;
  border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
  border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};

  &:last-child {
    border-right-width: 0px;
  }
  &:first-child {
    border-left-width: 0px;
  }
`;

export const StyledTdContent = styled.div`
  max-height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledRowActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;
`;

export const StyledDataTableFooter = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.large};
  align-items: start;
  justify-content: space-between;
`;

export const StyledPaginationActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: start;
`;
