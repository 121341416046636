import { StyledInput } from "../../../styles/InputStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledFlexLayout } from "../../../styles/LayoutStyles";
import { useLanguageContext } from "../../../context/LanguageContext";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import MultiSelect from "../../../components/select/main/multiSelect/MultiSelect";

function RolePageView({
  roleData,
  permissions,
  onNameChange,
  onPermissionsChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="role_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"role_page_save"}
      >
        <StyledFlexLayout direction="column">
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            required
            error={EVAApiHelper.getResponseError("name", submitError)}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={roleData.name}
              onChange={onNameChange}
            />
          </InputWrapper>

          {/* Allergen select */}
          <InputWrapper
            label={translate("eva.main.entities.permissions")}
            error={EVAApiHelper.getResponseErrorArray(
              "permissions",
              submitError,
              (index) => permissions[index].name
            )}
          >
            <MultiSelect
              options={permissions.map((permission) => {
                return { value: permission.id, label: permission.name };
              })}
              values={roleData.permissions}
              onChange={onPermissionsChange}
            />
          </InputWrapper>
        </StyledFlexLayout>
      </FormContainer>
    </OverlayLoader>
  );
}

export default RolePageView;
