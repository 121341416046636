import FrontofficePage from "../../pages/frontoffice/FrontofficePage";
import { useFrontofficeRouteValidator } from "../validators/FrontofficeRouteValidator";
import { RouteConfig } from "./RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFrontofficeRouteConfig() {
  return new RouteConfig()
    .setPath("/frontoffice")
    .setValidator(useFrontofficeRouteValidator())
    .setPage(<FrontofficePage />)
}

export default useFrontofficeRouteConfig;