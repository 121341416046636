import { useState } from "react";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import useCustomEffect from "../../../hooks/useCustomEffect";
import ColumnConfig from "../main/config/column/ColumnConfig";
import { useLanguageContext } from "../../../context/LanguageContext";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";

function VisitorsDataTable() {
  const callEvaApi = useEvaApi();
  const { translateEntity, translate } = useLanguageContext();
  const [fields, setFields] = useState([]);

  //Create the datatable config
  const config = new DataTableConfig("visitors");

  //Set the datatable fetch info
  config.setFetchRequest("visitors");
  config.setFetchParams({
    with_visitor_fields_data: true,
    with_visitor_visitor_type_data: true,
  });

  //Set default order
  config.setOrderBy("id");
  config.setOrderDirection("asc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("visitor_type.title")
      .setData((row) => translateEntity(row.visitor_type, "title"))
      .setOrderable(false)
      .setSearchable(false),

    ...fields.map((field) =>
      new ColumnConfig()
        .setKey(`fields.${field.id}`)
        .setLabel(translateEntity(field, "label"))
        .setData((row) => getFieldColumnData(field, row))
        .setOrderable(false)
        .setSearchable(false)
    ),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([useDeleteRowActionConfig("visitor")]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This effect will fetch the fields from the API
   */
  useCustomEffect(() => {
    fetchFields();
  });

  /**
   * This method will fetch the fields from the API
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup(config.getFetchKey())
        .setParams({ with_field_options_data: true })
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
    );
  }

  /**
   * This method will return the column data for the given field and row
   * @param {object} field
   * @param {object} row
   * @returns {any}
   */
  function getFieldColumnData(field, row) {
    // Find the field data for the given field
    const fieldData = row.fields?.find((f) => f.field === field.id);

    // If the field is a select, return the translation of the selected option
    if (field.type === "select") {
      const option = field.options.find(
        (o) => o.id === fieldData?.field_option
      );
      return option ? translateEntity(option, "label") : "";
    }
    // If the field is a boolean, return the translation of the value
    else if (field.type === "boolean") {
      return fieldData?.value
        ? translate("eva.main.general.yes")
        : translate("eva.main.general.no");
    }

    // Otherwise, return the value of the field
    return fieldData?.value ?? "";
  }

  return <DataTable controller={dataTable} />;
}

export default VisitorsDataTable;
