import FileHelper from "../../../helpers/FileHelper";
import FileInputView from "./FileInputView";

function FileInput({ values, onChange, accept, multiple = false }) {
  //Make sure the values are an array
  values = Array.isArray(values) ? values : [...(values ? [values] : [])];

  /**
   * This method will handle the file selection.
   */
  async function onSelect() {
    const newFiles = await FileHelper.select(accept, multiple);
    if (onChange && newFiles) {
      onChange(multiple ? [...values, ...newFiles] : newFiles);
    }
  }

  /**
   * This method will handle the clear functionality.
   */
  function onRemove(value) {
    const newValues = [...values];
    const index = newValues.indexOf(value);
    if (index !== -1) {
      newValues.splice(index, 1);
      if (onChange) {
        onChange(multiple ? newValues : null);
      }
    }
  }

  /**
   * This method will return the file name of the given file
   * @param {File|string|null} file
   * @returns {string}
   */
  function getFileName(file) {
    if (file instanceof File) {
      return file.name;
    } else if (typeof file === "string" && file.includes("/")) {
      return file.split("/").pop();
    } else {
      return file;
    }
  }

  return (
    <FileInputView
      values={values}
      onSelect={onSelect}
      onRemove={onRemove}
      getFileName={getFileName}
    />
  );
}

export default FileInput;
