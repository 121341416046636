import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import ContactPageView from "./ContactPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function ContactPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { contact } = useParams();
  const authContext = useAuthContext();
  const [contactData, setContactData] = useState({
    name: "",
    phone_number: "",
    location_id: authContext.auth.location,
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    if (contact) {
      fetchContactData();
    }
  });

  /**
   * This method will fetch the user data from the API
   */
  async function fetchContactData() {
    await callEvaApi(
      new EvaApiCall(`contacts/${contact}`)
        .setLoadingGroup("contact_page_fetch")
        .setOnSuccess((response) => {
          setContactData({
            name: response.data.data.name,
            location_id: response.data.data.location,
            phone_number: response.data.data.phone_number,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the location on input change
   * @param {any} value
   */
  function onLocationIdChange(value) {
    setContactData({ ...contactData, location_id: value });
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setContactData({ ...contactData, name: e.target.value });
  }

  /**
   * This method will update the phone number on input change
   * @param {event} e
   */
  function onPhoneNumberchange(e) {
    setContactData({ ...contactData, phone_number: e.target.value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(contact ? `contacts/${contact}` : "contacts")
        .setLoadingGroup("contact_page_save")
        .setMethod(contact ? "PUT" : "POST")
        .setData(contactData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <ContactPageView
      contact={contact}
      contactData={contactData}
      onLocationIdChange={onLocationIdChange}
      onNameChange={onNameChange}
      onPhoneNumberchange={onPhoneNumberchange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default ContactPage;
