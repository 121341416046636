import { useLanguageContext } from "../../../context/LanguageContext";
import CompanyLanguagePage from "../../../pages/company_languages/company_language/CompanyLanguagePage";
import { useCompanyLanguageCreateRouteValidator } from "../../validators/companyLanguages/CompanyLanguageCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useCompanyLanguagesRouteConfig from "./CompanyLanguagesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCompanyLanguageCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/company_languages/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.company_language"),
        },
      ])
    )
    .setValidator(useCompanyLanguageCreateRouteValidator())
    .setPage(<CompanyLanguagePage />)
    .setParent(useCompanyLanguagesRouteConfig());
}

export default useCompanyLanguageCreateRouteConfig;
