import TerminalsDataTable from "../../../components/dataTable/tables/TerminalsDataTable";
import { StyledFlexLayout } from "../../../styles/LayoutStyles";
import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { StyledRoundedOverflowXWrapper } from "../../../styles/WrapperStyles";
import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledTextButton } from "../../../styles/ButtonStyles";
import HiddenLoader from "../../../components/loader/HiddenLoader/HiddenLoader";

function TerminalsPageView({
  terminalUserData,
  showPasswordEdit,
  onShowPasswordEditChange,
  submitError,
  handleSubmit,
  onPasswordChange,
}) {
  const { translate } = useLanguageContext();
  const theme = useTheme();

  return (
    <StyledFlexLayout
      style={{ gap: theme.spacing.large, flexDirection: "column" }}
    >
      <OverlayLoader groups="terminals_page_user_fetch">
        <StyledRoundedOverflowXWrapper>
          <table>
            <thead>
              <tr>
                <th>{translate("eva.main.validation.attributes.email")}</th>
                <th>{translate("eva.main.validation.attributes.password")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{terminalUserData.email}</td>
                <td>
                  {showPasswordEdit ? (
                    <InputWrapper
                      error={EVAApiHelper.getResponseError(
                        "password",
                        submitError
                      )}
                    >
                      <StyledInput
                        type="password"
                        width="100%"
                        value={terminalUserData.password}
                        onChange={onPasswordChange}
                        placeholder={translate(
                          "eva.main.validation.placeholders.password"
                        )}
                      />
                    </InputWrapper>
                  ) : (
                    translate("eva.main.validation.placeholders.password")
                  )}
                </td>
                <td style={{ width: 0 }}>
                  {showPasswordEdit ? (
                    <HiddenLoader groups="terminals_page_user_submit">
                      <StyledFlexLayout>
                        <StyledTextButton
                          onClick={() => onShowPasswordEditChange(false)}
                          title={translate("eva.main.general.cancel")}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </StyledTextButton>

                        <StyledTextButton
                          onClick={handleSubmit}
                          title={translate("eva.main.general.save")}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </StyledTextButton>
                      </StyledFlexLayout>
                    </HiddenLoader>
                  ) : (
                    <StyledTextButton
                      onClick={() => onShowPasswordEditChange(true)}
                      title={translate("eva.main.entities.states.edit", [
                        {
                          key: "entities",
                          value: translate("eva.main.entities.terminal"),
                        },
                      ])}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </StyledTextButton>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </StyledRoundedOverflowXWrapper>
      </OverlayLoader>

      <TerminalsDataTable />
    </StyledFlexLayout>
  );
}

export default TerminalsPageView;
