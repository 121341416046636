import { useParams } from "react-router-dom";
import { useRouteContext } from "../../../context/RouteContext";
import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import VisitorTypePageView from "./VisitorTypePageView";
import useEntityTranslations from "../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function VisitorTypePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { visitor_type } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [flows, setFlows] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [fields, setFields] = useState([]);
  const [visitorTypeData, setVisitorTypeData] = useState({
    name: "",
    order: 0,
    earliest_time: "",
    latest_time: "",
    print_count: 0,
    returning: false,
    requires_approval: false,
    validity_fields: [],
    translations: [],
    department_id: null,
    flow_id: null,
    layout_id: null,
  });

  const translationsController = useEntityTranslations(
    visitorTypeData.translations,
    onTranslationsChange
  );

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (visitor_type) {
      fetchVisitorTypeData();
    }

    fetchDepartments();
    fetchFields();
    fetchFlows();
    fetchLayouts();
  });

  /**
   * This method will fetch the fields data from the API
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup("visitor_type_page_fetch")
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the flows data from the API
   */
  async function fetchFlows() {
    await callEvaApi(
      new EvaApiCall("flows")
        .setLoadingGroup("visitor_type_page_fetch")
        .setOnSuccess((response) => {
          setFlows(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the layouts data from the API
   */
  async function fetchLayouts() {
    await callEvaApi(
      new EvaApiCall("layouts")
        .setLoadingGroup("visitor_type_page_fetch")
        .setOnSuccess((response) => {
          setLayouts(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the departments data from the API
   */
  async function fetchDepartments() {
    await callEvaApi(
      new EvaApiCall("departments")
        .setLoadingGroup("visitor_type_page_fetch")
        .setOnSuccess((response) => {
          setDepartments(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the visitor type data from the API
   */
  async function fetchVisitorTypeData() {
    await callEvaApi(
      new EvaApiCall(`visitor_types/${visitor_type}`)
        .setLoadingGroup("visitor_type_page_fetch")
        .setOnSuccess((response) => {
          setVisitorTypeData({
            name: response.data.data.name,
            order: response.data.data.order,
            earliest_time: response.data.data.earliest_time,
            latest_time: response.data.data.latest_time,
            validity_fields: response.data.data.validity_fields,
            print_count: response.data.data.print_count,
            returning: response.data.data.returning,
            requires_approval: response.data.data.requires_approval,
            department_id: response.data.data.department,
            flow_id: response.data.data.flow,
            layout_id: response.data.data.layout,
            translations: response.data.data.translations.map(
              (translation) => ({
                language_id: translation.language,
                title: translation.title,
                message: translation.message,
              })
            ),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setVisitorTypeData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will update the department on input change
   * @param {any} value
   */
  function onDepartmentChange(value) {
    setVisitorTypeData({ ...visitorTypeData, department_id: value });
  }

  /**
   * This method will update the flow on input change
   * @param {any} value
   */
  function onFlowChange(value) {
    setVisitorTypeData({ ...visitorTypeData, flow_id: value });
  }

  /**
   * This method will update the layout on input change
   * @param {any} value
   */
  function onLayoutChange(value) {
    setVisitorTypeData({ ...visitorTypeData, layout_id: value });
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setVisitorTypeData({ ...visitorTypeData, name: e.target.value });
  }

  /**
   * This method will handle the order change
   * @param {event} e
   */
  function onOrderChange(e) {
    setVisitorTypeData({ ...visitorTypeData, order: e.target.value });
  }

  /**
   * This method will handle the earliest time change
   * @param {event} e
   */
  function onEarliestTimeChange(e) {
    setVisitorTypeData({ ...visitorTypeData, earliest_time: e.target.value });
  }

  /**
   * This method will handle the latest time change
   * @param {event} e
   */
  function onLatestTimeChange(e) {
    setVisitorTypeData({ ...visitorTypeData, latest_time: e.target.value });
  }

  /**
   * This method will handle the print count change
   * @param {event} e
   */
  function onPrintCountChange(e) {
    setVisitorTypeData({ ...visitorTypeData, print_count: e.target.value });
  }

  /**
   * This method will handle the returning change
   * @param {any} value
   */
  function onReturningChange(value) {
    setVisitorTypeData({ ...visitorTypeData, returning: value });
  }

  /**
   * This method will handle the requires approval change
   * @param {any} value
   */
  function onRequiresApprovalChange(value) {
    setVisitorTypeData({ ...visitorTypeData, requires_approval: value });
  }

  /**
   * This method will update the fields on input change
   * @param {array} values
   */
  function onValidityFieldsChange(values) {
    setVisitorTypeData({
      ...visitorTypeData,
      validity_fields: values,
    });
  }

  /**
   * This method will handle the cancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit functionality
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        visitor_type ? `visitor_types/${visitor_type}` : `visitor_types`
      )
        .setLoadingGroup("visitor_type_page_submit")
        .setMethod(visitor_type ? "PUT" : "POST")
        .setData(visitorTypeData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <VisitorTypePageView
      visitor_type={visitor_type}
      visitorTypeData={visitorTypeData}
      departments={departments}
      fields={fields}
      flows={flows}
      layouts={layouts}
      onDepartmentChange={onDepartmentChange}
      onFlowChange={onFlowChange}
      onLayoutChange={onLayoutChange}
      onNameChange={onNameChange}
      onOrderChange={onOrderChange}
      onEarliestTimeChange={onEarliestTimeChange}
      onLatestTimeChange={onLatestTimeChange}
      onPrintCountChange={onPrintCountChange}
      onReturningChange={onReturningChange}
      onRequiresApprovalChange={onRequiresApprovalChange}
      onValidityFieldsChange={onValidityFieldsChange}
      translationsController={translationsController}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default VisitorTypePage;
