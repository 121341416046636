import { useRef } from "react";
import { useDraggableContext } from "../../../../../../context/DraggableContext";
import FlowPageFieldsFormView from "./FlowPageFieldsFormView";

function FlowPageFieldsForm({ flowData, setFlowData, fields, submitError }) {
  const draggableContext = useDraggableContext();
  const lastDragSwitchTime = useRef(0);

  /**
   * This method will handle the add row event.
   */
  function onAddRow() {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].fields =
        newSteps[prevFlowData.selectedStep].fields || [];
      newSteps[prevFlowData.selectedStep].fields.push([]);

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the delete row event.
   * @param {int} index
   */
  function onDeleteRow(index) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].fields.splice(index, 1);

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the add field event.
   * @param {int} rowIndex
   * @param {int} fieldId
   */
  function onAddField(rowIndex, fieldId) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].fields[rowIndex].push({
        field_id: fieldId,
        required_terminal: false,
        required_backoffice: false,
        clear: false,
      });

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the delete field event.
   * @param {int} rowIndex
   * @param {int} fieldIndex
   */
  function onDeleteField(rowIndex, fieldIndex) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].fields[rowIndex].splice(
        fieldIndex,
        1
      );

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the field options change event.
   * @param {int} rowIndex
   * @param {int} fieldIndex
   * @param {int} values
   */
  function onFieldOptionsChange(rowIndex, fieldIndex, values) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].fields[rowIndex][fieldIndex] = {
        ...newSteps[prevFlowData.selectedStep].fields[rowIndex][fieldIndex],
        required_terminal: values.includes("required_terminal"),
        required_backoffice: values.includes("required_backoffice"),
        clear: values.includes("clear"),
      };

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the drag enter event.
   * @param {string} dragged
   * @param {string} target
   */
  function onDragEnter(dragged, target) {
    //Dont do anything if the last drag switch was less than 100ms ago.
    if (Date.now() - lastDragSwitchTime.current < 100) {
      return;
    }

    //Get the coords of the dragged and target elements.
    const draggedCoords = dragged.split("_")[2].split(",");
    const targetCoords = target.split("_")[2].split(",");

    //Re-insert the dragged element to the target position.
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];

      //Row drag
      if (draggedCoords.length === 1) {
        const draggedRow =
          newSteps[prevFlowData.selectedStep].fields[draggedCoords[0]];
        newSteps[prevFlowData.selectedStep].fields.splice(draggedCoords[0], 1);
        newSteps[prevFlowData.selectedStep].fields.splice(
          targetCoords[0],
          0,
          draggedRow
        );
      }
      //Field drag
      else {
        const draggedField =
          newSteps[prevFlowData.selectedStep].fields[draggedCoords[0]][
            draggedCoords[1]
          ];
        newSteps[prevFlowData.selectedStep].fields[draggedCoords[0]].splice(
          draggedCoords[1],
          1
        );

        const targetFieldIndex =
          targetCoords.length === 1 ? 0 : targetCoords[1];
        newSteps[prevFlowData.selectedStep].fields[targetCoords[0]].splice(
          targetFieldIndex,
          0,
          draggedField
        );
      }

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });

    //Make sure to set the dragged id to the target id.
    draggableContext.updateDragged(
      targetCoords.length < draggedCoords.length
        ? `${target},0`
        : targetCoords.length > draggedCoords.length
        ? target.split(",")[0]
        : target
    );

    //Update the last drag switch time.
    lastDragSwitchTime.current = Date.now();
  }

  return (
    <FlowPageFieldsFormView
      flowData={flowData}
      fields={fields}
      onAddRow={onAddRow}
      onDeleteRow={onDeleteRow}
      onAddField={onAddField}
      onDeleteField={onDeleteField}
      onFieldOptionsChange={onFieldOptionsChange}
      onDragEnter={onDragEnter}
      submitError={submitError}
    />
  );
}

export default FlowPageFieldsForm;
