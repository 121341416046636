import { useState } from "react";
import { useParams } from "react-router-dom";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useAuthContext } from "../../../../context/AuthContext";
import OrderPageView from "./OrderPageView";
import { useParameterContext } from "../../../../context/ParameterContext";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function OrderPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { order } = useParams();
  const authContext = useAuthContext();
  const parameterContext = useParameterContext();
  const { translate } = useLanguageContext();
  const [showOrderOverview, setShowOrderOverview] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [orderData, setOrderData] = useState({
    employee_id: null,
    dishes: [],
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    setShowOrderOverview(false);
    setOrderData({
      employee_id: null,
      dishes: [],
    });

    fetchEmployees();
    fetchDishes();

    if (order) {
      fetchOrderData();
    }
  });

  /**
   * Effect to reset the submit errors on screen change
   */
  useCustomEffect(() => {
    setSubmitError(null);
  }, [showOrderOverview]);

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("canteen_order_page_fetch")
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the dishes from the API
   */
  async function fetchDishes() {
    await callEvaApi(
      new EvaApiCall("canteen/dishes")
        .setLoadingGroup("canteen_order_page_fetch")
        .setParams({ scheduled_only: true, with_dish_allergens_data: true })
        .setOnSuccess((response) => {
          if (response.data.data.records.length <= 0) {
            routeContext.back();
          }
          setDishes(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the orderData from the API
   */
  async function fetchOrderData() {
    await callEvaApi(
      new EvaApiCall(`canteen/orders/${order}`)
        .setLoadingGroup("canteen_order_page_fetch")
        .setParams({ with_order_order_dishes_data: true })
        .setOnSuccess((response) => {
          if (response.data.data.status !== "pending") {
            routeContext.back();
          }

          const dishes = response.data.data.order_dishes.map(
            (orderDish) => orderDish.dish
          );

          setOrderData({
            employee_id: response.data.data.employee,
            dishes: dishes,
            storedDishes: dishes,
          });
          setShowOrderOverview(true);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Determines how many times the dish with the given id has been ordered
   * @param {integer} id
   * @returns {integer}
   */
  function getOrderDishCount(id) {
    return orderData.dishes.filter((dish) => dish === id).length;
  }

  /**
   * Adds the dish with the given id to the order
   * @param {integer} id
   */
  function addDishToOrder(id) {
    //Don't add more if the order permissions is at it's max dish count
    const maxDishesParam = parameterContext.getParameter(
      "canteen.max.order.dishes"
    );
    if (
      maxDishesParam &&
      maxDishesParam <= orderData.dishes.length &&
      !authContext.hasPermission("canteen.orders.create.unlimited.dishes")
    ) {
      return;
    }

    const newDishes = orderData.dishes.slice();
    const ordered = orderData.dishes.filter((dish) => dish === id).length;

    if (getRealRemainingDishCount(id) > ordered) {
      newDishes.push(id);
    }

    setOrderData({ ...orderData, dishes: newDishes });
  }

  /**
   * This method will return how many of the given dish are still remaining, allready ordered dishes are taken into account here.
   * @param {int} id
   * @returns {int} remaining
   */
  function getRealRemainingDishCount(id) {
    let remaining = dishes.find((dish) => dish.id === id).remaining;
    if (orderData.storedDishes) {
      remaining += orderData.storedDishes.filter((dish) => dish === id).length;
    }
    return remaining;
  }

  /**
   * Removes the dish with the given id from the order
   * @param {integer} id
   */
  function removeDishFromOrder(id) {
    const newDishes = orderData.dishes.slice();
    const arrayIndex = newDishes.indexOf(id);
    if (arrayIndex > -1) {
      newDishes.splice(arrayIndex, 1);
    }
    setOrderData({ ...orderData, dishes: newDishes });

    if (showOrderOverview && newDishes.length <= 0) {
      setShowOrderOverview(false);
    }
  }

  /**
   * Determines which dish objects have been ordered at least once
   * @returns {array}
   */
  function getOrderedDishes() {
    return dishes.filter((dish) => orderData.dishes.includes(dish.id));
  }

  /**
   * Calculates the price of this order
   * @returns {decimal}
   */
  function getOrderPrice() {
    let price = 0;
    getOrderedDishes().forEach(
      (dish) => (price += getOrderDishCount(dish.id) * dish.price)
    );

    return price;
  }

  /**
   * This method will get the employee for which this order will be
   * @returns {object}
   */
  function getOrderEmployee() {
    const orderEmployeeID =
      orderData.employee_id !== ""
        ? orderData.employee_id
        : authContext.auth.user.employee &&
          typeof authContext.auth.user.employee === "object"
        ? authContext.auth.user.employee.id
        : authContext.auth.user.employee;

    const orderEmployee = employees.find(
      (employee) => orderEmployeeID === employee.id
    );

    return orderEmployee;
  }

  /**
   * This method will return if the order employee is allergic to this order
   * @returns {boolean}
   */
  function isAllergicToOrder() {
    const orderEmployee = getOrderEmployee();
    if (!orderEmployee) {
      return false;
    }

    let allergic = false;

    orderData.dishes.forEach((orderDish) => {
      const dish = dishes.find((dish) => dish.id === orderDish);
      dish.allergens.forEach((dishAllergen) => {
        if (
          orderEmployee.allergens.find(
            (employeeAllergen) => employeeAllergen === dishAllergen.id
          )
        ) {
          allergic = true;
        }
      });
    });

    return allergic;
  }

  /**
   * This method will update the employee id on input change
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setOrderData({ ...orderData, employee_id: value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    setShowOrderOverview(false);
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    if (
      isAllergicToOrder() &&
      !window.confirm(translate("eva.main.questions.allergic_to_order"))
    ) {
      return;
    }

    await callEvaApi(
      new EvaApiCall(order ? `canteen/orders/${order}` : "canteen/orders")
        .setLoadingGroup("canteen_order_page_save")
        .setMethod(order ? "PUT" : "POST")
        .setData(orderData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <OrderPageView
      orderData={orderData}
      dishes={dishes}
      employees={employees}
      showOrderOverview={showOrderOverview}
      setShowOrderOverview={setShowOrderOverview}
      getRealRemainingDishCount={getRealRemainingDishCount}
      getOrderDishCount={getOrderDishCount}
      addDishToOrder={addDishToOrder}
      removeDishFromOrder={removeDishFromOrder}
      getOrderPrice={getOrderPrice}
      onEmployeeIdChange={onEmployeeIdChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default OrderPage;
