import { useLanguageContext } from "../../../context/LanguageContext";
import ButtonPage from "../../../pages/buttons/button/ButtonPage";
import { useButtonEditRouteValidator } from "../../validators/buttons/ButtonEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useButtonsRouteConfig from "./ButtonsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useButtonEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/buttons/:button/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.button"),
        }
      ])
    )
    .setValidator(useButtonEditRouteValidator())
    .setPage(<ButtonPage />)
    .setParent(useButtonsRouteConfig());
}

export default useButtonEditRouteConfig;