import { useLanguageContext } from "../../../../context/LanguageContext";
import { useAuthContext } from "../../../../context/AuthContext";
import useFieldConditionEditRouteConfig from "../../../../routes/configs/fields/conditions/FieldConditionEditRouteConfig";
import useFieldConditionCreateRouteConfig from "../../../../routes/configs/fields/conditions/FieldConditionCreateRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useBooleanColumnConfig from "../../main/config/column/default/BooleanColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function FieldConditionsDataTable({ fieldId }) {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("field_conditions");
  config.setHeader(translate("eva.main.entities.field_conditions"));

  //Set the datatable fetch info
  config.setFetchRequest(`fields/${fieldId}/conditions`);

  //Set the datatable order by
  config.setOrderBy("created_at");
  config.setOrderDirection("desc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("condition.translation")
      .setLockActive(true)
      .setData((row) => translate(`eva.main.conditions.${row.condition}`)),
    useBasicColumnConfig("value").setLockActive(true),
    useBooleanColumnConfig("active"),
    useBooleanColumnConfig("stop_registration"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig(
      "field_condition",
      useFieldConditionEditRouteConfig()
    ),
    useDeleteRowActionConfig("field_condition").setDisabled(
      () => !authContext.hasPermission("fields.delete")
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "field_condition",
      useFieldConditionCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default FieldConditionsDataTable;
