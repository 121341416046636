import { useAuthContext } from "../../../../context/AuthContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import useCalamityTypeCreateRouteConfig from "../../../../routes/configs/calamities/types/CalamityTypeCreateRouteConfig";
import useCalamityTypeEditRouteConfig from "../../../../routes/configs/calamities/types/CalamityTypeEditRouteConfig";
import DataTable, { useDataTable } from "../../main/DataTable";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";

function CalamityTypesDataTable() {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("calamity_types");

  //Set the datatable fetch info
  config.setFetchRequest("calamity_types");

  //Set the datatable order by
  config.setOrderBy("order");
  config.setOrderDirection("asc");

  //Set the datatable header
  config.setHeader(translate("eva.main.entities.calamity_types"));

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("order"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("calamity_type", useCalamityTypeEditRouteConfig()),
    useDeleteRowActionConfig("calamity_type").setDisabled(
      (row) => !authContext.hasPermission("calamities.update")
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "calamity_type",
      useCalamityTypeCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default CalamityTypesDataTable;
