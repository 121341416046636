import { useLanguageContext } from "../../../context/LanguageContext";
import LanguagesPage from "../../../pages/languages/index/LanguagesPage";
import { useLanguagesRouteValidator } from "../../validators/LanguagesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useLanguagesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/languages")
    .setLabel(translate("eva.main.entities.languages"))
    .setValidator(useLanguagesRouteValidator())
    .setPage(<LanguagesPage />);
}

export default useLanguagesRouteConfig;
