import { useNotFoundRouteValidator } from "../validators/NotFoundRouteValidator";
import { RouteConfig } from "./RouteConfig";

function useNotFoundRouteConfig() {
  return new RouteConfig()
    .setPath("*")
    .setValidator(useNotFoundRouteValidator());
}

export default useNotFoundRouteConfig;
