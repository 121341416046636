import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import TinyEditor from "../../../components/tinyEditor/TinyEditor";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";

function AnnouncementPageView({
  descriptionController,
  announcementData,
  onTitleChange,
  onActiveFromChange,
  onActiveUntilChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="announcement_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"announcement_page_save"}
      >
        <InputWrapper
          label={translate("eva.main.validation.attributes.title")}
          error={EVAApiHelper.getResponseError("title", submitError)}
          required
        >
          <StyledInput
            type="text"
            placeholder={translate("eva.main.validation.attributes.title")}
            value={announcementData.title}
            onChange={onTitleChange}
          />
        </InputWrapper>

        <StyledFlexLayout margin={`${theme.spacing.normal} 0px 0px 0px`}>
          <StyledFlexLayoutItem>
            <InputWrapper
              label={translate("eva.main.validation.attributes.active_from")}
              error={EVAApiHelper.getResponseError("active_from", submitError)}
              required
            >
              <StyledInput
                type="datetime-local"
                value={announcementData.active_from}
                onChange={onActiveFromChange}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>

          <StyledFlexLayoutItem>
            <InputWrapper
              label={translate("eva.main.validation.attributes.active_until")}
              error={EVAApiHelper.getResponseError("active_until", submitError)}
              required
            >
              <StyledInput
                type="datetime-local"
                id="active_until"
                name="active_until"
                value={announcementData.active_until}
                onChange={onActiveUntilChange}
              />
            </InputWrapper>
          </StyledFlexLayoutItem>
        </StyledFlexLayout>

        <InputWrapper
          margin={`${theme.spacing.normal} 0px`}
          label={translate("eva.main.validation.attributes.description")}
          error={EVAApiHelper.getResponseError("description", submitError)}
          required
        >
          <TinyEditor controller={descriptionController} />
        </InputWrapper>
      </FormContainer>
    </OverlayLoader>
  );
}

export default AnnouncementPageView;
