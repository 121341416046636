import { useRef, useState } from "react";
import { EDGE_OFFSET, getArrowPath, getEdgePath } from "./FlowChartEdgePaths";
import FlowChartEdgeView from "./FlowChartEdgeView";
import useCustomEffect from "../../../hooks/useCustomEffect";

function FlowChartEdge({ edge, nodeRefs, zoomPanController }) {
  edge.fromPort = edge.fromPort || "bottom";
  edge.toPort = edge.toPort || "top";

  const fromElement = nodeRefs[`FLOW_CHART_NODE_${edge.from}`];
  const toElement = nodeRefs[`FLOW_CHART_NODE_${edge.to}`];

  const [svgPositioning, setSvgPositioning] = useState(null);
  const [edgePath, setEdgePath] = useState(null);
  const [arrowPath, setArrowPath] = useState(null);
  const [edgePathCenter, setEdgePathCenter] = useState(null);

  const svgRef = useRef(null);
  const pathRef = useRef(null);

  /**
   * UseEffect for calculating the svg positioning when the fromElement or toElement changes
   */
  useCustomEffect(() => {
    setSvgPositioning(getSvgPositioning());
  }, [fromElement, toElement]);

  /**
   * UseEffect for calculating the edge path and arrow path when the svg positioning changes
   */
  useCustomEffect(() => {
    setEdgePath(
      getEdgePath(
        getPortPosition(fromElement, edge.fromPort),
        edge.fromPort,
        getPortPosition(toElement, edge.toPort),
        edge.toPort
      )
    );

    setArrowPath(
      getArrowPath(getPortPosition(toElement, edge.toPort), edge.toPort)
    );
  }, [svgPositioning, edge.fromPort, edge.toPort]);

  /**
   * UseEffect for calculating the edge path center when the edge path changes
   */
  useCustomEffect(() => {
    setEdgePathCenter(
      pathRef.current && edgePath
        ? pathRef.current?.getPointAtLength(
            pathRef.current?.getTotalLength() / 2
          )
        : null
    );
  }, [edgePath]);

  /**
   * UseEffect for correctly repositioning the edge when the nodes are resized or moved
   */
  useCustomEffect(() => {
    function refreshSvgPositioning() {
      setSvgPositioning(getSvgPositioning());
    }

    //Observe the resize events of the elements
    const resizeObserver = new ResizeObserver(refreshSvgPositioning);
    if (fromElement) {
      resizeObserver.observe(fromElement);
    }
    if (toElement) {
      resizeObserver.observe(toElement);
    }

    //observe the transform attribute in the style of the elements
    const mutationObserver = new MutationObserver(refreshSvgPositioning);
    if (fromElement) {
      mutationObserver.observe(fromElement, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
    if (toElement) {
      mutationObserver.observe(toElement, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [fromElement, toElement]);

  /**
   * This method will return the position of the port.
   * @param {JSX.Element} element
   * @param {string} port
   * @returns {object}
   */
  function getPortPosition(element, port) {
    if (!element || !svgPositioning) return null;

    const rect = getNormalizedRect(element);
    rect.x -= svgPositioning.x;
    rect.y -= svgPositioning.y;

    switch (port) {
      case "top":
        return {
          x: rect.x + rect.width / 2,
          y: rect.y,
        };
      case "bottom":
        return {
          x: rect.x + rect.width / 2,
          y: rect.y + rect.height,
        };
      case "left":
        return {
          x: rect.x,
          y: rect.y + rect.height / 2,
        };
      case "right":
        return {
          x: rect.x + rect.width,
          y: rect.y + rect.height / 2,
        };
      default:
        return;
    }
  }

  /**
   * This method will return the positioning of the svg.
   * @returns {object}
   */
  function getSvgPositioning() {
    if (!fromElement || !toElement) return null;

    //Get the normalized rects
    const fromRect = getNormalizedRect(fromElement);
    const toRect = getNormalizedRect(toElement);

    //Determine the min values
    const minX = Math.min(fromRect.x, toRect.x) - EDGE_OFFSET;
    const minY = Math.min(fromRect.y, toRect.y) - EDGE_OFFSET;

    //Determine the max values
    const maxX =
      Math.max(fromRect.x + fromRect.width, toRect.x + toRect.width) +
      EDGE_OFFSET;
    const maxY =
      Math.max(fromRect.y + fromRect.height, toRect.y + toRect.height) +
      EDGE_OFFSET;

    //Return the positioning
    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  /**
   * This method will return the normalized rect of the element.
   * @param {JSX.Element} element
   * @returns {object}
   */
  function getNormalizedRect(element) {
    if (!element) return null;

    const rect = element.getBoundingClientRect();
    const editorRect =
      zoomPanController.editorContentRef.current.getBoundingClientRect();

    return {
      x: rect.x - editorRect.x,
      y: rect.y - editorRect.y,
      width: rect.width,
      height: rect.height,
    };
  }

  return (
    <FlowChartEdgeView
      fromElement={fromElement}
      toElement={toElement}
      edge={edge}
      svgPositioning={svgPositioning}
      edgePath={edgePath}
      edgePathCenter={edgePathCenter}
      arrowPath={arrowPath}
      svgRef={svgRef}
      pathRef={pathRef}
    />
  );
}

export default FlowChartEdge;
