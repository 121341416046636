import { RouteConfig } from "./RouteConfig";
import { useLanguageContext } from "../../context/LanguageContext";
import { useAuditTrailsRouteValidator } from "../validators/AuditTrailsRouteValidator";
import AuditTrailsPage from "../../pages/audit_trails/index/AuditTrailsPage";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useAuditTrailsRouteConfig() {
  const { translate } = useLanguageContext();
  return new RouteConfig()
    .setPath("/dashboard/audit_trails")
    .setLabel(translate("eva.main.entities.audit_trails"))
    .setValidator(useAuditTrailsRouteValidator())
    .setPage(<AuditTrailsPage />);
}

export default useAuditTrailsRouteConfig;
