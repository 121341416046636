import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OrdersPreparePageView from "./OrdersPreparePageView";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function OrdersPreparePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const navigate = useNavigate();
  const [prepareList, setPrepareList] = useState([]);

  /**
   * Initializing the component
   */
  useCustomEffect(() => {
    fetchOrders();
  });

  /**
   * This method will fetch the orders from the API
   */
  async function fetchOrders() {
    await callEvaApi(
      new EvaApiCall("canteen/orders")
        .setLoadingGroup("canteen_orders_prepare_fetch")
        .setParams({
          with_order_order_dishes_data: true,
          with_order_dish_dish_data: true,
          with_order_employee_data: true,
        })
        .setOnSuccess((response) => {
          if (response.data.data.records.length > 0) {
            convertOrders(response.data.data.records);
          } else {
            routeContext.back();
          }
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will convert the given orders into a preparation list format
   *
   * @param {object[]} orders
   */
  function convertOrders(orders) {
    const newPrepareList = [];
    if (orders.filter((order) => order.status !== "done").length <= 0) {
      navigate("/dashboard/canteen/orders/log");
    }

    orders.forEach((order) => {
      order.order_dishes.forEach((orderDish) => {
        const prepareListIndex = newPrepareList.findIndex(
          (prepareListItem) => prepareListItem.dish.id === orderDish.dish.id
        );

        orderDish = { ...orderDish, order: order };

        if (prepareListIndex < 0) {
          newPrepareList.push({
            dish: orderDish.dish,
            status: orderDish.status,
            total: 1,
            employees: [{ employee: order.employee, amount: 1 }],
          });
        } else {
          newPrepareList[prepareListIndex].total++;

          const employeeIndex = newPrepareList[
            prepareListIndex
          ].employees.findIndex(
            (item) => item.employee.name === order.employee.name
          );

          if (employeeIndex < 0) {
            newPrepareList[prepareListIndex].employees.push({
              employee: order.employee,
              amount: 1,
            });
          } else {
            newPrepareList[prepareListIndex].employees[employeeIndex].amount++;
          }
        }
      });
    });

    setPrepareList(newPrepareList);
  }

  /**
   * This method will mark the given dish as done using the API
   * @param {integer} dish
   */
  async function onPrepareDish(dish) {
    await callEvaApi(
      new EvaApiCall(`canteen/orders/prepare/${dish}`)
        .setLoadingGroup(`canteen_orders_prepare_${dish}`)
        .setParams({
          with_order_order_dishes_data: true,
          with_order_dish_dish_data: true,
          with_order_employee_data: true,
        })
        .setMethod("POST")
        .setOnSuccess((response) => {
          convertOrders(response.data.data);
        })
    );
  }

  return (
    <OrdersPreparePageView
      prepareList={prepareList}
      onPrepareDish={onPrepareDish}
    />
  );
}

export default OrdersPreparePage;
