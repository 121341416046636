import styled from "styled-components";

export const StyledHtmlSizeInput = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;

  & > :first-child {
    flex: 1;
  }
`;
