import { useLanguageContext } from "../../../../context/LanguageContext";
import HtmlHelper from "../../../../helpers/HtmlHelper";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function OrdersLogDataTable() {
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("canteen_orders_log");

  //Set the datatable fetch info
  config.setFetchRequest("canteen/order_logs");

  //Set the datatable order by
  config.setOrderBy("created_at");
  config.setOrderDirection("desc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location_name").setLabel(
      translate("eva.main.validation.attributes.location_id")
    ),
    useBasicColumnConfig("employee_name").setLabel(
      translate("eva.main.validation.attributes.employee_id")
    ),
    useBasicColumnConfig("price"),
    useBasicColumnConfig("dishes").setData(
      (row) => HtmlHelper.jsonToHtmlString(row.dishes),
      true
    ),
    useBasicColumnConfig("created_at").setUseFromToDateTime(true),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default OrdersLogDataTable;
