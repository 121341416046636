import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledDashboardButton,
  StyledButtonIcon,
  StyledButtonLabel,
} from "./DashboardNavButtonStyles";
import { Link } from "react-router-dom";

function DashboardNavButtonView({ routeConfig, icon, folded, active }) {
  return (
    <Link to={routeConfig.path}>
      <StyledDashboardButton $active={active} title={routeConfig.label}>
        <StyledButtonIcon>
          <FontAwesomeIcon icon={icon} />
        </StyledButtonIcon>

        <StyledButtonLabel $hide={folded}>
          {routeConfig.label}
        </StyledButtonLabel>
      </StyledDashboardButton>
    </Link>
  );
}

export default DashboardNavButtonView;
