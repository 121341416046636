import { createContext, useContext, useRef, useState } from "react";
import RandomizeHelper from "../helpers/RandomizeHelper";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

const LoadContext = createContext();

export function LoadProvider({ children }) {
  const [groups, setGroups] = useState([]);
  const tagsRef = useRef([]);

  /**
   * This method will enable the loader for the given group
   * @param {string} group
   * @returns {string|null} tag
   */
  function enableLoader(group) {
    if (!group) {
      return null;
    }

    const tag = `${group}____${RandomizeHelper.getUUID()}`;
    const firstFromGroup = !tagsRef.current.find((t) => t.startsWith(group));
    tagsRef.current.push(tag);

    if (firstFromGroup) {
      setGroups((prevGroups) => [...prevGroups, group]);
    }

    return tag;
  }

  /**
   * This method will disable the loader for the given tag
   * @param {string} tag
   */
  function disableLoader(tag) {
    if (!tag) {
      return;
    }

    tagsRef.current = tagsRef.current.filter((t) => t !== tag);

    const [group] = tag.split("____");
    const lastFromGroup = !tagsRef.current.find((t) => t.startsWith(group));

    if (lastFromGroup) {
      setGroups((prevGroups) => prevGroups.filter((g) => g !== group));
    }
  }

  /**
   * This method will check if the given group or groups are currently loading
   * @param {string|string[]} grps
   * @returns {boolean}
   */
  function isLoading(grps) {
    if (!grps) {
      return false;
    }

    if (Array.isArray(grps)) {
      for (let i = 0; i < grps.length; i++) {
        if (groups.includes(groups[i])) {
          return true;
        }
      }
      return false;
    } else {
      return groups.includes(grps);
    }
  }

  return useContextDefiner(
    new ContextDefinition("loadContext", LoadContext)
      .setData({ groups, enableLoader, disableLoader, isLoading })
      .setChildren(children)
  );
}

export function useLoadContext() {
  return useContext(LoadContext);
}
