import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../../../../styles/CardStyles";
import Select from "../../../../../components/select/main/Select";
import StatisticsBar from "../../../../../components/statistics/bar/StatisticsBar";
import StatisticsCounter from "../../../../../components/statistics/counter/StatisticsCounter";
import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import OverlayLoader from "../../../../../components/loader/OverlayLoader/OverlayLoader";

function PresentEmployeesStatisticView({
  departments,
  onDepartmentChange,
  totalEmployees,
  presentEmployees,
  percentagePresentEmployees,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="dashboard_employee_fetch">
      <StyledCard>
        <StyledCardHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>{translate("eva.main.statistics.present_employees")}</h2>
        </StyledCardHeader>

        <StyledCardBody>
          <Select
            value={departments.current}
            withNullOption={true}
            options={departments.records.map((department) => {
              return { value: department.id, label: department.name };
            })}
            onChange={onDepartmentChange}
          />

          <StatisticsBar amount={percentagePresentEmployees} />

          <p>
            <span
              style={{
                fontSize: theme.font.size.xl,
                fontWeight: "bold",
                marginRight: theme.spacing.normal,
              }}
            >
              <StatisticsCounter amount={presentEmployees} />
            </span>
            / {totalEmployees}{" "}
            {translate("eva.main.statistics.present_employees").toLowerCase()}
          </p>
        </StyledCardBody>
      </StyledCard>
    </OverlayLoader>
  );
}

export default PresentEmployeesStatisticView;
