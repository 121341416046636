import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";
import IconSelect from "../../main/iconSelect/IconSelect";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../modal/Modal";

function HelpSelectView({ helpText, options, onChange, disabled }) {
  const { translate } = useLanguageContext();

  return (
    <>
      <IconSelect
        options={options}
        onChange={onChange}
        disabled={disabled}
        title={translate("eva.main.general.help")}
        icon={(value) => <FontAwesomeIcon icon={faQuestion} />}
      />

      {helpText && (
        <Modal title={helpText.title} onClose={() => onChange(null)}>
          <div dangerouslySetInnerHTML={{ __html: helpText.description }} />
        </Modal>
      )}
    </>
  );
}

export default HelpSelectView;
