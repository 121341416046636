import { useState } from "react";
import LayoutPageView from "./LayoutPageView";
import useZoomPanEditor from "../../../components/zoomPanEditor/useZoomPanEditor";
import RandomizeHelper from "../../../helpers/RandomizeHelper";
import { getDefaultLayoutSettings } from "../../../components/layout/LayoutDefaultSettings";
import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import {
  layoutFromApi,
  removeFromLayout,
} from "../../../components/layout/LayoutMethods";
import { useParams } from "react-router-dom";

function LayoutPage() {
  const callEvaApi = useEvaApi();
  const zoomPanController = useZoomPanEditor();
  const [images, setImages] = useState([]);
  const { layout } = useParams();
  const [layoutData, setLayoutData] = useState({
    name: "",
    focused_item_id: null,
    hovered_item_id: null,
    layout: {
      id: RandomizeHelper.getUUID(),
      type: "page",
      style: getDefaultLayoutSettings("page"),
      children: [],
    },
  });

  /**
   * UseEffect for initializations and cleanup
   */
  useCustomEffect(() => {
    fetchImages();

    if (layout) {
      fetchLayoutData();
    }

    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  });

  /**
   * This method will fetch the layout data from the API
   */
  async function fetchLayoutData() {
    await callEvaApi(
      new EvaApiCall(`layouts/${layout}`)
        .setLoadingGroup("layout_page_fetch")
        .setParams({
          with_layout_layout_data: true,
          with_layout_item_image_data: true,
          with_layout_item_children_data: true,
        })
        .setOnSuccess((response) => {
          setLayoutData((prev) => ({
            ...prev,
            name: response.data.data.name,
            layout: layoutFromApi(response.data.data.layout),
          }));
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the images from the API
   */
  async function fetchImages() {
    await callEvaApi(
      new EvaApiCall("files")
        .setLoadingGroup("layout_page_fetch")
        .setParams({
          where: [{ column: "type", value: "image" }],
        })
        .setOnSuccess((response) => {
          setImages(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the window key up event
   * @param {Event} e
   */
  function onKeyUp(e) {
    if (e.key === "Delete") {
      setLayoutData((prev) => ({
        ...prev,
        layout:
          prev.focused_item_id !== prev.layout.id
            ? removeFromLayout(prev.layout, prev.focused_item_id)
            : prev.layout,
        focused_item_id:
          prev.focused_item_id !== prev.layout.id ? null : prev.focused_item_id,
      }));
    }
  }

  return (
    <LayoutPageView
      layout={layout}
      layoutData={layoutData}
      setLayoutData={setLayoutData}
      zoomPanController={zoomPanController}
      images={images}
    />
  );
}

export default LayoutPage;
