import ScreenLoader from "./components/loader/ScreenLoader/ScreenLoader";
import { AppProvider } from "./context/AppContext";
import AppRoutes from "./routes/Routes";

function App() {
  return (
    <AppProvider>
      <AppRoutes />

      <ScreenLoader groups="EVA" />
    </AppProvider>
  );
}

export default App;
