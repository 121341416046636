import { useLanguageContext } from "../../../context/LanguageContext";
import UserPage from "../../../pages/users/user/UserPage";
import { useUserEditRouteValidator } from "../../validators/users/UserEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useUsersRouteConfig from "./UsersRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useUserEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/users/:user/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.users"),
        },
      ])
    )
    .setValidator(useUserEditRouteValidator())
    .setPage(<UserPage />)
    .setParent(useUsersRouteConfig());
}

export default useUserEditRouteConfig;
