import { useState } from "react";
import LocationPageView from "./LocationPageView";
import { useParams } from "react-router-dom";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function LocationPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { company, location } = useParams();
  const [locationData, setLocationData] = useState({
    name: "",
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (location) {
      fetchLocationData();
    }
  });

  /**
   * This method will fetch the location data from the API
   */
  async function fetchLocationData() {
    await callEvaApi(
      new EvaApiCall(`locations/${company}/${location}`)
        .setLoadingGroup("location_page_fetch")
        .setOnSuccess((response) => {
          setLocationData({
            name: response.data.data.name,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setLocationData({ ...locationData, name: e.target.value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        location ? `locations/${company}/${location}` : `locations/${company}`
      )
        .setLoadingGroup("location_page_save")
        .setMethod(location ? "PUT" : "POST")
        .setData(locationData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <LocationPageView
      locationData={locationData}
      onNameChange={onNameChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default LocationPage;
