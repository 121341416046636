import { useState } from "react";
import FrontofficePageView from "./FrontofficePageView";
import { StartPageConfig } from "./pages/start/StartPage";

function FrontofficePage() {
  const [pageConfig, setPageConfig] = useState(StartPageConfig);
  return (
    <FrontofficePageView
      pageConfig={pageConfig}
      setPageConfig={setPageConfig}
    />
  );
}


export default FrontofficePage;
