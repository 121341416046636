import { layoutToApi } from "../../../../../components/layout/LayoutMethods";
import { useRouteContext } from "../../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../../hooks/useEvaApi";
import MainFormView from "./MainFormView";

function MainForm({ layout, layoutData, setLayoutData }) {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();

  /**
   * This method will handle the name change
   * @param {Event} e
   */
  function onNameChange(e) {
    setLayoutData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  }

  /**
   * This method will handle the cancel event
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit event
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(layout ? `layouts/${layout}` : "layouts")
        .setLoadingGroup("layout_page_submit")
        .setMethod(layout ? "PUT" : "POST")
        .setData({ ...layoutData, layout: layoutToApi(layoutData.layout) })
        .setAlertError(true)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <MainFormView
      layoutData={layoutData}
      setLayoutData={setLayoutData}
      onNameChange={onNameChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

export default MainForm;
