import HtmlMultiSizeInputView from "./HtmlMultiSizeInputView";

function HtmlMultiSizeInput({
  inputs,
  types,
  icon,
  iconRotation = 0,
  rotateIcon = false,
}) {
  /**
   * This method will return the icon rotation for the given input
   * @param {object} input
   * @param {number} index
   * @returns {number}
   */
  function getIconRotation(input, index) {
    const rotation = rotateIcon
      ? iconRotation + 90 * index
      : input.iconRotation ?? 0;

    if (rotation < 0) {
      return rotation + 360;
    } else if (rotation >= 360) {
      return rotation - 360;
    } else {
      return rotation;
    }
  }

  return (
    <HtmlMultiSizeInputView
      inputs={inputs}
      types={types}
      icon={icon}
      getIconRotation={getIconRotation}
    />
  );
}

export default HtmlMultiSizeInput;
