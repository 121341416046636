import styled from "styled-components";
import { StyledIconButton } from "../../../styles/ButtonStyles";

export const StyledAside = styled.aside`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border-right: 1px solid ${(props) => props.theme.colors.primary.lightest};
  width: ${(props) => (props.$folded ? "60px" : "200px")};
`;

export const StyledLogoWrapper = styled.div`
  height: 80px;
  padding: ${(props) => props.theme.spacing.large};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledLogo = styled.img`
  max-height: 100%;
  margin: 0px auto 0px 0px;
  border-radius: 0px;
  opacity: ${(props) => (props.$hide ? 0 : 1)};
`;

export const StyledFoldoutButton = styled(StyledIconButton)`
  position: absolute;
  right: 0px;
  transform: translateX(50%);
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};

  &:hover {
    border-color: transparent;
    color: ${(props) => props.theme.colors.on.accent};
  }
`;

export const StyledNavWrapper = styled.div`
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.large};
  padding: ${(props) =>
    `${props.theme.spacing.large} 0px ${props.theme.spacing.large} ${props.theme.spacing.large}`};
`;
