import FileHelper from "../../../helpers/FileHelper";
import ImageInputView from "./ImageInputView";

function ImageInput({ value, onChange, size = "170px" }) {
  /**
   * This method will return the src for the image
   * @returns {string}
   */
  function getSrc() {
    return value instanceof File
      ? URL.createObjectURL(value)
      : FileHelper.apiPath(value);
  }

  /**
   * This method will select a file and call the onChange method with the selected file
   */
  async function onSelect() {
    const file = await FileHelper.select("image/png,image/jpeg,image/jpg");
    if (onChange && file) {
      onChange(file);
    }
  }

  /**
   * This method will clear the current value
   */
  function onClear() {
    if (onChange) {
      onChange(null);
    }
  }

  return (
    <ImageInputView
      src={getSrc()}
      onSelect={onSelect}
      onClear={onClear}
      size={size}
    />
  );
}

export default ImageInput;
