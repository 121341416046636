import { createContext, useContext, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";
import useCustomEffect from "../hooks/useCustomEffect";
import { useAppContext } from "./AppContext";
import CookieHelper from "../helpers/CookieHelper";

const AuthContext = createContext();

export let STATIC_AUTH = null;

export function AuthProvider({ children }) {
  const appContext = useAppContext();
  const callEvaApi = useEvaApi();
  const [data, setData] = useState({
    user: null,
    location: null,
    initialized: false,
  });

  /**
   * UseEffect for fetching the user when the component mounts
   */
  useCustomEffect(() => {
    fetchUser();
  });

  /**
   * UseEffect for updating the location when the terminal changes
   */
  useCustomEffect(() => {
    if (appContext.contexts.terminalContext?.terminal) {
      updateLocation(appContext.contexts.terminalContext.terminal.location);
    }
  }, [appContext.contexts.terminalContext?.terminal]);

  /**
   * This method will update the data state with the given callback and update the STATIC_AUTH variable
   * @param {function(prev) : object} callback
   */
  function updateData(callback) {
    setData((prev) => {
      const newData = callback(prev);
      STATIC_AUTH = newData;
      return newData;
    });
  }

  /**
   * This method will return the auth fetch params
   * @returns {object}
   */
  function getFetchParams() {
    return {
      with_user_company_data: true,
      with_company_locations_data: true,
      with_user_roles_data: true,
      with_role_permissions_data: true,
    };
  }

  /**
   * This method will fetch the user from the API
   */
  async function fetchUser() {
    const userId = localStorage.getItem("user_id");

    if (userId) {
      await callEvaApi(
        new EvaApiCall(`users/${userId}`)
          .setLoadingGroup("CONTEXT")
          .setParams(getFetchParams())
          .setOnSuccess((response) => {
            //Update the state with the new user and location
            const newUser = response.data.data;
            updateData((prev) => ({
              ...prev,
              user: newUser,
              location: getUserLocation(newUser),
            }));
          })
          .setAlertError(false)
          .setAlertSuccess(false)
      );
    }

    updateData((prev) => ({
      ...prev,
      initialized: true,
    }));
  }

  /**
   * This method will try to login to user with the given credentials
   * @param {string} email
   * @param {string} password
   * @param {string} tfaCode
   */
  async function login(
    email,
    password,
    tfaCode,
    onSuccess = null,
    onError = null
  ) {
    await callEvaApi(
      new EvaApiCall("login")
        .setLoadingGroup("login")
        .setParams(getFetchParams())
        .setMethod("POST")
        .setData({
          email: email,
          password: password,
          tfa_code: tfaCode,
        })
        .setOnSuccess((response) => {
          //Block the app render to avoid flash fetches (more contexts will need to load)
          appContext.setBlockAppRender(true);

          //Get the user and tokens from the response
          const newUser = response.data.data.user;
          const newTokens = response.data.data.tokens;

          //Set the cookies and local storage items
          localStorage.setItem("user_id", newUser.id);
          CookieHelper.set(
            "access_token",
            newTokens.access.token,
            "/",
            new Date(newTokens.access.expires_at)
          );
          CookieHelper.set(
            "refresh_token",
            newTokens.refresh.token,
            "/",
            new Date(newTokens.refresh.expires_at)
          );

          //Update the state
          updateData((prev) => ({
            ...prev,
            user: newUser,
            location: getUserLocation(newUser),
          }));

          //Call the success callback if it exists
          if (onSuccess) {
            onSuccess(response);
          }
        })
        .setOnError(onError)
        .setAlertError(true)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will log the current user in to the company with the given id
   * @param {int} companyId
   */
  async function loginToCompany(companyId, onSuccess = null, onError = null) {
    await callEvaApi(
      new EvaApiCall(`login/${companyId}`)
        .setLoadingGroup("login_to_company")
        .setParams(getFetchParams())
        .setMethod("POST")
        .setOnSuccess((response) => {
          //Block the app render to avoid flash fetches (more contexts will need to load)
          appContext.setBlockAppRender(true);

          //Update the state with the new user and location
          const newUser = response.data.data;
          updateData((prev) => ({
            ...prev,
            user: newUser,
            location: getUserLocation(newUser),
          }));

          //Call the onSuccess callback if it exists
          if (onSuccess) {
            onSuccess(response);
          }
        })
        .setOnError(onError)
        .setAlertError(true)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will try to logout the current authenticated user
   *
   * @returns {JSONResponse}
   */
  async function logout(onSuccess = null, onError = null) {
    await callEvaApi(
      new EvaApiCall("logout")
        .setParams(getFetchParams())
        .setLoadingGroup("logout")
        .setMethod("POST")
        .setOnSuccess((response) => {
          //Block the app render to avoid flash fetches (more contexts will need to load)
          appContext.setBlockAppRender(true);

          //Get the new user, if it exists it means the user is still logged in
          const newUser = response.data.data;
          if (newUser) {
            updateData((prev) => ({
              ...prev,
              user: newUser,
              location: getUserLocation(newUser),
            }));
          } else {
            updateData((prev) => ({
              ...prev,
              user: null,
              location: null,
            }));

            //Remove the cookies and local storage items
            localStorage.removeItem("user_id");
            CookieHelper.remove("access_token");
            CookieHelper.remove("refresh_token");
          }

          //Call the success callback if it exists
          if (onSuccess) {
            onSuccess(response);
          }
        })
        .setOnError(onError)
        .setAlertError(true)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will return if the authenticated user has the given permission or not
   * @param {string} permission
   * @return {bool}
   */
  function hasPermission(permission) {
    if (!data.user) {
      return false;
    }

    if (data.user.role_type === "superadmin") {
      return true;
    }

    for (let i = 0; i < data.user.roles.length; i++) {
      for (let j = 0; j < data.user.roles[i].permissions.length; j++) {
        if (data.user.roles[i].permissions[j].name === permission) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * This method will return the location of the given user
   * @param {object} user
   * @param {integer} locationId The location id if you do not want to use the local storage
   * @returns {integer|string}
   */
  function getUserLocation(user, locationId = undefined) {
    if (user?.location) {
      return user.location;
    }

    if (locationId === null) {
      return null;
    }

    return (
      user?.company?.locations?.find(
        (loc) =>
          parseInt(loc.id) ===
          (locationId ?? parseInt(localStorage.getItem("location_id")))
      )?.id ?? null
    );
  }

  /**
   * This method will update the current location of the user
   * @param {integer|string} locationId
   */
  function updateLocation(locationId) {
    const newLocation = getUserLocation(data.user, locationId);
    localStorage.setItem("location_id", newLocation);
    updateData((prev) => ({
      ...prev,
      location: newLocation,
    }));
  }

  return useContextDefiner(
    new ContextDefinition("authContext", AuthContext)
      .setData({
        auth: data,
        login,
        loginToCompany,
        logout,
        hasPermission,
        updateLocation,
      })
      .setChildren(children)
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
