import { useLanguageContext } from "../../context/LanguageContext";
import { StyledCard, StyledCardBody } from "../../styles/CardStyles";
import LanguageFormSelect from "../select/custom/language/form/LanguageFormSelect";
import { StyledHeader } from "./EntityTranslationsContainerStyles";

function EntityTranslationsContainerView({ controller, children, style }) {
  const { translate } = useLanguageContext();

  return (
    <StyledCard style={style}>
      <StyledHeader>
        <h2>{translate("eva.main.entities.translations")}</h2>

        <LanguageFormSelect
          value={controller?.languageId}
          onChange={controller?.onLanguageChange}
        />
      </StyledHeader>

      <StyledCardBody>{children}</StyledCardBody>
    </StyledCard>
  );
}

export default EntityTranslationsContainerView;
