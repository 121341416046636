import { useRef } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import FlowChartNodeView from "./FlowChartNodeView";

function FlowChartNode({ node, onRefInit, onRefClear }) {
  const nodeRef = useRef(null);

  /**
   * UseEffect for initializing the node
   */
  useCustomEffect(() => {
    if (onRefInit) {
      onRefInit(nodeRef.current);
    }
    return () => {
      if (onRefClear) {
        onRefClear(node.id);
      }
    };
  }, [nodeRef.current]);

  return <FlowChartNodeView node={node} nodeRef={nodeRef} />;
}

export default FlowChartNode;
