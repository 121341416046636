import FrontOfficePageConfig from "../../FrontOfficePageConfig";
import SignInPageView from "./SignInPageView";

function SignInPage({ setPageConfig }) {
  return <SignInPageView setPageConfig={setPageConfig} />;
}

export const SignInPageConfig = new FrontOfficePageConfig(
  SignInPage,
  "sign_in"
);

export default SignInPage;
