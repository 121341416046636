import FlowPageStepFormView from "./FlowPageStepFormView";

function FlowPageStepForm({
  flowData,
  setFlowData,
  questionnaires,
  fields,
  houserules,
  messageTemplates,
  employees,
  submitError,
}) {
  /**
   * This method will handle the close event.
   */
  function onClose() {
    setFlowData((prevFlowData) => ({
      ...prevFlowData,
      selectedStep: null,
    }));
  }

  /**
   * This method will handle the delete event.
   */
  function onDelete() {
    setFlowData((prevFlowData) => {
      const newSteps = prevFlowData.steps.filter(
        (step, index) => index !== prevFlowData.selectedStep
      );

      //Make sure all step links are still correct
      newSteps.forEach((step) => {
        if (step.on_fail_step === prevFlowData.selectedStep) {
          step.on_fail_step = null;
          step.on_fail = null;
        } else if (
          step.on_fail_step !== null &&
          step.on_fail_step > prevFlowData.selectedStep
        ) {
          step.on_fail_step--;
        }
      });

      return {
        ...prevFlowData,
        steps: newSteps,
        selectedStep: null,
      };
    });
  }

  return (
    flowData.selectedStep !== null && (
      <FlowPageStepFormView
        flowData={flowData}
        setFlowData={setFlowData}
        questionnaires={questionnaires}
        fields={fields}
        houserules={houserules}
        messageTemplates={messageTemplates}
        employees={employees}
        onClose={onClose}
        onDelete={onDelete}
        submitError={submitError}
      />
    )
  );
}

export default FlowPageStepForm;
