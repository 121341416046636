import { CalamityButton } from "./CalamityActivationButtonStyles";
import CalamityActivationForm from "./form/CalamityActivationForm";

function CalamityActivationButtonView({
  onFormOpen,
  onFormClose,
  isFormVisible,
  disabled,
}) {
  return (
    <>
      <CalamityButton onClick={onFormOpen} disabled={disabled}>
        SOS
      </CalamityButton>

      {isFormVisible && <CalamityActivationForm onClose={onFormClose} />}
    </>
  );
}

export default CalamityActivationButtonView;
