import { useState } from "react";
import DashboardNavGroupView from "./DashboardNavGroupView";

function DashboardNavGroup({ uniqueKey, label, folded, children }) {
  const [visible, setVisible] = useState(
    localStorage.getItem(getStorageKey()) === "true"
  );

  /**
   * This method will return if this group should be shown or hidden
   * @returns {bool}
   */
  function shouldShow() {
    const childrenToCheck = Array.isArray(children) ? children : [children];
    for (let i = 0; i < childrenToCheck.length; i++) {
      if (childrenToCheck[i].props.routeConfig.validator.validate() === true) {
        return true;
      }
    }

    return false;
  }

  /**
   * This method will handle the visibility change
   */
  function onVisabilityChange() {
    const newValue = !visible;
    setVisible(newValue);
    if (uniqueKey) {
      localStorage.setItem(getStorageKey(), newValue);
    }
  }

  /**
   * This method will return the storage key for this group
   * @returns {string}
   */
  function getStorageKey() {
    return `dashboard_button_group_${uniqueKey}_visible`;
  }

  return shouldShow() ? (
    <DashboardNavGroupView
      label={label}
      folded={folded}
      visible={visible}
      onVisabilityChange={onVisabilityChange}
    >
      {children}
    </DashboardNavGroupView>
  ) : (
    ""
  );
}

export default DashboardNavGroup;
