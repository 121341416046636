import { createContext, useContext, useRef, useState } from "react";
import useCustomEffect from "../hooks/useCustomEffect";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

const DraggableContext = createContext();

export function DraggableProvider({ children }) {
  const minDragDistance = 12.5;
  const [dragged, setDragged] = useState(null);
  const hovered = useRef([]);

  /**
   * UseEffect to make sure that the cursor is set to dragging when we are dragging
   */
  useCustomEffect(() => {
    if (dragged) {
      document.body.classList.add("dragging");
    } else {
      document.body.classList.remove("dragging");
    }
    return () => {
      document.body.classList.remove("dragging");
    };
  }, [dragged]);

  /**
   * This method will start the drag event.
   * @param {Event} event
   * @param {string | int} id
   */
  function startDrag(event, id) {
    //Prevent the default behavior of the event.
    event.preventDefault();

    //Set the start position and dragging state.
    const startPosition = { x: event.clientX, y: event.clientY };
    let dragging = false;

    //Listen for the mouse move and mouse up events.
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    //Handle the mouse move event while dragging.
    function onMouseMove(event) {
      const dragDistanceX = Math.abs(event.clientX - startPosition.x);
      const dragDistanceY = Math.abs(event.clientY - startPosition.y);
      if (
        (dragDistanceX > minDragDistance || dragDistanceY > minDragDistance) &&
        !dragging
      ) {
        setDragged(id);
        dragging = true;
      }
    }

    //Handle the mouse up event while dragging.
    function onMouseUp() {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      if (dragging) {
        setDragged(null);
        hovered.current = [];
      }
    }
  }

  /**
   * This method will add the hovered id to the hovered list.
   * @param {string} id
   * @returns {boolean}
   */
  function addHovered(id) {
    if (!hovered.current.includes(id) && id !== dragged && dragged) {
      hovered.current.push(id);
      return true;
    }

    return false;
  }

  /**
   * This method will remove the hovered id from the hovered list.
   * @param {string} id
   * @returns {boolean}
   */
  function removeHovered(id) {
    if (hovered.current.includes(id) && id !== dragged && dragged) {
      hovered.current = hovered.current.filter((hoveredId) => hoveredId !== id);
      return true;
    }

    return false;
  }

  /**
   * This method will return the last hovered id.
   * @returns {string | null}
   */
  function getLastHovered() {
    return hovered.current.length > 0
      ? hovered.current[hovered.current.length - 1]
      : null;
  }

  /**
   * This method will update the dragged id.
   * @param {string} id
   */
  function updateDragged(id) {
    setDragged(id);
    if (hovered.current.includes(id)) {
      hovered.current = hovered.current.filter((hoveredId) => hoveredId !== id);
    }
  }

  return useContextDefiner(
    new ContextDefinition("draggableContext", DraggableContext)
      .setData({
        dragged,
        updateDragged,
        addHovered,
        removeHovered,
        getLastHovered,
        startDrag,
      })
      .setChildren(children)
  );
}

export function useDraggableContext() {
  return useContext(DraggableContext);
}
