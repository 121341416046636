import useBasicColumnConfig from "./BasicColumnConfig";

/**
 * @param {string} key for example: "created_at" or "updated_at"
 * @returns {ColumnConfig}
 */
function useTimestampColumnConfig(key) {
  return useBasicColumnConfig(key).setActive(false);
}

export default useTimestampColumnConfig;
