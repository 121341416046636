import {
  faBriefcase,
  faBuilding,
  faBullhorn,
  faBurger,
  faFileInvoiceDollar,
  faFlag,
  faAddressBook,
  faGear,
  faHome,
  faList,
  faCodeCompare,
  faQuestion,
  faTags,
  faUsers,
  faPhotoFilm,
  faCity,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faTowerBroadcast,
  faBook,
  faMessage,
  faShieldHalved,
  faBorderNone,
  faDesktop,
  faPalette,
  faUpRightFromSquare,
  faCodeBranch,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  StyledAside,
  StyledFoldoutButton,
  StyledLogo,
  StyledLogoWrapper,
  StyledNav,
  StyledNavWrapper,
} from "./DashboardNavStyles";
import DashboardNavButton from "./button/DashboardNavButton";
import DashboardNavGroup from "./group/DashboardNavGroup";
import useDashboardRouteConfig from "../../../routes/configs/DashboardRouteConfig";
import useCompaniesRouteConfig from "../../../routes/configs/companies/CompaniesRouteConfig";
import useAnnouncementsRouteConfig from "../../../routes/configs/announcements/AnnouncementsRouteConfig";
import useLanguagesRouteConfig from "../../../routes/configs/languages/LanguagesRouteConfig";
import useUsersRouteConfig from "../../../routes/configs/users/UsersRouteConfig";
import useRolesRouteConfig from "../../../routes/configs/roles/RolesRouteConfig";
import useEmployeesRouteConfig from "../../../routes/configs/employees/EmployeesRouteConfig";
import useFieldsRouteConfig from "../../../routes/configs/fields/FieldsRouteConfig";
import useQuestionnairesRouteConfig from "../../../routes/configs/questionnaires/QuestionnairesRouteConfig";
import useParametersRouteConfig from "../../../routes/configs/ParametersRouteConfig";
import useFilesRouteConfig from "../../../routes/configs/files/FilesRouteConfig";
import useContactsRouteConfig from "../../../routes/configs/contacts/ContactsRouteConfig";
import useDepartmentsRouteConfig from "../../../routes/configs/departments/DepartmentsRouteConfig";
import useCompanyLanguagesRouteConfig from "../../../routes/configs/companyLanguages/CompanyLanguagesRouteConfig";
import useAuditTrailsRouteConfig from "../../../routes/configs/AuditTrailsRouteConfig";
import useHelpTextsRouteConfig from "../../../routes/configs/helpTexts/HelpTextsRouteConfig";
import useReleaseNotesRouteConfig from "../../../routes/configs/ReleaseNotesRouteConfig";
import useDishesRouteConfig from "../../../routes/configs/canteen/dishes/DishesRouteConfig";
import useOrdersRouteConfig from "../../../routes/configs/canteen/orders/OrdersRouteConfig";
import useOrdersLogRouteConfig from "../../../routes/configs/canteen/orders/OrdersLogRouteConfig";
import useCalamitiesRouteConfig from "../../../routes/configs/calamities/CalamitiesRouteConfig";
import useCalamitiesLogRouteConfig from "../../../routes/configs/calamities/CalamitiesLogRouteConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMessageTemplatesRouteConfig from "../../../routes/configs/message_templates/MessageTemplatesRouteConfig";
import useIPLocksRouteConfig from "../../../routes/configs/ip_locks/IPLocksRouteConfig";
import useLayoutsRouteConfig from "../../../routes/configs/layouts/LayoutsRouteConfig";
import useTerminalsRouteConfig from "../../../routes/configs/terminals/TerminalsRouteConfig";
import useThemeSettingsRouteConfig from "../../../routes/configs/ThemeSettingsRouteConfig";
import useButtonsRouteConfig from "../../../routes/configs/buttons/ButtonsRouteConfig";
import useFlowsRouteConfig from "../../../routes/configs/flows/FlowsRouteConfig";
import useVisitorTypesRouteConfig from "../../../routes/configs/visitor_types/VisitorTypesRouteConfig";
import useVisitorsRouteConfig from "../../../routes/configs/visitors/VisitorsRouteConfig";

function DashboardNavView({ folded, onFoldedChange }) {
  const { translate } = useLanguageContext();

  return (
    <StyledAside $folded={folded}>
      <StyledLogoWrapper>
        {/* Dashboard EVA Logo */}
        <StyledLogo src="/assets/images/logo.png" alt="EVA" $hide={folded} />

        {/* Dashboard Navigation Foldout Button */}
        <StyledFoldoutButton
          onClick={() => onFoldedChange(!folded)}
          title={translate(
            `eva.main.general.${folded ? "expand" : "collapse"}`
          )}
        >
          <FontAwesomeIcon
            icon={
              folded
                ? faUpRightAndDownLeftFromCenter
                : faDownLeftAndUpRightToCenter
            }
          />
        </StyledFoldoutButton>
      </StyledLogoWrapper>

      <StyledNavWrapper>
        <StyledNav>
          {/* General group */}
          <DashboardNavGroup
            uniqueKey="general"
            label={translate("eva.main.general.general")}
            folded={folded}
          >
            {/* Dashboard */}
            <DashboardNavButton
              routeConfig={useDashboardRouteConfig()}
              icon={faHome}
              folded={folded}
              onlyActiveOnExactPath={true}
            />

            {/* Visitors */}
            <DashboardNavButton
              routeConfig={useVisitorsRouteConfig()}
              icon={faUsers}
              folded={folded}
            />

            {/* Companies */}
            <DashboardNavButton
              routeConfig={useCompaniesRouteConfig()}
              icon={faBuilding}
              folded={folded}
            />

            {/* Announcements */}
            <DashboardNavButton
              routeConfig={useAnnouncementsRouteConfig()}
              icon={faBullhorn}
              folded={folded}
            />

            {/* Languages */}
            <DashboardNavButton
              routeConfig={useLanguagesRouteConfig()}
              icon={faFlag}
              folded={folded}
            />

            {/* Users */}
            <DashboardNavButton
              routeConfig={useUsersRouteConfig()}
              icon={faUsers}
              folded={folded}
            />

            {/* Roles */}
            <DashboardNavButton
              routeConfig={useRolesRouteConfig()}
              icon={faUsers}
              folded={folded}
            />

            {/* Employees  */}
            <DashboardNavButton
              routeConfig={useEmployeesRouteConfig()}
              icon={faBriefcase}
              folded={folded}
            />

            {/* Terminals */}
            <DashboardNavButton
              routeConfig={useTerminalsRouteConfig()}
              icon={faDesktop}
              folded={folded}
            />

            {/* Fields  */}
            <DashboardNavButton
              routeConfig={useFieldsRouteConfig()}
              icon={faTags}
              folded={folded}
            />

            {/* Questionnaires  */}
            <DashboardNavButton
              routeConfig={useQuestionnairesRouteConfig()}
              icon={faQuestion}
              folded={folded}
            />

            {/* Message templates  */}
            <DashboardNavButton
              routeConfig={useMessageTemplatesRouteConfig()}
              icon={faMessage}
              folded={folded}
            />

            {/* Calamities */}
            <DashboardNavButton
              routeConfig={useCalamitiesRouteConfig()}
              icon={faTowerBroadcast}
              folded={folded}
              activePathExceptions={["log"]}
            />

            {/* Calamity Log */}
            <DashboardNavButton
              routeConfig={useCalamitiesLogRouteConfig()}
              icon={faBook}
              folded={folded}
            />

            {/* Parameters  */}
            <DashboardNavButton
              routeConfig={useParametersRouteConfig()}
              icon={faGear}
              folded={folded}
            />

            {/* IP Lock */}
            <DashboardNavButton
              routeConfig={useIPLocksRouteConfig()}
              icon={faShieldHalved}
              folded={folded}
            />

            {/* Files */}
            <DashboardNavButton
              routeConfig={useFilesRouteConfig()}
              icon={faPhotoFilm}
              folded={folded}
            />

            {/* Buttons */}
            <DashboardNavButton
              routeConfig={useButtonsRouteConfig()}
              icon={faUpRightFromSquare}
              folded={folded}
            />

            {/* Visitor Types */}
            <DashboardNavButton
              routeConfig={useVisitorTypesRouteConfig()}
              icon={faUserTag}
              folded={folded}
            />

            {/* Contacts */}
            <DashboardNavButton
              routeConfig={useContactsRouteConfig()}
              icon={faAddressBook}
              folded={folded}
            />

            {/* Departments */}
            <DashboardNavButton
              routeConfig={useDepartmentsRouteConfig()}
              icon={faCity}
              folded={folded}
            />

            {/* Flows */}
            <DashboardNavButton
              routeConfig={useFlowsRouteConfig()}
              icon={faCodeBranch}
              folded={folded}
            />

            {/* Layouts */}
            <DashboardNavButton
              routeConfig={useLayoutsRouteConfig()}
              icon={faBorderNone}
              folded={folded}
            />

            {/* Theme settings */}
            <DashboardNavButton
              routeConfig={useThemeSettingsRouteConfig()}
              icon={faPalette}
              folded={folded}
            />

            {/* Company Languages  */}
            <DashboardNavButton
              routeConfig={useCompanyLanguagesRouteConfig()}
              icon={faFlag}
              folded={folded}
            />

            {/* Audit trails  */}
            <DashboardNavButton
              routeConfig={useAuditTrailsRouteConfig()}
              icon={faList}
              folded={folded}
            />

            {/* Help texts  */}
            <DashboardNavButton
              routeConfig={useHelpTextsRouteConfig()}
              icon={faQuestion}
              folded={folded}
            />

            {/* Release notes*/}
            <DashboardNavButton
              routeConfig={useReleaseNotesRouteConfig()}
              icon={faCodeCompare}
              folded={folded}
            />
          </DashboardNavGroup>

          {/* Canteen group */}
          <DashboardNavGroup
            uniqueKey="canteen"
            label={translate("eva.main.general.canteen")}
            folded={folded}
          >
            {/* Dishes */}
            <DashboardNavButton
              routeConfig={useDishesRouteConfig()}
              icon={faBurger}
              folded={folded}
            />

            {/* Orders */}
            <DashboardNavButton
              routeConfig={useOrdersRouteConfig()}
              icon={faFileInvoiceDollar}
              activePathExceptions={["log"]}
              folded={folded}
            />

            {/* Orders log */}
            <DashboardNavButton
              routeConfig={useOrdersLogRouteConfig()}
              icon={faList}
              folded={folded}
            />
          </DashboardNavGroup>
        </StyledNav>
      </StyledNavWrapper>
    </StyledAside>
  );
}

export default DashboardNavView;
