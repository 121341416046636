import styled from "styled-components";

export const StyledScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: ${(props) =>
    `rgba(${props.theme.colors.overlay.primary}, 0.8)`};
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
`;
