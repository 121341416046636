import { useLanguageContext } from "../../../context/LanguageContext";
import QuestionnairePage from "../../../pages/questionnaires/questionnaire/QuestionnairePage";
import { useQuestionnaireCreateRouteValidator } from "../../validators/questionnaires/QuestionnaireCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useQuestionnairesRouteConfig from "./QuestionnairesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useQuestionnaireCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/questionnaires/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.questionnaire"),
        },
      ])
    )
    .setValidator(useQuestionnaireCreateRouteValidator())
    .setPage(<QuestionnairePage />)
    .setParent(useQuestionnairesRouteConfig());
}

export default useQuestionnaireCreateRouteConfig;
