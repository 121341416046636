import { useLanguageContext } from "../../../../context/LanguageContext";
import OrderPage from "../../../../pages/canteen/orders/order/OrderPage";
import { useOrderCreateRouteValidator } from "../../../validators/canteen/orders/OrderCreateRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useOrdersRouteConfig from "./OrdersRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useOrderCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/orders/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.order"),
        },
      ])
    )
    .setValidator(useOrderCreateRouteValidator())
    .setPage(<OrderPage />)
    .setParent(useOrdersRouteConfig());
}

export default useOrderCreateRouteConfig;
