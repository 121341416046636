import { useAuthContext } from "../../context/AuthContext";
import { useLanguageContext } from "../../context/LanguageContext";
import { StyledButton } from "../../styles/ButtonStyles";
import { StyledRoundedOverflowXWrapper } from "../../styles/WrapperStyles";
import HiddenLoader from "../loader/HiddenLoader/HiddenLoader";

function CalamityTableView({ company, calamityContext, stopCalamity }) {
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  return (
    <StyledRoundedOverflowXWrapper>
      <table>
        <thead>
          <tr>
            <th>{translate("eva.main.entities.location")}</th>
            <th>{translate("eva.main.validation.attributes.activated_by")}</th>
            <th>{translate("eva.main.entities.calamity_type")}</th>
            <th>{translate("eva.main.entities.calamity_tool")}</th>
            <th>{translate("eva.main.validation.attributes.comment")}</th>
            <th style={{ width: "0" }}></th>
          </tr>
        </thead>
        <tbody>
          {company.locations.map((location) => {
            const calamity = calamityContext.calamities.find(
              (calamity) => calamity.location === location.id
            );

            return (
              <tr key={location.id}>
                <td>{location.name}</td>
                <td>{calamity?.activated_by}</td>
                <td>{calamity?.type}</td>
                <td>{calamity?.tool}</td>
                <td>{calamity?.comment}</td>
                <td>
                  <HiddenLoader groups={`calamities_stop_page_${calamity?.id}`}>
                    <StyledButton
                      onClick={() => stopCalamity(calamity)}
                      disabled={
                        !calamity ||
                        !authContext.hasPermission("calamities.delete")
                      }
                    >
                      {translate("eva.main.general.stop")}
                    </StyledButton>
                  </HiddenLoader>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledRoundedOverflowXWrapper>
  );
}

export default CalamityTableView;
