import { useAuthContext } from "../../../../context/AuthContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import useQuestionAnswerCreateRouteConfig from "../../../../routes/configs/questionnaires/questions/answers/QuestionAnswerCreateRouteConfig";
import useQuestionAnswerEditRouteConfig from "../../../../routes/configs/questionnaires/questions/answers/QuestionAnswerEditRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useBooleanColumnConfig from "../../main/config/column/default/BooleanColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function QuestionAnswersDataTable({ question }) {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("question_answers");

  //Set the datatable header
  config.setHeader(translate("eva.main.entities.question_answers"));

  //Set the datatable fetch info
  config.setFetchRequest(`questions/${question}/answers`);

  //Set default order
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //Set the datatable comuns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("order"),
    useBooleanColumnConfig("correct"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig(
      "question_answer",
      useQuestionAnswerEditRouteConfig()
    ),
    useDeleteRowActionConfig("question_answer").setDisabled(
      () => !authContext.hasPermission("questionnaires.update")
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "question_answer",
      useQuestionAnswerCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default QuestionAnswersDataTable;
