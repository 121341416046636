import { useState } from "react";
import { useParams } from "react-router-dom";
import CompanyPageView from "./CompanyPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function CompanyPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { company } = useParams();
  const [companyData, setCompanyData] = useState({
    fetched: false,
    name: "",
    contact: "",
    contact_details: "",
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (company) {
      fetchCompanyData();
    }
  });

  /**
   * This method will fetch the company data from the API
   */
  async function fetchCompanyData() {
    await callEvaApi(
      new EvaApiCall(`companies/${company}`)
        .setLoadingGroup("company_page_fetch")
        .setOnSuccess((response) => {
          setCompanyData({
            fetched: true,
            name: response.data.data.name,
            contact: response.data.data.contact,
            contact_details: response.data.data.contact_details,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setCompanyData({ ...companyData, name: e.target.value });
  }

  /**
   * This method will update the contact on input change
   * @param {event} e
   */
  function onContactChange(e) {
    setCompanyData({ ...companyData, contact: e.target.value });
  }

  /**
   * This method will update the contact details on input change
   * @param {event} e
   */
  function onContactDetailsChange(e) {
    setCompanyData({ ...companyData, contact_details: e.target.value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(company ? `companies/${company}` : "companies")
        .setLoadingGroup("company_page_save")
        .setMethod(company ? "PUT" : "POST")
        .setData(companyData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <CompanyPageView
      company={company}
      companyData={companyData}
      onNameChange={onNameChange}
      onContactChange={onContactChange}
      onContactDetailsChange={onContactDetailsChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default CompanyPage;
