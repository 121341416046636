import { useTheme } from "styled-components";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import { StyledCardHeader } from "../../../../styles/CardStyles";
import { StyledInput } from "../../../../styles/InputStyles";
import { StyledFlexLayout } from "../../../../styles/LayoutStyles";
import { StyledFormCard, StyledFormCardBody } from "./FlowPageFormStyles";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import { StyledInputError } from "../../../../components/input/wrapper/InputWrapperStyles";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";

function FlowPageFormView({
  flowData,
  onNameChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFormCard>
      <StyledCardHeader>
        <h2>{translate("eva.main.entities.flow")}</h2>
      </StyledCardHeader>

      <StyledFormCardBody>
        {/* Name input */}
        <InputWrapper
          label={translate("eva.main.validation.attributes.name")}
          error={EVAApiHelper.getResponseError("name", submitError)}
          compactError={true}
          required
        >
          <StyledInput
            type="text"
            value={flowData.name}
            placeholder={translate("eva.main.validation.attributes.name")}
            onChange={onNameChange}
          />
        </InputWrapper>

        {/* Buttons */}
        <StyledFlexLayout style={{ marginTop: theme.spacing.normal }}>
          <StyledAltButton onClick={onCancel}>
            {translate("eva.main.general.cancel")}
          </StyledAltButton>

          <HiddenLoader groups={"flow_page_submit"}>
            <StyledButton onClick={onSubmit}>
              {translate("eva.main.general.save")}
            </StyledButton>
          </HiddenLoader>
        </StyledFlexLayout>

        {/* Error message */}
        <StyledInputError>
          {EVAApiHelper.getResponseError("message", submitError, true)}
        </StyledInputError>
      </StyledFormCardBody>
    </StyledFormCard>
  );
}

export default FlowPageFormView;
