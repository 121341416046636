import CompactError from "../../error/compact/CompactError";
import {
  StyledHeader,
  StyledInputError,
  StyledInputRequirement,
  StyledInputWrapper,
} from "./InputWrapperStyles";

function InputWrapper({
  label,
  required,
  error,
  compactError = false,
  children,
  margin,
  alignItems,
  style,
}) {
  return (
    <StyledInputWrapper margin={margin} alignItems={alignItems} style={style}>
      <StyledHeader>
        {label}
        {required && <StyledInputRequirement>*</StyledInputRequirement>}
        {compactError && <CompactError error={error} />}
      </StyledHeader>

      {children}

      {error && !compactError ? (
        <StyledInputError
          dangerouslySetInnerHTML={{ __html: error }}
        ></StyledInputError>
      ) : (
        ""
      )}
    </StyledInputWrapper>
  );
}

export default InputWrapper;
