import FrontOfficePageConfig from "../../FrontOfficePageConfig";
import SignOutPageView from "./SignOutPageView";

function SignOutPage({ setPageConfig }) {
  return <SignOutPageView setPageConfig={setPageConfig} />;
}

export const SignOutPageConfig = new FrontOfficePageConfig(
  SignOutPage,
  "sign_out"
);

export default SignOutPage;
