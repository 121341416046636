import { useAuthContext } from "../../context/AuthContext";
import useRouteValidator from "./RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useNotFoundRouteValidator() {
  const authContext = useAuthContext();

  return useRouteValidator(() => {
    if (!authContext.auth.user) {
      return { to: "/login", replace: true };
    } else {
      if (authContext.auth.user?.role_type === "terminal") {
        return { to: "/frontoffice", replace: true };
      }
      return { to: "/dashboard", replace: true };
    }
  });
}
