import { useState } from "react";
import {
  addToLayout,
  removeFromLayout,
  updateLayoutOnDrag,
} from "../../../../../../components/layout/LayoutMethods";
import RandomizeHelper from "../../../../../../helpers/RandomizeHelper";
import HierarchyView from "./HierarchyView";
import { useDraggableContext } from "../../../../../../context/DraggableContext";
import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import { getDefaultLayoutSettings } from "../../../../../../components/layout/LayoutDefaultSettings";

function Hierarchy({ layoutData, setLayoutData }) {
  const [clickDisabled, setClickDisabled] = useState(false);
  const draggableContext = useDraggableContext();

  /**
   * UseEffect for disabling the click event when dragging.
   */
  useCustomEffect(() => {
    setClickDisabled(draggableContext.dragged ? true : false);
  }, [draggableContext.dragged]);

  /**
   * This method will add a new item based on the given value to the given item in the layout
   * @param {object} item
   * @param {string} value
   */
  function onAdd(item, value = null) {
    value = value ?? (item.type === "page" ? "box" : "subbox");

    setLayoutData((prev) => ({
      ...prev,
      layout: addToLayout(prev.layout, item.id, {
        id: RandomizeHelper.getUUID(),
        type: value,
        style: getDefaultLayoutSettings(value),
        children: [],
      }),
    }));
  }

  /**
   * This method will activate the edit mode for the given item
   * @param {object} item
   * @param {Event} e
   */
  function onEdit(item, e = null) {
    //Block the item onClick if the click was on one of the buttons or the item is disabled.
    if (
      clickDisabled ||
      (e &&
        e.target &&
        e.target.id !== item.id &&
        e.target.tagName.toLowerCase() !== "p")
    ) {
      return;
    }

    setLayoutData((prev) => ({
      ...prev,
      focused_item_id: item.id,
      hovered_item_id: null,
    }));
  }

  /**
   * This method will delete the given item from the layout
   * @param {object} item
   */
  function onDelete(item) {
    setLayoutData((prev) => ({
      ...prev,
      layout: removeFromLayout(prev.layout, item.id),
    }));
  }

  /**
   * This method will handle the mouse enter event of the given layout item
   * @param {object} item
   */
  function onMouseEnter(item) {
    setLayoutData((prev) => ({
      ...prev,
      hovered_item_id: item.id,
    }));
  }

  /**
   * This method will handle the mouse leave event of the given item
   * @param {object} item
   */
  function onMouseLeave(item) {
    setLayoutData((prev) => ({
      ...prev,
      hovered_item_id: null,
    }));
  }

  /**
   * This method will handle the drag enter event of the given items
   * @param {string} dragged
   * @param {string} target
   */
  function onDragEnter(dragged, target) {
    setLayoutData((prev) => ({
      ...prev,
      layout: updateLayoutOnDrag(prev.layout, dragged, target),
    }));

    return true;
  }

  return (
    <HierarchyView
      layoutData={layoutData}
      onAdd={onAdd}
      onEdit={onEdit}
      onDelete={onDelete}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragEnter={onDragEnter}
    />
  );
}

export default Hierarchy;
