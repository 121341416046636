import EntityTranslationsContainerView from "./EntityTranslationsContainerView";

function EntityTranslationsContainer({ controller, children, style }) {
  return (
    <EntityTranslationsContainerView controller={controller} style={style}>
      {children}
    </EntityTranslationsContainerView>
  );
}

export default EntityTranslationsContainer;
