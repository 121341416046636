import { useLocation } from "react-router-dom";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import { useAppContext } from "./AppContext";
import useCustomEffect from "../hooks/useCustomEffect";
import { createContext, useContext, useState } from "react";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

const HelpTextContext = createContext();

export function HelpTextProvider({ children }) {
  const { contexts } = useAppContext();
  const callEvaApi = useEvaApi();
  const location = useLocation();
  const [helpTexts, setHelpTexts] = useState([]);

  /**
   * UseEffect for fetching the help texts when the language or user changes
   */
  useCustomEffect(() => {
    fetchHelpTexts();
  }, [contexts.languageContext?.language, contexts.authContext?.auth?.user]);

  /**
   * This method will fetch the help texts from the API
   */
  async function fetchHelpTexts() {
    if (
      !contexts.languageContext?.language ||
      !contexts.authContext?.auth?.user ||
      contexts.authContext?.auth?.user?.role_type === "terminal"
    ) {
      setHelpTexts([]);
      return;
    }

    await callEvaApi(
      new EvaApiCall("help_texts")
        .setLoadingGroup("CONTEXT")
        .setParams({
          where: [
            {
              column: "language_id",
              value: contexts.languageContext.language.id,
            },
          ],
        })
        .setOnSuccess((response) => {
          setHelpTexts(response.data.data.records);
        })
        .setAlertError(false)
        .setAlertSuccess(false)
    );
  }

  /**
   * This method will find and return the help nodes that belong to the given path
   * @param {string} path
   * @returns {object[]}
   */
  function getHelpTexts(path) {
    return helpTexts.filter((helpText) => path.startsWith(helpText.path));
  }

  /**
   * This method will return the help nodes that belong to the current path
   * @returns {object[]}
   */
  function getCurrentHelpTexts() {
    return getHelpTexts(location.pathname);
  }

  return useContextDefiner(
    new ContextDefinition("helpTextContext", HelpTextContext)
      .setData({ helpTexts, getHelpTexts, getCurrentHelpTexts })
      .setChildren(children)
  );
}

export function useHelpTextContext() {
  return useContext(HelpTextContext);
}
