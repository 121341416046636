import { useLanguageContext } from "../../../context/LanguageContext";
import DepartmentsPage from "../../../pages/departments/index/DepartmentsPage";
import { useDepartmentsRouteValidator } from "../../validators/departments/DepartmentsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useDepartmentsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/departments")
    .setLabel(translate("eva.main.entities.departments"))
    .setValidator(useDepartmentsRouteValidator())
    .setPage(<DepartmentsPage />);
}

export default useDepartmentsRouteConfig;
