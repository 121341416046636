import HouseruleStepView from "./HouseruleStepView";

function HouseruleStep({
  step,
  visitorData,
  setVisitorData,
  setPageConfig,
  processStep,
  previousStep,
}) {
  const houseruleData = visitorData.houserules?.find(
    (h) => h.id === step.file.id
  );

  /**
   * This method will handle the read event
   */
  function onRead() {
    setVisitorData((prevData) => ({
      ...prevData,
      houserules: [
        ...(prevData.houserules ?? []).filter((h) => h.id !== step.file.id),
        {
          ...houseruleData,
          id: step.file.id,
          read: true,
        },
      ],
    }));
  }

  /**
   * This method will handle the accept change event
   * @param {bool} value
   */
  function onAcceptChange(value) {
    setVisitorData((prevData) => ({
      ...prevData,
      houserules: [
        ...(prevData.houserules ?? []).filter((h) => h.id !== step.file.id),
        {
          ...houseruleData,
          id: step.file.id,
          accepted: true,
        },
      ],
    }));
  }

  return (
    <HouseruleStepView
      step={step}
      visitorData={visitorData}
      houseruleData={houseruleData}
      setPageConfig={setPageConfig}
      processStep={processStep}
      previousStep={previousStep}
      onRead={onRead}
      onAcceptChange={onAcceptChange}
    />
  );
}

export default HouseruleStep;
