import { useLanguageContext } from "../../../context/LanguageContext";
import HelpTextsPage from "../../../pages/help_texts/index/HelpTextsPage";
import { useHelpTextsRouteValidator } from "../../validators/HelpTextsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useHelpTextsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/help_texts")
    .setLabel(translate("eva.main.entities.help_texts"))
    .setValidator(useHelpTextsRouteValidator())
    .setPage(<HelpTextsPage />);
}

export default useHelpTextsRouteConfig;
