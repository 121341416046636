import { useParams } from "react-router-dom";
import { useState } from "react";
import { useRouteContext } from "../../../context/RouteContext";
import IPLockPageView from "./IPLockPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function IPLockPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { ip_lock } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [ip_lockData, setIplockData] = useState({
    ip: "",
    type: "",
    access: true,
    name: "",
  });

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (ip_lock) {
      fetchIplockData();
    }
  });

  /**
   * This method will fetch the IP lock data from the API
   */
  async function fetchIplockData() {
    await callEvaApi(
      new EvaApiCall(`ip_locks/${ip_lock}`)
        .setLoadingGroup("ip_lock_page_fetch")
        .setOnSuccess((response) => {
          setIplockData({
            ip: response.data.data.ip,
            access: response.data.data.access,
            name: response.data.data.name,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the IP on input change
   * @param {any} e
   */
  function onIpChange(e) {
    setIplockData((current) => ({ ...current, ip: e.target.value }));
  }

  /**
   * This method will update the access on input change
   * @param {any} value
   */
  function onAccessChange(value) {
    setIplockData((current) => ({ ...current, access: value }));
  }

  /**
   * This method will update the name on input change
   * @param {any} e
   */
  function onNameChange(e) {
    setIplockData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(ip_lock ? `ip_locks/${ip_lock}` : "ip_locks")
        .setLoadingGroup("ip_lock_page_submit")
        .setMethod(ip_lock ? "PUT" : "POST")
        .setData(ip_lockData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <IPLockPageView
      ip_lock={ip_lock}
      ip_lockData={ip_lockData}
      onNameChange={onNameChange}
      onIpChange={onIpChange}
      onAccessChange={onAccessChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default IPLockPage;
