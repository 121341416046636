export function getEditorContentStyles(theme) {
  return `
    * {
      margin: 0;
    }

    body {
      color: ${theme.colors.on.primary};
      font-size: ${theme.font.size.default};
      margin: ${theme.spacing.normal};
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
      padding-left: ${theme.spacing.large};
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
      padding-left: ${theme.spacing.large};
    }
    ul ul, ol ul {
      list-style-type: circle;
    }
    ul ul ul, ol ol ul, ul ol ul, ol ul ul {
      list-style-type: square;
    }
    ol ol, ul ol {
      list-style-type: lower-latin;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background: ${theme.colors.primary.lightest};
    }
    ::-webkit-scrollbar-thumb {
      border: 2px solid ${theme.colors.primary.lightest};
      background: ${theme.colors.primary.darkest};
      border-radius: 4.5px;
    }
  `;
}
