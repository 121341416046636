import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";

import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import Select from "../../../components/select/main/Select";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";

function DepartmentPageView({
  departments,
  departmentData,
  translationsController,
  onNameChange,
  onParentChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="department_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"department_page_save"}
        >
          {/* Name input */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={departmentData.name}
              onChange={onNameChange}
            />
          </InputWrapper>

          {/* Parent input */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.parent_id")}
            error={EVAApiHelper.getResponseError("parent_id", submitError)}
          >
            <Select
              value={departmentData.parent_id}
              options={departments.map((department) => {
                return { value: department.id, label: department.name };
              })}
              withNullOption={true}
              onChange={onParentChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="department_page_fetch">
        <EntityTranslationsContainer
          controller={translationsController}
          style={{ marginTop: theme.spacing.large }}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={translationsController.getTranslationError(
              "name",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={translationsController.getValue("name")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "name"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default DepartmentPageView;
