import React from "react";

function DraggableView({
  children,
  id,
  dragged,
  draggable,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
}) {
  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      "data-draggable-id": id,
      onMouseDown: child.props.onMouseDown
        ? (e) => {
            onMouseDown(e);
            child.props.onMouseDown(e);
          }
        : onMouseDown,
      onMouseEnter: child.props.onMouseEnter
        ? (e) => {
            onMouseEnter(e);
            child.props.onMouseEnter(e);
          }
        : onMouseEnter,
      onMouseLeave: child.props.onMouseLeave
        ? (e) => {
            onMouseLeave(e);
            child.props.onMouseLeave(e);
          }
        : onMouseLeave,
      style: {
        ...child.props.style,
        opacity: dragged ? 0.33 : 1,
        cursor: draggable ? "move" : "initial",
      },
    })
  );
}

export default DraggableView;
