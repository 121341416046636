import { useAppContext } from "../context/AppContext";
import useCustomEffect from "./useCustomEffect";

export class ContextDefinition {
  constructor(name, context) {
    this.name = name;
    this.context = context;
    this.data = {};
    this.children = null;
  }

  setData(data) {
    this.data = {
      ...this.data,
      ...data,
    };
    return this;
  }

  setChildren(children) {
    this.children = children;
    return this;
  }
}

/**
 * This hook will define a context in the AppContext
 * @param {ContextDefinition} contextDefinition
 * @returns {JSX.Element}
 */
function useContextDefiner(contextDefinition) {
  const appContext = useAppContext();

  /**
   * Make sure the context is registered in the AppContext on mount
   */
  useCustomEffect(() => {
    appContext.onContextChange(contextDefinition.name, contextDefinition.data);
  });

  /**
   * Update the context in the AppContext when the data changes
   */
  useCustomEffect(() => {
    appContext.onContextChange(contextDefinition.name, contextDefinition.data);
  }, getChangesArray());

  /**
   * This method will return the changes array
   * @returns {any[]}
   */
  function getChangesArray() {
    return Object.keys(contextDefinition.data)
      .filter((key) => typeof contextDefinition.data[key] !== "function")
      .map((key) => contextDefinition.data[key]);
  }

  return (
    <contextDefinition.context.Provider value={contextDefinition.data}>
      {appContext.contexts[contextDefinition.name] &&
        contextDefinition.children}
    </contextDefinition.context.Provider>
  );
}

export default useContextDefiner;
