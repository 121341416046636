import useMiddlewares from "../../middlewares/Middleware";
import useRouteValidator from "./RouteValidator";
import useRoleTypeMiddleware from "../../middlewares/RoleTypeMiddleware";
import useAuthMiddleware from "../../middlewares/AuthMiddleware";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useFrontofficeRouteValidator() { 
  return useRouteValidator(
    useMiddlewares([
      useAuthMiddleware(), 
      useRoleTypeMiddleware("terminal"),
    ])
  );
}