import { useDraggableContext } from "../../context/DraggableContext";
import useCustomEffect from "../../hooks/useCustomEffect";
import DraggableView from "./DraggableView";

function Draggable({
  children,
  id,
  draggable = true,
  onTargetEnter = (dragged, target) => {},
  onTargetLeave = (dragged, target) => {},
}) {
  const realId = `DRAGGABLE_${id}`;
  const draggableContext = useDraggableContext();
  const dragged = draggableContext.dragged === realId;

  /**
   * UseEffect to remove the draggable from the hovered list when the component unmounts.
   */
  useCustomEffect(() => {
    return () => {
      onMouseLeave();
    };
  });

  /**
   * This method will handle the mouse down event.
   * @param {Event} event
   */
  function onMouseDown(event) {
    //Dont do anything if the draggable is disabled.
    if (!draggable) {
      return;
    }

    //Check if the element is the draggable element. (needed for nested draggables)
    let element = event.target;
    while (element && element.getAttribute("data-draggable-id") !== realId) {
      //If the element is another draggable, dont do anything.
      if (element.getAttribute("data-draggable-id")?.startsWith("DRAGGABLE_")) {
        return;
      }

      //If the element is an input, select, textarea or button, dont do anything.
      if (
        ["INPUT", "SELECT", "TEXTAREA", "BUTTON"].includes(
          element.tagName.toUpperCase()
        )
      ) {
        return;
      }

      element = element.parentElement;

      //If the element is not found, dont do anything.
      if (!element) {
        return;
      }
    }

    //Start the drag event.
    draggableContext.startDrag(event, realId);
  }

  /**
   * This method will handle the mouse enter event.
   */
  function onMouseEnter() {
    if (
      draggableContext.addHovered(realId) &&
      onTargetEnter(draggableContext.dragged, realId) === true
    ) {
      draggableContext.removeHovered(realId);
    }
  }

  /**
   * This method will handle the mouse leave event.
   */
  function onMouseLeave() {
    if (draggableContext.removeHovered(realId)) {
      onTargetLeave(
        draggableContext.dragged,
        draggableContext.getLastHovered()
      );
    }
  }

  return (
    <DraggableView
      id={realId}
      dragged={dragged}
      draggable={draggable}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </DraggableView>
  );
}

export default Draggable;
