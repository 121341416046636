import { useLanguageContext } from "../../../context/LanguageContext";
import AnnouncementPage from "../../../pages/announcements/announcement/AnnouncementPage";
import useAnnouncementsRouteValidator from "../../validators/AnnouncementsRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useAnnouncementsRouteConfig from "./AnnouncementsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useAnnouncementEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/announcements/:announcement/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.announcement"),
        },
      ])
    )
    .setValidator(useAnnouncementsRouteValidator())
    .setPage(<AnnouncementPage />)
    .setParent(useAnnouncementsRouteConfig());
}

export default useAnnouncementEditRouteConfig;
