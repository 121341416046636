import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  StyledHiddenLoader,
  StyledHiddenLoaderChildContainer,
  StyledHiddenLoaderOverlay,
} from "./HiddenLoaderStyles";
import { useLoadContext } from "../../../context/LoadContext";

function HiddenLoaderView({ groups, iconSize, children, containerStyle }) {
  const loadContext = useLoadContext();
  const isLoading = loadContext.isLoading(groups);

  return (
    <StyledHiddenLoader style={containerStyle}>
      <StyledHiddenLoaderChildContainer $hide={isLoading}>
        {children}
      </StyledHiddenLoaderChildContainer>

      {isLoading ? (
        <StyledHiddenLoaderOverlay>
          <FontAwesomeIcon icon={faSpinner} size={iconSize} spin />
        </StyledHiddenLoaderOverlay>
      ) : (
        ""
      )}
    </StyledHiddenLoader>
  );
}

export default HiddenLoaderView;
