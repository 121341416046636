import useAuthMiddleware from "../../middlewares/AuthMiddleware";
import useCompanyTypeMiddleware from "../../middlewares/CompanyTypeMiddleware";
import useMiddlewares from "../../middlewares/Middleware";
import useRouteValidator from "./RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useLanguagesRouteValidator() {
  return useRouteValidator(
    useMiddlewares([
      useAuthMiddleware(),
      useCompanyTypeMiddleware("superadmin"),
    ])
  );
}
