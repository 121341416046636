import styled from "styled-components";

export const StyledDashboardNavGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledDashboardNavGroupLabel = styled.p`
  color: ${(props) => props.theme.colors.primary.lightest};
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
`;
