import { useLanguageContext } from "../../../context/LanguageContext";
import CompanyLanguagesPage from "../../../pages/company_languages/index/CompanyLanguagesPage";
import { useCompanyLanguagesRouteValidator } from "../../validators/companyLanguages/CompanyLanguagesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCompanyLanguagesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/company_languages")
    .setLabel(translate("eva.main.entities.company_languages"))
    .setValidator(useCompanyLanguagesRouteValidator())
    .setPage(<CompanyLanguagesPage />);
}

export default useCompanyLanguagesRouteConfig;
