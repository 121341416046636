import { useState } from "react";
import QuestionnaireStepView from "./QuestionnaireStepView";
import useCustomEffect from "../../../../../../hooks/useCustomEffect";

function QuestionnaireStep({
  step,
  visitorData,
  setVisitorData,
  setPageConfig,
  processStep,
  previousStep,
}) {
  const [questionnaire, setQuestionnaire] = useState(getQuestionnaire());

  /**
   * This effect will update the questionnaire object when the step changes
   */
  useCustomEffect(() => {
    setQuestionnaire(getQuestionnaire());
  }, [step.questionnaire]);

  /**
   * This method will return the new questionnaire object with random questions from each group
   * @returns {Object}
   */
  function getQuestionnaire() {
    // Clone the questionnaire object
    const questionnaire = { ...step.questionnaire };
    const groups = {};

    //Sort the questions by order
    questionnaire.questions.sort((a, b) => a.order - b.order);

    // Find the grouped questions and remove them from the questions array
    for (let i = questionnaire.questions.length - 1; i >= 0; i--) {
      const question = questionnaire.questions[i];

      //sort the answers by order
      question.answers.sort((a, b) => a.order - b.order);

      // If the question has a group, add it to the groups object
      if (question.group) {
        if (!groups[question.group]) {
          groups[question.group] = [];
        }

        groups[question.group].push({ ...question });
        questionnaire.questions.splice(i, 1);
      }
    }

    // Add one random question from each group to the questionnaire
    for (const group in groups) {
      const randomIndex = Math.floor(Math.random() * groups[group].length);
      questionnaire.questions.push(groups[group][randomIndex]);
    }

    // Return the new questionnaire
    return questionnaire;
  }

  /**
   * This method will handle the question change event
   * @param {object} question
   * @param {any} value
   */
  function onQuestionChange(question, value) {
    setVisitorData((prevData) => ({
      ...prevData,
      questions: [
        ...(prevData.questions ?? []).filter((q) => q.id !== question.id),
        {
          id: question.id,
          value: value,
        },
      ],
    }));
  }

  /**
   * This method will clear all the answers from the visitor data
   */
  function clearAnswers() {
    setVisitorData((prevData) => ({
      ...prevData,
      questions: prevData.questions.filter(
        (q) => !questionnaire.questions.find((q2) => q2.id === q.id)
      ),
    }));
  }

  /**
   * This method will handle the previous step event
   */
  async function onPreviousStep() {
    await previousStep();
    clearAnswers();
  }

  return (
    <QuestionnaireStepView
      step={step}
      questionnaire={questionnaire}
      visitorData={visitorData}
      setPageConfig={setPageConfig}
      processStep={processStep}
      previousStep={onPreviousStep}
      onQuestionChange={onQuestionChange}
    />
  );
}

export default QuestionnaireStep;
