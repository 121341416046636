class HtmlHelper {
  /**
   * This method will convert the given json object to an html string
   * @param {Object} json
   * @returns {string} The html string
   */
  static jsonToHtmlString(json) {
    if (!json) {
      return "";
    }

    /**
     * This method will process the given value and return the html string
     * @param {string} value
     * @returns {string} The html string
     */
    function processValue(value, add = "") {
      //Handle the case where the value is any type other than an object or array
      if (value === null || typeof value !== "object") {
        return typeof value === "string"
          ? `"${HtmlHelper.removeHtmlTags(value)}"${add}`
          : value;
      }
      //Handle the case where the value is an array
      else if (Array.isArray(value)) {
        let arrayString = "";
        value.forEach((element, index) => {
          arrayString += HtmlHelper.jsonHtmlIndentation(
            processValue(element, index === value.length - 1 ? "" : ",")
          );
        });

        return arrayString.length === 0 ? "[ ]" : `[${arrayString}]${add}`;
      }
      //Handle the case where the value is an object
      else {
        let objectString = "";
        Object.entries(value ?? {}).forEach(([key, val], index) => {
          objectString += HtmlHelper.jsonHtmlIndentation(
            `${HtmlHelper.jsonHtmlKey(key)}: ${processValue(
              val,
              index === Object.entries(value ?? {}).length - 1 ? "" : ","
            )}`
          );
        });

        return `{${objectString}}`;
      }
    }

    //Create the html string
    return processValue(json);
  }

  /**
   * This method will return the given key with quotes and bold
   * @param {string} key
   * @returns {string} The html string
   */
  static jsonHtmlKey(key) {
    return `<strong style="opacity: 0.5;">"${key}"</strong>`;
  }

  /**
   * This method will return the given value with indentation
   * @param {string} value
   * @returns {string} The html string
   */
  static jsonHtmlIndentation(value) {
    return `<div style="padding-left: 20px;">${value}</div>`;
  }

  /**
   * @param {string} html
   * @returns {string} The html string without tags
   */
  static removeHtmlTags(htmlString) {
    return htmlString.replace(/(<([^>]+)>)/gi, "");
  }
}

export default HtmlHelper;
