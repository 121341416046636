import { RouteConfig } from "./RouteConfig";
import { useLanguageContext } from "../../context/LanguageContext";
import { useParametersRouteValidator } from "../validators/ParametersRouteValidator";
import ParametersPage from "../../pages/parameters/index/ParametersPage";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useParametersRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/parameters")
    .setLabel(translate("eva.main.entities.parameters"))
    .setValidator(useParametersRouteValidator())
    .setPage(<ParametersPage />);
}

export default useParametersRouteConfig;
