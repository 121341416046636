import {
  StyledRange,
  StyledRangeInput,
  StyledRangeInputWrapper,
  StyledRangeThumb,
  StyledRangeValue,
} from "./RangeInputStyles";

function RangeInputView({
  inputValue,
  min,
  percentage,
  rangeRef,
  onRangeMouseDown,
  onInputChange,
}) {
  return (
    <StyledRangeInputWrapper>
      <StyledRange ref={rangeRef} onMouseDown={onRangeMouseDown}>
        <StyledRangeValue percentage={percentage}>
          <StyledRangeThumb />
        </StyledRangeValue>
      </StyledRange>

      <StyledRangeInput
        type="number"
        value={inputValue}
        placeholder={min}
        onChange={onInputChange}
      />
    </StyledRangeInputWrapper>
  );
}

export default RangeInputView;
