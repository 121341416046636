import { useLanguageContext } from "../../../../context/LanguageContext";
import LocationPage from "../../../../pages/companies/company/location/LocationPage";
import { useLocationsRouteValidator } from "../../../validators/LocationsRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useCompanyEditRouteConfig from "../CompanyEditRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useLocationCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/companies/:company/locations/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.location"),
        },
      ])
    )
    .setValidator(useLocationsRouteValidator())
    .setPage(<LocationPage />)
    .setParent(useCompanyEditRouteConfig());
}

export default useLocationCreateRouteConfig;
