import { useLanguageContext } from "../../../context/LanguageContext";
import FlowsPage from "../../../pages/flows/index/FlowsPage";
import { useFlowsRouteValidator } from "../../validators/flows/FlowsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFlowsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/flows")
    .setLabel(translate("eva.main.entities.flows"))
    .setValidator(useFlowsRouteValidator())
    .setPage(<FlowsPage />);
}

export default useFlowsRouteConfig;
