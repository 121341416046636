import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { StyledFlowPage } from "./FlowPageStyles";
import FlowPageChart from "./chart/FlowPageChart";
import FlowPageForm from "./form/FlowPageForm";
import FlowPageStepForm from "./form/step/FlowPageStepForm";

function FlowPageView({
  flow,
  flowData,
  setFlowData,
  questionnaires,
  fields,
  houserules,
  messageTemplates,
  employees,
  submitError,
  setSubmitError,
}) {
  return (
    <OverlayLoader
      groups={"flow_page_fetch"}
      containerStyle={{ width: "100%", height: "100%" }}
      iconSize={"2xl"}
    >
      <StyledFlowPage>
        <FlowPageChart
          flowData={flowData}
          setFlowData={setFlowData}
          submitError={submitError}
        />

        <FlowPageForm
          flow={flow}
          flowData={flowData}
          setFlowData={setFlowData}
          submitError={submitError}
          setSubmitError={setSubmitError}
        />

        <FlowPageStepForm
          flowData={flowData}
          setFlowData={setFlowData}
          questionnaires={questionnaires}
          fields={fields}
          houserules={houserules}
          messageTemplates={messageTemplates}
          employees={employees}
          submitError={submitError}
        />
      </StyledFlowPage>
    </OverlayLoader>
  );
}

export default FlowPageView;
