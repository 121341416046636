import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../Select";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { StyledAltIconButton } from "../../../../styles/ButtonStyles";

function IconSelect({ value, options, onChange, icon, disabled, title }) {
  return (
    <Select
      as={StyledAltIconButton}
      value={value}
      options={options}
      onChange={onChange}
      valueVisual={(value) =>
        icon ? icon(value) : <FontAwesomeIcon icon={faQuestion} />
      }
      hideCaret={true}
      disabled={disabled}
      title={title}
    />
  );
}

export default IconSelect;
