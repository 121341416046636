import { useAuthContext } from "../context/AuthContext";

function usePermissionMiddleware(permissions, type = "and") {
  permissions = Array.isArray(permissions) ? permissions : [permissions];
  const authContext = useAuthContext();

  /**
   * This method will validate the middleware
   * @returns {boolean}
   */
  function validate() {
    for (let i = 0; i < permissions.length; i++) {
      if (type === "and" && !authContext.hasPermission(permissions[i])) {
        return false;
      } else if (type === "or" && authContext.hasPermission(permissions[i])) {
        return true;
      }
    }

    return type === "and";
  }

  /**
   * This method return the redirect instructions on fail
   * @returns {{ to: string, replace: boolean }}
   */
  function redirect() {
    return { to: "/dashboard", replace: true };
  }

  //return the middleware
  return { validate, redirect };
}

export default usePermissionMiddleware;
