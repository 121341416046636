import { useLanguageContext } from "../../../context/LanguageContext";
import FieldPage from "../../../pages/fields/field/FieldPage";
import { useFieldCreateRouteValidator } from "../../validators/fields/FieldCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useFieldsRouteConfig from "./FieldsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFieldCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/fields/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.field"),
        },
      ])
    )
    .setValidator(useFieldCreateRouteValidator())
    .setPage(<FieldPage />)
    .setParent(useFieldsRouteConfig());
}

export default useFieldCreateRouteConfig;
