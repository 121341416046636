import { useLanguageContext } from "../../../context/LanguageContext";
import QuestionnairePage from "../../../pages/questionnaires/questionnaire/QuestionnairePage";
import { useQuestionnaireEditRouteValidator } from "../../validators/questionnaires/QuestionnaireEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useQuestionnairesRouteConfig from "./QuestionnairesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useQuestionnaireEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/questionnaires/:questionnaire/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.questionnaire"),
        },
      ])
    )
    .setValidator(useQuestionnaireEditRouteValidator())
    .setPage(<QuestionnairePage />)
    .setParent(useQuestionnairesRouteConfig());
}

export default useQuestionnaireEditRouteConfig;
