import { useState } from "react";
import TinyMessageEditorView from "./TinyMessageEditorView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function TinyMessageEditor({ controller }) {
  const callEvaApi = useEvaApi();
  const [editorHeight, setEditorHeight] = useState(null);
  const [fields, setFields] = useState([]);

  //Define the available tags
  const tags = [
    "date",
    "name",
    "email",
    "time",
    ...fields.map((field) => field.key),
  ].sort();

  /**
   * UseEffect to fetch the fields from the API
   */
  useCustomEffect(() => {
    fetchFields();
  });

  /**
   * UseEffect for updating the editor height when the editor is resized
   */
  useCustomEffect(() => {
    if (controller.initialized) {
      setEditorHeight(
        controller.editorRef.current.editorContainer.offsetHeight
      );

      controller.editorRef.current.on("ResizeEditor", (e) => {
        setEditorHeight(e.target.container.offsetHeight);
      });

      return () => {
        controller.editorRef.current.off("ResizeEditor");
      };
    }
  }, [controller.initialized]);

  /**
   * This message will fetch the fields from the API
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup("tiny_message_editor_fetch")
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
    );
  }

  /**
   * This method will handle the tag click
   * @param {String} tag
   */
  function onTagClick(tag) {
    if (tag && controller.initialized) {
      controller.insertAtCursor(`[${tag}]`);
    }
  }

  return (
    <TinyMessageEditorView
      controller={controller}
      tags={tags}
      onTagClick={onTagClick}
      editorHeight={editorHeight}
    />
  );
}

export default TinyMessageEditor;
