import CheckboxView from "./CheckboxView";

function Checkbox({
  value = false,
  onChange = (value) => {},
  disabled = false,
  label,
}) {
  return (
    <CheckboxView
      value={value}
      onChange={onChange}
      disabled={disabled}
      label={label}
    />
  );
}

export default Checkbox;
