import useButtonCreateRouteConfig from "../../../routes/configs/buttons/ButtonCreateRouteConfig";
import useButtonEditRouteConfig from "../../../routes/configs/buttons/ButtonEditRouteConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function ButtonsDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("buttons");

  //Set the datatable fetch info
  config.setFetchRequest("buttons");
  config.setFetchParams({
    with_button_layout_data: true,
  });

  //Set default order
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //Set the datatable comuns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("order"),
    useBasicColumnConfig("layout.name"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("button", useButtonEditRouteConfig()),
    useDeleteRowActionConfig("button"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("button", useButtonCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default ButtonsDataTable;
