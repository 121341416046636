import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import usePermissionMiddleware from "../../../middlewares/PermissionMiddleware";
import useRoleTypeMiddleware from "../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useUserCreateRouteValidator() {
  return useRouteValidator(
    useMiddlewares([
      useAuthMiddleware(),
      useRoleTypeMiddleware(["superadmin", "user"], "or"),
      usePermissionMiddleware("users.create"),
    ])
  );
}
