import styled from "styled-components";
import { StyledScreenOverlay } from "../../styles/OverlayStyles";
import { StyledCard } from "../../styles/CardStyles";

export const StyledLoginWidthConstraint = styled.div`
  max-width: 28rem;
  margin: 0px auto;
  padding: 0px ${(props) => props.theme.spacing.normal};
`;

export const StyledQRCodeWrapper = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledLoginLogo = styled.img`
  max-width: 50%;
  margin: ${(props) => props.theme.spacing.large} auto
    ${(props) => props.theme.spacing.large} auto;
  border-radius: 0;
  display: block;
`;

export const StyledLoginAnnouncementsOverlay = styled(StyledScreenOverlay)`
  overflow-y: auto;
  padding: ${(props) => props.theme.spacing.normal} 0px
    ${(props) => props.theme.spacing.large} 0px;
  color: ${(props) => props.theme.colors.on.primary};
  display: block;
`;

export const StyledLoginAnnouncementsHeader = styled.header`
  margin-block: ${(props) => props.theme.spacing.large};
`;

export const StyledLoginAnnouncementCard = styled(StyledCard)`
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;
