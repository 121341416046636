import { useLanguageContext } from "../../../context/LanguageContext";
import IPLockPage from "../../../pages/ip_locks/ip_lock/IPLockPage";
import { useIPLockEditRouteValidator } from "../../validators/ip_locks/IPLockEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useIPLocksRouteConfig from "./IPLocksRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useIPLockEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/ip_locks/:ip_lock/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.ip_lock"),
        },
      ])
    )
    .setValidator(useIPLockEditRouteValidator())
    .setPage(<IPLockPage />)
    .setParent(useIPLocksRouteConfig());
}

export default useIPLockEditRouteConfig;
