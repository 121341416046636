import { useLanguageContext } from "../../../context/LanguageContext";
import CompanyPage from "../../../pages/companies/company/CompanyPage";
import { useCompaniesRouteValidator } from "../../validators/CompaniesRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useCompaniesRouteConfig from "./CompaniesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCompanyEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/companies/:company/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.company"),
        },
      ])
    )
    .setValidator(useCompaniesRouteValidator())
    .setPage(<CompanyPage />)
    .setParent(useCompaniesRouteConfig());
}

export default useCompanyEditRouteConfig;
