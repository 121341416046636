import styled from "styled-components";
import { MultiStyle } from "../../../context/ThemeContext";

export const StyledSelect = styled.div`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.input.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-color")
      .setBack("transparent")
      .setFront(props.theme["settings.input.color.primary.border"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "border-radius")
      .setBack(props.theme.radius.default)
      .setFront(`${props.theme["settings.input.border.radius"]}px`)
      .get()}
  ${(props) =>
    MultiStyle.new(props, "padding")
      .setBack(props.theme.spacing.normal)
      .setFront(`${props.theme["settings.input.padding"]}px`)
      .get()}
  ${(props) => MultiStyle.new(props, "width").setFront("100%").get()}

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  border-width: 1px;
  border-style: solid;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};

  &:hover {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.input.color.background.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.input.color.text.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.dark)
        .setFront(props.theme["settings.input.color.primary.border.focus"])
        .get()}
  }

  &:focus-within {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setFront(props.theme["settings.input.color.background.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setFront(props.theme["settings.input.color.text.focus"])
        .get()}
    ${(props) =>
      MultiStyle.new(props, "border-color")
        .setBack(props.theme.colors.accent.normal)
        .setFront(props.theme["settings.input.color.primary.border.focus"])
        .get()}
  }
`;

export const StyledValue = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledPlaceholder = styled.p`
  ${(props) =>
    MultiStyle.new(props, "color")
      .setFront(props.theme["settings.input.color.primary.text"])
      .setBack(props.theme.colors.on.primary)
      .get()}
  opacity: 0.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledInput = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
`;

export const StyledOption = styled.option`
  ${(props) =>
    MultiStyle.new(props, "background-color")
      .setBack(props.theme.colors.primary.lightest)
      .setFront(props.theme["settings.input.color.primary.background"])
      .get()}
  ${(props) =>
    MultiStyle.new(props, "color")
      .setBack(props.theme.colors.on.primary)
      .setFront(props.theme["settings.input.color.primary.text"] ?? "inherit")
      .get()}

  &:checked {
    ${(props) =>
      MultiStyle.new(props, "background-color")
        .setBack(props.theme.colors.primary.normal)
        .get()}
    ${(props) =>
      MultiStyle.new(props, "color")
        .setBack(props.theme.colors.on.primary)
        .get()}
  }
`;

export const StyledPlaceholderOption = styled(StyledOption)`
  &:only-child {
    display: initial;
  }

  &:not(:only-child) {
    display: none;
  }
`;
