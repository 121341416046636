import { useAuthContext } from "../context/AuthContext";

function useCompanyTypeMiddleware(type) {
  const authContext = useAuthContext();

  /**
   * This method will validate the middleware
   * @returns {boolean}
   */
  function validate() {
    return authContext.auth.user?.company.type === type;
  }

  /**
   * This method return the redirect instructions on fail
   * @returns {{ to: string, replace: boolean }}
   */
  function redirect() {
    return { to: "/dashboard", replace: true };
  }

  //return the middleware
  return { validate, redirect };
}

export default useCompanyTypeMiddleware;
