import { useParams } from "react-router-dom";
import FieldPageView from "./FieldPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useState } from "react";
import { useRouteContext } from "../../../context/RouteContext";
import useEntityTranslations from "../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function FieldPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { field } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [fieldData, setFieldData] = useState({
    key: "",
    type: "text",
    fetchedType: null,
    translations: [],
  });

  const translationsController = useEntityTranslations(
    fieldData.translations,
    onTranslationsChange
  );

  //UseEffect to initialize the component
  useCustomEffect(() => {
    if (field) {
      fetchFieldData();
    }
  });

  /**
   * Fetch the field data from the API
   */
  async function fetchFieldData() {
    await callEvaApi(
      new EvaApiCall(`fields/${field}`)
        .setLoadingGroup("field_page_fetch")
        .setOnSuccess((response) => {
          const newData = response.data.data;
          setFieldData({
            key: newData.key,
            type: newData.type,
            fetchedType: newData.type,
            translations: newData.translations.map((translation) => ({
              language_id: translation.language,
              label: translation.label,
            })),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the key change
   * @param {event} e
   */
  function onKeyChange(e) {
    setFieldData((current) => ({ ...current, key: e.target.value }));
  }

  /**
   * This method will handle the type change
   * @param {any} value
   */
  function onTypeChange(value) {
    setFieldData((current) => ({ ...current, type: value }));
  }

  /**
   * Handle the translations change
   * @param {object[]} value
   */
  function onTranslationsChange(value) {
    setFieldData((current) => ({ ...current, translations: value }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(field ? `fields/${field}` : "fields")
        .setLoadingGroup("field_page_save")
        .setMethod(field ? "PUT" : "POST")
        .setData(fieldData, ["fetchedType"])
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <FieldPageView
      field={field}
      fieldData={fieldData}
      translationsController={translationsController}
      onKeyChange={onKeyChange}
      onTypeChange={onTypeChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default FieldPage;
