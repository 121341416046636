import Layout from "../../../components/layout/Layout";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import ZoomPanEditor from "../../../components/zoomPanEditor/ZoomPanEditor";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledLayoutPage } from "./LayoutPageStyles";
import MainForm from "./form/main/MainForm";
import SettingsForm from "./form/settings/SettingsForm";

function LayoutPageView({
  zoomPanController,
  layout,
  layoutData,
  setLayoutData,
  images,
}) {
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader
      groups={"layout_page_fetch"}
      containerStyle={{ height: "100%", width: "100%" }}
      iconSize={"2xl"}
    >
      <StyledLayoutPage>
        <ZoomPanEditor controller={zoomPanController}>
          <Layout
            layoutData={layoutData}
            setLayoutData={setLayoutData}
            content={translate("eva.main.layout.types.content")}
            languageSelect={translate("eva.main.layout.types.language")}
          />
        </ZoomPanEditor>

        <MainForm
          layout={layout}
          layoutData={layoutData}
          setLayoutData={setLayoutData}
        />

        <SettingsForm
          layoutData={layoutData}
          setLayoutData={setLayoutData}
          images={images}
        />
      </StyledLayoutPage>
    </OverlayLoader>
  );
}

export default LayoutPageView;
