import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  StyledAltIconButton,
  StyledButton,
  StyledTextButton,
} from "../../../styles/ButtonStyles";
import HiddenLoader from "../../loader/HiddenLoader/HiddenLoader";
import {
  StyledDataTableFooter,
  StyledDataTableHeader,
  StyledDataTableWrapper,
  StyledFromToDateTime,
  StyledHeader,
  StyledHeaderLeft,
  StyledHeaderRight,
  StyledPaginationActions,
  StyledRowActions,
  StyledSettings,
  StyledSettingsItem,
  StyledSettingsWrapper,
  StyledTable,
  StyledTableWrapper,
  StyledTd,
  StyledTdContent,
  StyledTh,
  StyledThActions,
  StyledThContent,
  StyledTr,
} from "./DataTableStyles";
import { useTheme } from "styled-components";
import {
  faBackwardFast,
  faBackwardStep,
  faCaretDown,
  faCaretUp,
  faEye,
  faEyeSlash,
  faForwardFast,
  faForwardStep,
  faGear,
  faRefresh,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Draggable from "../../draggable/Draggable";
import OverlayLoader from "../../loader/OverlayLoader/OverlayLoader";
import { StyledInput } from "../../../styles/InputStyles";
import Select from "../../select/main/Select";
import InputWrapper from "../../input/wrapper/InputWrapper";

function DataTableView({ controller }) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledDataTableWrapper>
      <StyledDataTableHeader>
        <StyledHeaderLeft>
          {/* Header */}
          {controller.config.header && (
            <StyledHeader>{controller.config.header}</StyledHeader>
          )}

          {/* Limit */}
          <Select
            title={translate("eva.main.pagination.max_results")}
            value={controller.filters.limit}
            options={controller.config.limits.map((limit) => ({
              label: limit,
              value: limit,
            }))}
            onChange={controller.onLimitChange}
          />

          {/* From - To DateTime */}
          {controller.columns.map((column) => {
            if (!column.config.useFromToDateTime) return null;
            const fromFilterItem = controller.filters.where.find(
              (w) => w.column === column.config.key && w.operator === ">="
            );
            const untilFilterItem = controller.filters.where.find(
              (w) => w.column === column.config.key && w.operator === "<="
            );

            return (
              <InputWrapper
                key={column.config.key}
                label={`${column.config.label} (${translate(
                  "eva.main.general.from"
                )} - ${translate("eva.main.general.until")})`}
              >
                <StyledFromToDateTime>
                  <StyledInput
                    type="datetime-local"
                    value={fromFilterItem?.value}
                    onChange={(e) =>
                      controller.onFromToDateTimeChange(e, column, ">=")
                    }
                  />

                  <StyledInput
                    type="datetime-local"
                    value={untilFilterItem?.value}
                    onChange={(e) =>
                      controller.onFromToDateTimeChange(e, column, "<=")
                    }
                  />
                </StyledFromToDateTime>
              </InputWrapper>
            );
          })}
        </StyledHeaderLeft>

        <StyledHeaderRight>
          {/* Header actions */}
          {controller.config.headerActions.map(
            (headerAction, index) =>
              !headerAction.hidden(controller) && (
                <HiddenLoader
                  key={index}
                  groups={headerAction.loadingGroups(controller)}
                >
                  <StyledButton
                    disabled={headerAction.disabled(controller)}
                    style={{
                      height: theme.container.size.normal,
                      width: !headerAction.label(controller)
                        ? theme.container.size.normal
                        : "",
                      padding: !headerAction.label(controller)
                        ? "0px"
                        : `0px ${theme.spacing.normal}`,
                    }}
                    onClick={() => headerAction.callback(controller)}
                    title={headerAction.title(controller)}
                  >
                    {headerAction.icon(controller) ? (
                      <FontAwesomeIcon icon={headerAction.icon(controller)} />
                    ) : (
                      ""
                    )}
                    {(headerAction.icon(controller) ? " " : "") +
                      headerAction.label(controller)}
                  </StyledButton>
                </HiddenLoader>
              )
          )}

          {/* Refresh */}
          <HiddenLoader groups={controller.config.getFetchKey()}>
            <StyledAltIconButton
              title={translate("eva.main.general.refresh")}
              onClick={() => controller.fetch()}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </StyledAltIconButton>
          </HiddenLoader>

          {/* Settings */}
          <StyledSettingsWrapper>
            {/* Settings button */}
            <StyledAltIconButton
              ref={controller.settingsDropdown.buttonRef}
              onClick={() =>
                controller.settingsDropdown.setOpen((open) => !open)
              }
              title={translate("eva.main.general.table_settings")}
            >
              <FontAwesomeIcon icon={faGear} />
            </StyledAltIconButton>

            {controller.settingsDropdown.open && (
              <StyledSettings ref={controller.settingsDropdown.dropdownRef}>
                {/* Settings columns */}
                {controller.columns.map(
                  (column) =>
                    !column.config.hidden && (
                      <Draggable
                        key={column.config.key}
                        id={`COLUMN_${column.config.key}`}
                        onTargetEnter={controller.onColumnDragEnter}
                      >
                        <StyledSettingsItem $active={column.active}>
                          {!column.config.lockActive && (
                            <StyledTextButton
                              onClick={() =>
                                controller.onColumnActiveChange(column)
                              }
                            >
                              <FontAwesomeIcon
                                icon={column.active ? faEye : faEyeSlash}
                              />
                            </StyledTextButton>
                          )}

                          {column.config.label}
                        </StyledSettingsItem>
                      </Draggable>
                    )
                )}

                {/* Settings Reset */}
                <StyledAltIconButton
                  onClick={controller.onSettingsReset}
                  style={{ marginLeft: "auto" }}
                  title={translate("eva.main.general.reset")}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </StyledAltIconButton>
              </StyledSettings>
            )}
          </StyledSettingsWrapper>
        </StyledHeaderRight>
      </StyledDataTableHeader>

      <OverlayLoader groups={controller.config.getFetchKey()} iconSize={"xl"}>
        <StyledTableWrapper>
          <StyledTable>
            <thead>
              <StyledTr>
                {controller.columns.map((column) => {
                  if (!column.active) return null;

                  const search = controller.filters.where.find(
                    (w) => w.column === column.config.key && !w.operator
                  );

                  return (
                    <StyledTh
                      key={column.config.key}
                      $highlight={
                        controller.filters.order_by === column.config.key
                      }
                      style={{ ...column.config.headerStyle }}
                    >
                      <StyledThContent>
                        {/* Header column label */}
                        {!search &&
                          !column.config.labelHeaderHidden &&
                          column.config.label}

                        {/* Header column Search */}
                        {search && (
                          <StyledInput
                            type="text"
                            placeholder={column.config.label}
                            value={search.value}
                            onChange={(e) =>
                              controller.onSearchChange(e, column)
                            }
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                controller.onSearchConfirm(column);
                              }
                            }}
                            style={{
                              width: "100%",
                              minWidth: "75px",
                            }}
                          />
                        )}

                        <StyledThActions>
                          {/* Header column order by */}
                          {column.config.orderable && (
                            <StyledTextButton
                              title={translate("eva.main.general.sort")}
                              onClick={() => controller.onOrderByPress(column)}
                            >
                              <FontAwesomeIcon
                                size="sm"
                                icon={
                                  controller.filters.order_direction === "asc"
                                    ? faCaretUp
                                    : faCaretDown
                                }
                              />
                            </StyledTextButton>
                          )}

                          {/* Header column search activate */}
                          {column.config.searchable && !search && (
                            <StyledTextButton
                              title={translate("eva.main.general.search")}
                              onClick={() => controller.onSearchPress(column)}
                            >
                              <FontAwesomeIcon size="xs" icon={faSearch} />
                            </StyledTextButton>
                          )}

                          {/* Header column search cancel */}
                          {search && (
                            <StyledTextButton
                              onClick={() =>
                                controller.onSearchCancelPress(column)
                              }
                            >
                              <FontAwesomeIcon size="xs" icon={faXmark} />
                            </StyledTextButton>
                          )}
                        </StyledThActions>
                      </StyledThContent>
                    </StyledTh>
                  );
                })}

                {/* Row actions header column */}
                {controller.config.rowActions.length > 0 && (
                  <StyledTh style={{ width: 0 }}></StyledTh>
                )}
              </StyledTr>
            </thead>

            <tbody>
              {controller.data.records.map((row) => (
                <StyledTr key={row.id}>
                  {/* Row column data */}
                  {controller.columns.map((column) => {
                    if (!column.active) return null;

                    return (
                      <StyledTd key={column.config.key}>
                        {column.config.dataAsHtml ? (
                          <StyledTdContent
                            dangerouslySetInnerHTML={{
                              __html: column.config.data(row, controller),
                            }}
                          />
                        ) : (
                          <StyledTdContent>
                            {column.config.data(row, controller)}
                          </StyledTdContent>
                        )}
                      </StyledTd>
                    );
                  })}

                  {/* Row actions */}
                  {controller.config.rowActions.length > 0 && (
                    <StyledTd>
                      <HiddenLoader
                        groups={controller.config.rowActionsLoadingGroups(row)}
                      >
                        <StyledRowActions>
                          {controller.config.rowActions.map(
                            (rowAction, index) =>
                              !rowAction.hidden(row, controller) && (
                                <HiddenLoader
                                  key={index}
                                  groups={rowAction.loadingGroups(
                                    row,
                                    controller
                                  )}
                                >
                                  <StyledTextButton
                                    title={rowAction.title(row, controller)}
                                    disabled={rowAction.disabled(
                                      row,
                                      controller
                                    )}
                                    onClick={() =>
                                      rowAction.callback(row, controller)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={rowAction.icon(row, controller)}
                                    />
                                  </StyledTextButton>
                                </HiddenLoader>
                              )
                          )}
                        </StyledRowActions>
                      </HiddenLoader>
                    </StyledTd>
                  )}
                </StyledTr>
              ))}

              {/* No results found */}
              {controller.data.records.length === 0 && (
                <StyledTr>
                  <StyledTd colSpan={"100%"} style={{ textAlign: "center" }}>
                    {translate("eva.main.general.no_results")}
                  </StyledTd>
                </StyledTr>
              )}
            </tbody>
          </StyledTable>
        </StyledTableWrapper>
      </OverlayLoader>

      {controller.data.pagination && (
        <StyledDataTableFooter>
          {/* Pagination info */}
          <small>
            {translate("eva.main.pagination.results", [
              { key: "first", value: controller.data.pagination.first_record },
              { key: "last", value: controller.data.pagination.last_record },
              { key: "total", value: controller.data.pagination.total_records },
            ])}
          </small>

          {/* Pagination buttons */}
          <StyledPaginationActions>
            {/* First page button */}
            <StyledAltIconButton
              title={translate("eva.main.pagination.first_page")}
              disabled={controller.data.pagination.page <= 1}
              onClick={() => controller.onPageChange(1)}
            >
              <FontAwesomeIcon icon={faBackwardFast} />
            </StyledAltIconButton>

            {/* Previous page button */}
            <StyledAltIconButton
              title={translate("eva.main.pagination.previous_page")}
              disabled={controller.data.pagination.page <= 1}
              onClick={() =>
                controller.onPageChange(controller.data.pagination.page - 1)
              }
            >
              <FontAwesomeIcon icon={faBackwardStep} />
            </StyledAltIconButton>

            {/* Next page button */}
            <StyledAltIconButton
              title={translate("eva.main.pagination.next_page")}
              disabled={
                controller.data.pagination.page >=
                controller.data.pagination.total_pages
              }
              onClick={() => {
                controller.onPageChange(controller.data.pagination.page + 1);
              }}
            >
              <FontAwesomeIcon icon={faForwardStep} />
            </StyledAltIconButton>

            {/* Last page button */}
            <StyledAltIconButton
              title={translate("eva.main.pagination.last_page")}
              disabled={
                controller.data.pagination.page >=
                controller.data.pagination.total_pages
              }
              onClick={() => {
                controller.onPageChange(controller.data.pagination.total_pages);
              }}
            >
              <FontAwesomeIcon icon={faForwardFast} />
            </StyledAltIconButton>
          </StyledPaginationActions>
        </StyledDataTableFooter>
      )}
    </StyledDataTableWrapper>
  );
}

export default DataTableView;
