import { useAlertContext } from "../../../../context/AlertContext";
import MainFooterAlertsView from "./MainFooterAlertsView";

function MainFooterAlerts() {
  const alertContext = useAlertContext();

  function onHideAlert(alert) {
    alertContext.hideAlert(alert.uuid);
  }

  return (
    <MainFooterAlertsView
      alerts={alertContext.alerts}
      onHideAlert={onHideAlert}
    />
  );
}

export default MainFooterAlerts;
