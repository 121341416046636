import CalamityActivationFormView from "./CalamityActivationFormView";
import { useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useCalamityContext } from "../../../context/CalamityContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function CalamityActivationForm({ onClose }) {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const calamityContext = useCalamityContext();

  const [tools, setTools] = useState([]);
  const [types, setTypes] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [calamityData, setCalamityData] = useState({
    calamity_type_id: "",
    calamity_tool_id: "",
    comment: "",
    location_id: authContext.auth.location,
  });

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    fetchTools();
    fetchTypes();
  });

  /**
   * This method will fetch the available calamity tools from the API
   */
  async function fetchTools() {
    await callEvaApi(
      new EvaApiCall("calamity_tools")
        .setLoadingGroup("calamity_activation_form_fetch")
        .setOnSuccess((response) => {
          setTools(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the available calamity types from the API
   */
  async function fetchTypes() {
    await callEvaApi(
      new EvaApiCall("calamity_types")
        .setLoadingGroup("calamity_activation_form_fetch")
        .setOnSuccess((response) => {
          setTypes(response.data.data.records);
        })
    );
  }

  /**
   * This method will update the calamity location on input change
   * @param {any} value
   */
  function onLocationIdChange(value) {
    setCalamityData({ ...calamityData, location_id: value });
  }

  /**
   * This method will update the calamity calamity_type_id on input change
   * @param {any} value
   */
  function onTypeChange(value) {
    setCalamityData({ ...calamityData, calamity_type_id: value });
  }

  /**
   * This method will update the calamity calamity_tool_id on input change
   * @param {any} value
   */
  function onToolChange(value) {
    setCalamityData({ ...calamityData, calamity_tool_id: value });
  }

  /**
   * This method will update the calamity comment on input change
   * @param {any} value
   */
  function onCommentChange(e) {
    setCalamityData({ ...calamityData, comment: e.target.value });
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall("calamities")
        .setMethod("POST")
        .setLoadingGroup("calamity_activation_form_submit")
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          onClose();
          calamityContext.fetchCalamities();
        })
        .setData(calamityData)
    );
  }

  return (
    <CalamityActivationFormView
      types={types}
      tools={tools}
      onClose={onClose}
      calamityData={calamityData}
      onLocationIdChange={onLocationIdChange}
      onTypeChange={onTypeChange}
      onToolChange={onToolChange}
      onCommentChange={onCommentChange}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}
export default CalamityActivationForm;
