import useTerminalCreateRouteConfig from "../../../routes/configs/terminals/TerminalCreateRouteConfig";
import useTerminalEditRouteConfig from "../../../routes/configs/terminals/TerminalEditRouteConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";

function TerminalsDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("terminals");

  //Set the datatable fetch info
  config.setFetchRequest("terminals");
  config.setFetchParams({
    with_terminal_location_data: true,
    with_terminal_layout_data: true,
  });

  //Set the datatable order by
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location.name"),
    useBasicColumnConfig("name"),
    useBasicColumnConfig("layout.name"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("terminal", useTerminalEditRouteConfig()),
    useDeleteRowActionConfig("terminal"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("terminal", useTerminalCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default TerminalsDataTable;
