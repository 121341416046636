import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledScreenLoaderOverlay,
  StyledScreenLoaderOverlayInner,
  StyledScreenOverlayLogo,
} from "./ScreenLoaderStyles";
import { useLoadContext } from "../../../context/LoadContext";

function ScreenLoaderView({ groups }) {
  const loadContext = useLoadContext();

  return loadContext.isLoading(groups) ? (
    <StyledScreenLoaderOverlay>
      <StyledScreenLoaderOverlayInner>
        <StyledScreenOverlayLogo src="/assets/images/logo.png" alt="EVA" />
        <FontAwesomeIcon icon={faSpinner} spin size="xl" />
      </StyledScreenLoaderOverlayInner>
    </StyledScreenLoaderOverlay>
  ) : (
    ""
  );
}

export default ScreenLoaderView;
