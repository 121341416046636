import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledTextButton } from "../../../../../../styles/ButtonStyles";
import {
  StyledHierarchy,
  StyledHierarchyItem,
  StyledHierarchyItemActions,
  StyledHierarchyItemChildren,
  StyledHierarchyItemLine,
  StyledHierarchyItemWrapper,
} from "./HierarchyStyles";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import Draggable from "../../../../../../components/draggable/Draggable";
import { useDraggableContext } from "../../../../../../context/DraggableContext";

function HierarchyView({
  layoutData,
  onAdd,
  onEdit,
  onDelete,
  onMouseEnter,
  onMouseLeave,
  onDragEnter,
}) {
  const draggableContext = useDraggableContext();
  const { translate } = useLanguageContext();

  function render(item, nested = false) {
    return (
      <div key={item.id}>
        <StyledHierarchyItemWrapper>
          {/* Attach line */}
          {nested && <StyledHierarchyItemLine />}

          {/* Item */}
          <Draggable
            id={item.id}
            onTargetEnter={onDragEnter}
            draggable={item.type !== "page"}
          >
            <StyledHierarchyItem
              id={item.id}
              onClick={(e) => onEdit(item, e)}
              onMouseMove={() => onMouseEnter(item)}
              onMouseLeave={() => onMouseLeave(item)}
              hovered={
                item.id === layoutData.hovered_item_id &&
                !draggableContext.dragged
              }
            >
              <p>{translate(`eva.main.layout.types.${item.type}`)}</p>

              <StyledHierarchyItemActions>
                {/* Add button */}
                {(item.type === "page" || item.type === "box") && (
                  <StyledTextButton onClick={() => onAdd(item)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </StyledTextButton>
                )}

                {/* Add select */}
                {item.type === "subbox" && (
                  <Select
                    as={StyledTextButton}
                    valueVisual={(value) => <FontAwesomeIcon icon={faPlus} />}
                    hideCaret={true}
                    options={[
                      {
                        value: "language",
                        label: translate(`eva.main.layout.types.language`),
                      },
                      {
                        value: "image",
                        label: translate(`eva.main.layout.types.image`),
                      },
                      {
                        value: "content",
                        label: translate(`eva.main.layout.types.content`),
                      },
                    ]}
                    onChange={(value) => onAdd(item, value)}
                  />
                )}

                {/* Edit button */}
                <StyledTextButton onClick={() => onEdit(item)}>
                  <FontAwesomeIcon icon={faPen} />
                </StyledTextButton>

                {/* Delete button */}
                {item.type !== "page" && (
                  <StyledTextButton onClick={() => onDelete(item)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </StyledTextButton>
                )}
              </StyledHierarchyItemActions>
            </StyledHierarchyItem>
          </Draggable>
        </StyledHierarchyItemWrapper>

        {/* Children */}
        {item.children.length > 0 && (
          <StyledHierarchyItemChildren nested={nested}>
            {item.children.map((child) => render(child, true))}
          </StyledHierarchyItemChildren>
        )}
      </div>
    );
  }

  return <StyledHierarchy>{render(layoutData.layout)}</StyledHierarchy>;
}

export default HierarchyView;
