import { useLanguageContext } from "../../../context/LanguageContext";
import RolePage from "../../../pages/roles/role/RolePage";
import { useRoleCreateRouteValidator } from "../../validators/roles/RolesCreateValidator";
import { RouteConfig } from "../RouteConfig";
import useRolesRouteConfig from "./RolesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useRoleCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/roles/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.role"),
        },
      ])
    )
    .setValidator(useRoleCreateRouteValidator())
    .setPage(<RolePage />)
    .setParent(useRolesRouteConfig());
}

export default useRoleCreateRouteConfig;
