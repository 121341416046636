import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import SchedulingForm from "../../../../components/schedulingForm/SchedulingForm";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import MultiSelect from "../../../../components/select/main/multiSelect/MultiSelect";
import LocationFormSelect from "../../../../components/select/custom/location/form/LocationFormSelect";
import ImageInput from "../../../../components/input/image/ImageInput";

function DishPageView({
  dish,
  dishData,
  allergens,
  onLocationIdChange,
  onImageChange,
  onNameChange,
  onPriceChange,
  onCapacityChange,
  onCaloriesChange,
  onHealthScoreChange,
  onCategoryChange,
  onDescriptionChange,
  onAllergensChange,
  onScheduleChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexLayout
      style={{ flexDirection: "column", gap: theme.spacing.large }}
    >
      <OverlayLoader groups="canteen_dish_page_fetch">
        <FormContainer
          nullLocation={false}
          locationValue={dishData.location_id}
          onLocationChange={onLocationIdChange}
          locationDisabled={dish !== undefined}
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"canteen_dish_page_save"}
        >
          <InputWrapper
            label={translate("eva.main.entities.location")}
            error={EVAApiHelper.getResponseError("location_id", submitError)}
            margin={`0px 0px ${theme.spacing.normal}`}
          >
            <LocationFormSelect
              value={dishData.location_id}
              onChange={onLocationIdChange}
              disabled={dish !== undefined}
              useNullOption={false}
            />
          </InputWrapper>

          <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal} 0px`}>
            {/* Image input */}
            <InputWrapper
              error={EVAApiHelper.getResponseError("image", submitError)}
            >
              <ImageInput value={dishData.image} onChange={onImageChange} />
            </InputWrapper>

            <StyledFlexLayoutItem>
              <StyledFlexLayout
                margin={`0px 0px ${theme.spacing.normal} 0px`}
                alignItems="start"
              >
                <StyledFlexLayoutItem>
                  {/* Name input */}
                  <InputWrapper
                    label={translate("eva.main.validation.attributes.name")}
                    required
                    error={EVAApiHelper.getResponseError("name", submitError)}
                  >
                    <StyledInput
                      type="text"
                      placeholder={translate(
                        "eva.main.validation.attributes.name"
                      )}
                      value={dishData.name}
                      onChange={onNameChange}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>

                {/* Price input */}
                <InputWrapper
                  label={translate("eva.main.validation.attributes.price")}
                  required
                  error={EVAApiHelper.getResponseError("price", submitError)}
                >
                  <StyledInput
                    type="number"
                    value={dishData.price}
                    onChange={onPriceChange}
                  />
                </InputWrapper>

                {/* Capacity input */}
                <InputWrapper
                  label={translate("eva.main.validation.attributes.capacity")}
                  required
                  error={EVAApiHelper.getResponseError("capacity", submitError)}
                >
                  <StyledInput
                    type="number"
                    value={dishData.capacity}
                    onChange={onCapacityChange}
                  />
                </InputWrapper>
              </StyledFlexLayout>

              {/* Description input */}
              <InputWrapper
                label={translate("eva.main.validation.attributes.description")}
                error={EVAApiHelper.getResponseError(
                  "description",
                  submitError
                )}
              >
                <StyledInput
                  as="textarea"
                  rows={4}
                  placeholder={translate(
                    "eva.main.validation.attributes.description"
                  )}
                  value={dishData.description}
                  onChange={onDescriptionChange}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>

          {/* Category input */}
          <InputWrapper
            style={{ marginBottom: theme.spacing.normal }}
            label={translate("eva.main.validation.attributes.category")}
            error={EVAApiHelper.getResponseError("category", submitError)}
          >
            <StyledInput
              type="text"
              value={dishData.category}
              onChange={onCategoryChange}
              placeholder={translate("eva.main.validation.attributes.category")}
            />
          </InputWrapper>

          {/* Allergen select */}
          <InputWrapper
            label={translate("eva.main.entities.allergens")}
            error={EVAApiHelper.getResponseErrorArray(
              "allergens",
              submitError,
              (index) =>
                translate(`eva.main.allergens.${allergens[index].name}`)
            )}
          >
            <MultiSelect
              options={allergens.map((allergen) => {
                return {
                  value: allergen.id,
                  label: translate(`eva.main.allergens.${allergen.name}`),
                };
              })}
              values={dishData.allergens}
              onChange={onAllergensChange}
            />
          </InputWrapper>

          <StyledFlexLayout style={{ marginBlock: theme.spacing.normal }}>
            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.calories")}
                error={EVAApiHelper.getResponseError("calories", submitError)}
              >
                <StyledInput
                  type="number"
                  value={dishData.calories}
                  onChange={onCaloriesChange}
                  placeholder={translate(
                    "eva.main.validation.attributes.calories"
                  )}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>

            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.health_score")}
                error={EVAApiHelper.getResponseError(
                  "health_score",
                  submitError
                )}
              >
                <StyledInput
                  type="number"
                  value={dishData.health_score}
                  onChange={onHealthScoreChange}
                  placeholder={translate(
                    "eva.main.validation.attributes.health_score"
                  )}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader groups="canteen_dish_page_fetch">
        <SchedulingForm
          schedule={dishData.schedule}
          onScheduleChange={onScheduleChange}
          submitError={submitError}
        />
      </OverlayLoader>
    </StyledFlexLayout>
  );
}

export default DishPageView;
