import { useTheme } from "styled-components";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../../context/LanguageContext";
import Select from "../../../../components/select/main/Select";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import FormContainer from "../../../../components/formContainer/FormContainer";
import { StyledInput } from "../../../../styles/InputStyles";
import EntityTranslationsContainer from "../../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";

function FieldConditionPageView({
  fieldData,
  fieldConditionData,
  translationsController,
  onConditionChange,
  onValueChange,
  onDateRelativeChange,
  onActiveChange,
  onStopRegistrationChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  /**
   * This method will return the conditions based on the field type
   * @returns {object[]}
   */
  function getConditions() {
    switch (fieldData?.type) {
      case "boolean":
        return ["equals", "not_equals"];
      default:
        return [
          "equals",
          "not_equals",
          "greater_than",
          "greater_than_or_equals",
          "less_than",
          "less_than_or_equals",
        ];
    }
  }

  /**
   * This method will return the value field based on the field type
   * @returns {JSX.Element} The value field
   */
  function getValueField() {
    switch (fieldData?.type) {
      case "boolean":
        return (
          <Select
            value={fieldConditionData.value}
            onChange={onValueChange}
            options={[
              { value: true, label: translate("eva.main.general.yes") },
              { value: false, label: translate("eva.main.general.no") },
            ]}
          />
        );

      default:
        return (
          <StyledInput
            type={
              fieldData?.type === "text" ? "number" : fieldData?.type || "text"
            }
            value={fieldConditionData.value}
            placeholder={translate("eva.main.validation.attributes.value")}
            onChange={(e) => onValueChange(e.target.value)}
          />
        );
    }
  }

  return (
    <>
      <OverlayLoader groups="field_condition_page_fetch">
        <FormContainer
          header={translate("eva.main.general.data")}
          submitError={submitError}
          submitLoadingGroup={`field_condition_page_save`}
          onCancel={onCancel}
          onSubmit={onSubmit}
        >
          <StyledFlexLayout style={{ flexDirection: "column" }}>
            <StyledFlexLayout>
              {/* Condition field */}
              <InputWrapper
                label={translate("eva.main.validation.attributes.condition")}
                error={EVAApiHelper.getResponseError("condition", submitError)}
                required
              >
                <Select
                  value={fieldConditionData.condition}
                  onChange={onConditionChange}
                  options={getConditions().map((condition) => ({
                    value: condition,
                    label: translate(`eva.main.conditions.${condition}`),
                  }))}
                />
              </InputWrapper>

              {/* Value field */}
              <StyledFlexLayoutItem>
                <InputWrapper
                  label={translate(
                    `eva.main.validation.attributes.${
                      fieldData?.type === "text" ? "char_count" : "value"
                    }`
                  )}
                  error={EVAApiHelper.getResponseError("value", submitError)}
                  required
                >
                  {getValueField()}
                </InputWrapper>
              </StyledFlexLayoutItem>
            </StyledFlexLayout>

            {/* Date relative field */}
            {fieldData?.type === "date" && (
              <InputWrapper
                label={translate(
                  "eva.main.validation.attributes.date_relative"
                )}
                error={EVAApiHelper.getResponseError(
                  "date_relative",
                  submitError
                )}
              >
                <Select
                  value={fieldConditionData.date_relative}
                  onChange={onDateRelativeChange}
                  options={[
                    { value: true, label: translate("eva.main.general.yes") },
                    { value: false, label: translate("eva.main.general.no") },
                  ]}
                />
              </InputWrapper>
            )}

            {/* Active field */}
            <InputWrapper
              label={translate("eva.main.validation.attributes.active")}
              error={EVAApiHelper.getResponseError("active", submitError)}
            >
              <Select
                value={fieldConditionData.active}
                onChange={onActiveChange}
                options={[
                  { value: true, label: translate("eva.main.general.yes") },
                  { value: false, label: translate("eva.main.general.no") },
                ]}
              />
            </InputWrapper>

            {/* Stop registration field */}
            <InputWrapper
              label={translate(
                "eva.main.validation.attributes.stop_registration"
              )}
              error={EVAApiHelper.getResponseError(
                "stop_registration",
                submitError
              )}
            >
              <Select
                value={fieldConditionData.stop_registration}
                onChange={onStopRegistrationChange}
                options={[
                  { value: true, label: translate("eva.main.general.yes") },
                  { value: false, label: translate("eva.main.general.no") },
                ]}
              />
            </InputWrapper>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      {/* Translations form */}
      <OverlayLoader
        groups="field_condition_page_fetch"
        containerStyle={{ marginTop: theme.spacing.large }}
      >
        <EntityTranslationsContainer controller={translationsController}>
          {/* Message translation field */}
          <InputWrapper
            label={translate("eva.main.validation.attributes.message")}
            error={translationsController.getTranslationError(
              "message",
              submitError
            )}
          >
            <StyledInput
              as="textarea"
              value={translationsController.getValue("message")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "message"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default FieldConditionPageView;
