import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledTextButton } from "../../../styles/ButtonStyles";
import {
  StyledFile,
  StyledFileInput,
  StyledFileName,
  StyledFiles,
  StyledPlaceHolder,
  StyledUploadButton,
} from "./FileInputStyles";
import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import FileHelper from "../../../helpers/FileHelper";
import FilePreview from "../../filePreview/FilePreview";

function FileInputView({ values, onSelect, onRemove, getFileName }) {
  const { translate } = useLanguageContext();

  return (
    <StyledFileInput>
      <StyledFiles>
        {values.map((value, index) => (
          <StyledFile key={value.toString() + index}>
            <FilePreview path={value}>
              <StyledFileName>
                <FontAwesomeIcon
                  icon={FileHelper.pathIcon(getFileName(value))}
                  size="lg"
                />

                {getFileName(value)}
              </StyledFileName>
            </FilePreview>

            <StyledTextButton onClick={() => onRemove(value)}>
              <FontAwesomeIcon icon={faXmark} />
            </StyledTextButton>
          </StyledFile>
        ))}

        {values.length <= 0 && (
          <StyledPlaceHolder>
            {translate("eva.main.general.select")}
          </StyledPlaceHolder>
        )}
      </StyledFiles>

      <StyledUploadButton onClick={onSelect}>
        <FontAwesomeIcon icon={faDownload} />
      </StyledUploadButton>
    </StyledFileInput>
  );
}

export default FileInputView;
