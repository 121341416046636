import { useLanguageContext } from "../../../context/LanguageContext";
import FlowPage from "../../../pages/flows/flow/FlowPage";
import { useFlowEditRouteValidator } from "../../validators/flows/FlowsEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useFlowsRouteConfig from "./FlowsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFlowEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/flows/:flow/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.flow"),
        },
      ])
    )
    .setValidator(useFlowEditRouteValidator())
    .setPage(<FlowPage />)
    .setParent(useFlowsRouteConfig());
}

export default useFlowEditRouteConfig;
