import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  StyledInput,
  StyledOption,
  StyledPlaceholder,
  StyledPlaceholderOption,
  StyledSelect,
  StyledValue,
} from "./SelectStyles";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function SelectView({
  as: Element = StyledSelect,
  disabled,
  valueVisual,
  value,
  selectedOption,
  options,
  onChange,
  hideCaret,
  title,
  placeholder,
}) {
  const { translate } = useLanguageContext();

  return (
    <Element disabled={disabled} style={{ position: "relative" }}>
      {valueVisual ? (
        // Custom value visual
        valueVisual(value)
      ) : selectedOption?.label ? (
        // Selected option
        <StyledValue>{selectedOption?.label}</StyledValue>
      ) : (
        // Placeholder
        <StyledPlaceholder>
          {placeholder ?? translate("eva.main.general.select")}
        </StyledPlaceholder>
      )}

      {/* Caret */}
      {!hideCaret && <FontAwesomeIcon icon={faCaretDown} size="sm" />}

      {/* Actual select */}
      <StyledInput
        value={!selectedOption ? "SELECT_PLACEHOLDER_VALUE" : `${value}`}
        onChange={onChange}
        disabled={disabled}
        title={title}
      >
        {/* options */}
        {options?.map((option) => (
          <StyledOption
            key={option.value}
            value={`${option.value}`}
            disabled={option.disabled}
          >
            {option.label}
          </StyledOption>
        ))}

        {/* placeholder value options */}
        <StyledPlaceholderOption value="SELECT_PLACEHOLDER_VALUE"></StyledPlaceholderOption>
      </StyledInput>
    </Element>
  );
}

export default SelectView;
