import { useLanguageContext } from "../../../context/LanguageContext";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import LocationFormSelect from "../../../components/select/custom/location/form/LocationFormSelect";
import MultiSelect from "../../../components/select/main/multiSelect/MultiSelect";
import Select from "../../../components/select/main/Select";
import { StyledFlexLayout } from "../../../styles/LayoutStyles";

function TerminalPageView({
  terminal,
  terminalData,
  buttons,
  visitorTypes,
  screensavers,
  layouts,
  onButtonsChange,
  onNameChange,
  onLocationIdChange,
  onLayoutIdChange,
  onVisitorTypesChange,
  onScreensaversChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="terminal_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"terminal_page_save"}
      >
        <StyledFlexLayout style={{ flexDirection: "column" }}>
          <InputWrapper
            label={translate("eva.main.entities.location")}
            error={EVAApiHelper.getResponseError("location_id", submitError)}
            required
          >
            <LocationFormSelect
              value={terminalData.location_id}
              onChange={onLocationIdChange}
              disabled={terminal !== undefined}
              useNullOption={false}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            required
          >
            <StyledInput
              type="text"
              onChange={onNameChange}
              placeholder={translate("eva.main.validation.attributes.name")}
              value={terminalData.name}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.buttons")}
            error={EVAApiHelper.getResponseErrorArray(
              "buttons",
              submitError,
              (index) =>
                buttons.find(
                  (button) => terminalData.buttons[index] === button.id
                )?.name
            )}
          >
            <MultiSelect
              options={buttons.map((button) => {
                return {
                  value: button.id,
                  label: button.name,
                };
              })}
              values={terminalData.buttons}
              onChange={onButtonsChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.visitor_types")}
            error={EVAApiHelper.getResponseErrorArray(
              "visitor_types",
              submitError,
              (index) =>
                visitorTypes.find(
                  (visType) => terminalData.visitor_types[index] === visType.id
                )?.name
            )}
          >
            <MultiSelect
              options={visitorTypes.map((visitorType) => {
                return {
                  value: visitorType.id,
                  label: visitorType.name,
                };
              })}
              values={terminalData.visitor_types}
              onChange={onVisitorTypesChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.screensavers")}
            error={EVAApiHelper.getResponseErrorArray(
              "screensavers",
              submitError,
              (index) =>
                screensavers.find(
                  (screensaver) =>
                    terminalData.screensavers[index] === screensaver.id
                )?.name
            )}
          >
            <MultiSelect
              options={screensavers.map((screensaver) => {
                return {
                  value: screensaver.id,
                  label: screensaver.name,
                };
              })}
              values={terminalData.screensavers}
              onChange={onScreensaversChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.layout_id")}
            error={EVAApiHelper.getResponseError("layout_id", submitError)}
          >
            <Select
              options={layouts.map((layout) => ({
                value: layout.id,
                label: layout.name,
              }))}
              value={terminalData.layout_id}
              onChange={onLayoutIdChange}
            />
          </InputWrapper>
        </StyledFlexLayout>
      </FormContainer>
    </OverlayLoader>
  );
}

export default TerminalPageView;
