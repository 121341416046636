import styled from "styled-components";
import { StyledButton } from "../../../../styles/ButtonStyles";

export const StyledDashboardButton = styled(StyledButton)`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
  width: 100%;
  text-align: left;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: ${(props) =>
    props.$active
      ? props.theme.colors.accent.normal
      : props.theme.colors.primary.lightest};
  color: ${(props) =>
    props.$active
      ? props.theme.colors.on.accent
      : props.theme.colors.on.primary};
`;

export const StyledButtonLabel = styled.label`
  opacity: ${(props) => (props.$hide ? "0%" : "100%")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonIcon = styled.div`
  width: 17.5px;
`;
