import {
  ThemeProvider as OriginalThemeProvider,
  useTheme,
} from "styled-components";
import DarkTheme from "../themes/DarkTheme";
import { createContext, useContext, useState } from "react";
import useEvaApi, { EvaApiCall } from "../hooks/useEvaApi";
import { useAppContext } from "./AppContext";
import useCustomEffect from "../hooks/useCustomEffect";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";

export let FRONTOFFICE_THEME = false;

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const callEvaApi = useEvaApi();
  const { contexts } = useAppContext();
  const [data, setData] = useState({
    theme: DarkTheme,
    settings: null,
  });
  const theme = {
    ...data.theme,
    ...data.settings,
  };

  /**
   * UseEffect for fetching the theme settings
   */
  useCustomEffect(() => {
    fetchThemeSettings();
  }, [contexts.authContext?.auth?.user?.role_type]);

  useCustomEffect(() => {
    FRONTOFFICE_THEME = data.settings !== null;
  }, [data.settings]);

  /**
   * This method will fetch the theme settings from the API
   */
  async function fetchThemeSettings() {
    if (contexts.authContext?.auth?.user?.role_type !== "terminal") {
      return;
    }

    await callEvaApi(
      new EvaApiCall("theme_settings")
        .setLoadingGroup("CONTEXT")
        .setOnSuccess((response) => {
          setData((prev) => {
            const newSettings = {};
            response.data.data.records.forEach((record) => {
              newSettings[`settings.${record.key}`] = record.value;
            });

            return {
              ...prev,
              settings: newSettings,
            };
          });
        })
    );
  }

  return useContextDefiner(
    new ContextDefinition("themeContext", ThemeContext).setChildren(
      <OriginalThemeProvider theme={theme}>{children}</OriginalThemeProvider>
    )
  );
}

export function useThemeContext() {
  return useContext(ThemeContext);
}

export class MultiStyle {
  constructor(props, attribute) {
    this.props = props;
    this.attribute = attribute;
    this.frontoffice = "";
    this.backoffice = "";
  }

  setFront(value) {
    this.frontoffice = value;
    return this;
  }

  setBack(value) {
    this.backoffice = value;
    return this;
  }

  get() {
    let value = this.props.theme.FRONTOFFICE
      ? this.frontoffice
      : this.backoffice;

    return value && `${this.attribute}: ${value};`;
  }

  static new(props, attribute) {
    return new MultiStyle(props, attribute);
  }
}

export function Themed({ children, frontoffice = false }) {
  const theme = useTheme();
  return (
    <OriginalThemeProvider theme={{ ...theme, FRONTOFFICE: frontoffice }}>
      {children}
    </OriginalThemeProvider>
  );
}
