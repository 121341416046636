import CalamityTableView from "./CalamityTableView";
import { useAuthContext } from "../../context/AuthContext";
import { useCalamityContext } from "../../context/CalamityContext";
import useEvaApi, { EvaApiCall } from "../../hooks/useEvaApi";

function CalamityTable() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();

  const company = authContext.auth?.user?.company;
  const calamityContext = useCalamityContext();

  /**
   * This method will stop and delete the specified calamity
   */
  async function stopCalamity(calamity) {
    await callEvaApi(
      new EvaApiCall(`calamities/${calamity.id}`)
        .setMethod("DELETE")
        .setLoadingGroup(`calamities_stop_page_${calamity.id}`)
    );

    calamityContext.fetchCalamities();
  }

  return (
    <CalamityTableView
      company={company}
      calamityContext={calamityContext}
      stopCalamity={stopCalamity}
    />
  );
}

export default CalamityTable;
