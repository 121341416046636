import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  StyledDashboardNavGroup,
  StyledDashboardNavGroupLabel,
} from "./DashboardNavGroupStyles";
import { StyledTextButton } from "../../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguageContext } from "../../../../context/LanguageContext";

function DashboardNavGroupView({
  label,
  folded,
  visible,
  onVisabilityChange,
  children,
}) {
  const { translate } = useLanguageContext();

  return (
    <StyledDashboardNavGroup>
      {folded ? (
        ""
      ) : (
        <StyledDashboardNavGroupLabel>
          {label}

          <StyledTextButton
            title={translate("eva.main.general.visibility")}
            onClick={onVisabilityChange}
          >
            <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
          </StyledTextButton>
        </StyledDashboardNavGroupLabel>
      )}

      {!visible ? children : ""}
    </StyledDashboardNavGroup>
  );
}

export default DashboardNavGroupView;
