import styled from "styled-components";

export const StyledHtmlMultiSizeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledHtmlMultiSizeInput = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;

  & > :last-child {
    flex: 1;
  }
`;
