import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import TinyEditor from "../../../components/tinyEditor/TinyEditor";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import LanguageFormSelect from "../../../components/select/custom/language/form/LanguageFormSelect";

function HelpTextPageView({
  helpTextData,
  descriptionController,
  onCodeChange,
  onTitleChange,
  onPathChange,
  onLanguageIdChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="help_text_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"help_text_page_save"}
      >
        <StyledFlexLayout direction="column">
          <StyledFlexLayout>
            <InputWrapper
              label={translate("eva.main.entities.language")}
              required
              error={EVAApiHelper.getResponseError("language_id", submitError)}
            >
              <LanguageFormSelect
                value={helpTextData.language_id}
                onChange={onLanguageIdChange}
              />
            </InputWrapper>

            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.code")}
                required
                error={EVAApiHelper.getResponseError("code", submitError)}
              >
                <StyledInput
                  type="text"
                  placeholder={translate(
                    "eva.main.validation.placeholders.code.help_text"
                  )}
                  value={helpTextData.code}
                  onChange={onCodeChange}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>

          <InputWrapper
            label={translate("eva.main.validation.attributes.title")}
            required
            error={EVAApiHelper.getResponseError("title", submitError)}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.title")}
              value={helpTextData.title}
              onChange={onTitleChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.path")}
            required
            error={EVAApiHelper.getResponseError("path", submitError)}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.placeholders.path")}
              value={helpTextData.path}
              onChange={onPathChange}
            />
          </InputWrapper>

          <InputWrapper
            label={translate("eva.main.validation.attributes.description")}
            required
            error={EVAApiHelper.getResponseError("description", submitError)}
          >
            <TinyEditor controller={descriptionController} />
          </InputWrapper>
        </StyledFlexLayout>
      </FormContainer>
    </OverlayLoader>
  );
}

export default HelpTextPageView;
