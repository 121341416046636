import { useAuthContext } from "../../../../context/AuthContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import useQuestionCreateRouteConfig from "../../../../routes/configs/questionnaires/questions/QuestionCreateRouteConfig";
import useQuestionEditRouteConfig from "../../../../routes/configs/questionnaires/questions/QuestionEditRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useImageColumnConfig from "../../main/config/column/default/ImageColumnConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function QuestionsDataTable({ questionnaire }) {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("questions");

  //Set the datatable header
  config.setHeader(translate("eva.main.entities.questions"));

  //Set the datatable fetch info
  config.setFetchRequest(`questionnaires/${questionnaire}/questions`);

  //Set default order
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //Set the datatable comuns
  config.setColumns([
    useImageColumnConfig(),
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("order"),
    useBasicColumnConfig("importance_factor"),
    useBasicColumnConfig("group"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("question", useQuestionEditRouteConfig()),
    useDeleteRowActionConfig("question").setDisabled(
      () => !authContext.hasPermission("questionnaires.update")
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("question", useQuestionCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default QuestionsDataTable;
