import { useState } from "react";
import useZoomPanEditor from "../zoomPanEditor/useZoomPanEditor";
import FlowChartView from "./FlowChartView";

function FlowChart({ nodes, edges }) {
  const zoomPanController = useZoomPanEditor({
    scaleDisabled: true,
    onlyAllowBackgroundInteraction: true,
  });
  const [nodeRefs, setNodeRefs] = useState({});

  /**
   * This method will initialize the node reference.
   * @param {object} nodeRef
   */
  function onNodeRefInit(nodeRef) {
    setNodeRefs((prev) => ({ ...prev, [`${nodeRef.id}`]: nodeRef }));
  }

  /**
   * This method will clear the node reference.
   * @param {int} id
   */
  function onNodeRefClear(id) {
    setNodeRefs((prev) => {
      const newRefs = { ...prev };
      delete newRefs[id];
      return newRefs;
    });
  }

  return (
    <FlowChartView
      zoomPanController={zoomPanController}
      nodes={nodes}
      nodeRefs={nodeRefs}
      edges={edges}
      onNodeRefInit={onNodeRefInit}
      onNodeRefClear={onNodeRefClear}
    />
  );
}

export default FlowChart;
