import styled from "styled-components";
import { StyledCard, StyledCardBody } from "../../../../../styles/CardStyles";

export const StyledFormCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledFormCardBody = styled(StyledCardBody)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 350px;
  padding: 0;
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.normal};
  padding-bottom: ${(props) => props.theme.spacing.large};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledFormButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
  margin-top: ${(props) => props.theme.spacing.normal};
`;
