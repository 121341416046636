import OverlayLoaderView from "./OverlayLoaderView";

function OverlayLoader({
  groups,
  iconSize,
  containerStyle,
  overlayStyle,
  children,
}) {
  return (
    <OverlayLoaderView
      groups={groups}
      iconSize={iconSize}
      containerStyle={containerStyle}
      overlayStyle={overlayStyle}
    >
      {children}
    </OverlayLoaderView>
  );
}

export default OverlayLoader;
