import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledInput } from "../../../../styles/InputStyles";
import FormContainer from "../../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";

function LocationPageView({
  locationData,
  onNameChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="location_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"location_page_save"}
      >
        <InputWrapper
          label={translate("eva.main.validation.attributes.name")}
          error={EVAApiHelper.getResponseError("name", submitError)}
          required
        >
          <StyledInput
            type="text"
            placeholder={translate("eva.main.validation.attributes.name")}
            value={locationData.name}
            onChange={onNameChange}
          />
        </InputWrapper>
      </FormContainer>
    </OverlayLoader>
  );
}

export default LocationPageView;
