import { useParams } from "react-router-dom";
import { useRouteContext } from "../../../context/RouteContext";
import { useState } from "react";
import useEntityTranslations from "../../../hooks/useEntityTranslations";
import { useTinyEditor } from "../../../components/tinyEditor/TinyEditor";
import useCustomEffect from "../../../hooks/useCustomEffect";
import ButtonPageView from "./ButtonPageView";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function ButtonPage() {
  const callEvaApi = useEvaApi();
  const { button } = useParams();
  const routeContext = useRouteContext();
  const [submitError, setSubmitError] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [buttonData, setButtonData] = useState({
    name: "",
    order: 0,
    layout_id: null,
    translations: [],
  });

  const translationsController = useEntityTranslations(
    buttonData.translations,
    onTranslationsChange
  );

  const buttonMessageController = useTinyEditor(
    translationsController.getValue("message"),
    (value) => translationsController.onTranslationChange(value, "message")
  );

  /**
   * UseEffect to initialize the component
   */
  useCustomEffect(() => {
    fetchLayouts();
    if (button) {
      fetchButtonData();
    }
  });

  /**
   * This method will fetch the button data from the API
   */
  async function fetchButtonData() {
    await callEvaApi(
      new EvaApiCall(`buttons/${button}`)
        .setLoadingGroup("button_page_fetch")
        .setOnSuccess((response) => {
          const newData = response.data.data;
          setButtonData((current) => ({
            ...current,
            name: newData.name,
            active: newData.active,
            order: newData.order,
            layout_id: newData.layout,
            translations: newData.translations.map((translation) => ({
              language_id: translation.language,
              title: translation.title,
              message: translation.message,
            })),
          }));
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the layouts from the API
   */
  async function fetchLayouts() {
    await callEvaApi(
      new EvaApiCall("layouts")
        .setLoadingGroup("button_page_fetch")
        .setOnSuccess((response) => {
          setLayouts(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the name change
   * @param {Event} e
   */
  function onNameChange(e) {
    setButtonData((current) => ({
      ...current,
      name: e.target.value,
    }));
  }

  /**
   * This method will handle the order change
   * @param {any} value
   */
  function onOrderChange(e) {
    setButtonData((current) => ({
      ...current,
      order: e.target.value,
    }));
  }

  /**
   * This method will handle the layout id change
   * @param {any} value
   */
  function onLayoutIdChange(value) {
    setButtonData((current) => ({
      ...current,
      layout_id: value,
    }));
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setButtonData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the cancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit functionality
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(button ? `buttons/${button}` : `buttons`)
        .setMethod(button ? "PUT" : "POST")
        .setData(buttonData)
        .setLoadingGroup("button_page_submit")
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <ButtonPageView
      buttonData={buttonData}
      buttonMessageController={buttonMessageController}
      translationsController={translationsController}
      layouts={layouts}
      onNameChange={onNameChange}
      onOrderChange={onOrderChange}
      onLayoutIdChange={onLayoutIdChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default ButtonPage;
