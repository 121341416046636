import { useLanguageContext } from "../../../context/LanguageContext";
import VisitorTypePage from "../../../pages/visitor_types/visitor_type/VisitorTypePage";
import { RouteConfig } from "../RouteConfig";
import useVisitorTypesRouteConfig from "./VisitorTypesRouteConfig";
import { useVisitorTypeCreateRouteValidator } from "../../validators/visitor_types/VisitorTypeCreateRouteValidator";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useVisitorTypeCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/visitor_types/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.visitor_type")
        }
      ])
    )
    .setValidator(useVisitorTypeCreateRouteValidator())
    .setPage(<VisitorTypePage />)
    .setParent(useVisitorTypesRouteConfig());
}

export default useVisitorTypeCreateRouteConfig;