import StatisticSettings from "./statistics/settings/StatisticSettings";
import { StyledFlexLayout } from "../../../styles/LayoutStyles";

function DashboardPageView({ statistics, setStatistics }) {
  return (
    <StyledFlexLayout style={{ flexDirection: "column" }}>
      <StatisticSettings
        statistics={statistics}
        setStatistics={setStatistics}
      />

      {Object.keys(statistics).map((statistic) => {
        return (
          statistics[statistic].visible &&
          !statistics[statistic].disabled &&
          statistics[statistic].component && (
            <div key={statistic}>
              {statistics[statistic].component}
            </div>
          )
        );
      })}
    </StyledFlexLayout>
  );
}

export default DashboardPageView;
