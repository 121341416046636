import CalamityToolsDataTable from "../../../components/dataTable/tables/calamities/CalamityToolsDataTable";
import CalamityTypesDataTable from "../../../components/dataTable/tables/calamities/CalamityTypesDataTable";
import CalamityTable from "../../../components/calamityTable/CalamityTable";
import { StyledFlexLayout } from "../../../styles/LayoutStyles";
import { useTheme } from "styled-components";

function CalamitiesPageView() {
  const theme = useTheme();

  return (
    <StyledFlexLayout
      style={{ gap: theme.spacing.large, flexDirection: "column" }}
    >
      <CalamityTable />
      <CalamityToolsDataTable />
      <CalamityTypesDataTable />
    </StyledFlexLayout>
  );
}

export default CalamitiesPageView;
