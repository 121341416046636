import { useLanguageContext } from "../../context/LanguageContext";
import FileHelper from "../../helpers/FileHelper";
import PdfViewer from "../pdfViewer/PdfViewer";
import {
  StyledCloseButton,
  StyledFilePreview,
  StyledOverlay,
  StyledPreview,
  StyledPreviewContainer,
} from "./FilePreviewStyles";

function FilePreviewView({
  path,
  showPreview,
  onClose,
  onOpen,
  children,
  disabled,
  pathType,
}) {
  const { translate } = useLanguageContext();

  return (
    <>
      <StyledFilePreview onClick={onOpen} disabled={disabled}>
        {children}
      </StyledFilePreview>

      {showPreview && (
        <StyledOverlay>
          <StyledPreviewContainer
            style={{
              alignItems: pathType === "attachment" ? "initial" : "center",
            }}
          >
            {pathType === "image" && (
              <StyledPreview
                src={FileHelper.apiPath(path)}
                alt={FileHelper.apiPath(path)}
              />
            )}

            {pathType === "video" && (
              <StyledPreview as="video" controls={false} autoPlay>
                <source src={FileHelper.apiPath(path)} />
              </StyledPreview>
            )}

            {pathType === "attachment" && (
              <PdfViewer url={FileHelper.apiPath(path, true)} />
            )}
          </StyledPreviewContainer>

          <StyledCloseButton style={{ display: "block" }} onClick={onClose}>
            {translate("eva.main.general.close")}
          </StyledCloseButton>
        </StyledOverlay>
      )}
    </>
  );
}

export default FilePreviewView;
