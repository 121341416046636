import styled from "styled-components";
import { StyledButton } from "../../styles/ButtonStyles";

export const CalamityButton = styled(StyledButton)`
  height: ${(props) => props.theme.container.size.normal};
  background-color: #dc2626;
  font-weight: bold;
  padding-block: 0;
  color: "white";

  &:hover {
    background-color: #b91c1c;
    color: "white";
  }
`;
