import { useLanguageContext } from "../../../context/LanguageContext";
import FieldPage from "../../../pages/fields/field/FieldPage";
import { useFieldEditRouteValidator } from "../../validators/fields/FieldEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useFieldsRouteConfig from "./FieldsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFieldEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/fields/:field/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.field"),
        },
      ])
    )
    .setValidator(useFieldEditRouteValidator())
    .setPage(<FieldPage />)
    .setParent(useFieldsRouteConfig());
}

export default useFieldEditRouteConfig;
