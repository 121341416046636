import { useLanguageContext } from "../../../../../../context/LanguageContext";
import ColumnConfig from "../ColumnConfig";

/**
 * @param {string} key
 * @returns {ColumnConfig}
 */
function useBasicColumnConfig(key) {
  const { translate } = useLanguageContext();

  /**
   * This method will try to get the correct label for the given key
   * @returns {string}
   */
  function getLabel() {
    const keyParts = key.split(".");
    let translationKey = key;

    //If the key contains translation at the end, then the first part is the key for the translation.
    if (keyParts.length > 1 && keyParts[1] === "translation") {
      translationKey = keyParts[0];
    }

    //If the key has multiple parts and the second part is not translation, then the key is "first_id" for the translation.
    if (keyParts.length > 1 && keyParts[1] !== "translation") {
      translationKey = keyParts[0] + "_id";
    }

    return translate(`eva.main.validation.attributes.${translationKey}`);
  }

  /**
   * This method will try to get the correct data for the given key
   * @param {object} row
   * @returns {any}
   */
  function getData(row) {
    const keyParts = key.split(".");

    //If the key contains translation at the end, then the first part is the key of the data.
    if (keyParts.length > 1 && keyParts[1] === "translation") {
      return row[keyParts[0]];
    }
    //If the key has multiple parts and the second part is not translation, then the key is "object?.key" for the data.
    else if (keyParts.length > 1 && keyParts[1] !== "translation") {
      const object = row[keyParts[0]];
      return object ? object[keyParts[1]] : null;
    }
    //If the key has only one part, then the key is the key for the data.
    else {
      return row[key];
    }
  }

  return new ColumnConfig().setKey(key).setLabel(getLabel()).setData(getData);
}

export default useBasicColumnConfig;
