import { useTheme } from "styled-components";
import { useLanguageContext } from "../../context/LanguageContext";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../styles/LayoutStyles";
import InputWrapper from "../input/wrapper/InputWrapper";
import { StyledInput } from "../../styles/InputStyles";
import FormContainer from "../formContainer/FormContainer";
import EVAApiHelper from "../../helpers/EVAApiHelper";
import Select from "../select/main/Select";
import MultiSelect from "../select/main/multiSelect/MultiSelect";

function SchedulingFormView({
  schedule,
  onFrequencyChange,
  onIntervalChange,
  onStartDateChange,
  onDaysChange,
  onMonthInputChange,
  onMonthDayChange,
  onMonthWeekChange,
  onMonthDayOfWeekChange,
  onYearMonthChange,
  getIntervalHint,
  weekdays,
  yearmonths,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <FormContainer header={translate("eva.main.calendar.schedule")}>
      <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal}`}>
        <StyledFlexLayoutItem>
          <InputWrapper
            label={translate(
              "eva.main.validation.attributes.schedule_frequency"
            )}
            error={EVAApiHelper.getResponseError(
              "schedule_frequency",
              submitError
            )}
          >
            <Select
              options={[
                {
                  label: translate("eva.main.general.select"),
                  value: "none",
                },
                {
                  label: translate("eva.main.calendar.frequencies.daily"),
                  value: "daily",
                },
                {
                  label: translate("eva.main.calendar.frequencies.weekly"),
                  value: "weekly",
                },
                {
                  label: translate("eva.main.calendar.frequencies.monthly"),
                  value: "monthly",
                },
                {
                  label: translate("eva.main.calendar.frequencies.yearly"),
                  value: "yearly",
                },
              ]}
              value={schedule?.frequency}
              onChange={onFrequencyChange}
            />
          </InputWrapper>
        </StyledFlexLayoutItem>

        {schedule?.frequency !== "none" ? (
          <InputWrapper
            label={
              translate("eva.main.validation.attributes.schedule_interval") +
              " (" +
              getIntervalHint() +
              ")"
            }
            error={EVAApiHelper.getResponseError(
              "schedule_interval",
              submitError
            )}
          >
            <StyledInput
              type="number"
              value={schedule?.interval}
              onChange={onIntervalChange}
            />
          </InputWrapper>
        ) : (
          ""
        )}
      </StyledFlexLayout>

      {schedule?.frequency !== "none" ? (
        <>
          <InputWrapper
            label={translate(
              "eva.main.validation.attributes.schedule_start_date"
            )}
            margin={`0px 0px ${theme.spacing.normal}`}
            error={EVAApiHelper.getResponseError(
              "schedule_start_date",
              submitError
            )}
          >
            <StyledInput
              type="date"
              value={schedule?.start_date}
              onChange={onStartDateChange}
            />
          </InputWrapper>

          {schedule?.frequency === "weekly" ? (
            <InputWrapper
              label={translate("eva.main.validation.attributes.schedule_days")}
              error={
                EVAApiHelper.getResponseErrorArray(
                  "schedule_days",
                  submitError,
                  (index) =>
                    translate("eva.main.calendar.days." + schedule?.days[index])
                ) ?? EVAApiHelper.getResponseError("schedule_days", submitError)
              }
            >
              <MultiSelect
                options={weekdays.map((weekday) => {
                  return {
                    label: translate("eva.main.calendar.days." + weekday),
                    value: weekday,
                  };
                })}
                values={schedule?.days}
                onChange={onDaysChange}
              />
            </InputWrapper>
          ) : (
            ""
          )}

          {schedule?.frequency === "monthly" ||
          schedule?.frequency === "yearly" ? (
            <>
              <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal}`}>
                <input
                  type="radio"
                  name="month_input_usage"
                  value="month_day"
                  checked={schedule?.month_input_usage === "month_day"}
                  onClick={() => onMonthInputChange("month_day")}
                  onChange={() => {}}
                />

                <StyledFlexLayoutItem>
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.schedule_month_day"
                    )}
                    error={EVAApiHelper.getResponseError(
                      "schedule_month_day",
                      submitError
                    )}
                  >
                    <StyledInput
                      type="number"
                      value={schedule?.month_day}
                      onChange={onMonthDayChange}
                      disabled={schedule?.month_input_usage !== "month_day"}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>
              </StyledFlexLayout>

              <StyledFlexLayout margin={`0px 0px ${theme.spacing.normal}`}>
                <input
                  type="radio"
                  name="month_input_usage"
                  value="month_week"
                  checked={schedule?.month_input_usage === "month_week"}
                  onClick={() => onMonthInputChange("month_week")}
                  onChange={() => {}}
                />

                <InputWrapper
                  label={translate(
                    "eva.main.validation.attributes.schedule_month_week"
                  )}
                  error={EVAApiHelper.getResponseError(
                    "schedule_month_week",
                    submitError
                  )}
                >
                  <Select
                    options={[
                      {
                        label: translate("eva.main.calendar.weeks.first"),
                        value: "first",
                      },
                      {
                        label: translate("eva.main.calendar.weeks.second"),
                        value: "second",
                      },
                      {
                        label: translate("eva.main.calendar.weeks.third"),
                        value: "third",
                      },
                      {
                        label: translate("eva.main.calendar.weeks.fourth"),
                        value: "fourth",
                      },
                      {
                        label: translate("eva.main.calendar.weeks.last"),
                        value: "last",
                      },
                    ]}
                    value={schedule?.month_week}
                    onChange={onMonthWeekChange}
                    disabled={schedule?.month_input_usage !== "month_week"}
                  />
                </InputWrapper>

                <StyledFlexLayoutItem>
                  <InputWrapper
                    label={translate(
                      "eva.main.validation.attributes.schedule_month_day_of_week"
                    )}
                    error={EVAApiHelper.getResponseError(
                      "schedule_month_day_of_week",
                      submitError
                    )}
                  >
                    <Select
                      options={weekdays.map((weekday) => {
                        return {
                          label: translate("eva.main.calendar.days." + weekday),
                          value: weekday,
                        };
                      })}
                      value={schedule?.month_day_of_week}
                      onChange={onMonthDayOfWeekChange}
                      disabled={schedule?.month_input_usage !== "month_week"}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>
              </StyledFlexLayout>
            </>
          ) : (
            ""
          )}

          {schedule?.frequency === "yearly" ? (
            <InputWrapper
              label={translate(
                "eva.main.validation.attributes.schedule_year_month"
              )}
              error={EVAApiHelper.getResponseError(
                "schedule_year_month",
                submitError
              )}
            >
              <Select
                options={yearmonths.map((month) => {
                  return {
                    label: translate("eva.main.calendar.months." + month),
                    value: month,
                  };
                })}
                value={schedule?.year_month}
                onChange={onYearMonthChange}
              />
            </InputWrapper>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </FormContainer>
  );
}

export default SchedulingFormView;
