import { useLanguageContext } from "../../context/LanguageContext";
import ThemeSettingsPage from "../../pages/theme_settings/index/ThemeSettingsPage";
import { useThemeSettingsRouteValidator } from "../validators/ThemeSettingsRouteValidator";
import { RouteConfig } from "./RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useThemeSettingsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/theme_settings")
    .setLabel(translate("eva.main.entities.theme_settings"))
    .setValidator(useThemeSettingsRouteValidator())
    .setPage(<ThemeSettingsPage />);
}

export default useThemeSettingsRouteConfig;
