import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledAltIconButton,
  StyledTextButton,
} from "../../styles/ButtonStyles";
import {
  StyledEditor,
  StyledEditorBackground,
  StyledEditorContent,
  StyledEditorInfo,
  StyledEditorZoomIndicator,
} from "./ZoomPanEditorStyles";
import {
  faExpand,
  faLock,
  faRefresh,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../context/LanguageContext";

function ZoomPanEditorView({ controller, children }) {
  const { translate } = useLanguageContext();

  return (
    <StyledEditor
      onWheel={controller.onScroll}
      onMouseDown={controller.onMouseDown}
      ref={controller.editorRef}
    >
      <StyledEditorBackground
        ref={controller.editorBackgroundRef}
        position={controller.data.position}
      />

      <StyledEditorContent
        position={controller.data.position}
        scale={controller.data.scale}
        ref={controller.editorContentRef}
      >
        {children}
      </StyledEditorContent>

      <StyledEditorInfo>
        <StyledEditorZoomIndicator disabled={controller.data.scaleDisabled}>
          {Math.round(controller.data.scale * 100)}%
          <StyledTextButton
            onClick={controller.resetZoom}
            title={translate("eva.main.general.reset")}
          >
            <FontAwesomeIcon icon={faRefresh} size="lg" />
          </StyledTextButton>
        </StyledEditorZoomIndicator>

        <StyledAltIconButton
          onClick={controller.centerContent}
          title={translate("eva.main.general.center")}
        >
          <FontAwesomeIcon icon={faExpand} size="lg" />
        </StyledAltIconButton>

        <StyledAltIconButton
          onClick={controller.onLockClick}
          title={translate(
            `eva.main.general.${controller.data.locked ? "unlock" : "lock"}`
          )}
        >
          <FontAwesomeIcon
            icon={controller.data.locked ? faLock : faUnlock}
            size="lg"
          />
        </StyledAltIconButton>
      </StyledEditorInfo>
    </StyledEditor>
  );
}

export default ZoomPanEditorView;
