import StepLayoutView from "./StepLayoutView";

function StepLayout({
  children,
  step,
  visitorData,
  setPageConfig,
  processStep,
  previousStep,
  proceedDisabled,
}) {
  return (
    <StepLayoutView
      step={step}
      visitorData={visitorData}
      setPageConfig={setPageConfig}
      processStep={processStep}
      previousStep={previousStep}
      proceedDisabled={proceedDisabled}
    >
      {children}
    </StepLayoutView>
  );
}

export default StepLayout;
