import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { StyledCompactError } from "./CompactErrorStyles";

function CompactErrorView({ error }) {
  const theme = useTheme();

  return (
    <StyledCompactError title={error}>
      <FontAwesomeIcon icon={faWarning} color={theme.colors.accent.normal} />
    </StyledCompactError>
  );
}

export default CompactErrorView;
