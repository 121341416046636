import HtmlColorInputView from "./HtmlColorInputView";

function HtmlColorInput({
  value,
  onChange,
  useOpacityInput = true,
  useVisabilityInput = true,
  disabled = false,
}) {
  const rgbaValue = stringToRGBA(value);
  const hexValue = rgbaToHexString(rgbaValue);
  const visible = value !== null && value !== undefined;

  /**
   * This method will convert the color value to RGBA
   * @param {string} value
   * @returns {number[]}
   */
  function stringToRGBA(value) {
    if (!value) {
      return {
        r: 0,
        g: 0,
        b: 0,
        a: useOpacityInput ? 0 : 1,
      };
    }

    if (value.startsWith("#")) {
      return hexStringToRGBA(value);
    } else if (value.startsWith("rgba")) {
      return rgbaStringToRGBA(value);
    } else if (value.startsWith("rgb")) {
      return rgbStringToRGBA(value);
    }
  }

  /**
   * This method will convert the hex color to RGBA
   * @param {string} hex
   * @returns {number[]}
   */
  function hexStringToRGBA(hex) {
    hex = hex.startsWith("#") ? hex.substring(1) : hex;
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16),
      a: 1,
    };
  }

  /**
   * This method will convert the RGBA color string to RGBA array
   * @param {string} rgba
   * @returns {number[]}
   */
  function rgbaStringToRGBA(rgba) {
    const rgbaArray = rgba
      .substring(5, rgba.length - 1)
      .split(",")
      .map((v) => parseFloat(v.trim()));

    return {
      r: rgbaArray[0],
      g: rgbaArray[1],
      b: rgbaArray[2],
      a: rgbaArray[3],
    };
  }

  /**
   * This method will convert the RGB color string to RGBA array
   * @param {string} rgb
   * @returns {number[]}
   */
  function rgbStringToRGBA(rgb) {
    const rgbaArray = rgb
      .substring(4, rgb.length - 1)
      .split(",")
      .map((v) => parseFloat(v.trim()))
      .concat(1);

    return {
      r: rgbaArray[0],
      g: rgbaArray[1],
      b: rgbaArray[2],
      a: rgbaArray[3],
    };
  }

  /**
   * This method will convert the RGB color to hex string
   * @param {{ r: number, g: number, b: number, a: number }} rgba
   * @returns {string}
   */
  function rgbaToHexString(rgba) {
    const toHex = (val) => {
      const hex = val.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };

    return `#${toHex(rgba.r)}${toHex(rgba.g)}${toHex(rgba.b)}`;
  }

  /**
   * This method will convert the RGBA color to RGBA string
   * @param {{r: number, g: number, b: number, a: number}} rgba
   * @returns {string}
   */
  function rgbaToRGBAString(rgba) {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  }

  /**
   * This method will handle the color change event
   * @param {Event} e
   */
  function onColorChange(e) {
    if (onChange) {
      const newRgbaValue = stringToRGBA(e.target.value);
      newRgbaValue.a = rgbaValue.a;
      onChange(rgbaToRGBAString(newRgbaValue));
    }
  }

  /**
   * This method will handle the opacity change event
   * @param {number} val
   */
  function onOpacityChange(val) {
    if (onChange) {
      const newRgbaValue = { ...rgbaValue };
      newRgbaValue.a = val;
      onChange(rgbaToRGBAString(newRgbaValue));
    }
  }

  /**
   * This method will handle the visability change event
   */
  function onVisabilityChange() {
    if (onChange) {
      onChange(visible ? null : rgbaToRGBAString(rgbaValue));
    }
  }

  return (
    <HtmlColorInputView
      rgbaValue={rgbaValue}
      hexValue={hexValue}
      visible={visible}
      useOpacityInput={useOpacityInput}
      useVisabilityInput={useVisabilityInput}
      disabled={disabled}
      onColorChange={onColorChange}
      onOpacityChange={onOpacityChange}
      onVisabilityChange={onVisabilityChange}
    />
  );
}

export default HtmlColorInput;
