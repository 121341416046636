import { useState } from "react";
import { useTerminalContext } from "../../../../context/TerminalContext";
import FrontOfficePageConfig from "../../FrontOfficePageConfig";
import VisitorTypePageView from "./VisitorTypePageView";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { StartPageConfig } from "../start/StartPage";

function VisitorTypePage({ pageConfig, setPageConfig }) {
  const callEvaApi = useEvaApi();
  const { terminal } = useTerminalContext();
  const visitorType = terminal?.visitor_types?.find(
    (visType) =>
      parseInt(visType.id) === parseInt(pageConfig.data?.visitorTypeId)
  );
  const layout = visitorType?.layout || terminal?.layout;
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(null);
  const [visitorData, setVisitorData] = useState({
    visitor_type_id: visitorType?.id,
  });

  /**
   * UseEffect for processing the current step or fetching the first step if it's not set
   */
  useCustomEffect(() => {
    processStep();
  });

  /**
   * This method will process the current step and get the next one
   * @param {int|null} stepId
   * @param {object} data
   */
  async function processStep(stepId = null, data = null) {
    await callEvaApi(
      new EvaApiCall(`flows/${visitorType?.flow}/steps/${stepId}/process`)
        .setLoadingGroup("process_step")
        .setMethod("POST")
        .setParams(getFetchParams())
        .setErrorState(setErrors)
        .setData(data)
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          if (newStep) {
            setStep(newStep);
          } else {
            createVisitor();
          }
        })
    );
  }

  /**
   * This method will try to go back to the previous step
   * @param {int|null} stepId
   */
  async function previousStep() {
    await callEvaApi(
      new EvaApiCall(`flows/${visitorType?.flow}/steps/${step?.id}/previous`)
        .setLoadingGroup("previous_step")
        .setParams(getFetchParams())
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          setStep(newStep);
        })
    );
  }

  /**
   * This method will create the visitor
   */
  async function createVisitor() {
    await callEvaApi(
      new EvaApiCall(`visitors`)
        .setLoadingGroup("create_visitor")
        .setMethod("POST")
        .setData(visitorData)
        .setAlertError(true)
        .setAlertSuccess(true)
        .setOnSuccess((response) => {
          setPageConfig(StartPageConfig);
        })
    );
  }

  /**
   * This method will return the fetch params for the API
   * @returns {object}
   */
  function getFetchParams() {
    return {
      with_flow_step_fields_data: true,
      with_flow_step_field_field_data: true,
      with_flow_step_questionnaire_data: true,
      with_flow_step_file_data: true,
      with_field_options_data: true,
      with_questionnaire_questions_data: true,
      with_question_answers_data: true,
    };
  }

  return (
    <VisitorTypePageView
      layout={layout}
      step={step}
      errors={errors}
      visitorData={visitorData}
      setVisitorData={setVisitorData}
      processStep={processStep}
      previousStep={previousStep}
      setPageConfig={setPageConfig}
    />
  );
}

export const VisitorTypePageConfig = new FrontOfficePageConfig(
  VisitorTypePage,
  "visitor_type"
);

export default VisitorTypePage;
