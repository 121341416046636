import { useParams } from "react-router-dom";
import { useRouteContext } from "../../../../context/RouteContext";
import FieldConditionPageView from "./FieldConditionPageView";
import { useState } from "react";
import useEntityTranslations from "../../../../hooks/useEntityTranslations";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function FieldConditionPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { field, field_condition } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [fieldConditionData, setFieldConditionData] = useState({
    field_id: field,
    condition: "",
    value: "",
    active: true,
    stop_registration: false,
    date_relative: false,
    translations: [],
  });

  const translationsController = useEntityTranslations(
    fieldConditionData.translations,
    onTranslationsChange
  );

  //UseEffect to initialize the component
  useCustomEffect(() => {
    fetchField();
  });

  /**
   * UseEffect to fetch the field condition data if the fieldData is loaded
   */
  useCustomEffect(() => {
    if (field_condition && fieldData) {
      fetchFieldConditionData();
    }
  }, [fieldData]);

  /**
   * Fetch the field condition data from the API
   */
  async function fetchFieldConditionData() {
    await callEvaApi(
      new EvaApiCall(`field_conditions/${field_condition}`)
        .setLoadingGroup("field_condition_page_fetch")
        .setOnSuccess((response) => {
          const newData = response.data.data;

          //Make sure to convert the date relative to a date if the field is a date
          if (fieldData.type === "date" && !isNaN(newData.value)) {
            const today = new Date();
            today.setDate(today.getDate() + parseInt(newData.value));
            newData.value = today.toISOString().split("T")[0];
            newData.date_relative = true;
          }

          //Make sure to convert the boolean values to boolean
          if (fieldData.type === "boolean") {
            newData.value = newData.value === "1";
          }

          setFieldConditionData((current) => ({
            ...current,
            condition: newData.condition,
            value: newData.value,
            active: newData.active,
            stop_registration: newData.stop_registration,
            date_relative: newData.date_relative ? true : false,
            translations: newData.translations.map((translation) => ({
              language_id: translation.language,
              message: translation.message,
            })),
          }));
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the field data from the API
   */
  async function fetchField() {
    await callEvaApi(
      new EvaApiCall(`fields/${field}`)
        .setLoadingGroup("field_condition_page_fetch")
        .setOnSuccess((response) => {
          setFieldData(response.data.data);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the condition change
   * @param {any} value
   */
  function onConditionChange(value) {
    setFieldConditionData((current) => ({ ...current, condition: value }));
  }

  /**
   * This method will handle the value change
   * @param {any} value
   */
  function onValueChange(value) {
    setFieldConditionData((current) => ({ ...current, value: value }));
  }

  /**
   * This method will handle the date relative change
   * @param {bool} value
   */
  function onDateRelativeChange(value) {
    setFieldConditionData((current) => ({ ...current, date_relative: value }));
  }

  /**
   * This method will handle the active change
   * @param {bool} value
   */
  function onActiveChange(value) {
    setFieldConditionData((current) => ({ ...current, active: value }));
  }

  /**
   * This method will handle the stop registration change
   * @param {bool} value
   */
  function onStopRegistrationChange(value) {
    setFieldConditionData((current) => ({
      ...current,
      stop_registration: value,
    }));
  }

  /**
   * Handle the translations change
   * @param {object[]} value
   */
  function onTranslationsChange(value) {
    //Update the translations
    setFieldConditionData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        field_condition
          ? `field_conditions/${field_condition}`
          : "field_conditions"
      )
        .setLoadingGroup("field_condition_page_save")
        .setMethod(field_condition ? "PUT" : "POST")
        .setData(fieldConditionData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <FieldConditionPageView
      fieldData={fieldData}
      fieldConditionData={fieldConditionData}
      translationsController={translationsController}
      onConditionChange={onConditionChange}
      onValueChange={onValueChange}
      onDateRelativeChange={onDateRelativeChange}
      onActiveChange={onActiveChange}
      onStopRegistrationChange={onStopRegistrationChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default FieldConditionPage;
