import { useLanguageContext } from "../../../context/LanguageContext";
import { RouteConfig } from "../RouteConfig";
import VisitorTypesPage from "../../../pages/visitor_types/index/VisitorTypesPage";
import { useVisitorTypesRouteValidator } from "../../validators/visitor_types/VisitorTypesRouteValidator";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useVisitorTypesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/visitor_types")
    .setLabel(translate("eva.main.entities.visitor_types"))
    .setValidator(useVisitorTypesRouteValidator())
    .setPage(<VisitorTypesPage />);
}

export default useVisitorTypesRouteConfig;