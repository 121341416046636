import styled from "styled-components";
import { StyledAltIconButton } from "../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledImageInputWrapper = styled.div`
  position: relative;
`;

export const StyledImageInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: ${(props) => props.theme.radius.default};
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.primary.lightest};
  color: ${(props) => props.theme.colors.on.primary};
  overflow: hidden;

  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledImageIcon = styled(FontAwesomeIcon)`
  min-width: 20px;
  min-height: 20px;
  width: 50%;
  height: 50%;
`;

export const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
`;

export const StyledClearButton = styled(StyledAltIconButton)`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 25px;
  height: 25px;
`;
