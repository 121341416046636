import { useAuthContext } from "../context/AuthContext";

function useRoleTypeMiddleware(type, operator = "and") {
  type = Array.isArray(type) ? type : [type];
  const authContext = useAuthContext();

  /**
   * This method will validate the middleware
   * @returns {boolean}
   */
  function validate() {
    const userRoleType = authContext.auth.user?.role_type;

    if (operator === "and") {
      return type.every((expectedType) => userRoleType === expectedType);
    } else if (operator === "or") {
      return type.some((expectedType) => userRoleType === expectedType); 
    }

    return userRoleType === type;
  }

  /**
   * This method return the redirect instructions on fail
   * @returns {{ to: string, replace: boolean }}
   */
  function redirect() {
    if (authContext.auth.user?.role_type === 'terminal') {
      return { to: "/frontoffice", replace: true };
    }
    return { to: "/dashboard", replace: true };
  }

  //return the middleware
  return { validate, redirect };
}

export default useRoleTypeMiddleware;
