import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../styles/InputStyles";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import LocationFormSelect from "../../../components/select/custom/location/form/LocationFormSelect";

function ContactPageView({
  contact,
  contactData,
  onLocationIdChange,
  onNameChange,
  onPhoneNumberchange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <OverlayLoader groups="contact_page_fetch">
      <FormContainer
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
        submitLoadingGroup={"contact_page_save"}
      >
        <InputWrapper
          label={translate("eva.main.entities.location")}
          error={EVAApiHelper.getResponseError("location_id", submitError)}
          margin={`0px 0px ${theme.spacing.normal}`}
        >
          <LocationFormSelect
            value={contactData.location_id}
            onChange={onLocationIdChange}
            disabled={contact !== undefined}
          />
        </InputWrapper>

        <InputWrapper
          label={translate("eva.main.validation.attributes.name")}
          error={EVAApiHelper.getResponseError("name", submitError)}
          margin={`0px 0px ${theme.spacing.normal}`}
          required
        >
          <StyledInput
            type="text"
            placeholder={translate("eva.main.validation.attributes.name")}
            value={contactData.name}
            onChange={onNameChange}
          />
        </InputWrapper>

        <InputWrapper
          label={translate("eva.main.validation.attributes.phone_number")}
          error={EVAApiHelper.getResponseError("phone_number", submitError)}
          required
        >
          <StyledInput
            type="text"
            placeholder={translate(
              "eva.main.validation.placeholders.phone_number"
            )}
            value={contactData.phone_number}
            onChange={onPhoneNumberchange}
          />
        </InputWrapper>
      </FormContainer>
    </OverlayLoader>
  );
}

export default ContactPageView;
