import { useLanguageContext } from "../../../../context/LanguageContext";
import OrdersPreparePage from "../../../../pages/canteen/orders/prepare/OrdersPreparePage";
import { useOrderPrepareRouteValidator } from "../../../validators/canteen/orders/OrderPrepareRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useOrdersRouteConfig from "./OrdersRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useOrderPrepareRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/orders/prepare")
    .setLabel(
      translate("eva.main.entities.states.prepare", [
        {
          key: "entities",
          value: translate("eva.main.entities.orders"),
        },
      ])
    )
    .setValidator(useOrderPrepareRouteValidator())
    .setPage(<OrdersPreparePage />)
    .setParent(useOrdersRouteConfig());
}

export default useOrderPrepareRouteConfig;
