import { useState } from "react";
import { useParams } from "react-router-dom";
import DishPageView from "./DishPageView";
import { useAuthContext } from "../../../../context/AuthContext";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";

function DishPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { dish } = useParams();
  const authContext = useAuthContext();
  const [allergens, setAllergens] = useState([]);
  const [dishData, setDishData] = useState({
    name: "",
    description: "",
    price: 0.0,
    capacity: 1,
    calories: 0,
    health_score: 0,
    category: "",
    image: null,
    allergens: [],
    schedule: null,
    location_id:
      authContext.auth.location ??
      authContext.auth.user.company.locations[0].id,
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * Initializes the component
   */
  useCustomEffect(() => {
    fetchAllergens();

    if (dish) {
      fetchDishData();
    }
  });

  /**
   * Fetch the allergens from the API
   */
  async function fetchAllergens() {
    await callEvaApi(
      new EvaApiCall("canteen/allergens")
        .setLoadingGroup("canteen_dish_page_fetch")
        .setOnSuccess((response) => {
          setAllergens(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Fetch the dish data from the API
   */
  async function fetchDishData() {
    await callEvaApi(
      new EvaApiCall(`canteen/dishes/${dish}`)
        .setLoadingGroup("canteen_dish_page_fetch")
        .setOnSuccess((response) => {
          setDishData({
            name: response.data.data.name,
            description: response.data.data.description,
            price: response.data.data.price,
            capacity: response.data.data.capacity,
            calories: response.data.data.calories,
            health_score: response.data.data.health_score,
            category: response.data.data.category,
            image: response.data.data.image,
            allergens: response.data.data.allergens,
            schedule: response.data.data.schedule,
            location_id: response.data.data.location,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the location id on input change
   * @param {any} value
   */
  function onLocationIdChange(value) {
    setDishData({ ...dishData, location_id: value });
  }

  /**
   * This method will update the image on input change
   * @param {any} value
   */
  function onImageChange(value) {
    setDishData({ ...dishData, image: value });
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setDishData({ ...dishData, name: e.target.value });
  }

  /**
   * This method will update the price on input change
   * @param {event} e
   */
  function onPriceChange(e) {
    setDishData({ ...dishData, price: e.target.value });
  }

  /**
   * This method will update the capacity on input change
   * @param {event} e
   */
  function onCapacityChange(e) {
    setDishData({ ...dishData, capacity: e.target.value });
  }

  /**
   * This method will update the calories on input change
   * @param {event} e
   */
  function onCaloriesChange(e) {
    setDishData({ ...dishData, calories: e.target.value });
  }

  /**
   * This method will update the health score on input change
   * @param {event} e
   */
  function onHealthScoreChange(e) {
    setDishData({ ...dishData, health_score: e.target.value });
  }

  /**
   * This method will update the category on input change
   * @param {event} e
   */
  function onCategoryChange(e) {
    setDishData({ ...dishData, category: e.target.value });
  }

  /**
   * This method will update the description on input change
   * @param {event} e
   */
  function onDescriptionChange(e) {
    setDishData({ ...dishData, description: e.target.value });
  }

  /**
   * This method will update the allergens on input change
   * @param {array} values
   */
  function onAllergensChange(values) {
    setDishData({
      ...dishData,
      allergens: values,
    });
  }

  /**
   * This method will update the schedule on input change
   * @param {object} schedule
   */
  function onScheduleChange(schedule) {
    setDishData({ ...dishData, schedule: schedule });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(dish ? `canteen/dishes/${dish}` : "canteen/dishes")
        .setLoadingGroup("canteen_dish_page_save")
        .setMethod(dish ? "PUT" : "POST")
        .setData(dishData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <DishPageView
      dish={dish}
      dishData={dishData}
      allergens={allergens}
      onLocationIdChange={onLocationIdChange}
      onImageChange={onImageChange}
      onNameChange={onNameChange}
      onPriceChange={onPriceChange}
      onCapacityChange={onCapacityChange}
      onCaloriesChange={onCaloriesChange}
      onHealthScoreChange={onHealthScoreChange}
      onCategoryChange={onCategoryChange}
      onDescriptionChange={onDescriptionChange}
      onAllergensChange={onAllergensChange}
      onScheduleChange={onScheduleChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default DishPage;
