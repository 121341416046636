import styled from "styled-components";
import { StyledScreenOverlay } from "../../../styles/OverlayStyles";

export const StyledScreenLoaderOverlay = styled(StyledScreenOverlay)`
  z-index: 1000;
`;

export const StyledScreenLoaderOverlayInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.default};
  background-color: ${(props) => props.theme.colors.accent.normal};
  box-shadow: ${(props) => props.theme.shadow.default};
  margin-top: ${(props) => props.theme.spacing.large};
`;

export const StyledScreenOverlayLogo = styled.img`
  border-radius: 0;
  width: 100px;
`;
