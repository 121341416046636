import { useLanguageContext } from "../../../context/LanguageContext";
import EmployeesPage from "../../../pages/employees/index/EmployeesPage";
import { useEmployeesRouteValidator } from "../../validators/employees/EmployeesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useEmployeesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/employees")
    .setLabel(translate("eva.main.entities.employees"))
    .setValidator(useEmployeesRouteValidator())
    .setPage(<EmployeesPage />);
}

export default useEmployeesRouteConfig;
