import styled from "styled-components";

export const StyledHiddenLoader = styled.div`
  position: relative;
`;

export const StyledHiddenLoaderChildContainer = styled.div`
  opacity: ${(props) => (props.$hide ? 0 : 1)};
  pointer-events: ${(props) => (props.$hide ? "none" : "initial")};
`;

export const StyledHiddenLoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
