import { useLanguageContext } from "../../../../context/LanguageContext";
import { useAuthContext } from "../../../../context/AuthContext";
import useFieldOptionCreateRouteConfig from "../../../../routes/configs/fields/options/FieldOptionCreateRouteConfig";
import useFieldOptionEditRouteConfig from "../../../../routes/configs/fields/options/FieldOptionEditRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function FieldOptionsDataTable({ fieldId }) {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("field_options");
  config.setHeader(translate("eva.main.entities.field_options"));

  //Set the datatable fetch info
  config.setFetchRequest(`fields/${fieldId}/options`);

  //Set the datatable order by
  config.setOrderBy("key");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("key").setLockActive(true),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("field_option", useFieldOptionEditRouteConfig()),
    useDeleteRowActionConfig("field_option").setDisabled(
      () => !authContext.hasPermission("fields.delete")
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "field_option",
      useFieldOptionCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default FieldOptionsDataTable;
