import { useLanguageContext } from "../../../../context/LanguageContext";
import OrdersLogPage from "../../../../pages/canteen/orders/log/OrdersLogPage";
import { useOrdersLogRouteValidator } from "../../../validators/canteen/orders/OrdersLogRouteValidator";
import { RouteConfig } from "../../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useOrdersLogRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/orders/log")
    .setLabel(translate("eva.main.entities.order_log"))
    .setValidator(useOrdersLogRouteValidator())
    .setPage(<OrdersLogPage />);
}

export default useOrdersLogRouteConfig;
