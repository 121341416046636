import { useAuthContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import LocationFormSelectView from "./LocationFormSelectView";

function LocationFormSelect({
  value,
  onChange,
  disabled,
  useNullOption = true,
}) {
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  /**
   * Determine the options for this select
   * @returns {object[]}
   */
  function getOptions() {
    const locations = authContext.auth.user
      ? authContext.auth.user.company.locations.map((location) => {
          return { label: location.name, value: location.id };
        })
      : [];
    if (useNullOption) {
      locations.unshift({
        label: translate("eva.main.general.all"),
        value: null,
      });
    }
    return locations;
  }

  return (
    authContext.auth.user?.company.type !== "superadmin" && (
      <LocationFormSelectView
        options={getOptions()}
        value={value}
        onChange={onChange}
        disabled={authContext.auth.location ? true : disabled}
      />
    )
  );
}

export default LocationFormSelect;
