import { useParams } from "react-router-dom";
import { useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import LanguagePageView from "./LanguagePageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function LanguagePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { language } = useParams();
  const languageContext = useLanguageContext();
  const [languageData, setLanguageData] = useState({
    fetched: false,
    id: "",
    code: "",
    label: "",
    default: false,
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (language) {
      fetchLanguageData();
    }
  });

  /**
   * This method will fetch the initial data from the API
   */
  async function fetchLanguageData() {
    await callEvaApi(
      new EvaApiCall(`languages/${language}`)
        .setLoadingGroup("language_page_fetch")
        .setOnSuccess((response) => {
          setLanguageData({
            fetched: true,
            id: response.data.data.id,
            code: response.data.data.code,
            label: response.data.data.label,
            default: response.data.data.default,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the code on input change
   * @param {event} e
   */
  function onCodeChange(e) {
    setLanguageData({ ...languageData, code: e.target.value });
  }

  /**
   * This method will update the label on input change
   * @param {event} e
   */
  function onLabelChange(e) {
    setLanguageData({ ...languageData, label: e.target.value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(language ? `languages/${language}` : "languages")
        .setLoadingGroup("language_page_save")
        .setMethod(language ? "PUT" : "POST")
        .setData(languageData, ["fetched", "default"])
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          routeContext.back();
          languageContext.fetchLanguages();
        })
    );
  }

  return (
    <LanguagePageView
      languageData={languageData}
      onCodeChange={onCodeChange}
      onLabelChange={onLabelChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default LanguagePage;
