import { createContext, useContext, useState } from "react";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";
import RandomizeHelper from "../helpers/RandomizeHelper";

const AlertContext = createContext();

export function AlertProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  /**
   * This method will display the given message on the screen
   * @param {string} message
   * @param {string} type the type of message to display (info, success, error)
   * @param {int} duration the duration in milliseconds that the alert will be displayed
   * @returns {string}
   */
  function showAlert(message, type = "info", duration = 5000) {
    const uuid = RandomizeHelper.getUUID();
    const newAlert = {
      uuid: uuid,
      message: message,
      type: type,
      timeout: setTimeout(() => hideAlert(uuid), duration),
    };

    setAlerts((current) => [...current, newAlert]);

    return newAlert.uuid;
  }

  /**
   * This method will hide the alert with the given uuid
   * @param {string} uuid
   */
  function hideAlert(uuid) {
    setAlerts((current) => {
      const alert = current.find((a) => a.uuid === uuid);
      clearTimeout(alert.timeout);
      return current.filter((a) => a.uuid !== uuid);
    });
  }

  return useContextDefiner(
    new ContextDefinition("alertContext", AlertContext)
      .setData({ alerts, showAlert, hideAlert })
      .setChildren(children)
  );
}

export function useAlertContext() {
  return useContext(AlertContext);
}
