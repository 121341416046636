import GenericStyles from "./GenericStyles";
import TableStyles from "./TableStyles";
import TinyEditorStyles from "./TinyEditorStyles";

function GlobalStyles() {
  return (
    <>
      <GenericStyles />
      <TinyEditorStyles />
      <TableStyles />
    </>
  );
}

export default GlobalStyles;
