import { useState } from "react";
import DashboardNavView from "./DashboardNavView";

function DashboardNav() {
  const [folded, setFolded] = useState(
    localStorage.getItem("dashboard_nav_folded") === "true"
  );

  /**
   * This method will handle the folded change
   * @param {boolean} value
   */
  function onFoldedChange(value) {
    setFolded(value);
    localStorage.setItem("dashboard_nav_folded", value);
  }

  return <DashboardNavView folded={folded} onFoldedChange={onFoldedChange} />;
}

export default DashboardNav;
