import styled from "styled-components";

export const StyledHtmlColorInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.small};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const StyledColorInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledColorInput = styled.input`
  flex: 1;
  &:hover {
    cursor: pointer;
  }
`;
