import { useLanguageContext } from "../../../../context/LanguageContext";
import useFieldCreateRouteConfig from "../../../../routes/configs/fields/FieldCreateRouteConfig";
import useFieldEditRouteConfig from "../../../../routes/configs/fields/FieldEditRouteConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDuplicateRowActionConfig from "../../main/config/rowAction/default/DuplicateRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";

function FieldsDataTable() {
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("fields");

  //Set the datatable fetch info
  config.setFetchRequest("fields");

  //Set the datatable order by
  config.setOrderBy("key");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("key").setLockActive(true),
    useBasicColumnConfig("type.translation")
      .setData((row) => translate(`eva.main.input.types.${row.type}`))
      .setLockActive(true),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("field", useFieldEditRouteConfig()),
    useDuplicateRowActionConfig("field"),
    useDeleteRowActionConfig("field"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("field", useFieldCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default FieldsDataTable;
