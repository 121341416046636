class CookieHelper {
  /**
   * This method will set a cookie with the given name and value
   * @param {string} name
   * @param {string} value
   * @param {string} path
   * @param {Date} expires
   */
  static set(name, value, path = "/", expires = null) {
    let cookie = `${name}=${encodeURIComponent(
      value
    )}; path=${path}; secure; SameSite=Strict;`;

    if (expires) {
      cookie += ` expires=${expires.toUTCString()};`;
    }
    document.cookie = cookie;
  }

  /**
   * This method will return the value of the cookie with the given name
   * @param {string} name
   * @returns {string|null}
   */
  static get(name) {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    for (let i = 0; i < cookies.length; i++) {
      const [cookieName, cookieValue] = cookies[i].split("=");
      if (cookieName?.toLowerCase() === name.toLowerCase()) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  /**
   * This method will remove the cookie with the given name
   * @param {string} name
   * @param {string} path
   */
  static remove(name, path = "/") {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; SameSite=Strict;`;
  }
}

export default CookieHelper;
