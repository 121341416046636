import styled from "styled-components";
import { StyledAltButton } from "../../styles/ButtonStyles";

export const StyledCounter = styled.div`
  display: flex;
  gap: 0px;
  align-items: center;
`;

export const StyledCounterMinus = styled(StyledAltButton)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid transparent;
`;

export const StyledCounterValue = styled.p`
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  padding: ${(props) => props.theme.spacing.normal};
  border-top: 1px solid ${(props) => props.theme.colors.primary.light};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.light};
`;

export const StyledCounterPlus = styled(StyledAltButton)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid transparent;
`;
