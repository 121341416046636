import { useLanguageContext } from "../../../../context/LanguageContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useOrderCreateRouteConfig from "../../../../routes/configs/canteen/orders/OrderCreateRouteConfig";
import useOrderEditRouteConfig from "../../../../routes/configs/canteen/orders/OrderEditRouteConfig";
import useOrderPrepareRouteConfig from "../../../../routes/configs/canteen/orders/OrderPrepareRouteConfig";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";
import HeaderActionConfig from "../../main/config/headerAction/HeaderActionConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";

function OrdersDataTable() {
  const callEvaApi = useEvaApi();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();
  const orderCreateRouteConfig = useOrderCreateRouteConfig();

  //Create the datatable config
  const config = new DataTableConfig("canteen_orders");

  //Set the datatable fetch info
  config.setFetchRequest("canteen/orders");
  config.setFetchParams({
    with_order_employee_data: true,
    with_order_location_data: true,
  });

  //Set the datatable order by
  config.setOrderBy("employee.name");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location.name"),
    useBasicColumnConfig("employee.name").setLockActive(true),
    useBasicColumnConfig("status.translation").setData((row) =>
      translate("eva.main.statuses." + row.status)
    ),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("order", useOrderEditRouteConfig()),
    useDeleteRowActionConfig(
      "order",
      (row) => `canteen/orders/${row.id}`
    ).setDisabled(
      (row) =>
        row.status !== "pending" ||
        (row.employee.id !== authContext.auth.user.employee &&
          !authContext.hasPermission("canteen.orders.delete")) ||
        (row.employee.id === authContext.auth.user.employee &&
          !authContext.hasPermission("canteen.orders.delete.self") &&
          !authContext.hasPermission("canteen.orders.delete"))
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  //Set the datatable header actions
  config.setHeaderActions([
    new HeaderActionConfig()
      .setLabel(translate("eva.main.general.cook"))
      .setCallback(handlePrepare)
      .setDisabled(
        useOrderPrepareRouteConfig().validator.validate() !== true ||
          dataTable.data?.records?.length <= 0
      )
      .setLoadingGroups(`${config.getDataTableKey()}_prepare`),
    useCreateHeaderActionConfig("order", orderCreateRouteConfig).setDisabled(
      orderCreateRouteConfig.validator.validate(
        dataTable.data?.records?.length > 0 ? dataTable.data?.records[0] : null
      ) !== true
    ),
  ]);

  /**
   * This method will set all orders to the preparing state
   * @returns
   */
  async function handlePrepare() {
    //Instantly navigate to the prepare page if there are orders that are not pending
    if (
      dataTable.data?.records?.length > 0 &&
      dataTable.data?.records[0].status !== "pending"
    ) {
      navigate("/dashboard/canteen/orders/prepare");
      return;
    }

    if (!window.confirm(translate("eva.main.questions.prepare.order"))) {
      return;
    }

    await callEvaApi(
      new EvaApiCall("canteen/orders/prepare")
        .setLoadingGroup(`${config.getDataTableKey()}_prepare`)
        .setRedirectOnSuccess("/dashboard/canteen/orders/prepare")
        .setMethod("post")
    );
  }

  return <DataTable controller={dataTable} />;
}

export default OrdersDataTable;
