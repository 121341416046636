import { useLanguageContext } from "../../../../context/LanguageContext";
import FieldConditionPage from "../../../../pages/fields/field/condition/FieldConditionPage";
import { useFieldEditRouteValidator } from "../../../validators/fields/FieldEditRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useFieldEditRouteConfig from "../FieldEditRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFieldConditionCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/fields/:field/conditions/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.field_condition"),
        },
      ])
    )
    .setValidator(useFieldEditRouteValidator())
    .setPage(<FieldConditionPage />)
    .setParent(useFieldEditRouteConfig());
}

export default useFieldConditionCreateRouteConfig;
