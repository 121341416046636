import { useLanguageContext } from "../../../context/LanguageContext";
import MessageTemplatePage from "../../../pages/message_templates/message_template/MessageTemplatePage";
import { useMessageTemplateEditRouteValidator } from "../../validators/message_templates/MessageTemplateEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useMessageTemplatesRouteConfig from "./MessageTemplatesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useMessageTemplateEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/message_templates/:message_template/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.message_template"),
        },
      ])
    )
    .setValidator(useMessageTemplateEditRouteValidator())
    .setPage(<MessageTemplatePage />)
    .setParent(useMessageTemplatesRouteConfig());
}

export default useMessageTemplateEditRouteConfig;
