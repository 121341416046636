import useEmployeeCreateRouteConfig from "../../../routes/configs/employees/EmployeeCreateRouteConfig";
import useEmployeeEditRouteConfig from "../../../routes/configs/employees/EmployeeEditRouteConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useBooleanColumnConfig from "../main/config/column/default/BooleanColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function EmployeesDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("employees");

  //Set the datatable fetch info
  config.setFetchRequest("employees");
  config.setFetchParams({
    with_employee_location_data: true,
    with_employee_department_data: true,
  });

  //Set the datatable order by
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location.name"),
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("email").setLockActive(true),
    useBooleanColumnConfig("present"),
    useBasicColumnConfig("department.name"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("employee", useEmployeeEditRouteConfig()),
    useDeleteRowActionConfig("employee"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("employee", useEmployeeCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default EmployeesDataTable;
