import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledInput } from "../../../styles/InputStyles";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../components/formContainer/FormContainer";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import LocationsDataTable from "../../../components/dataTable/tables/LocationsDataTable";

function CompanyPageView({
  company,
  companyData,
  onNameChange,
  onContactChange,
  onContactDetailsChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledFlexLayout direction="column" gap={theme.spacing.large}>
      <OverlayLoader groups="company_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"company_page_save"}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.name")}
            error={EVAApiHelper.getResponseError("name", submitError)}
            required
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.name")}
              value={companyData.name}
              onChange={onNameChange}
            />
          </InputWrapper>

          <StyledFlexLayout margin={`${theme.spacing.normal} 0px 0px`}>
            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.contact")}
                error={EVAApiHelper.getResponseError("contact", submitError)}
                required
              >
                <StyledInput
                  type="text"
                  placeholder={translate(
                    "eva.main.validation.attributes.contact"
                  )}
                  value={companyData.contact}
                  onChange={onContactChange}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>

            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate(
                  "eva.main.validation.attributes.contact_details"
                )}
                error={EVAApiHelper.getResponseError(
                  "contact_details",
                  submitError
                )}
              >
                <StyledInput
                  type="text"
                  placeholder={translate(
                    "eva.main.validation.attributes.contact_details"
                  )}
                  value={companyData.contact_details}
                  onChange={onContactDetailsChange}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      {companyData.fetched ? <LocationsDataTable companyId={company} /> : ""}
    </StyledFlexLayout>
  );
}

export default CompanyPageView;
