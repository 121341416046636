import { useLanguageContext } from "../../../context/LanguageContext";
import { RouteConfig } from "../RouteConfig";
import { useTerminalEditRouteValidator } from "../../validators/terminals/TerminalEditRouteValidator";
import useTerminalsRouteConfig from "./TerminalsRouteConfig";
import TerminalPage from "../../../pages/terminals/terminal/TerminalPage";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useTerminalEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/terminals/:terminal/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.terminal")
        }
      ])
    )
    .setValidator(useTerminalEditRouteValidator())
    .setPage(<TerminalPage />)
    .setParent(useTerminalsRouteConfig());
}

export default useTerminalEditRouteConfig;