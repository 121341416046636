import styled from "styled-components";

export const StyledFileInput = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${(props) => props.theme.colors.primary.lightest};
  border-radius: ${(props) => props.theme.radius.default};
  overflow: hidden;
`;

export const StyledFiles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.large};
  padding: ${(props) => props.theme.spacing.normal};

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.light};
  }
`;

export const StyledFileName = styled.p`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const StyledPlaceHolder = styled(StyledFileName)`
  opacity: 0.3;
  padding: ${(props) => props.theme.spacing.normal};
`;

export const StyledActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.normal};
  background-color: ${(props) => props.theme.colors.primary.darkest};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledUploadButton = styled.button`
  padding: ${(props) => props.theme.spacing.normal};
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};

  &:hover {
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;
