import {
  faCheck,
  faInfo,
  faWarning,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  StyledAlert,
  StyledAlertClose,
  StyledAlertLabel,
  StyledFooterAlerts,
} from "./MainFooterAlertsStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MainFooterAlertsView({ alerts, onHideAlert }) {
  function getAlertIcon(alert) {
    switch (alert.type) {
      case "info":
        return faInfo;
      case "success":
        return faCheck;
      case "error":
        return faWarning;
      default:
        return faInfo;
    }
  }

  return (
    <StyledFooterAlerts>
      {alerts.map((alert) => (
        <StyledAlert key={alert.uuid}>
          <StyledAlertLabel>
            <FontAwesomeIcon icon={getAlertIcon(alert)} />
            {alert.message}
          </StyledAlertLabel>

          <StyledAlertClose onClick={() => onHideAlert(alert)}>
            <FontAwesomeIcon icon={faXmark} />
          </StyledAlertClose>
        </StyledAlert>
      ))}
    </StyledFooterAlerts>
  );
}

export default MainFooterAlertsView;
