import { useLanguageContext } from "../../../context/LanguageContext";
import CompanyLanguagePage from "../../../pages/company_languages/company_language/CompanyLanguagePage";
import { useCompanyLanguageEditRouteValidator } from "../../validators/companyLanguages/CompanyLanguageEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useCompanyLanguagesRouteConfig from "./CompanyLanguagesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCompanyLanguageEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/company_languages/:company_language/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.company_language"),
        },
      ])
    )
    .setValidator(useCompanyLanguageEditRouteValidator())
    .setPage(<CompanyLanguagePage />)
    .setParent(useCompanyLanguagesRouteConfig());
}

export default useCompanyLanguageEditRouteConfig;
