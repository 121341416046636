import { useLanguageContext } from "../../../context/LanguageContext";
import ContactsPage from "../../../pages/contacts/index/ContactsPage";
import { useContactsRouteValidator } from "../../validators/contacts/ContactsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useContactsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/contacts")
    .setLabel(translate("eva.main.entities.contacts"))
    .setValidator(useContactsRouteValidator())
    .setPage(<ContactsPage />);
}

export default useContactsRouteConfig;
