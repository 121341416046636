import useLanguageEditRouteConfig from "../../../routes/configs/languages/LanguageEditConfig";
import useLanguageCreateRouteConfig from "../../../routes/configs/languages/LanguageCreateConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function LanguagesDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("languages");

  //Set the datatable fetch info
  config.setFetchRequest("languages");

  //Set the datatable order by
  config.setOrderBy("label");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("code"),
    useBasicColumnConfig("label").setLockActive(true),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("language", useLanguageEditRouteConfig()),
    useDeleteRowActionConfig("language", null, true).setDisabled(
      (row) => row.default
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("language", useLanguageCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default LanguagesDataTable;
