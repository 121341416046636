class HeaderActionConfig {
  constructor() {
    this.icon = (dataTable) => null;
    this.title = (dataTable) => "";
    this.label = (dataTable) => "";
    this.callback = (dataTable) => {};
    this.disabled = (dataTable) => false;
    this.hidden = (dataTable) => false;
    this.loadingGroups = (dataTable) => [];
  }

  /**
   * @param {IconDefinition | function(dataTable) : IconDefinition} icon
   * @returns {this}
   */
  setIcon(icon) {
    this.icon = typeof icon === "function" ? icon : () => icon;
    return this;
  }

  /**
   * @param {string | function(dataTable) : string} title
   * @returns {this}
   */
  setTitle(title) {
    this.title = typeof title === "function" ? title : () => title;
    return this;
  }

  /**
   * @param {string | function(dataTable) : string} label
   * @returns {this}
   */
  setLabel(label) {
    this.label = typeof label === "function" ? label : () => label;
    return this;
  }

  /**
   * @param {function(dataTable)} callback
   * @returns {this}
   */
  setCallback(callback) {
    this.callback = callback;
    return this;
  }

  /**
   * @param {boolean | function(dataTable) : boolean} disabled
   * @returns {this}
   */
  setDisabled(disabled) {
    this.disabled = typeof disabled === "function" ? disabled : () => disabled;
    return this;
  }

  /**
   * @param {boolean | function(dataTable) : boolean} hidden
   * @returns {this}
   */
  setHidden(hidden) {
    this.hidden = typeof hidden === "function" ? hidden : () => hidden;
    return this;
  }

  /**
   * @param {string[] | function(dataTable) : string[]} loadingGroups
   * @returns {this}
   */
  setLoadingGroups(loadingGroups) {
    this.loadingGroups =
      typeof loadingGroups === "function" ? loadingGroups : () => loadingGroups;
    return this;
  }
}

export default HeaderActionConfig;
