import { useLanguageContext } from "../../../../context/LanguageContext";
import DishesPage from "../../../../pages/canteen/dishes/index/DishesPage";
import { useDishesRouteValidator } from "../../../validators/canteen/dishes/DishesRouteValidator";
import { RouteConfig } from "../../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useDishesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/dishes")
    .setLabel(translate("eva.main.entities.dishes"))
    .setValidator(useDishesRouteValidator())
    .setPage(<DishesPage />);
}

export default useDishesRouteConfig;
