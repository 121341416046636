import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledClearButton,
  StyledImage,
  StyledImageIcon,
  StyledImageInput,
  StyledImageInputWrapper,
} from "./ImageInputStyles";
import { faImage, faXmark } from "@fortawesome/free-solid-svg-icons";

function ImageInputView({ src, onSelect, onClear, size }) {
  return (
    <StyledImageInputWrapper>
      <StyledImageInput onClick={onSelect} size={size}>
        {!src && <StyledImageIcon icon={faImage} />}

        {src && <StyledImage src={src} alt="image" />}
      </StyledImageInput>

      {src && (
        <StyledClearButton onClick={onClear}>
          <FontAwesomeIcon icon={faXmark} />
        </StyledClearButton>
      )}
    </StyledImageInputWrapper>
  );
}

export default ImageInputView;
