import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  StyledOverlayLoader,
  StyledOverlayLoaderOverlay,
} from "./OverlayLoaderStyles";
import { useLoadContext } from "../../../context/LoadContext";

function OverlayLoaderView({
  groups,
  iconSize,
  containerStyle,
  overlayStyle,
  children,
}) {
  const loadContext = useLoadContext();
  const isLoading = loadContext.isLoading(groups);

  return (
    <StyledOverlayLoader style={containerStyle}>
      {children}

      {isLoading ? (
        <StyledOverlayLoaderOverlay style={overlayStyle}>
          <FontAwesomeIcon icon={faSpinner} size={iconSize} spin />
        </StyledOverlayLoaderOverlay>
      ) : (
        ""
      )}
    </StyledOverlayLoader>
  );
}

export default OverlayLoaderView;
