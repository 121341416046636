import { useDraggableContext } from "../../context/DraggableContext";
import { useLanguageContext } from "../../context/LanguageContext";
import FileHelper from "../../helpers/FileHelper";
import Draggable from "../draggable/Draggable";
import LanguageSelect from "../select/custom/language/LanguageSelect";
import { StyledLayoutItemImage, StyledLayoutItem } from "./LayoutStyles";

function LayoutView({
  readonly,
  layoutData,
  content,
  languageSelect,
  onMouseMove,
  onMouseLeave,
  onMouseClick,
  onDragEnter,
}) {
  const draggableContext = useDraggableContext();
  const { translateEntity } = useLanguageContext();

  /**
   * This method will return the rendered layout item.
   * @param {object} item
   * @returns {JSX.Element}
   */
  function render(item) {
    if (!item) {
      return null;
    }

    const component = (
      <StyledLayoutItem
        id={`LAYOUT_ITEM_${item.id}`}
        key={item.id}
        style={getLayoutItemStyle(item)}
        $focused={
          layoutData.focused_item_id === item.id && !draggableContext.dragged
        }
        $hovered={
          layoutData.hovered_item_id === item.id && !draggableContext.dragged
        }
        onMouseMove={(e) => onMouseMove(item, e)}
        onMouseLeave={() => onMouseLeave()}
        onClick={(e) => onMouseClick(item, e)}
      >
        {item.image && (
          <StyledLayoutItemImage
            $item={item}
            src={FileHelper.apiPath(translateEntity(item.image, "path"))}
            alt={""}
          />
        )}

        {item.children.map((child) => render(child))}

        {item.type === "content" && content}

        {item.type === "language" && (languageSelect ?? <LanguageSelect />)}
      </StyledLayoutItem>
    );

    /**
     * This method will return the style of the layout item
     * @param {object} item
     * @returns {object}
     */
    function getLayoutItemStyle(item) {
      const style = { ...item.style };
      if (item.type === "page" && readonly) {
        style.width = "100%";
        style.height = "100%";
      }

      if (item.type === "image") {
        delete style.width;
        delete style.height;
        delete style.maxWidth;
        delete style.maxHeight;
      }

      return style;
    }

    return readonly ? (
      component
    ) : (
      <Draggable
        key={item.id}
        id={item.id}
        draggable={item.type !== "page"}
        onTargetEnter={onDragEnter}
      >
        {component}
      </Draggable>
    );
  }

  return render(layoutData?.layout);
}

export default LayoutView;
