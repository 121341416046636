import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import useRouteValidator from "../../validators/RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
function useDashboardLayoutRouteValidator() {
  return useRouteValidator(useMiddlewares([useAuthMiddleware()]));
}

export default useDashboardLayoutRouteValidator;
