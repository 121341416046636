import { useLanguageContext } from "../../../context/LanguageContext";
import MessageTemplatesPage from "../../../pages/message_templates/index/MessageTemplatesPage";
import { useMessageTemplatesRouteValidator } from "../../validators/message_templates/MessageTemplatesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useMessageTemplatesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/message_templates")
    .setLabel(translate("eva.main.entities.message_templates"))
    .setValidator(useMessageTemplatesRouteValidator())
    .setPage(<MessageTemplatesPage />);
}

export default useMessageTemplatesRouteConfig;
