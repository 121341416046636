import { useState } from "react";
import { useParams } from "react-router-dom";
import DepartmentPageView from "./DepartmentPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEntityTranslations from "../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function DepartmentPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { department } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [departmentData, setDepartmentData] = useState({
    name: "",
    parent_id: null,
    translations: [],
  });
  const translationsController = useEntityTranslations(
    departmentData.translations,
    onTranslationsChange
  );

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    fetchDepartments();

    if (department) {
      fetchDepartmentData();
    }
  });

  /**
   * This method will fetch the available departments from the API,
   * excluding the department that matches the current department
   */
  async function fetchDepartments() {
    await callEvaApi(
      new EvaApiCall("departments")
        .setLoadingGroup("departments_page_fetch")
        .setOnSuccess((response) => {
          const newDepartments = response.data.data.records.filter(
            (dep) =>
              dep.id.toString() !== department?.toString() &&
              (dep.parent === null ||
                dep.parent?.toString() !== department?.toString())
          );
          setDepartments(newDepartments);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the department data from the API
   */
  async function fetchDepartmentData() {
    await callEvaApi(
      new EvaApiCall(`departments/${department}`)
        .setLoadingGroup("department_page_fetch")
        .setOnSuccess((response) => {
          setDepartmentData({
            name: response.data.data.name,
            parent_id: response.data.data.parent,
            translations: response.data.data.translations.map(
              (translation) => ({
                language_id: translation.language,
                name: translation.name,
              })
            ),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setDepartmentData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will update the parent on input change
   * @param {any} value
   */
  function onParentChange(value) {
    setDepartmentData((current) => ({ ...current, parent_id: value }));
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setDepartmentData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(department ? `departments/${department}` : "departments")
        .setLoadingGroup("department_page_save")
        .setMethod(department ? "PUT" : "POST")
        .setData(departmentData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <DepartmentPageView
      departments={departments}
      departmentData={departmentData}
      translationsController={translationsController}
      onNameChange={onNameChange}
      onParentChange={onParentChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default DepartmentPage;
