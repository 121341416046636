import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HtmlSizeInput from "../HtmlSizeInput";
import {
  StyledHtmlMultiSizeInput,
  StyledHtmlMultiSizeInputWrapper,
} from "./HtmlMultiSizeInputStyles";

function HtmlMultiSizeInputView({ inputs, types, icon, getIconRotation }) {
  return (
    <StyledHtmlMultiSizeInputWrapper>
      {inputs.map((input, index) => (
        <StyledHtmlMultiSizeInput key={index}>
          <FontAwesomeIcon
            icon={icon ?? input.icon}
            rotation={getIconRotation(input, index)}
          />

          <HtmlSizeInput
            value={input.value}
            onChange={input.onChange}
            types={types ?? input.types}
          />
        </StyledHtmlMultiSizeInput>
      ))}
    </StyledHtmlMultiSizeInputWrapper>
  );
}

export default HtmlMultiSizeInputView;
