import styled from "styled-components";

export const StyledCard = styled.div`
  border-radius: ${(props) => props.theme.radius.default};
  box-shadow: ${(props) => props.theme.shadow.default};
  border: 1px solid ${(props) => props.theme.colors.primary.darkest};
  background-color: ${(props) => props.theme.colors.primary.normal};
`;

export const StyledCardHeader = styled.header`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  padding: ${(props) => props.theme.spacing.normal};
  border-top-left-radius: ${(props) => props.theme.radius.default};
  border-top-right-radius: ${(props) => props.theme.radius.default};
`;

export const StyledCardBody = styled.div`
  background-color: ${(props) => props.theme.colors.primary.normal};
  color: ${(props) => props.theme.colors.on.primary};
  padding: ${(props) => props.theme.spacing.normal};
  border-bottom-left-radius: ${(props) => props.theme.radius.default};
  border-bottom-right-radius: ${(props) => props.theme.radius.default};

  &:first-child {
    border-top-left-radius: ${(props) => props.theme.radius.default};
    border-top-right-radius: ${(props) => props.theme.radius.default};
  }
`;
