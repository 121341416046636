import { createGlobalStyle } from "styled-components";

const TableStyles = createGlobalStyle`
  table {
    width: 100%;
    border-collapse: collapse;
    color: ${(props) => props.theme.colors.on.primary};
  }

  tr {
    thead & {
      background-color: ${(props) => props.theme.colors.primary.darkest};
    }

    tbody & {
      &:nth-child(odd) {
        background-color: ${(props) => props.theme.colors.primary.normal};
      }
      &:nth-child(even) {
        background-color: ${(props) => props.theme.colors.primary.light};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.primary.darkest};
      }
    }
  }

  th {
    text-align: left;
    border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
    border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
    padding: ${(props) => props.theme.spacing.normal};

    &:last-child {
      border-right-width: 0px;
    }
    &:first-child {
      border-left-width: 0px;
    }
  }

  td {
    text-align: left;
    border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
    border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
    padding: ${(props) => props.theme.spacing.normal};

    &:last-child {
      border-right-width: 0px;
    }
    &:first-child {
      border-left-width: 0px;
    }
  }
`;

export default TableStyles;
