import styled from "styled-components";

export const StyledScreensaver = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: ${(props) => `all ${props.$fadeTime / 3}ms linear`};
  opacity: ${(props) => (props.disabled ? 0 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;

  &:last-child {
    animation-name: fadeIn;
    animation-duration: ${(props) => `${props.$fadeTime}ms`};
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
