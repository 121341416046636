import { useLanguageContext } from "../../../context/LanguageContext";
import UsersPage from "../../../pages/users/index/UsersPage";
import { useUsersRouteValidator } from "../../validators/users/UsersRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useUsersRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/users")
    .setLabel(translate("eva.main.entities.users"))
    .setValidator(useUsersRouteValidator())
    .setPage(<UsersPage />);
}

export default useUsersRouteConfig;
