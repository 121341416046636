import FormContainerView from "./FormContainerView";

function FormContainer({
  header,
  children,
  onCancel,
  onSubmit,
  submitError,
  submitLoadingGroup,
}) {
  return (
    <FormContainerView
      header={header}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
      submitLoadingGroup={submitLoadingGroup}
    >
      {children}
    </FormContainerView>
  );
}

export default FormContainer;
