import { useTheme } from "styled-components";
import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import { StyledInput } from "../../../styles/InputStyles";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import Select from "../../../components/select/main/Select";
import FieldOptionsDataTable from "../../../components/dataTable/tables/field/FieldOptionsDataTable";
import EntityTranslationsContainer from "../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import FieldConditionsDataTable from "../../../components/dataTable/tables/field/FieldConditionsDataTable";

function FieldPageView({
  field,
  fieldData,
  translationsController,
  onKeyChange,
  onTypeChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="field_page_fetch">
        <FormContainer
          header={translate("eva.main.general.data")}
          submitError={submitError}
          submitLoadingGroup={`field_page_save`}
          onCancel={onCancel}
          onSubmit={onSubmit}
        >
          <StyledFlexLayout>
            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.key")}
                error={EVAApiHelper.getResponseError("key", submitError)}
                required
              >
                <StyledInput
                  type="text"
                  value={fieldData.key}
                  placeholder={`${translate("eva.main.entities.field")}_01`}
                  onChange={onKeyChange}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>

            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.type")}
                error={EVAApiHelper.getResponseError("type", submitError)}
                required
              >
                <Select
                  value={fieldData.type}
                  onChange={onTypeChange}
                  options={[
                    {
                      value: "text",
                      label: translate("eva.main.input.types.text"),
                    },
                    {
                      value: "date",
                      label: translate("eva.main.input.types.date"),
                    },
                    {
                      value: "time",
                      label: translate("eva.main.input.types.time"),
                    },
                    {
                      value: "boolean",
                      label: translate("eva.main.input.types.boolean"),
                    },
                    {
                      value: "select",
                      label: translate("eva.main.input.types.select"),
                    },
                  ]}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader
        groups="field_page_fetch"
        containerStyle={{ marginBlock: theme.spacing.large }}
      >
        <EntityTranslationsContainer controller={translationsController}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.label")}
            error={translationsController.getTranslationError(
              "label",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.label")}
              value={translationsController.getValue("label")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "label"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>

      {fieldData.fetchedType === "select" && (
        <FieldOptionsDataTable fieldId={field} />
      )}

      {fieldData.fetchedType && fieldData.fetchedType !== "select" && (
        <FieldConditionsDataTable fieldId={field} />
      )}
    </>
  );
}

export default FieldPageView;
