import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../../components/formContainer/FormContainer";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../../styles/InputStyles";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import EntityTranslationsContainer from "../../../../components/entityTranslationsContainer/EntityTranslationsContainer";

function FieldOptionPageView({
  fieldOptionData,
  translationsController,
  onKeyChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader groups="field_option_page_fetch">
        <FormContainer
          header={translate("eva.main.general.data")}
          submitError={submitError}
          submitLoadingGroup={`field_option_page_save`}
          onCancel={onCancel}
          onSubmit={onSubmit}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.key")}
            error={EVAApiHelper.getResponseError("key", submitError)}
            required
          >
            <StyledInput
              type="text"
              value={fieldOptionData.key}
              placeholder={`${translate("eva.main.entities.field")}_01`}
              onChange={onKeyChange}
            />
          </InputWrapper>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader
        groups="field_option_page_fetch"
        containerStyle={{ marginTop: theme.spacing.large }}
      >
        <EntityTranslationsContainer controller={translationsController}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.label")}
            error={translationsController.getTranslationError(
              "label",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.label")}
              value={translationsController.getValue("label")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "label"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default FieldOptionPageView;
