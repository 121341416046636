class StringHelper {
  /**
   * This method will pluralize the given word
   * @param {string} word
   * @returns {string}
   */
  static pluralize(word) {
    const loweredWord = word.toLowerCase();
    if (loweredWord.endsWith("y")) {
      return word.slice(0, -1) + "ies";
    } else if (loweredWord.endsWith("s")) {
      return word + "es";
    }
    return word + "s";
  }
}

export default StringHelper;
