import { RouteConfig } from "./RouteConfig";
import { useLanguageContext } from "../../context/LanguageContext";
import { useReleaseNotesRouteValidator } from "../validators/ReleaseNotesRouteValidator";
import ReleaseNotesPage from "../../pages/release_notes/index/ReleaseNotesPage";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useReleaseNotesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/release_notes")
    .setLabel(translate("eva.main.entities.release_notes"))
    .setValidator(useReleaseNotesRouteValidator())
    .setPage(<ReleaseNotesPage />);
}

export default useReleaseNotesRouteConfig;
