import styled from "styled-components";

export const StyledPdfViewer = styled.div`
  max-width: 100%;
  width: 600px;
  min-height: 50px;
`;

export const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
