import { useLanguageContext } from "../../../../context/LanguageContext";
import LanguageSelectView from "./LanguageSelectView";

function LanguageSelect({ disabled }) {
  const languageContext = useLanguageContext();

  /**
   * Handle the change of the select
   * @param {any} value
   */
  function onChange(value) {
    languageContext.updateLanguage(value);
  }

  return (
    <LanguageSelectView
      value={languageContext.language?.id}
      options={languageContext.languages.map((lang) => ({
        value: lang.id,
        label: lang.label,
      }))}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default LanguageSelect;
