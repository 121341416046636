import { useLanguageContext } from "../../../context/LanguageContext";
import SelectView from "./SelectView";

function Select({
  as,
  options,
  withNullOption = false,
  onChange,
  value,
  disabled,
  title,
  valueVisual,
  hideCaret,
  placeholder,
}) {
  const { translate } = useLanguageContext();

  // Add the null option to the options
  if (withNullOption) {
    options = [
      {
        value: null,
        label: translate("eva.main.general.select"),
      },
      ...options,
    ];
  }

  // Get the selected option
  const selectedOption = options?.find(
    (option) => `${option.value}` === `${value}`
  );

  /**
   * Handle the onChange event
   * @param {event} e
   */
  function handleChange(e) {
    if (onChange) {
      const newValue = options?.find(
        (option) => `${option.value}` === e.target.value
      )?.value;
      onChange(newValue);
    }
  }

  /**
   * This method will return the sorted options
   * @returns {object[]} The sorted options
   */
  function getSortedOptions() {
    return options?.sort((a, b) => {
      // The null option should be the first
      if (
        (a.label === translate("eva.main.general.select") &&
          a.value === null) ||
        (b.label === translate("eva.main.general.select") && b.value === null)
      ) {
        return a.label === translate("eva.main.general.select") &&
          a.value === null
          ? -1
          : 1;
      }

      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <SelectView
      as={as}
      disabled={disabled}
      valueVisual={valueVisual}
      value={value}
      selectedOption={selectedOption}
      options={getSortedOptions()}
      onChange={handleChange}
      hideCaret={hideCaret}
      title={title}
      placeholder={placeholder}
    />
  );
}

export default Select;
