import { useLanguageContext } from "../../../../../../context/LanguageContext";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../../../styles/LayoutStyles";
import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../../../../styles/InputStyles";
import Select from "../../../../../../components/select/main/Select";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import StepLayout from "../layout/StepLayout";

function FieldsStepView({
  step,
  errors,
  visitorData,
  onFieldChange,
  setPageConfig,
  processStep,
  previousStep,
}) {
  const { translate, translateEntity } = useLanguageContext();

  function getInputComponent(field) {
    const fieldData = visitorData.fields?.find((f) => f.id === field.id);

    switch (field.type) {
      case "text":
      case "date":
      case "time":
        return (
          <StyledInput
            value={fieldData?.value ?? ""}
            onChange={(e) => onFieldChange(field, e.target.value)}
            type={field.type}
            placeholder={translateEntity(field, "label")}
          />
        );
      case "select":
      case "boolean":
        return (
          <Select
            value={
              (field.type === "select"
                ? fieldData?.option_id
                : fieldData?.value) ?? ""
            }
            onChange={(value) => onFieldChange(field, value)}
            options={
              field.type === "boolean"
                ? [
                    { value: true, label: translate("eva.main.general.yes") },
                    { value: false, label: translate("eva.main.general.no") },
                  ]
                : field.options.map((option) => ({
                    value: option.id,
                    label: translateEntity(option, "label"),
                  }))
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <StepLayout
      step={step}
      visitorData={visitorData}
      setPageConfig={setPageConfig}
      processStep={processStep}
      previousStep={previousStep}
    >
      <StyledFlexLayout style={{ flexDirection: "column" }}>
        {step?.fields?.map((row, index) => (
          <StyledFlexLayout key={index}>
            {row.map((fieldItem) => (
              <StyledFlexLayoutItem key={fieldItem.id}>
                <InputWrapper
                  label={translateEntity(fieldItem.field, "label")}
                  required={fieldItem.required_terminal}
                  error={EVAApiHelper.getResponseError(
                    fieldItem.field.key,
                    errors
                  )}
                >
                  {getInputComponent(fieldItem.field)}
                </InputWrapper>
              </StyledFlexLayoutItem>
            ))}
          </StyledFlexLayout>
        ))}
      </StyledFlexLayout>
    </StepLayout>
  );
}

export default FieldsStepView;
