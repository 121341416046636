import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledCheckbox, StyledCheckboxContainer } from "./CheckboxStyles";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function CheckboxView({ value, onChange, disabled, label }) {
  return (
    <StyledCheckboxContainer
      disabled={disabled}
      onClick={() => onChange(!value)}
    >
      <StyledCheckbox>
        {value && <FontAwesomeIcon icon={faCheck} />}
      </StyledCheckbox>

      <label>{label}</label>
    </StyledCheckboxContainer>
  );
}

export default CheckboxView;
