import styled from "styled-components";

export const StyledLayoutItem = styled.div`
  position: relative;
  border: ${(props) =>
    props.$focused
      ? `1px dashed ${props.theme.colors.accent.normal}`
      : props.$hovered
      ? `1px dashed ${props.theme.colors.accent.dark}`
      : "0"};
`;

export const StyledLayoutItemImage = styled.img`
  width: ${(props) =>
    props.$item.type === "image" ? props.$item.style.width : "100%"};
  height: ${(props) =>
    props.$item.type === "image" ? props.$item.style.height : "100%"};
  max-height: ${(props) =>
    props.$item.type === "image" ? props.$item.style.maxHeight : "100%"};
  max-width: ${(props) =>
    props.$item.type === "image" ? props.$item.style.maxWidth : "100%"};
  object-fit: cover;
  position: ${(props) =>
    props.$item.type === "image" ? "initial" : "absolute"};
  top: 0;
  left: 0;
`;
