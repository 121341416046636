import { useRef, useState } from "react";
import RangeInputView from "./RangeInputView";
import useCustomEffect from "../../../hooks/useCustomEffect";

function RangeInput({ value = 0, min = 0, max = 1, onChange }) {
  value = clampedValue(value);
  const [inputValue, setInputValue] = useState(value);
  const percentage = valueToPercentage(value);
  const rangeRef = useRef(null);

  /**
   * This effect will update the input value based on the value prop
   */
  useCustomEffect(() => {
    setInputValue(value);
  }, [value]);

  /**
   * This method will return the clamped value based on the min and max values
   * @param {number} value
   * @returns {number}
   */
  function clampedValue(value) {
    return Math.min(max, Math.max(min, value));
  }

  /**
   * This method will return the value with a maximum of 2 decimal places
   * @param {any} value
   * @returns {number}
   */
  function clampedValueDecimals(value) {
    return value !== null && value.toString().length > 0
      ? Math.round(parseFloat(value * 100)) / 100
      : value;
  }

  /**
   * This method will return the percentage value of the given value
   * @param {number} value
   * @returns {number}
   */
  function valueToPercentage(value) {
    return ((value - min) / (max - min)) * 100;
  }

  /**
   * This method will return the value based on the mouse position
   * @param {Event} e
   * @returns {number}
   */
  function mousePosToValue(e) {
    const rect = rangeRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const percent = x / width;
    return clampedValue(percent * max);
  }

  /**
   * This method will handle the mouse down event on the range input
   * @param {Event} e
   */
  function onRangeMouseDown(e) {
    e.preventDefault();
    onChange(mousePosToValue(e));
    window.addEventListener("mousemove", onRangeMouseMove);
    window.addEventListener("mouseup", onRangeMouseUp);

    function onRangeMouseMove(e) {
      e.preventDefault();
      onChange(mousePosToValue(e));
    }

    function onRangeMouseUp() {
      window.removeEventListener("mousemove", onRangeMouseMove);
      window.removeEventListener("mouseup", onRangeMouseUp);
    }
  }

  /**
   * This method will handle the input change event
   * @param {Event} e
   */
  function onInputChange(e) {
    if (onChange) {
      setInputValue(e.target.value);

      const trimmed = e.target.value.trim();
      if (trimmed.length > 0 && !isNaN(trimmed)) {
        onChange(clampedValue(parseFloat(trimmed)));
      }
    }
  }

  return (
    <RangeInputView
      inputValue={clampedValueDecimals(inputValue)}
      min={min}
      percentage={percentage}
      rangeRef={rangeRef}
      onRangeMouseDown={onRangeMouseDown}
      onInputChange={onInputChange}
    />
  );
}

export default RangeInput;
