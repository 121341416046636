import { useLanguageContext } from "../../../../context/LanguageContext";
import DataTable, { useDataTable } from "../../main/DataTable";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";

function CalamitiesLogDataTable() {
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("calamities_log");

  //Set the datatable fetch info
  config.setFetchRequest("calamity_logs");

  //Set the datatable order by
  config.setOrderBy("created_at");
  config.setOrderDirection("desc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location_name").setLabel(
      translate("eva.main.validation.attributes.location_id")
    ),
    useBasicColumnConfig("activated_by"),
    useBasicColumnConfig("deactivated_by"),
    useBasicColumnConfig("tool").setLabel(
      translate("eva.main.validation.attributes.calamity_tool_id")
    ),
    useBasicColumnConfig("type").setLabel(
      translate("eva.main.validation.attributes.calamity_type_id")
    ),
    useBasicColumnConfig("comment"),
    useBasicColumnConfig("created_at").setUseFromToDateTime(true),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default CalamitiesLogDataTable;
