export const EDGE_OFFSET = 25;

export function getEdgePath(from, fromPort, to, toPort) {
  if (!from || !to) return;

  switch (fromPort) {
    case "top":
      switch (toPort) {
        case "top":
          return topToTopPath(from, to);
        case "right":
          return topToRightPath(from, to);
        case "bottom":
          return topToBottomPath(from, to);
        case "left":
          return topToLeftPath(from, to);
        default:
          return "";
      }
    case "right":
      switch (toPort) {
        case "top":
          return rightToTopPath(from, to);
        case "right":
          return rightToRightPath(from, to);
        case "bottom":
          return rightToBottomPath(from, to);
        case "left":
          return rightToLeftPath(from, to);
        default:
          return "";
      }
    case "bottom":
      switch (toPort) {
        case "top":
          return bottomToTopPath(from, to);
        case "right":
          return bottomToRightPath(from, to);
        case "bottom":
          return bottomToBottomPath(from, to);
        case "left":
          return bottomToLeftPath(from, to);
        default:
          return "";
      }
    case "left":
      switch (toPort) {
        case "top":
          return leftToTopPath(from, to);
        case "right":
          return leftToRightPath(from, to);
        case "bottom":
          return leftToBottomPath(from, to);
        case "left":
          return leftToLeftPath(from, to);
        default:
          return "";
      }
    default:
      return "";
  }
}

function topToTopPath(from, to) {
  const topY = Math.min(from.y, to.y) - EDGE_OFFSET;
  return `M ${from.x},${from.y} 
          L ${from.x},${topY} 
          L ${to.x},${topY} 
          L ${to.x},${to.y}`;
}

function topToRightPath(from, to) {
  if (to.x + EDGE_OFFSET < from.x && to.y < from.y - EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${from.x},${to.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x},${from.y - EDGE_OFFSET} 
          L ${to.x + EDGE_OFFSET},${from.y - EDGE_OFFSET} 
          L ${to.x + EDGE_OFFSET},${to.y} L ${to.x},${to.y}`;
}

function topToBottomPath(from, to) {
  if (to.y + EDGE_OFFSET < from.y - EDGE_OFFSET) {
    return `M ${from.x},${from.y}
            L ${from.x},${from.y - EDGE_OFFSET}
            L ${to.x},${from.y - EDGE_OFFSET}
            L ${to.x},${to.y}`;
  }

  const midX = (from.x + to.x) / 2;
  return `M ${from.x},${from.y} 
          L ${from.x},${from.y - EDGE_OFFSET} 
          L ${midX},${from.y - EDGE_OFFSET} 
          L ${midX},${to.y + EDGE_OFFSET} 
          L ${to.x},${to.y + EDGE_OFFSET} 
          L ${to.x},${to.y}`;
}

function topToLeftPath(from, to) {
  if (to.x - EDGE_OFFSET > from.x && to.y < from.y - EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${from.x},${to.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x},${from.y - EDGE_OFFSET} 
          L ${to.x - EDGE_OFFSET},${from.y - EDGE_OFFSET} 
          L ${to.x - EDGE_OFFSET},${to.y} 
          L ${to.x},${to.y}`;
}

function rightToTopPath(from, to) {
  if (to.y - EDGE_OFFSET > from.y && to.x > from.x + EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${to.x},${from.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x + EDGE_OFFSET},${from.y} 
          L ${from.x + EDGE_OFFSET},${to.y - EDGE_OFFSET} 
          L ${to.x},${to.y - EDGE_OFFSET} 
          L ${to.x},${to.y}`;
}

function rightToRightPath(from, to) {
  const right = Math.max(from.x, to.x) + EDGE_OFFSET;
  return `M ${from.x},${from.y} 
          L ${right},${from.y} 
          L ${right},${to.y} 
          L ${to.x},${to.y}`;
}

function rightToBottomPath(from, to) {
  if (to.y + EDGE_OFFSET < from.y && to.x > from.x + EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${to.x},${from.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x + EDGE_OFFSET},${from.y} 
          L ${from.x + EDGE_OFFSET},${to.y + EDGE_OFFSET} 
          L ${to.x},${to.y + EDGE_OFFSET} 
          L ${to.x},${to.y}`;
}

function rightToLeftPath(from, to) {
  if (to.x - EDGE_OFFSET > from.x + EDGE_OFFSET) {
    return `M ${from.x},${from.y}
            L ${from.x + EDGE_OFFSET},${from.y}
            L ${from.x + EDGE_OFFSET},${to.y}
            L ${to.x},${to.y}`;
  }

  const midY = (from.y + to.y) / 2;
  return `M ${from.x},${from.y} 
          L ${from.x + EDGE_OFFSET},${from.y} 
          L ${from.x + EDGE_OFFSET},${midY} 
          L ${to.x - EDGE_OFFSET},${midY} 
          L ${to.x - EDGE_OFFSET},${to.y} 
          L ${to.x},${to.y}`;
}

function bottomToTopPath(from, to) {
  if (to.y - EDGE_OFFSET > from.y + EDGE_OFFSET) {
    return `M ${from.x},${from.y}
            L ${from.x},${from.y + EDGE_OFFSET}
            L ${to.x},${from.y + EDGE_OFFSET}
            L ${to.x},${to.y}`;
  }

  const midX = (from.x + to.x) / 2;
  return `M ${from.x},${from.y} 
          L ${from.x},${from.y + EDGE_OFFSET} 
          L ${midX},${from.y + EDGE_OFFSET} 
          L ${midX},${to.y - EDGE_OFFSET} 
          L ${to.x},${to.y - EDGE_OFFSET} 
          L ${to.x},${to.y}`;
}

function bottomToRightPath(from, to) {
  if (to.x + EDGE_OFFSET < from.x && to.y > from.y + EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${from.x},${to.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x},${from.y + EDGE_OFFSET} 
          L ${to.x + EDGE_OFFSET},${from.y + EDGE_OFFSET} 
          L ${to.x + EDGE_OFFSET},${to.y} 
          L ${to.x},${to.y}`;
}

function bottomToBottomPath(from, to) {
  const bottomY = Math.max(from.y, to.y) + EDGE_OFFSET;
  return `M ${from.x},${from.y} 
          L ${from.x},${bottomY} 
          L ${to.x},${bottomY} 
          L ${to.x},${to.y}`;
}

function bottomToLeftPath(from, to) {
  if (to.x - EDGE_OFFSET > from.x && to.y > from.y + EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${from.x},${to.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x},${from.y + EDGE_OFFSET} 
          L ${to.x - EDGE_OFFSET},${from.y + EDGE_OFFSET} 
          L ${to.x - EDGE_OFFSET},${to.y} 
          L ${to.x},${to.y}`;
}

function leftToTopPath(from, to) {
  if (to.y - EDGE_OFFSET > from.y && to.x < from.x - EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${to.x},${from.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x - EDGE_OFFSET},${from.y} 
          L ${from.x - EDGE_OFFSET},${to.y - EDGE_OFFSET} 
          L ${to.x},${to.y - EDGE_OFFSET} 
          L ${to.x},${to.y}`;
}

function leftToRightPath(from, to) {
  if (to.x + EDGE_OFFSET < from.x - EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${from.x - EDGE_OFFSET},${from.y} 
            L ${from.x - EDGE_OFFSET},${to.y} 
            L ${to.x},${to.y}`;
  }

  const midY = (from.y + to.y) / 2;
  return `M ${from.x},${from.y} 
          L ${from.x - EDGE_OFFSET},${from.y} 
          L ${from.x - EDGE_OFFSET},${midY} 
          L ${to.x + EDGE_OFFSET},${midY} 
          L ${to.x + EDGE_OFFSET},${to.y} 
          L ${to.x},${to.y}`;
}

function leftToBottomPath(from, to) {
  if (to.y + EDGE_OFFSET < from.y && to.x < from.x - EDGE_OFFSET) {
    return `M ${from.x},${from.y} 
            L ${to.x},${from.y} 
            L ${to.x},${to.y}`;
  }

  return `M ${from.x},${from.y} 
          L ${from.x - EDGE_OFFSET},${from.y} 
          L ${from.x - EDGE_OFFSET},${to.y + EDGE_OFFSET} 
          L ${to.x},${to.y + EDGE_OFFSET} 
          L ${to.x},${to.y}`;
}

function leftToLeftPath(from, to) {
  const leftX = Math.min(from.x, to.x) - EDGE_OFFSET;
  return `M ${from.x},${from.y} 
          L ${leftX},${from.y} 
          L ${leftX},${to.y} 
          L ${to.x},${to.y}`;
}

export function getArrowPath(to, toPort) {
  if (!to) return;

  switch (toPort) {
    case "top":
      return `M ${to.x - 5},${to.y - 10} 
              L ${to.x},${to.y} 
              L ${to.x + 5},${to.y - 10}`;
    case "right":
      return `M ${to.x + 10},${to.y - 5} 
              L ${to.x},${to.y} 
              L ${to.x + 10},${to.y + 5}`;
    case "bottom":
      return `M ${to.x - 5},${to.y + 10} 
              L ${to.x},${to.y} 
              L ${to.x + 5},${to.y + 10}`;
    case "left":
      return `M ${to.x - 10},${to.y - 5} 
              L ${to.x},${to.y} 
              L ${to.x - 10},${to.y + 5}`;
    default:
      return "";
  }
}
