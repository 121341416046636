import { useLanguageContext } from "../../../../context/LanguageContext";
import DishPage from "../../../../pages/canteen/dishes/dish/DishPage";
import { useDishCreateRouteValidator } from "../../../validators/canteen/dishes/DishCreateRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useDishesRouteConfig from "./DishesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useDishCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/dishes/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.dish"),
        },
      ])
    )
    .setValidator(useDishCreateRouteValidator())
    .setPage(<DishPage />)
    .setParent(useDishesRouteConfig());
}

export default useDishCreateRouteConfig;
