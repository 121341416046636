import {
  findLayoutItem,
  removeFromLayout,
  updateLayoutItem,
} from "../../../../../components/layout/LayoutMethods";
import { getLayoutSettings } from "../../../../../components/layout/LayoutSettings";
import SettingsFormView from "./SettingsFormView";

function SettingsForm({ layoutData, setLayoutData, images }) {
  const item = layoutData.focused_item_id
    ? findLayoutItem(layoutData.layout, layoutData.focused_item_id)
    : null;

  const settings = item ? getLayoutSettings(item.type) : null;

  /**
   * This method will cancel the edit mode of the focused layout item
   */
  function onCancel() {
    setLayoutData((prev) => ({
      ...prev,
      focused_item_id: null,
    }));
  }

  /**
   * This method will delete the focused item from the layout
   */
  function onDelete() {
    setLayoutData((prev) => ({
      ...prev,
      layout: removeFromLayout(prev.layout, item.id),
      focused_item_id: null,
    }));
  }

  /**
   * This method will update the given setting of the focused layout item
   * @param {string} setting
   * @param {any} value
   * @param {boolean} isStyle
   */
  function onSettingChange(setting, value, isStyle = true) {
    setLayoutData((prev) => ({
      ...prev,
      layout: updateLayoutItem(prev.layout, item.id, (foundItem) => {
        if (isStyle) {
          return {
            ...foundItem,
            style: {
              ...foundItem.style,
              [setting]: value,
            },
          };
        } else {
          return {
            ...foundItem,
            [setting]: value,
          };
        }
      }),
    }));
  }

  return (
    <SettingsFormView
      item={item}
      settings={settings}
      images={images}
      onCancel={onCancel}
      onDelete={onDelete}
      onSettingChange={onSettingChange}
    />
  );
}

export default SettingsForm;
