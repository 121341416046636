import ModalView from "./ModalView";

function Modal({ title, children, onClose, loadingGroups }) {
  return (
    <ModalView title={title} onClose={onClose} loadingGroups={loadingGroups}>
      {children}
    </ModalView>
  );
}

export default Modal;
