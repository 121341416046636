import { useLanguageContext } from "../../../context/LanguageContext";
import { RouteConfig } from "../RouteConfig";
import { useTerminalsRouteValidator } from "../../validators/terminals/TerminalsRouteValidator";
import TerminalsPage from "../../../pages/terminals/index/TerminalsPage";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useTerminalsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/terminals")
    .setLabel(translate("eva.main.entities.terminals"))
    .setValidator(useTerminalsRouteValidator())
    .setPage(<TerminalsPage />);
}

export default useTerminalsRouteConfig;