import { useRef } from "react";
import useCustomEffect from "../../hooks/useCustomEffect";
import { useState } from "react";
import { useTheme } from "styled-components";

/**
 * @param {object} initData the initial data
 */
function useZoomPanEditor(initData) {
  const theme = useTheme();
  const [data, setData] = useState({
    locked: false,
    position: { x: 0, y: 0 },
    scale: 1,
    scaleDisabled: false,
    onlyAllowBackgroundInteraction: false,
    ...initData,
  });
  const scrollSensitivity = 1;

  const editorRef = useRef(null);
  const editorContentRef = useRef(null);
  const editorBackgroundRef = useRef(null);

  /**
   * UseEffect to center the content on mount.
   */
  useCustomEffect(() => {
    centerContent();
  }, [editorRef.current, editorContentRef.current]);

  /**
   * This method will handle the scroll event.
   * @param {Event} event
   */
  function onScroll(event) {
    if (data.locked || data.scaleDisabled) {
      return;
    }

    const newScale =
      data.scale * (1 - event.deltaY * scrollSensitivity * 0.001);

    setData((current) => ({ ...current, scale: getRestrictedScale(newScale) }));
  }

  /**
   * This method will handle the editor mouse movement.
   * @param {Event} event
   */
  function onMouseDown(event) {
    if (
      data.locked ||
      (data.onlyAllowBackgroundInteraction &&
        event.target !== editorRef.current &&
        event.target !== editorContentRef.current &&
        event.target !== editorBackgroundRef.current)
    ) {
      return;
    }

    let startX = event.clientX;
    let startY = event.clientY;

    function handleMouseMove(event) {
      const deltaX = event.clientX - startX;
      const deltaY = event.clientY - startY;

      setData((current) => ({
        ...current,
        position: {
          x: current.position.x + deltaX,
          y: current.position.y + deltaY,
        },
      }));

      startX = event.clientX;
      startY = event.clientY;
    }

    function handleMouseUp() {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  }

  /**
   * This method will center the content.
   */
  function centerContent() {
    const editorRect = editorRef.current?.getBoundingClientRect();
    const editorContentRect = editorContentRef.current?.getBoundingClientRect();

    if (editorRect && editorContentRect) {
      const originalContentSize = getOriginalContentSize();
      const newScale = getRestrictedScale(getMinScaleToFit());

      const newContentWidth = originalContentSize.width * newScale;
      const newContentHeight = originalContentSize.height * newScale;

      const newX = editorRect.width / 2 - newContentWidth / 2;
      const newY = editorRect.height / 2 - newContentHeight / 2;

      setData((current) => ({
        ...current,
        position: { x: newX, y: newY },
        scale: newScale,
      }));
    }
  }

  /**
   * This method will return the restricted scale.
   * @param {int} newScale
   * @returns {int}
   */
  function getRestrictedScale(newScale) {
    const minScale = Math.min(0.25, getMinScaleToFit());
    return Math.min(Math.max(minScale, newScale), 1);
  }

  /**
   * This method will calculate the original content size.
   * @returns {{ width: int, height: int}}
   */
  function getOriginalContentSize() {
    const editorContentRect = editorContentRef.current?.getBoundingClientRect();
    if (!editorContentRect) {
      return { width: 0, height: 0 };
    }

    return {
      width: editorContentRect.width / data.scale,
      height: editorContentRect.height / data.scale,
    };
  }

  /**
   * This method will calculate the minimum scale to fit the editor content.
   * @returns {int}
   */
  function getMinScaleToFit() {
    const editorFitSize = getEditorFitSize();
    const originalContentSize = getOriginalContentSize();

    return Math.min(
      editorFitSize.width / originalContentSize.width,
      editorFitSize.height / originalContentSize.height
    );
  }

  /**
   * This method will calculate the size content that would fit the editor
   * @returns {{ width: int, height: int}}
   */
  function getEditorFitSize() {
    const editorRect = editorRef.current?.getBoundingClientRect();
    if (!editorRect) {
      return { width: 0, height: 0 };
    }

    return {
      width:
        editorRect.width -
        parseFloat(theme.spacing.large.replace("px", "")) * 2,
      height:
        editorRect.height -
        parseFloat(theme.spacing.large.replace("px", "")) * 2,
    };
  }

  /**
   * Reset the scale to 1.
   */
  function resetZoom() {
    if (data.scale === 1) {
      return;
    }
    setData((current) => ({ ...current, scale: 1 }));
  }

  /**
   * This method will handle the lock click.
   */
  function onLockClick() {
    setData((current) => ({ ...current, locked: !current.locked }));
  }

  /**
   * This method will set the scale disabled.
   * @param {boolean} disabled
   */
  function setScaleDisabled(disabled) {
    setData((current) => ({ ...current, scaleDisabled: disabled }));
  }

  return {
    editorRef,
    editorContentRef,
    editorBackgroundRef,
    data,
    onLockClick,
    onScroll,
    onMouseDown,
    resetZoom,
    centerContent,
    setScaleDisabled,
  };
}

export default useZoomPanEditor;
