import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Select from "../../../../../components/select/main/Select";
import OverlayLoader from "../../../../../components/loader/OverlayLoader/OverlayLoader";
import FormContainer from "../../../../../components/formContainer/FormContainer";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../../styles/LayoutStyles";
import InputWrapper from "../../../../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../../../../styles/InputStyles";
import EVAApiHelper from "../../../../../helpers/EVAApiHelper";
import EntityTranslationsContainer from "../../../../../components/entityTranslationsContainer/EntityTranslationsContainer";

function QuestionAnswerPageView({
  questionAnswerData,
  translationsController,
  onNameChange,
  onOrderChange,
  onCorrectChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();
  const theme = useTheme();

  return (
    <>
      <OverlayLoader groups="question_answer_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"question_answer_page_submit"}
        >
          <StyledFlexLayout>
            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.name")}
                error={EVAApiHelper.getResponseError("name", submitError)}
                required
              >
                <StyledInput
                  value={questionAnswerData.name}
                  onChange={onNameChange}
                  placeholder={translate("eva.main.validation.attributes.name")}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>

            <InputWrapper
              label={translate("eva.main.validation.attributes.order")}
              error={EVAApiHelper.getResponseError("order", submitError)}
              required
            >
              <StyledInput
                type="number"
                value={questionAnswerData.order}
                onChange={onOrderChange}
              />
            </InputWrapper>

            <InputWrapper
              label={translate("eva.main.validation.attributes.correct")}
              error={EVAApiHelper.getResponseError("score", submitError)}
              required
            >
              <Select
                options={[
                  { value: true, label: translate("eva.main.general.yes") },
                  { value: false, label: translate("eva.main.general.no") },
                ]}
                value={questionAnswerData.correct}
                onChange={onCorrectChange}
              />
            </InputWrapper>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader
        groups="question_answer_page_fetch"
        containerStyle={{ marginTop: theme.spacing.large }}
      >
        <EntityTranslationsContainer controller={translationsController}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.answer")}
            error={translationsController.getTranslationError(
              "answer",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.answer")}
              value={translationsController.getValue("answer")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "answer"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>
    </>
  );
}

export default QuestionAnswerPageView;
