import { useEffect } from "react";

/**
 * This hook will call the callback one time for initialization
 * @param {function} callback
 * @param {array} dependencies
 */
function useCustomEffect(callback, dependencies = []) {
  useEffect(() => {
    return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useCustomEffect;
