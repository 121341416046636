import styled from "styled-components";
import { StyledCard, StyledCardHeader } from "../../../styles/CardStyles";

export const StyledReleaseNoteCard = styled(StyledCard)`
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

export const StyledReleaseNoteHeader = styled(StyledCardHeader)`
  display: flex;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: center;
`;
