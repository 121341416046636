import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTerminalContext } from "../../../../context/TerminalContext";
import { StyledAltButton } from "../../../../styles/ButtonStyles";
import { StyledFlexLayout } from "../../../../styles/LayoutStyles";
import { StartPageConfig } from "../start/StartPage";

function SignOutPageView({ setPageConfig }) {
  const { terminal } = useTerminalContext();
  const { translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexLayout style={{ flexDirection: "column" }}>
          <StyledAltButton onClick={() => setPageConfig(StartPageConfig)}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexLayout>
      }
    />
  );
}

export default SignOutPageView;
