import { useLanguageContext } from "../../../../../context/LanguageContext";
import LanguageFormSelectView from "./LanguageFormSelectView";

function LanguageFormSelect({ value, onChange, disabled }) {
  const languageContext = useLanguageContext();

  return (
    <LanguageFormSelectView
      options={languageContext.languages.map((lang) => ({
        label: lang.label,
        value: lang.id,
      }))}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default LanguageFormSelect;
