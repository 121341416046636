import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledCardBody } from "../../../styles/CardStyles";
import HiddenLoader from "../../../components/loader/HiddenLoader/HiddenLoader";
import {
  StyledReleaseNoteCard,
  StyledReleaseNoteHeader,
} from "./ReleaseNotesPageStyles";

function ReleaseNotesPageView({ releases }) {
  const { translate } = useLanguageContext();

  return (
    <HiddenLoader groups={"release_notes_page_fetch"} iconSize={"xl"}>
      {releases.map((release, index) => (
        <StyledReleaseNoteCard key={index}>
          <StyledReleaseNoteHeader>
            <h2>{release.version}</h2>
            <small>
              ({translate("eva.main.validation.attributes.date")}:{" "}
              {release.date})
            </small>
          </StyledReleaseNoteHeader>
          <StyledCardBody>
            <ul>
              {release.changes.map((change, index) => (
                <li key={index}>{change}</li>
              ))}
            </ul>
          </StyledCardBody>
        </StyledReleaseNoteCard>
      ))}
    </HiddenLoader>
  );
}

export default ReleaseNotesPageView;
