import RandomizeHelper from "../../helpers/RandomizeHelper";

/**
 * This method will remove the layout item with the given id from the given layout
 * @param {object} layout
 * @param {any} id
 * @returns {object}
 */
export function removeFromLayout(layout, id) {
  return {
    ...layout,
    children: layout.children
      .filter((child) => child.id !== id)
      .map((child) => removeFromLayout(child, id)),
  };
}

/**
 * This method will add the given item to the given parent within the given layout
 * @param {object} layout
 * @param {any} parentId
 * @param {object} item
 * @returns {object}
 */
export function addToLayout(layout, parentId, item) {
  const children = layout.children;
  if (layout.id === parentId) {
    children.push(item);
  }

  return {
    ...layout,
    children: children.map((child) => addToLayout(child, parentId, item)),
  };
}

/**
 * This method will switch the children with the given ids within the given layout
 * @param {object} layout
 * @param {any} childId1
 * @param {any} childId2
 * @returns {object}
 */
export function switchLayoutChildren(layout, childId1, childId2) {
  const children = layout.children;
  const index1 = children.findIndex((child) => child.id === childId1);
  const index2 = children.findIndex((child) => child.id === childId2);

  if (index1 >= 0 && index2 >= 0) {
    const child1 = children[index1];
    children.splice(index1, 1);
    children.splice(index2, 0, child1);

    return {
      ...layout,
      children: children,
    };
  }

  return {
    ...layout,
    children: children.map((child) =>
      switchLayoutChildren(child, childId1, childId2)
    ),
  };
}

/**
 * This method will update the layout item with the given id within the given layout
 * @param {object} layout
 * @param {any} id
 * @param {function(item) : object} updateCallback
 * @returns {object}
 */
export function updateLayoutItem(layout, id, updateCallback) {
  if (layout.id === id) {
    return updateCallback(layout);
  }

  return {
    ...layout,
    children: layout.children.map((child) =>
      updateLayoutItem(child, id, updateCallback)
    ),
  };
}

/**
 * This method will update the parent of the given item within the given layout
 * @param {object} layout
 * @param {object} itemWithParent
 * @param {any} newParentId
 * @returns {object}
 */
export function updateLayoutItemParent(layout, itemWithParent, newParentId) {
  // If the new parent id is the same as the current parent id, return the layout as it is
  if (newParentId === itemWithParent.parent.id) {
    return layout;
  }

  //Remove the item from the current parent
  if (layout.id === itemWithParent.parent.id) {
    layout.children = layout.children.filter(
      (child) => child.id !== itemWithParent.id
    );
  }
  //Add the item to the new parent
  else if (layout.id === newParentId) {
    const itemWithoutParent = { ...itemWithParent };
    delete itemWithoutParent.parent;
    layout.children.push(itemWithoutParent);
  }

  return {
    ...layout,
    children: layout.children.map((child) =>
      updateLayoutItemParent(child, itemWithParent, newParentId)
    ),
  };
}

/**
 * This method will update the layout based on the drag event
 * @param {object} layout
 * @param {string} dragged
 * @param {string} target
 * @returns {object}
 */
export function updateLayoutOnDrag(layout, dragged, target) {
  //get the dragged and target ids
  const draggedId = dragged.replace("DRAGGABLE_", "");
  const targetId = target.replace("DRAGGABLE_", "");

  //Create a new layout object
  let newLayout = { ...layout };

  //Find the dragged and target layout items with their parents
  const draggedItem = findLayoutItem(layout, draggedId, true);
  const targetItem = findLayoutItem(layout, targetId, true);

  if (
    getLayoutItemLayer(draggedItem.type) === getLayoutItemLayer(targetItem.type)
  ) {
    //If the dragged item is the same type as the target item but they have different parents, update the parent of the dragged item
    if (draggedItem.parent.id !== targetItem.parent.id) {
      newLayout = updateLayoutItemParent(
        newLayout,
        draggedItem,
        targetItem.parent.id
      );
    }
    //If the dragged item is the same type as the target item and they have the same parent, switch the children
    else {
      newLayout = switchLayoutChildren(newLayout, draggedId, targetId);
    }
  }
  //If the dragged item is one layer deeper than the target item, update the parent of the dragged item
  else if (
    getLayoutItemLayer(draggedItem.type) ===
    getLayoutItemLayer(targetItem.type) + 1
  ) {
    newLayout = updateLayoutItemParent(newLayout, draggedItem, targetId);
  }

  return newLayout;
}

/**
 * This method will try to find the item with the given id from the layout
 * @param {object} layout
 * @param {any} id
 * @param {boolean} withParent
 * @param {object} parent
 * @returns {object}
 */
export function findLayoutItem(layout, id, withParent = false, parent = null) {
  layout = withParent ? { ...layout, parent } : layout;

  if (layout.id === id) {
    return layout;
  }

  for (let i = 0; i < layout.children.length; i++) {
    const result = findLayoutItem(layout.children[i], id, withParent, layout);
    if (result) {
      return result;
    }
  }

  return null;
}

/**
 * This method will return the layer of the given layout item type
 * @param {string} type
 * @returns
 */
export function getLayoutItemLayer(type) {
  switch (type) {
    case "page":
      return 0;
    case "box":
      return 1;
    case "subbox":
      return 2;
    case "image":
    case "language":
    case "content":
      return 3;
    default:
      return -1;
  }
}

/**
 * This method will convert the layout object to the api format
 * @param {object} layout
 * @returns {object}
 */
export function layoutToApi(layout) {
  const newLayout = { ...layout };
  newLayout.image_id = layout.image ? layout.image.id : null;
  newLayout.children = layout.children.map((child) => layoutToApi(child));

  delete newLayout.image;

  return newLayout;
}

/**
 * This method will convert the layout object from the api format
 * @param {object} layout
 * @returns {object}
 */
export function layoutFromApi(layout) {
  const newLayout = { ...layout };
  newLayout.id = RandomizeHelper.getUUID();
  newLayout.children = layout.children.map((child) => layoutFromApi(child));

  delete newLayout.created_at;
  delete newLayout.updated_at;

  return newLayout;
}
