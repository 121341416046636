import { useLanguageContext } from "../../../context/LanguageContext";
import CalamitiesPage from "../../../pages/calamities/index/CalamitiesPage";
import { useCalamitiesRouteValidator } from "../../validators/calamities/CalamitiesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCalamitiesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/calamities")
    .setLabel(translate("eva.main.entities.calamities"))
    .setValidator(useCalamitiesRouteValidator())
    .setPage(<CalamitiesPage />);
}

export default useCalamitiesRouteConfig;
