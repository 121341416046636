import { useLanguageContext } from "../../../context/LanguageContext";
import { RouteConfig } from "../RouteConfig";
import useVisitorTypesRouteConfig from "./VisitorTypesRouteConfig";
import VisitorTypePage from "../../../pages/visitor_types/visitor_type/VisitorTypePage";
import { useVisitorTypeEditRouteValidator } from "../../validators/visitor_types/VisitorTypeEditRouteValidator";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useVisitorTypeEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/visitor_types/:visitor_type/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.visitor_type"),
        }
      ])
    )
    .setValidator(useVisitorTypeEditRouteValidator())
    .setPage(<VisitorTypePage />)
    .setParent(useVisitorTypesRouteConfig());
}

export default useVisitorTypeEditRouteConfig;