import styled from "styled-components";
import { StyledOutlinedIconButton } from "../../../../styles/ButtonStyles";
import { StyledNode as FlowStyledNode } from "../../../../components/flowChart/node/FlowChartNodeStyles";

export const StyledNode = styled(FlowStyledNode)`
  position: relative;
  &:hover {
    > :last-child {
      border-color: ${(props) => props.theme.colors.accent.dark};
    }
  }
`;

export const StyledNodeMessage = styled.div`
  background-color: ${(props) => props.theme.colors.primary.dark};
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.focused
        ? props.theme.colors.accent.normal
        : props.theme.colors.primary.normal};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 0;
  width: 27.5px;
  height: 27.5px;
  transform: translate(50%, -50%);
`;

export const StyledAddButton = styled(StyledOutlinedIconButton)`
  background-color: ${(props) => props.theme.colors.primary.dark};
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }
`;
