import { useTheme } from "styled-components";
import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTerminalContext } from "../../../../context/TerminalContext";
import { StyledFlexLayout } from "../../../../styles/LayoutStyles";
import { ButtonPageConfig } from "../button/ButtonPage";
import { SignInPageConfig } from "../sign_in/SignInPage";
import { SignOutPageConfig } from "../sign_out/SignOutPage";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";

function StartPageView({ setPageConfig }) {
  const theme = useTheme();
  const { translateEntity, translate } = useLanguageContext();
  const { terminal } = useTerminalContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexLayout
          style={{ flexDirection: "column", gap: theme.spacing.large }}
        >
          <StyledFlexLayout style={{ flexDirection: "column" }}>
            <StyledButton onClick={() => setPageConfig(SignInPageConfig)}>
              {translate("eva.frontoffice.general.sign_in")}
            </StyledButton>

            <StyledButton onClick={() => setPageConfig(SignOutPageConfig)}>
              {translate("eva.frontoffice.general.sign_out")}
            </StyledButton>
          </StyledFlexLayout>

          {terminal?.buttons?.length > 0 && (
            <StyledFlexLayout style={{ flexDirection: "column" }}>
              {terminal?.buttons
                ?.sort((a, b) => a.order - b.order)
                ?.map((button) => (
                  <StyledAltButton
                    key={button.id}
                    onClick={() =>
                      setPageConfig(
                        ButtonPageConfig.setData({ buttonId: button.id })
                      )
                    }
                  >
                    {translateEntity(button, "title")}
                  </StyledAltButton>
                ))}
            </StyledFlexLayout>
          )}
        </StyledFlexLayout>
      }
    />
  );
}

export default StartPageView;
