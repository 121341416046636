import { useLanguageContext } from "../../../context/LanguageContext";
import EmployeePage from "../../../pages/employees/employee/EmployeePage";
import { useEmployeeEditRouteValidator } from "../../validators/employees/EmployeeEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useEmployeesRouteConfig from "./EmployeesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useEmployeeEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/employees/:employee/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.employee"),
        },
      ])
    )
    .setValidator(useEmployeeEditRouteValidator())
    .setPage(<EmployeePage />)
    .setParent(useEmployeesRouteConfig());
}

export default useEmployeeEditRouteConfig;
