import { useRouteContext } from "../../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import FlowPageFormView from "./FlowPageFormView";

function FlowPageForm({
  flow,
  flowData,
  setFlowData,
  submitError,
  setSubmitError,
}) {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();

  /**
   * This method will handle the name change event.
   * @param {Event} e
   */
  function onNameChange(e) {
    setFlowData((prevFlowData) => ({
      ...prevFlowData,
      name: e.target.value,
    }));
  }

  /**
   * This method will handle the cancel event.
   */
  function onCancel() {
    routeContext.back();
  }

  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(flow ? `flows/${flow}` : "flows")
        .setMethod(flow ? "PUT" : "POST")
        .setLoadingGroup("flow_page_submit")
        .setRedirectOnSuccess(true)
        .setErrorState(setSubmitError)
        .setData(flowData, ["selectedStep"])
    );
  }

  return (
    flowData.selectedStep === null && (
      <FlowPageFormView
        flowData={flowData}
        onNameChange={onNameChange}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitError={submitError}
      />
    )
  );
}

export default FlowPageForm;
