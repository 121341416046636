import { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomEffect from "../../../hooks/useCustomEffect";
import AnnouncementPageView from "./AnnouncementPageView";
import { useRouteContext } from "../../../context/RouteContext";
import { useTinyEditor } from "../../../components/tinyEditor/TinyEditor";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function AnnouncementPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { announcement } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [announcementData, setAnnouncementData] = useState({
    title: "",
    active_from: "",
    active_until: "",
    description: "",
  });
  const descriptionController = useTinyEditor(
    announcementData.description,
    onDescriptionChange
  );

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    if (announcement) {
      fetchAnnouncementData();
    }
  });

  /**
   * This method will fetch the initial data from the API
   */
  async function fetchAnnouncementData() {
    await callEvaApi(
      new EvaApiCall(`announcements/${announcement}`)
        .setLoadingGroup("announcement_page_fetch")
        .setOnSuccess((response) => {
          setAnnouncementData({
            title: response.data.data.title,
            active_from: response.data.data.active_from,
            active_until: response.data.data.active_until,
            description: response.data.data.description,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the title on input change
   * @param {event} e
   */
  function onTitleChange(e) {
    setAnnouncementData({ ...announcementData, title: e.target.value });
  }

  /**
   * This method will update the active from on input change
   * @param {event} e
   */
  function onActiveFromChange(e) {
    setAnnouncementData({ ...announcementData, active_from: e.target.value });
  }

  /**
   * This method will update the active until on input change
   * @param {event} e
   */
  function onActiveUntilChange(e) {
    setAnnouncementData({ ...announcementData, active_until: e.target.value });
  }

  /**
   * This method will update the description on input change
   * @param {string} value
   */
  function onDescriptionChange(value) {
    setAnnouncementData({ ...announcementData, description: value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit(event) {
    await callEvaApi(
      new EvaApiCall(
        announcement ? `announcements/${announcement}` : "announcements"
      )
        .setLoadingGroup("announcement_page_save")
        .setMethod(announcement ? "PUT" : "POST")
        .setData(announcementData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <AnnouncementPageView
      descriptionController={descriptionController}
      announcementData={announcementData}
      onTitleChange={onTitleChange}
      onActiveFromChange={onActiveFromChange}
      onActiveUntilChange={onActiveUntilChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default AnnouncementPage;
