import useIPLockEditRouteConfig from "../../../routes/configs/ip_locks/IPLockEditRouteConfig";
import useIPLockCreateRouteConfig from "../../../routes/configs/ip_locks/IPLockCreateRouteConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useBooleanColumnConfig from "../main/config/column/default/BooleanColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function IPLocksDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("ip_locks");

  //Set the datatable fetch info
  config.setFetchRequest("ip_locks");

  //Set the datatable order by
  config.setOrderBy("created_at");
  config.setOrderDirection("desc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("ip").setLockActive(true),
    useBooleanColumnConfig("access"),
    useBasicColumnConfig("name"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("ip_lock", useIPLockEditRouteConfig()),
    useDeleteRowActionConfig("ip_lock"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("ip_lock", useIPLockCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default IPLocksDataTable;
