import { useLanguageContext } from "../../../context/LanguageContext";
import EmployeePage from "../../../pages/employees/employee/EmployeePage";
import { useEmployeeCreateRouteValidator } from "../../validators/employees/EmployeeCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useEmployeesRouteConfig from "./EmployeesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useEmployeeCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/employees/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.employee"),
        },
      ])
    )
    .setValidator(useEmployeeCreateRouteValidator())
    .setPage(<EmployeePage />)
    .setParent(useEmployeesRouteConfig());
}

export default useEmployeeCreateRouteConfig;
