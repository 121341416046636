import { useDraggableContext } from "../../../../context/DraggableContext";
import FlowPageChartView from "./FlowPageChartView";

function FlowPageChart({ flowData, setFlowData, submitError }) {
  const draggableContext = useDraggableContext();

  /**
   * This method will add a step to the flow.
   * @param {string} type
   * @param {int} index
   */
  function onAddStep(type, index) {
    setFlowData((current) => {
      const newSteps = [...current.steps];
      newSteps.splice(index, 0, { type: type });

      //Make sure all step links are still correct
      newSteps.forEach((step) => {
        if (step.on_fail_step !== null && step.on_fail_step >= index) {
          step.on_fail_step++;
        }
      });

      return {
        ...current,
        steps: newSteps,
        selectedStep:
          current.selectedStep === null
            ? null
            : index <= current.selectedStep
            ? current.selectedStep + 1
            : current.selectedStep,
      };
    });
  }

  /**
   * This method will set the selected flow step.
   * @param {int} index
   */
  function onStepClick(index) {
    setFlowData((current) => ({ ...current, selectedStep: index }));
  }

  /**
   * This method will handle the drag target enter event.
   * @param {string} draggedId
   * @param {string} targetId
   */
  function onDragTargetEnter(draggedId, targetId) {
    if (
      draggedId &&
      targetId &&
      draggedId.includes("STEP") &&
      targetId.includes("STEP")
    ) {
      //Get the indexes of the dragged and target steps.
      const draggedIndex = parseInt(draggedId.split("_")[2]);
      const targetIndex = parseInt(targetId.split("_")[2]);

      setFlowData((current) => {
        //re-insert the dragged step into the steps array at the target index.
        const newSteps = [...current.steps];
        const draggedStep = newSteps[draggedIndex];
        newSteps.splice(draggedIndex, 1);
        newSteps.splice(targetIndex, 0, draggedStep);

        //Update the selected step if needed.
        const newSelectedStep =
          current.selectedStep === draggedIndex
            ? targetIndex
            : current.selectedStep === targetIndex
            ? draggedIndex
            : current.selectedStep;

        //Make sure that the step links are still correct
        newSteps.forEach((step, index) => {
          if (step.on_fail_step === draggedIndex) {
            step.on_fail_step = targetIndex;
          } else if (step.on_fail_step === targetIndex) {
            step.on_fail_step = draggedIndex;
          }
        });

        return {
          ...current,
          steps: newSteps,
          selectedStep: newSelectedStep,
        };
      });

      draggableContext.updateDragged(targetId);
    }
  }

  return (
    <FlowPageChartView
      flowData={flowData}
      onAddStep={onAddStep}
      onStepClick={onStepClick}
      onDragTargetEnter={onDragTargetEnter}
      submitError={submitError}
    />
  );
}

export default FlowPageChart;
