import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import {
  StyledStatisticSettingsContainer,
  StyledStatisticSettingsItem,
  StyledStatisticSettingsWrapper,
} from "./StatisticSettingsStyles";
import { StyledAltIconButton } from "../../../../../styles/ButtonStyles";
import Checkbox from "../../../../../components/input/checkbox/Checkbox";

function StatisticSettingsView({
  settingsDropdown,
  statistics,
  toggleStatisticsVisibility,
}) {
  const { translate } = useLanguageContext();

  return (
    <StyledStatisticSettingsWrapper>
      <StyledAltIconButton
        onClick={settingsDropdown.onButtonClick}
        ref={settingsDropdown.buttonRef}
      >
        <FontAwesomeIcon icon={faGear} />
      </StyledAltIconButton>

      {settingsDropdown.open && (
        <StyledStatisticSettingsContainer ref={settingsDropdown.dropdownRef}>
          {Object.keys(statistics).map((key) => {
            const statistic = statistics[key];

            return (
              !statistic.disabled && (
                <StyledStatisticSettingsItem
                  key={key}
                >
                  <Checkbox value={statistic.visible} onChange={() => toggleStatisticsVisibility(key)} />
                  {translate(`eva.main.statistics.${key}`)}
                </StyledStatisticSettingsItem>
              )
            );
          })}
        </StyledStatisticSettingsContainer>
      )}
    </StyledStatisticSettingsWrapper>
  );
}

export default StatisticSettingsView;
