import { useLocation } from "react-router-dom";
import DashboardNavButtonView from "./DashboardNavButtonView";

function DashboardNavButton({
  routeConfig,
  icon,
  folded,
  onlyActiveOnExactPath = false,
  activePathExceptions = [],
}) {
  const location = useLocation();

  /**
   * This method will determine if the button should be active
   * @returns {bool}
   */
  function isActive() {
    let active = location.pathname.startsWith(routeConfig.path);

    if (onlyActiveOnExactPath) {
      active = location.pathname.endsWith(routeConfig.path);
    } else {
      activePathExceptions.forEach((exception) => {
        active = location.pathname.includes(exception) ? false : active;
      });
    }

    return active;
  }

  return routeConfig.validator.validate() === true ? (
    <DashboardNavButtonView
      routeConfig={routeConfig}
      icon={icon}
      folded={folded}
      active={isActive()}
    />
  ) : (
    ""
  );
}

export default DashboardNavButton;
