import { useLanguageContext } from "../../../context/LanguageContext";
import VisitorsPage from "../../../pages/visitors/index/VisitorsPage";
import { useVisitorsRouteValidator } from "../../validators/visitors/VisitorsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useVisitorsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/visitors")
    .setLabel(translate("eva.main.entities.visitors"))
    .setValidator(useVisitorsRouteValidator())
    .setPage(<VisitorsPage />);
}

export default useVisitorsRouteConfig;
