import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import StringHelper from "../../../../../../helpers/StringHelper";
import useEvaApi, { EvaApiCall } from "../../../../../../hooks/useEvaApi";
import RowActionConfig from "../RowActionConfig";

/**
 * @param {string} entity for example: "location"
 * @param {function(row, dataTable) : string} url defaults to `entity (plural) + /row.id`
 * @returns {RowActionConfig}
 */
function useDuplicateRowActionConfig(entity, url) {
  const callEvaApi = useEvaApi();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  /**
   * This method will try to duplicate the entity from the database
   * @param {object} row
   * @param {object} dataTable useDataTable()
   */
  async function handleDuplicate(row, dataTable) {
    //Get the url path and id from the path
    const duplicateUrl = getDuplicateUrl(row, dataTable);

    //Duplicate the entity
    await callEvaApi(
      new EvaApiCall(duplicateUrl)
        .setMethod("POST")
        .setLoadingGroup(
          `${dataTable.config.getDataTableKey()}_duplicate_${row.id}`
        )
        .setOnSuccess(() => dataTable.fetch())
    );
  }

  /**
   * This method will determine the url for the duplicate action
   * @param {object} row
   * @returns {string}
   */
  function getDuplicateUrl(row, dataTable) {
    return url
      ? url(row, dataTable)
      : StringHelper.pluralize(entity) + `/${row.id}/duplicate`;
  }

  /**
   * This method will determine if the delete button is disabled
   * @returns {bool}
   */
  function getDisabled() {
    return !authContext.hasPermission(
      `${StringHelper.pluralize(entity)}.create`
    );
  }

  return new RowActionConfig()
    .setIcon(faCopy)
    .setCallback(handleDuplicate)
    .setDisabled(getDisabled)
    .setTitle(
      translate("eva.main.entities.states.duplicate", [
        {
          key: "entities",
          value: translate(`eva.main.entities.${entity}`),
        },
      ])
    )
    .setLoadingGroups(
      (row, dataTable) =>
        `${dataTable.config.getDataTableKey()}_duplicate_${row.id}`
    );
}

export default useDuplicateRowActionConfig;
