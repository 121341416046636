import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const StyledPathSteps = styled.small`
  color: ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledPathStep = styled.b`
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;

export const StyledArrow = styled(FontAwesomeIcon)`
  margin-inline: ${(props) => props.theme.spacing.small};
`;
