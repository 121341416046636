import FlowPageHouseruleFormView from "./FlowPageHouseruleFormView";

function FlowPageHouseruleForm({
  flowData,
  setFlowData,
  houserules,
  submitError,
}) {
  /**
   * This method will handle the houserule change event.
   * @param {any} value
   */
  function onHouseruleChange(value) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].file_id = value;

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  return (
    <FlowPageHouseruleFormView
      flowData={flowData}
      houserules={houserules}
      onHouseruleChange={onHouseruleChange}
      submitError={submitError}
    />
  );
}

export default FlowPageHouseruleForm;
