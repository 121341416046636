import { useLanguageContext } from "../../../context/LanguageContext";
import FieldsPage from "../../../pages/fields/index/FieldsPage";
import { useFieldsRouteValidator } from "../../validators/fields/FieldsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFieldsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/fields")
    .setLabel(translate("eva.main.entities.fields"))
    .setValidator(useFieldsRouteValidator())
    .setPage(<FieldsPage />);
}

export default useFieldsRouteConfig;
