import FilePageView from "./FilePageView";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEntityTranslations from "../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function FilePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { file } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [fileData, setFileData] = useState({
    type: "image",
    name: "",
    use_translations: false,
    file: null,
    translations: [],
  });
  const translationsController = useEntityTranslations(
    fileData.translations,
    onTranslationsChange
  );

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (file) {
      fetchFileData();
    }
  });

  /**
   * This method will fetch the file data from the API
   */
  async function fetchFileData() {
    await callEvaApi(
      new EvaApiCall(`files/${file}`)
        .setLoadingGroup("file_page_fetch")
        .setOnSuccess((response) => {
          setFileData({
            type: response.data.data.type,
            file: response.data.data.path,
            name: response.data.data.name,
            use_translations: response.data.data.use_translations,
            translations: response.data.data.translations.map(
              (translation) => ({
                language_id: translation.language,
                name: translation.name,
                file: translation.path,
              })
            ),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will return the accept value for the file input
   * @returns {string}
   */
  function getAccept() {
    switch (fileData.type) {
      case "attachment":
        return "application/pdf";
      case "houserule":
        return ".mp4,.png,.jpg,.jpeg,application/pdf";
      case "image":
        return ".png,.jpg,.jpeg";
      default:
        return "*";
    }
  }

  /**
   * This method will update the type on input change
   * @param {any} value
   */
  function onTypeChange(value) {
    setFileData((current) => ({ ...current, type: value }));
  }

  /**
   * This method will update the image on input change
   * @param {File} value
   */
  function onFileChange(value) {
    setFileData((current) => ({ ...current, file: value }));
  }

  /**
   * This method will update the type on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setFileData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will handle the use translations change
   * @param {any} value
   * @returns
   */
  function onUseTranslationsChange(value) {
    setFileData((current) => ({
      ...current,
      use_translations: value,
    }));
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setFileData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(file ? `files/${file}` : "files")
        .setLoadingGroup("file_page_save")
        .setMethod(file ? "PUT" : "POST")
        .setData(fileData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <FilePageView
      file={file}
      fileData={fileData}
      accept={getAccept()}
      translationsController={translationsController}
      onTypeChange={onTypeChange}
      onFileChange={onFileChange}
      onNameChange={onNameChange}
      onUseTranslationsChange={onUseTranslationsChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default FilePage;
