import { useState } from "react";
import { useParams } from "react-router-dom";
import RolePageView from "./RolePageView";
import { useAuthContext } from "../../../context/AuthContext";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function RolePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { role } = useParams();
  const authContext = useAuthContext();
  const [permissions, setPermissions] = useState([]);
  const [roleData, setRoleData] = useState({
    name: "",
    permissions: [],
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    fetchPermissions();

    if (role) {
      fetchRoleData();
    }
  });

  /**
   * This method will fetch the initial data from the API
   */
  async function fetchRoleData() {
    await callEvaApi(
      new EvaApiCall(`roles/${role}`)
        .setLoadingGroup("role_page_fetch")
        .setOnSuccess((response) => {
          setRoleData({
            name: response.data.data.name,
            permissions: response.data.data.permissions,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Fetch the allergens from the API
   */
  async function fetchPermissions() {
    await callEvaApi(
      new EvaApiCall("permissions")
        .setLoadingGroup("role_page_fetch")
        .setOnSuccess((response) => {
          setPermissions(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setRoleData({ ...roleData, name: e.target.value });
  }

  /**
   * This method will update the name on input change
   * @param {array} values
   */
  function onPermissionsChange(values) {
    setRoleData({
      ...roleData,
      permissions: values,
    });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(role ? `roles/${role}` : "roles")
        .setLoadingGroup("role_page_save")
        .setMethod(role ? "PUT" : "POST")
        .setData(roleData)
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          //Refetch the authenticated user if the user has the role we just changed
          if (
            role &&
            authContext.auth.user.roles.find(
              (userRole) => userRole.id === parseInt(role)
            )
          ) {
            authContext.fetchUser();
          }
          routeContext.back();
        })
    );
  }

  return (
    <RolePageView
      roleData={roleData}
      permissions={permissions}
      onNameChange={onNameChange}
      onPermissionsChange={onPermissionsChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default RolePage;
