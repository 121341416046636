import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import useRoleTypeMiddleware from "../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useUsersRouteValidator() {
  return useRouteValidator(useMiddlewares([
    useAuthMiddleware(),
    useRoleTypeMiddleware(["superadmin", "user"], "or"),
  ]));
}
