import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

function useDropdown(openByDefault = false) {
  const [open, setOpen] = useState(openByDefault);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  /**
   * UseEffect to add listeners to the document
   */
  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  });

  /**
   * This method will handle the click event on the dropdown button
   */
  function onButtonClick() {
    setOpen((current) => !current);
  }

  /**
   * This method will handle any click event on the document
   * @param {event} event
   */
  function onDocumentClick(event) {
    if (
      dropdownRef.current &&
      buttonRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target) &&
      open
    ) {
      setOpen(false);
    }
  }

  return { dropdownRef, buttonRef, open, setOpen, onButtonClick };
}

export default useDropdown;
