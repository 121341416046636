import { useLanguageContext } from "../../../../context/LanguageContext";
import { useQuestionnaireEditRouteValidator } from "../../../validators/questionnaires/QuestionnaireEditRouteValidator";
import useQuestionnaireEditRouteConfig from "../QuestionnaireEditRouteConfig";
import { RouteConfig } from "../../RouteConfig";
import QuestionPage from "../../../../pages/questionnaires/questionnaire/question/QuestionPage";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useQuestionCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/questionnaires/:questionnaire/questions/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.question"),
        },
      ])
    )
    .setValidator(useQuestionnaireEditRouteValidator())
    .setPage(<QuestionPage />)
    .setParent(useQuestionnaireEditRouteConfig());
}

export default useQuestionCreateRouteConfig;
