import { useLanguageContext } from "../../../context/LanguageContext";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../context/AuthContext";
import useUserCreateRouteConfig from "../../../routes/configs/users/UserCreateRouteConfig";
import useUserEditRouteConfig from "../../../routes/configs/users/UserEditRouteConfig";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";

function UsersDataTable() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig(
    `users_${authContext.auth?.user?.company?.type}`
  );

  //Set the datatable fetch info
  config.setFetchRequest("users");
  config.setFetchParams({
    with_user_location_data: true,
    with_user_employee_data:
      authContext.auth?.user?.company?.type !== "superadmin",
    with_user_language_data:
      authContext.auth?.user?.company?.type !== "superadmin",
  });

  //Set the datatable order by
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location.name").setHidden(
      authContext.auth?.user?.company?.type === "superadmin"
    ),
    useBasicColumnConfig("name").setLockActive(true),
    useBasicColumnConfig("email").setLockActive(true),
    useBasicColumnConfig("employee.name").setHidden(
      authContext.auth?.user?.company?.type === "superadmin"
    ),
    useBasicColumnConfig("language.label"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faMobileScreenButton)
      .setCallback(handleTFAReset)
      .setDisabled(
        (row) =>
          !authContext.hasPermission("users.update") ||
          !row.two_factor_confirmed_at
      )
      .setTitle(translate("eva.main.auth.tfa.reset"))
      .setLoadingGroups(
        (row) => `${config.getDataTableKey()}_tfa_reset_${row.id}`
      ),
    useEditRowActionConfig("user", useUserEditRouteConfig()),
    useDeleteRowActionConfig("user").setDisabled(
      (row) =>
        row.email === "superadmin@evasolutions.eu" ||
        row.id === authContext.auth.user.id ||
        !authContext.hasPermission("users.delete")
    ),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("user", useUserCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This method will try and reset the tfa for the given row
   * @param {object} row
   * @returns
   */
  async function handleTFAReset(row) {
    if (!window.confirm(translate("eva.main.questions.reset.tfa"))) {
      return;
    }

    await callEvaApi(
      new EvaApiCall(`users/${row.id}/tfa/reset`)
        .setMethod("delete")
        .setLoadingGroup(`${config.getDataTableKey()}_tfa_reset_${row.id}`)
    );

    dataTable.fetch();
  }

  return <DataTable controller={dataTable} />;
}

export default UsersDataTable;
