import { useParams } from "react-router-dom";
import HelpTextPageView from "./HelpTextPageView";
import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useHelpTextContext } from "../../../context/HelpTextContext";
import { useRouteContext } from "../../../context/RouteContext";
import { useTinyEditor } from "../../../components/tinyEditor/TinyEditor";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function HelpTextPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { help_text } = useParams();
  const helpTextContext = useHelpTextContext();
  const [submitError, setSubmitError] = useState(null);
  const [helpTextData, setHelpTextData] = useState({
    code: "",
    title: "",
    path: "",
    description: "",
    language_id: null,
  });
  const descriptionController = useTinyEditor(
    helpTextData.description,
    onDescriptionChange
  );

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    if (help_text) {
      fetchHelpTextData();
    }
  });

  /**
   * This method will fetch the helpText data from the API
   */
  async function fetchHelpTextData() {
    await callEvaApi(
      new EvaApiCall(`help_texts/${help_text}`)
        .setLoadingGroup("help_text_page_fetch")
        .setOnSuccess((response) => {
          setHelpTextData({
            code: response.data.code,
            title: response.data.title,
            path: response.data.path,
            description: response.data.description,
            language_id: response.data.language,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the code on input change
   * @param {event} e
   */
  function onCodeChange(e) {
    setHelpTextData({ ...helpTextData, code: e.target.value });
  }

  /**
   * This method will update the title on input change
   * @param {event} e
   */
  function onTitleChange(e) {
    setHelpTextData({ ...helpTextData, title: e.target.value });
  }

  /**
   * This method will update the path on input change
   * @param {event} e
   */
  function onPathChange(e) {
    setHelpTextData({ ...helpTextData, path: e.target.value });
  }

  /**
   * This method will update the description on input change
   * @param {string} value
   */
  function onDescriptionChange(value) {
    setHelpTextData({ ...helpTextData, description: value });
  }

  /**
   * This method will update the language id on input change
   * @param {any} value
   */
  function onLanguageIdChange(value) {
    setHelpTextData({ ...helpTextData, language_id: value });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(help_text ? `help_texts/${help_text}` : "help_texts")
        .setLoadingGroup("help_text_page_save")
        .setMethod(help_text ? "PUT" : "POST")
        .setData(helpTextData)
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          routeContext.back();
          helpTextContext.fetchHelpTexts();
        })
    );
  }

  return (
    <HelpTextPageView
      helpTextData={helpTextData}
      descriptionController={descriptionController}
      onCodeChange={onCodeChange}
      onTitleChange={onTitleChange}
      onPathChange={onPathChange}
      onLanguageIdChange={onLanguageIdChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default HelpTextPage;
