import { useLanguageContext } from "../../../context/LanguageContext";
import IPLockPage from "../../../pages/ip_locks/ip_lock/IPLockPage";
import { useIPLockCreateRouteValidator } from "../../validators/ip_locks/IPLockCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useIPLocksRouteConfig from "./IPLocksRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useIPLockCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/ip_locks/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.ip_lock"),
        },
      ])
    )
    .setValidator(useIPLockCreateRouteValidator())
    .setPage(<IPLockPage />)
    .setParent(useIPLocksRouteConfig());
}

export default useIPLockCreateRouteConfig;
