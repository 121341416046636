import { useDraggableContext } from "../../context/DraggableContext";
import LayoutView from "./LayoutView";
import useCustomEffect from "../../hooks/useCustomEffect";
import { useState } from "react";
import { updateLayoutOnDrag } from "./LayoutMethods";

function Layout({ layoutData, setLayoutData, content, languageSelect }) {
  const [clickDisabled, setClickDisabled] = useState(false);
  const draggableContext = useDraggableContext();
  const readonly = setLayoutData === undefined || setLayoutData === null;

  /**
   * UseEffect for disabling the click event when dragging.
   */
  useCustomEffect(() => {
    setClickDisabled(draggableContext.dragged ? true : false);
  }, [draggableContext.dragged]);

  /**
   * This method will handle the mouse move even on the layout item.
   * @param {object} item
   * @param {Event} e
   */
  function onMouseMove(item, e) {
    if (readonly || !isItemMouseTarget(item, e)) {
      return;
    }

    setLayoutData((prev) => {
      return {
        ...prev,
        hovered_item_id: item.id,
      };
    });
  }

  /**
   * This method will handle the mouse leave event on the layout item.
   */
  function onMouseLeave() {
    if (readonly) {
      return;
    }

    setLayoutData((prev) => {
      return {
        ...prev,
        hovered_item_id: null,
      };
    });
  }

  /**
   * This method will handle the mouse click event on the layout item.
   * @param {object} item
   * @param {Event} e
   */
  function onMouseClick(item, e) {
    if (readonly || clickDisabled || !isItemMouseTarget(item, e)) {
      return;
    }

    setLayoutData((prev) => {
      return {
        ...prev,
        focused_item_id: item.id,
      };
    });
  }

  /**
   * This method will return if the given item is the mouse target of the given event.
   * @param {object} item
   * @param {Event} e
   * @returns {boolean}
   */
  function isItemMouseTarget(item, e) {
    let element = e.target;
    while (element.id !== `LAYOUT_ITEM_${item.id}`) {
      if (element.id.startsWith("LAYOUT_ITEM_")) {
        return false;
      }
      element = element.parentElement;
    }
    return true;
  }

  /**
   * This method will handle the drag enter event on the layout.
   * @param {string} dragged
   * @param {string} target
   */
  function onDragEnter(dragged, target) {
    setLayoutData((prev) => ({
      ...prev,
      layout: updateLayoutOnDrag(prev.layout, dragged, target),
    }));

    return true;
  }

  return (
    <LayoutView
      readonly={readonly}
      layoutData={layoutData}
      content={content}
      languageSelect={languageSelect}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseClick={onMouseClick}
      onDragEnter={onDragEnter}
    />
  );
}

export default Layout;
