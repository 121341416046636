import { useAuthContext } from "../../../context/AuthContext";
import useCompanyLanguageCreateRouteConfig from "../../../routes/configs/companyLanguages/CompanyLanguageCreateRouteConfig";
import useCompanyLanguageEditRouteConfig from "../../../routes/configs/companyLanguages/CompanyLanguageEditRouteConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useBooleanColumnConfig from "../main/config/column/default/BooleanColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import { useLanguageContext } from "../../../context/LanguageContext";

function CompanyLanguagesDataTable() {
  const authContext = useAuthContext();
  const languageContext = useLanguageContext();

  //Create the datatable config
  const config = new DataTableConfig("company_languages");

  //Set the datatable fetch info
  config.setFetchRequest("company_languages");
  config.setFetchParams({
    with_company_language_language_data: true,
  });

  //Set the datatable order by
  config.setOrderBy("language.label");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("language.label").setLockActive(true),
    useBooleanColumnConfig("active"),
    useBooleanColumnConfig("default"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig(
      "company_language",
      useCompanyLanguageEditRouteConfig()
    ),
    useDeleteRowActionConfig("company_language", null, false, (response) => {
      languageContext.fetchLanguages();
    })
      .setDisabled((row) => row.default)
      .setHidden((row) => authContext.auth.user.role_type !== "superadmin"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig(
      "company_language",
      useCompanyLanguageCreateRouteConfig()
    ),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  return <DataTable controller={dataTable} />;
}

export default CompanyLanguagesDataTable;
