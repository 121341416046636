import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RangeInput from "../../range/RangeInput";
import {
  StyledColorInput,
  StyledColorInputWrapper,
  StyledHtmlColorInput,
} from "./HtmlColorInputStyles";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { StyledTextButton } from "../../../../styles/ButtonStyles";
import { useLanguageContext } from "../../../../context/LanguageContext";

function HtmlColorInputView({
  rgbaValue,
  hexValue,
  visible,
  useOpacityInput,
  useVisabilityInput,
  disabled,
  onColorChange,
  onOpacityChange,
  onVisabilityChange,
}) {
  const { translate } = useLanguageContext();

  return (
    <StyledHtmlColorInput disabled={disabled}>
      <StyledColorInputWrapper>
        <StyledColorInput
          type="color"
          value={hexValue}
          onChange={onColorChange}
        />

        {useVisabilityInput && !disabled && (
          <StyledTextButton
            onClick={onVisabilityChange}
            title={translate(`eva.main.general.${visible ? "use" : "ignore"}`)}
            style={{ width: "15px" }}
          >
            <FontAwesomeIcon icon={visible ? faCheck : faXmark} />
          </StyledTextButton>
        )}
      </StyledColorInputWrapper>

      {useOpacityInput && !disabled && (
        <RangeInput
          value={rgbaValue.a}
          min={0}
          max={1}
          onChange={onOpacityChange}
        />
      )}
    </StyledHtmlColorInput>
  );
}

export default HtmlColorInputView;
