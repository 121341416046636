import CalamityActivationButtonView from "./CalamityActivationButtonView";
import { useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useCalamityContext } from "../../context/CalamityContext";

function CalamityActivationButton() {
  const [isFormVisible, setFormVisibility] = useState(false);
  const authContext = useAuthContext();
  const calamityContext = useCalamityContext();
  const disabled =
    calamityContext.calamities.find(
      (calamity) => calamity.location === authContext.auth.location
    ) !== undefined || !authContext.hasPermission("calamities.delete");

  function onFormClose() {
    setFormVisibility(false);
  }

  function onFormOpen() {
    setFormVisibility(true);
  }

  return (
    authContext.auth.user?.company.type !== "superadmin" && (
      <CalamityActivationButtonView
        onFormOpen={onFormOpen}
        isFormVisible={isFormVisible}
        onFormClose={onFormClose}
        disabled={disabled}
      />
    )
  );
}

export default CalamityActivationButton;
