import { useLanguageContext } from "../../../context/LanguageContext";
import FilesPage from "../../../pages/files/index/FilesPage";
import { useFilesRouteValidator } from "../../validators/files/FilesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFilesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/files")
    .setLabel(translate("eva.main.entities.files"))
    .setValidator(useFilesRouteValidator())
    .setPage(<FilesPage />);
}

export default useFilesRouteConfig;
