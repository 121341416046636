import { useParams } from "react-router-dom";
import QuestionAnswerPageView from "./QuestionAnswerPageView";
import { useState } from "react";
import useCustomEffect from "../../../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../../../context/RouteContext";
import useEntityTranslations from "../../../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../../../hooks/useEvaApi";

function QuestionAnswerPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { question, question_answer } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [questionAnswerData, setQuestionAnswerData] = useState({
    question_id: question,
    name: "",
    order: 0,
    correct: false,
    translations: [],
  });

  const translationsController = useEntityTranslations(
    questionAnswerData.translations,
    onTranslationsChange
  );

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (question_answer) {
      fetchQuestionAnswerData();
    }
  });

  /**
   * This method will fetch the publications data from the API
   */
  async function fetchQuestionAnswerData() {
    await callEvaApi(
      new EvaApiCall(`question_answers/${question_answer}`)
        .setLoadingGroup("question_answer_page_fetch")
        .setOnSuccess((response) => {
          setQuestionAnswerData({
            question_id: question,
            name: response.data.data.name,
            order: response.data.data.order,
            correct: response.data.data.correct,
            translations: response.data.data.translations.map(
              (translation) => ({
                language_id: translation.language,
                answer: translation.answer,
              })
            ),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setQuestionAnswerData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will update the order on input change
   * @param {event} e
   */
  function onOrderChange(e) {
    setQuestionAnswerData((current) => ({ ...current, order: e.target.value }));
  }

  /**
   * This method will update the correct on input change
   * @param {any} value
   */
  function onCorrectChange(value) {
    setQuestionAnswerData((current) => ({ ...current, correct: value }));
  }

  /**
   * Handle the translations change
   * @param {object[]} value
   */
  function onTranslationsChange(value) {
    setQuestionAnswerData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        question_answer
          ? `question_answers/${question_answer}`
          : `question_answers`
      )
        .setLoadingGroup("question_answer_page_submit")
        .setMethod(question_answer ? "PUT" : "POST")
        .setData(questionAnswerData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <QuestionAnswerPageView
      questionAnswerData={questionAnswerData}
      translationsController={translationsController}
      onNameChange={onNameChange}
      onOrderChange={onOrderChange}
      onCorrectChange={onCorrectChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default QuestionAnswerPage;
