import { useLanguageContext } from "../../../context/LanguageContext";
import LanguagePage from "../../../pages/languages/language/LanguagePage";
import { useLanguagesRouteValidator } from "../../validators/LanguagesRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useLanguagesRouteConfig from "./LanguagesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useLanguageEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/languages/:language/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.language"),
        },
      ])
    )
    .setValidator(useLanguagesRouteValidator())
    .setPage(<LanguagePage />)
    .setParent(useLanguagesRouteConfig());
}

export default useLanguageEditRouteConfig;
