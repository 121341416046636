import styled from "styled-components";
import { StyledInput } from "../../../styles/InputStyles";

export const StyledRangeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledRange = styled.div`
  flex: 1;
  height: 5px;
  background-color: ${(props) => props.theme.colors.primary.lightest};

  &:hover {
    cursor: pointer;

    & > :first-child {
      opacity: 0.66;
    }
  }
`;

export const StyledRangeValue = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.on.primary};
  width: ${(props) => props.percentage ?? 0}%;
  position: relative;
`;

export const StyledRangeThumb = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.on.primary};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
`;

export const StyledRangeInput = styled(StyledInput)`
  width: 75px;
  height: 22.5px;
  padding-block: 0;
`;
