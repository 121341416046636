import { useRef, useState } from "react";
import SettingsView from "./SettingsView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useTerminalContext } from "../../../context/TerminalContext";

function Settings() {
  const terminalContext = useTerminalContext();
  const openCommand = useRef({
    count: 0,
    timestamp: 0,
  });
  const [open, setOpen] = useState(terminalContext.terminal ? false : true);

  /**
   * UseEffect for handling the open command
   */
  useCustomEffect(() => {
    window.addEventListener("click", checkOpenCommand);
    return () => {
      window.removeEventListener("click", checkOpenCommand);
    };
  });

  /**
   * This method will check if the open command is triggered
   * @param {Event} event
   */
  function checkOpenCommand(event) {
    const clickDistanceThreshold = 15;
    const clickTimeThreshold = 1000;
    const clickAmountThreshold = 5;

    const realX = event.screenX - window.screenLeft;
    const realY =
      event.screenY -
      window.screenTop -
      (window.outerHeight - window.innerHeight);

    //Check if the click is in the top left corner of the screen plus a threshold
    if (realX <= clickDistanceThreshold && realY <= clickDistanceThreshold) {
      const timestamp = new Date().getTime();

      //Check if the click is within the time threshold
      if (timestamp - openCommand.current.timestamp <= clickTimeThreshold) {
        openCommand.current.count++;

        //Check if the click amount is within the threshold and open the settings if it is
        if (openCommand.current.count >= clickAmountThreshold) {
          setOpen(true);
        }
      }
      //Reset the count if the click is outside the time threshold
      else {
        openCommand.current.count = 1;
      }

      openCommand.current.timestamp = timestamp;
    }
  }

  /**
   * This method will close the settings
   */
  function onClose() {
    setOpen(false);
  }

  return <SettingsView open={open} onClose={onClose} />;
}

export default Settings;
