class FrontOfficePageConfig {
  constructor(page, pageName) {
    this.page = page;
    this.pageName = pageName;
    this.data = null;
  }

  setData(data) {
    this.data = data;
    return this;
  }
}

export default FrontOfficePageConfig;
