import Layout from "../../../../components/layout/Layout";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import FieldsStep from "./steps/fields/FieldsStep";
import HouseruleStep from "./steps/houserule/HouseruleStep";
import QuestionnaireStep from "./steps/questionnaire/QuestionnaireStep";

function VisitorTypePageView({
  layout,
  step,
  errors,
  visitorData,
  setVisitorData,
  processStep,
  previousStep,
  setPageConfig,
}) {
  /**
   * @returns {JSX.Element} The content of the current step
   */
  function getContent() {
    let Component = null;
    switch (step.type) {
      case "fields":
        Component = FieldsStep;
        break;
      case "questionnaire":
        Component = QuestionnaireStep;
        break;
      case "houserule":
        Component = HouseruleStep;
        break;
      default:
        break;
    }

    return Component ? (
      <Component
        key={step?.id}
        step={step}
        errors={errors}
        visitorData={visitorData}
        setVisitorData={setVisitorData}
        setPageConfig={setPageConfig}
        processStep={processStep}
        previousStep={previousStep}
      />
    ) : null;
  }

  return (
    <Layout
      layoutData={layout}
      content={
        step ? (
          getContent()
        ) : (
          <HiddenLoader groups={"process_step"} iconSize={"lg"}>
            <div style={{ height: "30px" }}></div>
          </HiddenLoader>
        )
      }
    />
  );
}

export default VisitorTypePageView;
