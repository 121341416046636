import { useLanguageContext } from "../../../context/LanguageContext";
import LayoutsPage from "../../../pages/layouts/index/LayoutsPage";
import { useLayoutsRouteValidator } from "../../validators/layouts/LayoutsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useLayoutsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/layouts")
    .setLabel(translate("eva.main.entities.layouts"))
    .setValidator(useLayoutsRouteValidator())
    .setPage(<LayoutsPage />);
}

export default useLayoutsRouteConfig;
