import { StyledNode, StyledNodeTitle } from "./FlowChartNodeStyles";

function FlowChartNodeView({ node, nodeRef }) {
  return (
    <div
      ref={nodeRef}
      id={`FLOW_CHART_NODE_${node?.id}`}
      onClick={node?.onClick}
      style={{
        position: "absolute",
        transform: `translate(${node?.pos?.x}px, ${node?.pos?.y}px)`,
      }}
    >
      {node?.element ?? (
        <StyledNode
          style={node?.style}
          onClick={node?.onClick}
          focused={node?.focused}
        >
          <StyledNodeTitle>{node?.title ?? node?.id}</StyledNodeTitle>
        </StyledNode>
      )}
    </div>
  );
}

export default FlowChartNodeView;
