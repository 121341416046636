import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledAltIconButton } from "../../styles/ButtonStyles";
import {
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledOverlay,
} from "./ModalStyles";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import OverlayLoader from "../loader/OverlayLoader/OverlayLoader";
import { useTheme } from "styled-components";
import { useLoadContext } from "../../context/LoadContext";

function ModalView({ title, children, onClose, loadingGroups }) {
  const theme = useTheme();
  const isLoading = useLoadContext().isLoading(loadingGroups);

  return (
    <StyledOverlay>
      <StyledModal>
        <StyledModalHeader>
          <h2>{title}</h2>

          {onClose && (
            <StyledAltIconButton onClick={!isLoading ? onClose : null}>
              <FontAwesomeIcon icon={faXmark} />
            </StyledAltIconButton>
          )}
        </StyledModalHeader>

        <StyledModalBody>
          <OverlayLoader
            groups={loadingGroups}
            iconSize={"xl"}
            containerStyle={{
              padding: theme.spacing.normal,
            }}
            overlayStyle={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {children}
          </OverlayLoader>
        </StyledModalBody>
      </StyledModal>
    </StyledOverlay>
  );
}

export default ModalView;
