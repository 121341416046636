import useGuestMiddleware from "../../middlewares/GuestMiddleware";
import useMiddlewares from "../../middlewares/Middleware";
import useRouteValidator from "./RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useLoginRouteValidator() {
  return useRouteValidator(useMiddlewares([useGuestMiddleware()]));
}
