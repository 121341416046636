import FieldsStepView from "./FieldsStepView";

function FieldsStep({
  step,
  errors,
  visitorData,
  setVisitorData,
  setPageConfig,
  processStep,
  previousStep,
}) {
  /**
   * This method will handle the field change event
   * @param {any} key
   * @param {any} value
   */
  function onFieldChange(field, value) {
    //Construct the new field data
    const newFieldData = { id: field.id };
    if (field.type === "select") {
      newFieldData.option_id = value;
    } else {
      newFieldData.value = value;
    }

    //Update the visitor data
    setVisitorData((prevData) => ({
      ...prevData,
      fields: [
        ...(prevData.fields ?? []).filter((f) => f.id !== field.id),
        newFieldData,
      ],
    }));
  }

  return (
    <FieldsStepView
      step={step}
      errors={errors}
      visitorData={visitorData}
      onFieldChange={onFieldChange}
      setPageConfig={setPageConfig}
      processStep={processStep}
      previousStep={previousStep}
    />
  );
}

export default FieldsStep;
