import { useTheme } from "styled-components";

function FlowChartEdgeView({
  fromElement,
  toElement,
  edge,
  svgPositioning,
  edgePath,
  edgePathCenter,
  arrowPath,
  svgRef,
  pathRef,
}) {
  const theme = useTheme();
  const color = edge?.color ?? theme.colors.on.primary;

  return (
    svgPositioning && (
      <div
        style={{
          position: "absolute",
          transform: `translate(${svgPositioning?.x}px, ${svgPositioning?.y}px)`,
          width: `${svgPositioning?.width}px`,
          height: `${svgPositioning?.height}px`,
          zIndex: edge?.zIndex ?? 0,
          pointerEvents: "none",
        }}
      >
        <svg
          ref={svgRef}
          style={{
            width: "100%",
            height: "100%",
            overflow: "visible",
            pointerEvents: "none",
          }}
        >
          {fromElement && toElement && (
            <>
              {edgePath && (
                <path
                  ref={pathRef}
                  d={edgePath}
                  fill="none"
                  stroke={color}
                  strokeWidth="2"
                />
              )}

              {arrowPath && (
                <path
                  d={arrowPath}
                  fill={color}
                  stroke={color}
                  strokeWidth="2"
                />
              )}
            </>
          )}
        </svg>

        {edge.element && edgePathCenter && (
          <div
            style={{
              position: "absolute",
              left: edgePathCenter.x,
              top: edgePathCenter.y,
              transform: "translate(-50%, -50%)",
              pointerEvents: "initial",
            }}
          >
            {edge.element}
          </div>
        )}
      </div>
    )
  );
}

export default FlowChartEdgeView;
