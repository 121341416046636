import MultiSelectView from "./MultiSelectView";

function MultiSelect({
  options,
  onChange,
  values,
  disabled,
  title,
  placeholder,
}) {
  //Determines which options are available to select
  const availableOptions = options?.filter(
    (option) => !values?.includes(option.value)
  );

  /**
   * This method will handle the functionality for when a value is selected
   * @param {any} value
   */
  function onSelectChange(value) {
    if (onChange) {
      const newvalue = [...values, value];
      onChange(newvalue);
    }
  }

  /**
   * This method will handle the functionality for when a value is clicked
   * @param value
   */
  function onValueClick(value) {
    if (onChange) {
      const newvalue = values?.filter((v) => v !== value);
      onChange(newvalue);
    }
  }

  /**
   * Clears all value
   */
  function onClear() {
    if (onChange) {
      onChange([]);
    }
  }

  return (
    <MultiSelectView
      availableOptions={availableOptions}
      options={options}
      onSelectChange={onSelectChange}
      onValueClick={onValueClick}
      onClear={onClear}
      values={values}
      title={title}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
}

export default MultiSelect;
