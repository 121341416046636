import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import {
  StyledAltButton,
  StyledTextButton,
} from "../../../../../../styles/ButtonStyles";
import {
  StyledField,
  StyledFieldHeader,
  StyledFieldHeaderLabel,
  StyledRow,
  StyledRowActions,
  StyledRowFields,
  StyledRowHeader,
} from "./FlowPageFieldsFormStyles";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Draggable from "../../../../../../components/draggable/Draggable";
import MultiSelect from "../../../../../../components/select/main/multiSelect/MultiSelect";
import CompactError from "../../../../../../components/error/compact/CompactError";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";

function FlowPageFieldsFormView({
  flowData,
  fields,
  onAddRow,
  onDeleteRow,
  onAddField,
  onDeleteField,
  onFieldOptionsChange,
  onDragEnter,
  submitError,
}) {
  const { translate } = useLanguageContext();

  return (
    <>
      {flowData.steps[flowData.selectedStep].fields?.map((row, rowIndex) => (
        <Draggable
          key={rowIndex}
          id={`FIELD_${rowIndex}`}
          onTargetEnter={onDragEnter}
        >
          <StyledRow key={rowIndex}>
            <StyledRowHeader>
              {/* Row Header */}
              {`${translate("eva.main.general.row")} ${rowIndex + 1}`}

              <StyledRowActions>
                {/* Add Field Select */}
                <Select
                  title={translate("eva.main.entities.states.create", [
                    {
                      key: "entities",
                      value: translate("eva.main.entities.field"),
                    },
                  ])}
                  as={StyledTextButton}
                  hideCaret={true}
                  valueVisual={(value) => <FontAwesomeIcon icon={faPlus} />}
                  onChange={(value) => onAddField(rowIndex, value)}
                  options={fields.map((field) => ({
                    value: field.id,
                    label: field.key,
                  }))}
                />

                {/* Delete Row Button */}
                <StyledTextButton
                  onClick={() => onDeleteRow(rowIndex)}
                  title={translate("eva.main.entities.states.delete", [
                    {
                      key: "entities",
                      value: translate("eva.main.general.row"),
                    },
                  ])}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </StyledTextButton>
              </StyledRowActions>
            </StyledRowHeader>

            {/* Row Fields */}
            {row.length > 0 && (
              <StyledRowFields>
                {row.map((field, fieldIndex) => {
                  const options = [];
                  if (field.required_terminal)
                    options.push("required_terminal");
                  if (field.required_backoffice)
                    options.push("required_backoffice");
                  if (field.clear) options.push("clear");

                  return (
                    <Draggable
                      key={`${rowIndex},${fieldIndex}`}
                      id={`FIELD_${rowIndex},${fieldIndex}`}
                      onTargetEnter={onDragEnter}
                    >
                      <StyledField>
                        <StyledFieldHeader>
                          <StyledFieldHeaderLabel>
                            {/* Field Header */}
                            {fields.find((f) => f.id === field.field_id)?.key}

                            {/* Field error */}
                            <CompactError
                              error={EVAApiHelper.getResponseErrorsLike(
                                `steps.${flowData.selectedStep}.fields.${rowIndex}.${fieldIndex}`,
                                submitError,
                                translate
                              )}
                            />
                          </StyledFieldHeaderLabel>

                          {/* Delete Field Button */}
                          <StyledTextButton
                            onClick={() => onDeleteField(rowIndex, fieldIndex)}
                            title={translate(
                              "eva.main.entities.states.delete",
                              [
                                {
                                  key: "entities",
                                  value: translate("eva.main.entities.field"),
                                },
                              ]
                            )}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </StyledTextButton>
                        </StyledFieldHeader>

                        {/* Field Options */}
                        <MultiSelect
                          placeholder={translate("eva.main.general.settings")}
                          onChange={(values) =>
                            onFieldOptionsChange(rowIndex, fieldIndex, values)
                          }
                          values={options}
                          options={[
                            {
                              value: "required_terminal",
                              label: `${translate(
                                "eva.main.general.required"
                              )} (${translate("eva.main.entities.terminal")})`,
                            },
                            {
                              value: "required_backoffice",
                              label: `${translate(
                                "eva.main.general.required"
                              )} (${translate("eva.main.general.backoffice")})`,
                            },
                            {
                              value: "clear",
                              label: translate("eva.main.general.clear"),
                            },
                          ]}
                        />
                      </StyledField>
                    </Draggable>
                  );
                })}
              </StyledRowFields>
            )}
          </StyledRow>
        </Draggable>
      ))}

      {/* Add Row Button */}
      <StyledAltButton onClick={onAddRow}>
        {translate("eva.main.entities.states.create", [
          { key: "entities", value: translate("eva.main.general.row") },
        ])}
      </StyledAltButton>
    </>
  );
}

export default FlowPageFieldsFormView;
