import { useLanguageContext } from "../../../context/LanguageContext";
import CompaniesPage from "../../../pages/companies/index/CompaniesPage";
import { useCompaniesRouteValidator } from "../../validators/CompaniesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCompaniesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/companies")
    .setLabel(translate("eva.main.entities.companies"))
    .setValidator(useCompaniesRouteValidator())
    .setPage(<CompaniesPage />);
}

export default useCompaniesRouteConfig;
