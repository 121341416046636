import { useLanguageContext } from "../../../context/LanguageContext";
import CalamitiesLogPage from "../../../pages/calamities/log/CalamitiesLogPage";
import { useCalamitiesLogRouteValidator } from "../../validators/calamities/log/CalamitiesLogRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCalamitiesLogRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/calamities/log")
    .setLabel(translate("eva.main.entities.calamities_log"))
    .setValidator(useCalamitiesLogRouteValidator())
    .setPage(<CalamitiesLogPage />);
}

export default useCalamitiesLogRouteConfig;
