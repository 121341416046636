import { StyledStatisticsBar, StyledStatisticsBarFilled } from "./StatisticsBarStyles";

function StatisticsBar({ amount }) {
  return (
    <StyledStatisticsBar>
      <StyledStatisticsBarFilled percentage={amount} />
    </StyledStatisticsBar>
  );
}

export default StatisticsBar;