import LoginPage from "../../pages/login/LoginPage";
import { RouteConfig } from "./RouteConfig";
import { useLoginRouteValidator } from "../validators/LoginRouteValidator";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useLoginRouteConfig() {
  return new RouteConfig()
    .setPath("/login")
    .setValidator(useLoginRouteValidator())
    .setPage(<LoginPage />);
}

export default useLoginRouteConfig;
