import { useLanguageContext } from "../../../context/LanguageContext";
import HelpTextPage from "../../../pages/help_texts/help_text/HelpTextPage";
import { useHelpTextsRouteValidator } from "../../validators/HelpTextsRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useHelpTextsRouteConfig from "./HelpTextsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useHelpTextEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/help_texts/:help_text/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.help_text"),
        },
      ])
    )
    .setValidator(useHelpTextsRouteValidator())
    .setPage(<HelpTextPage />)
    .setParent(useHelpTextsRouteConfig());
}

export default useHelpTextEditRouteConfig;
