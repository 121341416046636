import { useNavigate, useParams } from "react-router-dom";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import RowActionConfig from "../RowActionConfig";
import { faPen } from "@fortawesome/free-solid-svg-icons";

/**
 * @param {string} entity for example: "location"
 * @param {RouteConfig} routeConfig for example: useLocationEditRouteConfig()
 * @returns {RowActionConfig}
 */
function useEditRowActionConfig(entity, routeConfig) {
  const { translate } = useLanguageContext();
  const navigate = useNavigate();
  const params = useParams();

  return new RowActionConfig()
    .setIcon(faPen)
    .setCallback((row) =>
      navigate(
        routeConfig.getPathWithParams(params).replace("undefined", row.id)
      )
    )
    .setTitle(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate(`eva.main.entities.${entity}`),
        },
      ])
    )
    .setDisabled((row) => {
      if (routeConfig.validator) {
        return routeConfig.validator.validate(row) !== true;
      }
      return false;
    });
}

export default useEditRowActionConfig;
