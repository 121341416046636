import { useLanguageContext } from "../../../context/LanguageContext";
import FilePage from "../../../pages/files/file/FilePage";
import { useFileCreateRouteValidator } from "../../validators/files/FileCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useFilesRouteConfig from "./FilesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFileCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/files/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.file"),
        },
      ])
    )
    .setValidator(useFileCreateRouteValidator())
    .setPage(<FilePage />)
    .setParent(useFilesRouteConfig());
}

export default useFileCreateRouteConfig;
