import { useParams } from "react-router-dom";
import MessageTemplatePageView from "./MessageTemplatePageView";
import { useRouteContext } from "../../../context/RouteContext";
import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useTinyEditor } from "../../../components/tinyEditor/TinyEditor";
import useEntityTranslations from "../../../hooks/useEntityTranslations";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function MessageTemplatePage() {
  const callEvaApi = useEvaApi();
  const { message_template } = useParams();
  const routeContext = useRouteContext();
  const [submitError, setSubmitError] = useState(null);
  const [messageTemplateData, setMessageTemplateData] = useState({
    name: "",
    translations: [],
  });

  const translationsController = useEntityTranslations(
    messageTemplateData.translations,
    onTranslationsChange
  );

  const messageController = useTinyEditor(
    translationsController.getValue("message"),
    (value) => translationsController.onTranslationChange(value, "message")
  );

  /**
   * UseEffect to initialize the component
   */
  useCustomEffect(() => {
    if (message_template) {
      fetchMessageTemplateData();
    }
  });

  /**
   * This method will fetch the message template data from the API
   */
  async function fetchMessageTemplateData() {
    await callEvaApi(
      new EvaApiCall(`message_templates/${message_template}`)
        .setLoadingGroup("message_template_page_fetch")
        .setOnSuccess((response) => {
          const newData = response.data.data;
          setMessageTemplateData({
            name: newData.name,
            translations: newData.translations.map((translation) => ({
              language_id: translation.language,
              message: translation.message,
            })),
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will handle the name change
   * @param {Event} e
   */
  function onNameChange(e) {
    setMessageTemplateData((current) => ({
      ...current,
      name: e.target.value,
    }));
  }

  /**
   * This method will handle the translations change
   * @param {array} value
   */
  function onTranslationsChange(value) {
    setMessageTemplateData((current) => ({
      ...current,
      translations: value,
    }));
  }

  /**
   * This method will handle the cancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will handle the submit functionality
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        message_template
          ? `message_templates/${message_template}`
          : "message_templates"
      )
        .setLoadingGroup("message_template_page_submit")
        .setMethod(message_template ? "PUT" : "POST")
        .setData(messageTemplateData, ["language_id"])
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <MessageTemplatePageView
      messageTemplateData={messageTemplateData}
      messageController={messageController}
      translationsController={translationsController}
      onNameChange={onNameChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default MessageTemplatePage;
