import styled from "styled-components";
import { StyledTextButton } from "../../../../styles/ButtonStyles";

export const StyledFooterAlerts = styled.div`
  position: fixed;
  bottom: ${(props) => props.theme.spacing.large};
  right: ${(props) => props.theme.spacing.large};
  display: flex;
  flex-direction: column-reverse;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledAlert = styled.div`
  background-color: ${(props) =>
    `rgba(${props.theme.colors.accent.normalRGB}, 0.2)`};
  border-radius: ${(props) => props.theme.radius.default};
  padding: ${(props) => props.theme.spacing.normal};
  border: 1px solid ${(props) => props.theme.colors.accent.normal};
  color: ${(props) => props.theme.colors.accent.normal};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.large};
  animation-name: fadeIn;
  animation-duration: 0.5s;
  max-width: 500px;

  @keyframes fadeIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

export const StyledAlertLabel = styled.p`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  margin-right: auto;
`;

export const StyledAlertClose = styled(StyledTextButton)`
  margin-left: auto;
`;
