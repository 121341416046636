import { useLanguageContext } from "../../../context/LanguageContext";
import ContactPage from "../../../pages/contacts/contact/ContactPage";
import { useContactEditRouteValidator } from "../../validators/contacts/ContactEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useContactsRouteConfig from "./ContactsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useContactEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/contacts/:contact/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.contact"),
        },
      ])
    )
    .setValidator(useContactEditRouteValidator())
    .setPage(<ContactPage />)
    .setParent(useContactsRouteConfig());
}

export default useContactEditRouteConfig;
