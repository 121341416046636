import { useLanguageContext } from "../../../context/LanguageContext";
import QuestionnairesPage from "../../../pages/questionnaires/index/QuestionnairesPage";
import { useQuestionnairesRouteValidator } from "../../validators/questionnaires/QuestionnairesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useQuestionnairesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/questionnaires")
    .setLabel(translate("eva.main.entities.questionnaires"))
    .setValidator(useQuestionnairesRouteValidator())
    .setPage(<QuestionnairesPage />);
}

export default useQuestionnairesRouteConfig;
