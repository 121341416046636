import { useState } from "react";
import useEvaApi, { EvaApiCall } from "../../../../../hooks/useEvaApi";
import PresentEmployeesStatisticView from "./PresentEmployeesStatisticView";
import useCustomEffect from "../../../../../hooks/useCustomEffect";

function PresentEmployeesStatistic() {
  const callEvaApi = useEvaApi();

  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState({
    records: [],
    current: null,
  });

  // Filter the employees based on the selected department
  const filteredEmployees = departments.current
    ? employees.filter(
        (employee) =>
          parseInt(employee.department) === parseInt(departments.current)
      )
    : employees;

  // Calculate the total employees, present employees and the percentage of present employees
  const totalEmployees = filteredEmployees.length;
  const presentEmployees = filteredEmployees.filter(
    (employee) => employee.present
  ).length;
  const percentagePresentEmployees =
    totalEmployees > 0 ? (presentEmployees / totalEmployees) * 100 : 0;

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    fetchEmployees();
    fetchDepartments();
  }, []);

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("dashboard_employee_fetch")
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the departments from the API
   */
  async function fetchDepartments() {
    await callEvaApi(
      new EvaApiCall("departments")
        .setLoadingGroup("dashboard_employee_fetch")
        .setOnSuccess((response) => {
          const newDepartments = response.data.data.records;

          // Find the selected department based on local storage setting
          const newCurrent = newDepartments.find(
            (department) =>
              department.id ===
              parseInt(
                localStorage.getItem(
                  "dashboard_statistics_present_employees_department"
                )
              )
          );

          setDepartments((prev) => ({
            ...prev,
            records: newDepartments,
            current: newCurrent ? newCurrent.id : prev.current,
          }));
        })
    );
  }

  /**
   * This method will update the selected department id on input change
   * @param {any} departmentId
   */
  function onDepartmentChange(departmentId) {
    localStorage.setItem(
      "dashboard_statistics_present_employees_department",
      departmentId
    );
    setDepartments((prev) => ({
      ...prev,
      current: departmentId,
    }));
  }

  return (
    <PresentEmployeesStatisticView
      departments={departments}
      onDepartmentChange={onDepartmentChange}
      totalEmployees={totalEmployees}
      presentEmployees={presentEmployees}
      percentagePresentEmployees={percentagePresentEmployees}
    />
  );
}

export default PresentEmployeesStatistic;
