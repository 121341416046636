import styled from "styled-components";
import { StyledCardHeader } from "../../../../../styles/CardStyles";

export const StyledStepFormCardHeader = styled(StyledCardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.large};
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
`;
