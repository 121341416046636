import { useTheme } from "styled-components";
import { useLanguageContext } from "../../context/LanguageContext";
import { StyledAltButton, StyledButton } from "../../styles/ButtonStyles";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../styles/CardStyles";
import { StyledFlexLayout } from "../../styles/LayoutStyles";
import HiddenLoader from "../loader/HiddenLoader/HiddenLoader";
import EVAApiHelper from "../../helpers/EVAApiHelper";
import { StyledInputError } from "../input/wrapper/InputWrapperStyles";

function FormContainerView({
  header,
  children,
  onCancel,
  onSubmit,
  submitError,
  submitLoadingGroup,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <StyledCard>
      <StyledCardHeader>
        {!header || typeof header === "string" ? (
          <h2>{header ?? translate("eva.main.general.data")}</h2>
        ) : (
          header
        )}
      </StyledCardHeader>

      <StyledCardBody>
        {children}

        {onSubmit || onCancel ? (
          <StyledFlexLayout margin={`${theme.spacing.large} 0px 0px`}>
            {onCancel ? (
              <StyledAltButton onClick={onCancel}>
                {translate("eva.main.general.cancel")}
              </StyledAltButton>
            ) : (
              ""
            )}

            {onSubmit ? (
              <HiddenLoader groups={submitLoadingGroup}>
                <StyledButton onClick={onSubmit}>
                  {translate("eva.main.general.save")}
                </StyledButton>
              </HiddenLoader>
            ) : (
              ""
            )}
          </StyledFlexLayout>
        ) : (
          ""
        )}

        <StyledInputError>
          {EVAApiHelper.getResponseError("message", submitError)}
        </StyledInputError>
      </StyledCardBody>
    </StyledCard>
  );
}

export default FormContainerView;
