import { useTheme } from "styled-components";
import FormContainer from "../../../components/formContainer/FormContainer";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import OverlayLoader from "../../../components/loader/OverlayLoader/OverlayLoader";
import { useLanguageContext } from "../../../context/LanguageContext";
import EVAApiHelper from "../../../helpers/EVAApiHelper";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../styles/LayoutStyles";
import TranslationsDataTable from "../../../components/dataTable/tables/TranslationsDataTable";
import Select from "../../../components/select/main/Select";

function CompanyLanguagePageView({
  company_language,
  languages,
  companyLanguageData,
  onLanguageIdChange,
  onActiveChange,
  onDefaultChange,
  onSubmit,
  onCancel,
  submitError,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <OverlayLoader
        groups="company_language_page_fetch"
        containerStyle={{ marginBottom: theme.spacing.large }}
      >
        <FormContainer
          onCancel={onCancel}
          onSubmit={companyLanguageData.originalDefault ? null : onSubmit}
          submitError={submitError}
          submitLoadingGroup={"company_language_page_save"}
        >
          <InputWrapper
            label={translate("eva.main.validation.attributes.language_id")}
            error={EVAApiHelper.getResponseError("language_id", submitError)}
            required
          >
            <Select
              value={companyLanguageData.language_id}
              options={languages.map((lang) => ({
                value: lang.id,
                label: lang.label,
              }))}
              onChange={onLanguageIdChange}
              disabled={company_language !== undefined}
            />
          </InputWrapper>

          <StyledFlexLayout style={{ marginTop: theme.spacing.normal }}>
            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.active")}
                error={EVAApiHelper.getResponseError("active", submitError)}
                required
              >
                <Select
                  value={companyLanguageData.active}
                  options={[
                    { value: true, label: translate("eva.main.general.yes") },
                    { value: false, label: translate("eva.main.general.no") },
                  ]}
                  onChange={onActiveChange}
                  disabled={companyLanguageData.default}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>

            <StyledFlexLayoutItem>
              <InputWrapper
                label={translate("eva.main.validation.attributes.default")}
                error={EVAApiHelper.getResponseError("default", submitError)}
                required
              >
                <Select
                  value={companyLanguageData.default}
                  options={[
                    { value: true, label: translate("eva.main.general.yes") },
                    { value: false, label: translate("eva.main.general.no") },
                  ]}
                  onChange={onDefaultChange}
                  disabled={companyLanguageData.originalDefault}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      {company_language &&
      companyLanguageData.fetched &&
      languages.length > 0 ? (
        <TranslationsDataTable
          languageData={languages.find(
            (lang) => companyLanguageData.language_id === lang.id
          )}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default CompanyLanguagePageView;
