import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import ReleaseNotesPageView from "./ReleaseNotesPageView";
import { useState } from "react";

function ReleaseNotesPage() {
  const callEvaApi = useEvaApi();
  const [releases, setReleases] = useState([]);

  useCustomEffect(() => {
    fetchReleaseNotes();
  }, []);

  /*
   * Fetches release notes file from the API
   */
  async function fetchReleaseNotes() {
    await callEvaApi(
      new EvaApiCall("release_notes")
        .setLoadingGroup("release_notes_page_fetch")
        .setOnSuccess((response) => {
          setReleases(response.data.data);
        })
    );
  }

  return <ReleaseNotesPageView releases={releases} />;
}

export default ReleaseNotesPage;
