import { RouteConfig } from "./RouteConfig";
import { useDashboardRouteValidator } from "../validators/DashboardRouteValidator";
import DashboardPage from "../../pages/dashboard/index/DashboardPage";
import { useLanguageContext } from "../../context/LanguageContext";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useDashboardRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard")
    .setLabel(translate("eva.main.general.dashboard"))
    .setValidator(useDashboardRouteValidator())
    .setPage(<DashboardPage />);
}

export default useDashboardRouteConfig;
