import { useLanguageContext } from "../../../context/LanguageContext";
import { RouteConfig } from "../RouteConfig";
import IPLocksPage from "../../../pages/ip_locks/index/IPLocksPage";
import { useIPLocksRouteValidator } from "../../validators/ip_locks/IPLocksRouteValidator";

function useIPLocksRouteConfig() {
    const { translate } = useLanguageContext();

    return new RouteConfig()
        .setPath("/dashboard/ip_locks")
        .setLabel(translate("eva.main.entities.ip_locks"))
        .setValidator(useIPLocksRouteValidator())
        .setPage(<IPLocksPage />)
}

export default useIPLocksRouteConfig;