import StatisticSettingsView from "./StatisticSettingsView";
import useDropdown from "../../../../../hooks/useDropdown";
import { useAuthContext } from "../../../../../context/AuthContext";

function StatisticSettings({ statistics, setStatistics }) {
  const settingsDropdown = useDropdown();
  const authContext = useAuthContext(); 

  /**
   * This method will toggle the visibility of a statistic
   * @param {string} statistic
   */
  function toggleStatisticsVisibility(statistic) {
    setStatistics((previousState) => {
      const statistics = {
        ...previousState,
        [statistic]: { 
          ...previousState[statistic],
          visible: !previousState[statistic].visible,
         },
      };

      // Convert the statistics object to a boolean object
      const storageStatistics = { ...statistics };
      Object.keys(storageStatistics).forEach((key) => {
        storageStatistics[key] = storageStatistics[key].visible;
      });

      localStorage.setItem("dashboard_statistics", JSON.stringify(storageStatistics));
      return statistics;
    });
  }

  return authContext.auth?.user?.company?.type !== "superadmin" && (
    <StatisticSettingsView
      settingsDropdown={settingsDropdown}
      statistics={statistics}
      toggleStatisticsVisibility={toggleStatisticsVisibility}
    />
  );
}

export default StatisticSettings;
