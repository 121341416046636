import { useLanguageContext } from "../../../../context/LanguageContext";
import FieldOptionPage from "../../../../pages/fields/field/option/FieldOptionPage";
import { useFieldEditRouteValidator } from "../../../validators/fields/FieldEditRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useFieldEditRouteConfig from "../FieldEditRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFieldOptionEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/fields/:field/options/:field_option/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.field_option"),
        },
      ])
    )
    .setValidator(useFieldEditRouteValidator())
    .setPage(<FieldOptionPage />)
    .setParent(useFieldEditRouteConfig());
}

export default useFieldOptionEditRouteConfig;
