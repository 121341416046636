import Dashboard from "../../../layouts/dashboard/Dashboard";
import useDashboardLayoutRouteValidator from "../validators/DashboardLayoutRouteValidator";
import LayoutRouteConfig from "./LayoutRouteConfig";

function useDashboardLayoutRouteConfig() {
  return new LayoutRouteConfig()
    .setLayout(<Dashboard />)
    .setValidator(useDashboardLayoutRouteValidator());
}

export default useDashboardLayoutRouteConfig;
