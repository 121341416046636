import styled from "styled-components";

export const StyledDashboardContainer = styled.div`
  height: 100vh;
  display: flex;
`;

export const StyledDashboardMainContainer = styled.div`
  flex: 1;
  overflow-x: auto;
`;

export const StyledDashboardHeader = styled.header`
  height: 80px;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  color: ${(props) => props.theme.colors.on.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding: 0px ${(props) => props.theme.spacing.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.large};
`;

export const StyledDashboardHeaderUserContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledDashboardHeaderUser = styled.p`
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
  margin-right: ${(props) => props.theme.spacing.normal};
`;

export const StyledDashboardMain = styled.main`
  height: calc(100vh - 80px);
  padding: ${(props) => props.theme.spacing.large};
  overflow-y: auto;
  overflow-x: auto;
`;
