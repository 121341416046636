import styled from "styled-components";
import { StyledCardHeader } from "../../../../../styles/CardStyles";
import { StyledFormCardBody } from "../main/MainFormStyles";

export const StyledSettingsCardHeader = styled(StyledCardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledSettingsCardBody = styled(StyledFormCardBody)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.normal};
`;
