import { useParams } from "react-router-dom";
import CompanyLanguagePageView from "./CompanyLanguagePageView";
import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function CompanyLanguagePage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { company_language } = useParams();
  const languageContext = useLanguageContext();
  const [languages, setLanguages] = useState([]);
  const [companyLanguages, setCompanyLanguages] = useState([]);
  const [companyLanguageData, setCompanyLanguageData] = useState({
    language_id: null,
    active: true,
    default: false,
    originalDefault: false,
    fetched: false,
  });
  const [submitError, setSubmitError] = useState(null);

  /**
   * UseEffect for initializing the component
   */
  useCustomEffect(() => {
    fetchLanguages();
    fetchCompanyLanguages();

    if (company_language) {
      fetchCompanyLanguageData();
    }
  }, []);

  /**
   * Fetch the company languages from the API
   */
  async function fetchCompanyLanguages() {
    await callEvaApi(
      new EvaApiCall("company_languages")
        .setLoadingGroup("company_language_page_fetch")
        .setOnSuccess((response) => {
          setCompanyLanguages(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Fetch the languages from the API
   */
  async function fetchLanguages() {
    await callEvaApi(
      new EvaApiCall("languages")
        .setParams({ active_only: false })
        .setLoadingGroup("company_language_page_fetch")
        .setOnSuccess((response) => {
          setLanguages(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Fetch the company language data from the API
   */
  async function fetchCompanyLanguageData() {
    await callEvaApi(
      new EvaApiCall(`company_languages/${company_language}`)
        .setLoadingGroup("company_language_page_fetch")
        .setOnSuccess((response) => {
          const data = response.data.data;
          setCompanyLanguageData({
            language_id: data.language,
            active: data.active,
            default: data.default,
            originalDefault: data.default,
            fetched: true,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * Gets the languages which can still be selected
   * @returns {array} The filtered languages
   */
  function getFilteredLanguages() {
    return languages.filter(
      (lang) =>
        companyLanguageData.language_id === lang.id ||
        !companyLanguages.find(
          (companyLang) => companyLang.language === lang.id
        )
    );
  }

  /**
   * Handle the language id change
   * @param {any} value
   */
  function onLanguageIdChange(value) {
    setCompanyLanguageData((current) => ({
      ...companyLanguageData,
      language_id: value,
    }));
  }

  /**
   * Handle the active change
   * @param {any} value
   */
  function onActiveChange(value) {
    setCompanyLanguageData((current) => ({
      ...companyLanguageData,
      active: current.default ? true : value,
    }));
  }

  /**
   * Handle the default change
   * @param {any} e
   */
  function onDefaultChange(value) {
    setCompanyLanguageData((current) => ({
      ...companyLanguageData,
      default: value,
      active: value ? true : current.active,
    }));
  }

  /**
   * Navigate back to the previous page
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * Try to submit the data
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(
        company_language
          ? `company_languages/${company_language}`
          : "company_languages"
      )
        .setLoadingGroup("company_language_page_save")
        .setMethod(company_language ? "PUT" : "POST")
        .setData(companyLanguageData, ["originalDefault", "fetched"])
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          routeContext.back();
          languageContext.fetchLanguages();
        })
    );
  }

  return (
    <CompanyLanguagePageView
      company_language={company_language}
      languages={getFilteredLanguages()}
      companyLanguageData={companyLanguageData}
      onLanguageIdChange={onLanguageIdChange}
      onActiveChange={onActiveChange}
      onDefaultChange={onDefaultChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default CompanyLanguagePage;
