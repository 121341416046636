import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";

function StatisticsCounter ({ amount }) {
  const [currentCount, setCurrentCount] = useState(0);

  // Animate a count from 0 to the specified amount
  useCustomEffect(() => {
    setCurrentCount(0); 
    
    const interval = setInterval(() => {
      setCurrentCount((previousCount) => {
        const newCount = Math.min(previousCount + (amount > 250 ? 10 : 1), amount);
        if (newCount >= amount) {
          clearInterval(interval);
        }

        return newCount;
      });
    }, getAnimationTime());

    return () => clearInterval(interval);
  }, [amount]);

  /**
   * Calculate the time for the animation
   * @returns {number} time
   */
  function getAnimationTime() {
    const time = 1000 / (amount + 1);
    return time;
  }

  return currentCount;
}

export default StatisticsCounter;