export const pageSettings = [
  "width",
  "height",
  "gap",
  "flexDirection",
  "justifyContent",
  "alignItems",
  "image",
  "backgroundColor",
  "color",
  "padding",
];

export const boxSettings = [
  "width",
  "minWidth",
  "maxWidth",
  "height",
  "minHeight",
  "maxHeight",
  "flex",
  "gap",
  "flexDirection",
  "justifyContent",
  "alignItems",
  "image",
  "backgroundColor",
  "color",
  "borderRadius",
  "padding",
  "margin",
  "boxShadow",
];

export const subboxSettings = boxSettings;

export const contentSettings = [
  "width",
  "maxWidth",
  "height",
  "maxHeight",
  "margin",
];

export const languageSettings = ["margin"];

export const imageSettings = [
  "image",
  "width",
  "maxWidth",
  "height",
  "maxHeight",
  "borderRadius",
  "margin",
  "boxShadow",
];

/**
 * This method will return the layout settings for the given type
 * @param {string} type
 * @returns {string[]}
 */
export function getLayoutSettings(type) {
  switch (type) {
    case "page":
      return pageSettings;
    case "box":
      return boxSettings;
    case "subbox":
      return subboxSettings;
    case "content":
      return contentSettings;
    case "language":
      return languageSettings;
    case "image":
      return imageSettings;
    default:
      return [];
  }
}
