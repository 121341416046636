import useLayoutCreateRouteConfig from "../../../routes/configs/layouts/LayoutCreateRouteConfig";
import useLayoutEditRouteConfig from "../../../routes/configs/layouts/LayoutEditRouteConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useCreateHeaderActionConfig from "../main/config/headerAction/default/CreateHeaderActionConfig";
import useDeleteRowActionConfig from "../main/config/rowAction/default/DeleteRowActionConfig";
import useDuplicateRowActionConfig from "../main/config/rowAction/default/DuplicateRowActionConfig";
import useEditRowActionConfig from "../main/config/rowAction/default/EditRowActionConfig";

function LayoutsDataTable() {
  //Create the datatable config
  const config = new DataTableConfig("layouts");

  //Set the datatable fetch info
  config.setFetchRequest("layouts");

  //Set the datatable order by
  config.setOrderBy("name");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("name").setLockActive(true),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("layout", useLayoutCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  //Set the datatable row actions
  config.setRowActions([
    useEditRowActionConfig("layout", useLayoutEditRouteConfig()),
    useDuplicateRowActionConfig("layout"),
    useDeleteRowActionConfig("layout"),
  ]);

  return <DataTable controller={dataTable} />;
}

export default LayoutsDataTable;
