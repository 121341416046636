import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useCompanyTypeMiddleware from "../../../middlewares/CompanyTypeMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import usePermissionMiddleware from "../../../middlewares/PermissionMiddleware";
import useRoleTypeMiddleware from "../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useFlowsRouteValidator() {
  return useRouteValidator(
    useMiddlewares([
      useAuthMiddleware(),
      useCompanyTypeMiddleware("default"),
      useRoleTypeMiddleware(["superadmin", "user"], "or"),
      usePermissionMiddleware("flows.read"),
    ])
  );
}
