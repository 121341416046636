import useAuthMiddleware from "../../../../middlewares/AuthMiddleware";
import useCompanyTypeMiddleware from "../../../../middlewares/CompanyTypeMiddleware";
import useLocationMiddleware from "../../../../middlewares/LocationMiddleware";
import useMiddlewares from "../../../../middlewares/Middleware";
import useRoleTypeMiddleware from "../../../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "../../RouteValidator";

/**
 * @returns {{ validate: (order) => boolean|{ to: string, replace: boolean }}}
 */
export function useOrderRouteValidator() {
  const middlewaresValidation = useMiddlewares([
    useAuthMiddleware(),
    useCompanyTypeMiddleware("default"),
    useRoleTypeMiddleware(["superadmin", "user"], "or"),
    useLocationMiddleware(),
  ]);

  return useRouteValidator((order) => {
    if (order && order.status !== "pending") {
      return false;
    }
    return middlewaresValidation;
  });
}
