import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledButton,
  StyledIconButton,
  StyledTextButton,
} from "../../styles/ButtonStyles";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../styles/LayoutStyles";
import {
  StyledLoginAnnouncementCard,
  StyledLoginAnnouncementsHeader,
  StyledLoginAnnouncementsOverlay,
  StyledLoginLogo,
  StyledLoginWidthConstraint,
  StyledQRCodeWrapper,
} from "./LoginPageStyles";
import { faBell, faClose } from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTheme } from "styled-components";
import InputWrapper from "../../components/input/wrapper/InputWrapper";
import { StyledInput } from "../../styles/InputStyles";
import QRCode from "qrcode.react";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../styles/CardStyles";
import HiddenLoader from "../../components/loader/HiddenLoader/HiddenLoader";
import EVAApiHelper from "../../helpers/EVAApiHelper";

function LoginPageView({
  loginData,
  announcements,
  onEmailChange,
  onPasswordChange,
  onTFACodeChange,
  onQRCodeClick,
  tfaQRCodeURL,
  onSubmit,
  submitError,
  showAnnouncements,
  setShowAnnouncements,
}) {
  const theme = useTheme();
  const { translate } = useLanguageContext();

  return (
    <>
      <StyledLoginWidthConstraint>
        <StyledLoginLogo src="/assets/images/logo.png" alt="EVA Logo" />

        <StyledFlexLayout direction="column">
          <StyledCard>
            <StyledCardHeader>
              <StyledFlexLayout alignItems="center">
                <StyledFlexLayoutItem>
                  <h2>{translate("eva.main.auth.login")}</h2>
                </StyledFlexLayoutItem>

                <HiddenLoader groups="login_page_announcements_fetch">
                  {announcements.length > 0 ? (
                    <StyledTextButton
                      title={translate("eva.main.entities.announcements")}
                      color={theme.colors.accent.normal}
                      onClick={() => setShowAnnouncements(true)}
                    >
                      <StyledFlexLayout gap="5px" alignItems="center">
                        <FontAwesomeIcon icon={faBell} size="lg" />
                        <small>({announcements.length})</small>
                      </StyledFlexLayout>
                    </StyledTextButton>
                  ) : (
                    ""
                  )}
                </HiddenLoader>
              </StyledFlexLayout>
            </StyledCardHeader>

            <StyledCardBody>
              <InputWrapper
                for="email"
                label={translate("eva.main.validation.attributes.email")}
                required
                error={EVAApiHelper.getResponseError("email", submitError)}
                margin={`0px 0px ${theme.spacing.normal} 0px`}
              >
                <StyledInput
                  type="text"
                  id="email"
                  name="email"
                  value={loginData.email}
                  onChange={onEmailChange}
                  placeholder={translate(
                    "eva.main.validation.placeholders.email"
                  )}
                  margin={`0px 0px ${theme.spacing.normal}, 0px`}
                />
              </InputWrapper>

              <InputWrapper
                for="password"
                label={translate("eva.main.validation.attributes.password")}
                required
                error={EVAApiHelper.getResponseError("password", submitError)}
                margin={`0px 0px ${theme.spacing.normal} 0px`}
              >
                <StyledInput
                  type="password"
                  id="password"
                  name="password"
                  value={loginData.password}
                  onChange={onPasswordChange}
                  placeholder={translate(
                    "eva.main.validation.placeholders.password"
                  )}
                />
              </InputWrapper>

              <InputWrapper
                for="tfa_code"
                label={translate("eva.main.validation.attributes.tfa_code")}
                error={EVAApiHelper.getResponseError("tfa_code", submitError)}
                margin={`0px 0px ${theme.spacing.large} 0px`}
              >
                <StyledInput
                  type="text"
                  id="tfa_code"
                  name="tfa_code"
                  value={loginData.tfaCode}
                  onChange={onTFACodeChange}
                  placeholder={translate(
                    "eva.main.validation.placeholders.code.tfa"
                  )}
                />
              </InputWrapper>

              <HiddenLoader
                groups="login"
                containerStyle={{ display: "inline-block" }}
              >
                <StyledButton onClick={onSubmit}>
                  {translate("eva.main.general.proceed")}
                </StyledButton>
              </HiddenLoader>
            </StyledCardBody>
          </StyledCard>

          {/* TFA Code info */}
          {tfaQRCodeURL ? (
            <StyledCard>
              <StyledCardBody>
                <StyledFlexLayout alignItems="center">
                  <StyledQRCodeWrapper>
                    <QRCode onClick={onQRCodeClick} value={tfaQRCodeURL} />
                  </StyledQRCodeWrapper>
                  <p>{translate("eva.main.auth.tfa.info")}</p>
                </StyledFlexLayout>

                <StyledFlexLayout
                  alignItems="center"
                  margin={`${theme.spacing.large} 0px 0px 0px`}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/images/google_play_button.png"
                      alt="Google Play"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/images/apple_store_button.png"
                      alt="Apple Store"
                    />
                  </a>
                </StyledFlexLayout>
              </StyledCardBody>
            </StyledCard>
          ) : (
            ""
          )}
        </StyledFlexLayout>
      </StyledLoginWidthConstraint>

      {/* Announcements overlay display */}
      {showAnnouncements ? (
        <StyledLoginAnnouncementsOverlay>
          <StyledLoginWidthConstraint>
            {/* Announcements header */}
            <StyledLoginAnnouncementsHeader>
              <StyledFlexLayout alignItems="center">
                <FontAwesomeIcon icon={faBell} />

                <StyledFlexLayoutItem>
                  <h1>{translate("eva.main.entities.announcements")}</h1>
                </StyledFlexLayoutItem>

                <StyledIconButton
                  title={translate("eva.main.general.back")}
                  onClick={() => setShowAnnouncements(false)}
                >
                  <FontAwesomeIcon icon={faClose} />
                </StyledIconButton>
              </StyledFlexLayout>
            </StyledLoginAnnouncementsHeader>

            {/* Announcements */}
            <StyledFlexLayout direction="column" gap={theme.spacing.large}>
              {announcements.map((announcement, index) => (
                <StyledLoginAnnouncementCard key={index}>
                  <StyledCardHeader>
                    <h2>{announcement.title}</h2>
                  </StyledCardHeader>

                  <StyledCardBody
                    dangerouslySetInnerHTML={{
                      __html: announcement.description,
                    }}
                  />
                </StyledLoginAnnouncementCard>
              ))}
            </StyledFlexLayout>
          </StyledLoginWidthConstraint>
        </StyledLoginAnnouncementsOverlay>
      ) : (
        ""
      )}
    </>
  );
}

export default LoginPageView;
