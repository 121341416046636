import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { StyledIconButton } from "../../../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../../../styles/CardStyles";
import { StyledFlexLayout } from "../../../../styles/LayoutStyles";
import { useTheme } from "styled-components";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";

function OrdersPreparePageView({ prepareList, onPrepareDish }) {
  const theme = useTheme();

  return (
    <OverlayLoader groups="canteen_orders_prepare_fetch">
      <StyledFlexLayout direction="column" gap={theme.spacing.large}>
        {prepareList.map((prepareListItem, index) => (
          <StyledCard key={index}>
            <StyledCardHeader>
              <StyledFlexLayout
                alignItems="center"
                justifyContent="space-between"
              >
                <h2>
                  {prepareListItem.total} {prepareListItem.dish.name}
                </h2>

                <HiddenLoader
                  groups={`canteen_orders_prepare_${prepareListItem.dish.id}`}
                >
                  <StyledIconButton
                    disabled={prepareListItem.status === "done"}
                    onClick={() => {
                      onPrepareDish(prepareListItem.dish.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </StyledIconButton>
                </HiddenLoader>
              </StyledFlexLayout>
            </StyledCardHeader>

            <StyledCardBody>
              {prepareListItem.employees.map(
                (employeeItem, index) =>
                  `${employeeItem.employee.name} (${employeeItem.amount})` +
                  (index < prepareListItem.employees.length - 1 ? "  -  " : "")
              )}
            </StyledCardBody>
          </StyledCard>
        ))}
      </StyledFlexLayout>
    </OverlayLoader>
  );
}

export default OrdersPreparePageView;
