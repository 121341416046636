import { Editor } from "@tinymce/tinymce-react";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTheme } from "styled-components";
import { getEditorContentStyles } from "./TinyEditorStyles";

function TinyEditorView({ controller }) {
  const { language } = useLanguageContext();
  const theme = useTheme();

  return (
    <Editor
      onInit={(evt, editor) => controller.initialize(editor)}
      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
      init={{
        language: language.code,
        menubar: false,
        plugins: "link lists image",
        toolbar:
          "undo redo | styles | forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
        content_style: getEditorContentStyles(theme),
      }}
      content
      onEditorChange={controller.onEditorChange}
    />
  );
}

export default TinyEditorView;
