import { useTheme } from "styled-components";
import { useLanguageContext } from "../../../../context/LanguageContext";
import FormContainer from "../../../../components/formContainer/FormContainer";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";
import OverlayLoader from "../../../../components/loader/OverlayLoader/OverlayLoader";
import {
  StyledFlexLayout,
  StyledFlexLayoutItem,
} from "../../../../styles/LayoutStyles";
import QuestionAnswersDataTable from "../../../../components/dataTable/tables/questionnaire/QuestionAnswersDataTable";
import EntityTranslationsContainer from "../../../../components/entityTranslationsContainer/EntityTranslationsContainer";
import ImageInput from "../../../../components/input/image/ImageInput";

function QuestionPageView({
  question,
  questionData,
  translationsController,
  onNameChange,
  onImageChange,
  onOrderChange,
  onImportanceFactorChange,
  onGroupChange,
  onCancel,
  onSubmit,
  submitError,
}) {
  const { translate } = useLanguageContext();
  const theme = useTheme();

  return (
    <>
      <OverlayLoader groups="question_page_fetch">
        <FormContainer
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitError={submitError}
          submitLoadingGroup={"question_page_submit"}
        >
          <StyledFlexLayout>
            <InputWrapper
              error={EVAApiHelper.getResponseError("image", submitError)}
            >
              <ImageInput
                size={"115px"}
                value={questionData.image}
                onChange={onImageChange}
              />
            </InputWrapper>

            <StyledFlexLayoutItem>
              <StyledFlexLayout style={{ marginBottom: theme.spacing.normal }}>
                <StyledFlexLayoutItem>
                  <InputWrapper
                    label={translate("eva.main.validation.attributes.name")}
                    error={EVAApiHelper.getResponseError("name", submitError)}
                    required
                  >
                    <StyledInput
                      value={questionData.name}
                      onChange={onNameChange}
                      placeholder={translate(
                        "eva.main.validation.attributes.name"
                      )}
                    />
                  </InputWrapper>
                </StyledFlexLayoutItem>

                <InputWrapper
                  label={translate("eva.main.validation.attributes.order")}
                  error={EVAApiHelper.getResponseError("order", submitError)}
                  required
                >
                  <StyledInput
                    type="number"
                    value={questionData.order}
                    onChange={onOrderChange}
                  />
                </InputWrapper>

                <InputWrapper
                  label={translate(
                    "eva.main.validation.attributes.importance_factor"
                  )}
                  error={EVAApiHelper.getResponseError(
                    "importance_factor",
                    submitError
                  )}
                  required
                >
                  <StyledInput
                    type="number"
                    value={questionData.importance_factor}
                    onChange={onImportanceFactorChange}
                  />
                </InputWrapper>
              </StyledFlexLayout>

              <InputWrapper
                label={translate("eva.main.validation.attributes.group")}
                error={EVAApiHelper.getResponseError("group", submitError)}
              >
                <StyledInput
                  value={questionData.group}
                  onChange={onGroupChange}
                  placeholder={translate(
                    "eva.main.validation.attributes.group"
                  )}
                />
              </InputWrapper>
            </StyledFlexLayoutItem>
          </StyledFlexLayout>
        </FormContainer>
      </OverlayLoader>

      <OverlayLoader
        groups="question_page_fetch"
        containerStyle={{ marginBlock: theme.spacing.large }}
      >
        <EntityTranslationsContainer controller={translationsController}>
          <InputWrapper
            label={translate("eva.main.validation.attributes.question")}
            error={translationsController.getTranslationError(
              "question",
              submitError
            )}
          >
            <StyledInput
              type="text"
              placeholder={translate("eva.main.validation.attributes.question")}
              value={translationsController.getValue("question")}
              onChange={(e) =>
                translationsController.onTranslationChange(
                  e.target.value,
                  "question"
                )
              }
            />
          </InputWrapper>
        </EntityTranslationsContainer>
      </OverlayLoader>

      {questionData.fetched && <QuestionAnswersDataTable question={question} />}
    </>
  );
}

export default QuestionPageView;
