import Select from "../../../main/Select";

function LocationFormSelectView({ options, value, onChange, disabled }) {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default LocationFormSelectView;
