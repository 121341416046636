import useAuthMiddleware from "../../middlewares/AuthMiddleware";
import useCompanyTypeMiddleware from "../../middlewares/CompanyTypeMiddleware";
import useMiddlewares from "../../middlewares/Middleware";
import usePermissionMiddleware from "../../middlewares/PermissionMiddleware";
import useRoleTypeMiddleware from "../../middlewares/RoleTypeMiddleware";
import useRouteValidator from "./RouteValidator";

/**
 * @returns {{ validate: () => boolean|{ to: string, replace: boolean }}}
 */
export function useThemeSettingsRouteValidator() {
  return useRouteValidator(
    useMiddlewares([
      useAuthMiddleware(),
      useRoleTypeMiddleware(["superadmin", "user"], "or"),
      usePermissionMiddleware("theme_settings.update"),
    ])
  );
}
