import { useLanguageContext } from "../../../context/LanguageContext";
import DepartmentPage from "../../../pages/departments/department/DepartmentPage";
import { useDepartmentEditRouteValidator } from "../../validators/departments/DepartmentEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useDepartmentsRouteConfig from "./DepartmentsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useDepartmentEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/departments/:department/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.department"),
        },
      ])
    )
    .setValidator(useDepartmentEditRouteValidator())
    .setPage(<DepartmentPage />)
    .setParent(useDepartmentsRouteConfig());
}

export default useDepartmentEditRouteConfig;
