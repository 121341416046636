import { useTerminalContext } from "../../context/TerminalContext";
import { Themed } from "../../context/ThemeContext";
import { StyledFrontOfficePage } from "./FrontOfficePageStyles";
import { StartPageConfig } from "./pages/start/StartPage";
import Screensaver from "./screensaver/Screensaver";
import Settings from "./settings/Settings";

function FrontofficePageView({ pageConfig, setPageConfig }) {
  const terminalContext = useTerminalContext();
  const PageComponent = pageConfig?.page;

  return (
    <Themed frontoffice={true}>
      <StyledFrontOfficePage>
        {terminalContext.terminal && (
          <>
            {PageComponent && (
              <PageComponent
                pageConfig={pageConfig}
                setPageConfig={setPageConfig}
              />
            )}

            <Screensaver
              screensavers={terminalContext.terminal.screensavers}
              onActivation={() => setPageConfig(StartPageConfig)}
            />
          </>
        )}

        <Settings />
      </StyledFrontOfficePage>
    </Themed>
  );
}

export default FrontofficePageView;
