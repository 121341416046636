import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import StringHelper from "../../../../../../helpers/StringHelper";
import useEvaApi, { EvaApiCall } from "../../../../../../hooks/useEvaApi";
import RowActionConfig from "../RowActionConfig";

/**
 * @param {string} entity for example: "location"
 * @param {function(row, dataTable) : string} url defaults to `entityName (plural) + /row.id`
 * @param {function(row, dataTable) : bool | bool} extraConfirm for example: false
 * @param {function(response, row, dataTable) : void} onSuccess defaults to null
 * @returns {RowActionConfig}
 */
function useDeleteRowActionConfig(
  entity,
  url,
  extraConfirm = false,
  onSuccess = null
) {
  const callEvaApi = useEvaApi();
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  /**
   * This method will try to delete the entity from the database
   * @param {object} row
   * @param {object} dataTable useDataTable()
   */
  async function handleDelete(row, dataTable) {
    //Ask the user if he is sure
    if (!window.confirm(translate("eva.main.questions.delete"))) {
      return;
    }

    //Check for extra confirm
    if (
      extraConfirm === true ||
      (typeof extraConfirm === "function" && extraConfirm(row, dataTable))
    ) {
      const randomNumber = Math.floor(Math.random() * (500 - 100) + 100);
      const promptAnswer = prompt(
        `${translate("eva.main.questions.year_plus_day").replace(
          "?",
          ""
        )} + ${randomNumber}`
      );
      const currentDate = new Date();
      const correctAnswer =
        currentDate.getFullYear() + currentDate.getDate() + randomNumber;
      if (parseInt(promptAnswer) !== correctAnswer) {
        return;
      }
    }

    //Get the url path and id from the path
    const deleteUrl = getDeleteUrl(row, dataTable);

    //Delete the entity
    await callEvaApi(
      new EvaApiCall(deleteUrl)
        .setMethod("DELETE")
        .setLoadingGroup(
          `${dataTable.config.getDataTableKey()}_delete_${row.id}`
        )
        .setOnSuccess((response) => {
          if (onSuccess) {
            onSuccess(response, row, dataTable);
          }
        })
    );

    //Reload the data table
    dataTable.fetch();
  }

  /**
   * This method will determine the url for the delete action
   * @param {object} row
   * @returns {string}
   */
  function getDeleteUrl(row, dataTable) {
    return url
      ? url(row, dataTable)
      : StringHelper.pluralize(entity) + `/${row.id}`;
  }

  /**
   * This method will determine if the delete button is disabled
   * @returns {bool}
   */
  function getDisabled() {
    return !authContext.hasPermission(
      `${StringHelper.pluralize(entity)}.delete`
    );
  }

  return new RowActionConfig()
    .setIcon(faTrash)
    .setCallback(handleDelete)
    .setDisabled(getDisabled)
    .setTitle(
      translate("eva.main.entities.states.delete", [
        {
          key: "entities",
          value: translate(`eva.main.entities.${entity}`),
        },
      ])
    )
    .setLoadingGroups(
      (row, dataTable) =>
        `${dataTable.config.getDataTableKey()}_delete_${row.id}`
    );
}

export default useDeleteRowActionConfig;
