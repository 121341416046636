import { faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  StyledDashboardContainer,
  StyledDashboardHeader,
  StyledDashboardHeaderUser,
  StyledDashboardHeaderUserContainer,
  StyledDashboardMain,
  StyledDashboardMainContainer,
} from "./DashboardStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardNav from "./nav/DashboardNav";
import { StyledIconButton } from "../../styles/ButtonStyles";
import { Outlet } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";
import LanguageSelect from "../../components/select/custom/language/LanguageSelect";
import LocationSelect from "../../components/select/custom/location/LocationSelect";
import HelpSelect from "../../components/select/custom/help/HelpSelect";
import { useAuthContext } from "../../context/AuthContext";
import DashboardTitle from "./title/DashboardTitle";
import CalamityActivationButton from "../../components/calamityButton/CalamityActivationButton";
import HiddenLoader from "../../components/loader/HiddenLoader/HiddenLoader";

function DashboardView() {
  const { translate } = useLanguageContext();
  const authContext = useAuthContext();

  return (
    <StyledDashboardContainer>
      <DashboardNav />

      <StyledDashboardMainContainer>
        {/* Dashboard Header */}
        <StyledDashboardHeader>
          <DashboardTitle />

          <StyledDashboardHeaderUserContainer>
            {/* Dashboard User Info */}
            <StyledDashboardHeaderUser>
              <FontAwesomeIcon icon={faUser} />
              {authContext.auth.user?.name}
            </StyledDashboardHeaderUser>

            {/* Dashboard User Logout */}
            <HiddenLoader groups="logout">
              <StyledIconButton
                onClick={() => authContext.logout()}
                title={translate("eva.main.auth.logout")}
              >
                <FontAwesomeIcon icon={faSignOut} />
              </StyledIconButton>
            </HiddenLoader>

            <LanguageSelect />
            <LocationSelect />
            <HelpSelect />
            <CalamityActivationButton />
          </StyledDashboardHeaderUserContainer>
        </StyledDashboardHeader>

        {/* Dashboard Main Content */}
        <StyledDashboardMain>
          <Outlet />
        </StyledDashboardMain>
      </StyledDashboardMainContainer>
    </StyledDashboardContainer>
  );
}

export default DashboardView;
