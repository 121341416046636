import { useLanguageContext } from "../../../../../../context/LanguageContext";
import useBasicColumnConfig from "./BasicColumnConfig";

/**
 * @param {string} key
 * @param {function(row) : any} data default value is (row) => row[key]
 * @returns
 */
function useBooleanColumnConfig(key, data = (row) => row[key]) {
  const { translate } = useLanguageContext();

  return useBasicColumnConfig(key)
    .setData((row) =>
      data(row)
        ? translate("eva.main.general.yes")
        : translate("eva.main.general.no")
    )
    .setSearchable(false);
}

export default useBooleanColumnConfig;
