import { useState } from "react";
import FilePreviewView from "./FilePreviewView";
import FileHelper from "../../helpers/FileHelper";

function FilePreview({ path, children, onCloseCallback, onOpenCallback }) {
  const [showPreview, setShowPreview] = useState(false);
  const disabled = typeof path !== "string" || path.includes("[object Blob]");
  const pathType = FileHelper.pathType(path);

  /**
   * This method will handle the onOpen event
   */
  function onOpen() {
    if (disabled) {
      return;
    }
    onOpenCallback && onOpenCallback();
    setShowPreview(true);
  }

  /**
   * This method will handle the onClose event
   */
  function onClose() {
    onCloseCallback && onCloseCallback();
    setShowPreview(false);
  }

  return (
    <FilePreviewView
      path={path}
      showPreview={showPreview}
      onClose={onClose}
      onOpen={onOpen}
      disabled={disabled}
      pathType={pathType}
    >
      {children}
    </FilePreviewView>
  );
}

export default FilePreview;
