import { useLanguageContext } from "../../../../context/LanguageContext";
import ToolPage from "../../../../pages/calamities/tool/ToolPage";
import { useToolCreateRouteValidator } from "../../../validators/calamities/tools/ToolCreateRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useCalamitiesRouteConfig from "../CalamitiesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useCalamityToolCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/calamities/tools/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.calamity_tool"),
        },
      ])
    )
    .setValidator(useToolCreateRouteValidator())
    .setPage(<ToolPage />)
    .setParent(useCalamitiesRouteConfig());
}

export default useCalamityToolCreateRouteConfig;
