import { useLanguageContext } from "../../../context/LanguageContext";
import FilePage from "../../../pages/files/file/FilePage";
import { useFileEditRouteValidator } from "../../validators/files/FileEditRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useFilesRouteConfig from "./FilesRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useFileEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/files/:file/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.file"),
        },
      ])
    )
    .setValidator(useFileEditRouteValidator())
    .setPage(<FilePage />)
    .setParent(useFilesRouteConfig());
}

export default useFileEditRouteConfig;
