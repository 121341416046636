import { useLanguageContext } from "../../../../context/LanguageContext";
import OrderPage from "../../../../pages/canteen/orders/order/OrderPage";
import { useOrderEditRouteValidator } from "../../../validators/canteen/orders/OrderEditRouteValidator";
import { RouteConfig } from "../../RouteConfig";
import useOrdersRouteConfig from "./OrdersRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useOrderEditRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/canteen/orders/:order/edit")
    .setLabel(
      translate("eva.main.entities.states.edit", [
        {
          key: "entities",
          value: translate("eva.main.entities.order"),
        },
      ])
    )
    .setValidator(useOrderEditRouteValidator())
    .setPage(<OrderPage />)
    .setParent(useOrdersRouteConfig());
}

export default useOrderEditRouteConfig;
