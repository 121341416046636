import FlowPageMessagesFormView from "./FlowPageMessagesFormView";

function FlowPageMessagesForm({
  flowData,
  setFlowData,
  messageTemplates,
  employees,
  asSubForm = false,
  subFormHeader,
  submitError,
}) {
  /**
   * This method will handle the message creation event.
   * @param {int} id
   */
  function onCreateMessage(id) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].messages =
        newSteps[prevFlowData.selectedStep].messages || [];
      newSteps[prevFlowData.selectedStep].messages.push({
        message_template_id: id,
        receiver_type: "employee",
      });

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the message deletion event.
   * @param {int} index
   */
  function onDeleteMessage(index) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].messages.splice(index, 1);

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the receiver change event.
   * @param {int} index
   * @param {any} value
   */
  function onReceiverTypeChange(index, value) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].messages[index].receiver_type = value;
      newSteps[prevFlowData.selectedStep].messages[index].employee_id =
        value !== "employee_extern"
          ? null
          : newSteps[prevFlowData.selectedStep].messages[index].employee_id;

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  /**
   * This method will handle the employee change event.
   * @param {int} index
   * @param {any} value
   */
  function onEmployeeChange(index, value) {
    setFlowData((prevFlowData) => {
      const newSteps = [...prevFlowData.steps];
      newSteps[prevFlowData.selectedStep].messages[index].employee_id = value;

      return {
        ...prevFlowData,
        steps: newSteps,
      };
    });
  }

  return (
    <FlowPageMessagesFormView
      flowData={flowData}
      messageTemplates={messageTemplates}
      employees={employees}
      asSubForm={asSubForm}
      subFormHeader={subFormHeader}
      onCreateMessage={onCreateMessage}
      onDeleteMessage={onDeleteMessage}
      onReceiverTypeChange={onReceiverTypeChange}
      onEmployeeChange={onEmployeeChange}
      submitError={submitError}
    />
  );
}

export default FlowPageMessagesForm;
