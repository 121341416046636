import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import UserPageView from "./UserPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function UserPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const { user } = useParams();
  const authContext = useAuthContext();
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    language_id: null,
    employee_id: null,
    location_id: authContext.auth.location,
    roles: [],
  });
  const [submitError, setSubmitError] = useState(null);
  const passwordUpdateOnly =
    user &&
    authContext.hasPermission("users.update.self") &&
    !authContext.hasPermission("users.update");

  /**
   * initialize the component
   */
  useCustomEffect(() => {
    if (authContext.auth.user.company.type !== "superadmin") {
      fetchEmployees();
      fetchRoles();
    }

    if (user) {
      fetchUserData();
    }
  });

  /**
   * This method will fetch the user data from the API
   */
  async function fetchUserData() {
    await callEvaApi(
      new EvaApiCall(`users/${user}`)
        .setLoadingGroup("user_page_fetch")
        .setOnSuccess((response) => {
          if (response.data.data.email === "superadmin@evasolutions.eu") {
            routeContext.back();
          }

          setUserData({
            name: response.data.data.name,
            email: response.data.data.email,
            password: "",
            employee_id: response.data.data.employee,
            language_id: response.data.data.language,
            location_id: response.data.data.location,
            roles: response.data.data.roles,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("user_page_fetch")
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the employees from the API
   */
  async function fetchRoles() {
    await callEvaApi(
      new EvaApiCall("roles")
        .setLoadingGroup("user_page_fetch")
        .setOnSuccess((response) => {
          setRoles(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the name on input change
   * @param {any} value
   */
  function onLocationIdChange(value) {
    setUserData({ ...userData, location_id: value });
  }

  /**
   * This method will update the name on input change
   * @param {event} e
   */
  function onNameChange(e) {
    setUserData({ ...userData, name: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onEmailChange(e) {
    setUserData({ ...userData, email: e.target.value });
  }

  /**
   * This method will update the password on input change
   * @param {event} e
   */
  function onPasswordChange(e) {
    setUserData({ ...userData, password: e.target.value });
  }

  /**
   * This method will update the employee on input change
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setUserData({ ...userData, employee_id: value });
  }

  /**
   * This method will update the language on input change
   * @param {any} value
   */
  function onLanguageIdChange(value) {
    setUserData({ ...userData, language_id: value });
  }

  /**
   * This method will update the roles on input change
   * @param {array} values
   */
  function onRolesChange(values) {
    setUserData({ ...userData, roles: values });
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(user ? `users/${user}` : "users")
        .setLoadingGroup("user_page_save")
        .setMethod(user ? "PUT" : "POST")
        .setData(userData)
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          if (parseInt(user) === parseInt(authContext.auth.user.id)) {
            authContext.fetchUser();
          }
          routeContext.back();
        })
    );
  }

  return (
    <UserPageView
      user={user}
      userData={userData}
      passwordUpdateOnly={passwordUpdateOnly}
      roles={roles}
      employees={employees}
      onLocationIdChange={onLocationIdChange}
      onNameChange={onNameChange}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onLanguageIdChange={onLanguageIdChange}
      onEmployeeIdChange={onEmployeeIdChange}
      onRolesChange={onRolesChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default UserPage;
