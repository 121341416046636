class ColumnConfig {
  constructor() {
    this.key = "";
    this.label = "";
    this.labelHeaderHidden = false;
    this.searchable = true;
    this.orderable = true;
    this.active = true;
    this.lockActive = false;
    this.headerStyle = null;
    this.hidden = false;
    this.useFromToDateTime = false;

    this.data = (row) => "";
    this.dataAsHtml = false;
  }

  /**
   * @param {string} key
   * @returns {this}
   */
  setKey(key) {
    this.key = key;
    return this;
  }

  /**
   * @param {string} label
   * @returns {this}
   */
  setLabel(label) {
    this.label = label;
    return this;
  }

  /**
   * @param {string} labelHeaderHidden
   * @returns {this}
   */
  setLabelHeaderHidden(labelHeaderHidden) {
    this.labelHeaderHidden = labelHeaderHidden;
    return this;
  }

  /**
   * @param {function(row) : any} data
   * @param {boolean} asHtml
   * @returns {this}
   */
  setData(data, asHtml = false) {
    this.data = data;
    this.dataAsHtml = asHtml;
    return this;
  }

  /**
   * @param {boolean} searchable
   * @returns {this}
   */
  setSearchable(searchable) {
    this.searchable = searchable;
    return this;
  }

  /**
   * @param {boolean} orderable
   * @returns {this}
   */
  setOrderable(orderable) {
    this.orderable = orderable;
    return this;
  }

  /**
   * @param {boolean} active
   * @returns {this}
   */
  setActive(active) {
    this.active = active;
    return this;
  }

  /**
   * @param {boolean} lockActive
   * @returns {this}
   */
  setLockActive(lockActive) {
    this.lockActive = lockActive;
    return this;
  }

  /**
   * @param {object} headerStyle
   * @returns {this}
   */
  setHeaderStyle(headerStyle) {
    this.headerStyle = headerStyle;
    return this;
  }

  /**
   * @param {boolean} hidden
   * @returns {this}
   */
  setHidden(hidden) {
    this.hidden = hidden;
    return this;
  }

  /**
   * @param {boolean} useFromToDateTime
   * @returns {this}
   */
  setUseFromToDateTime(useFromToDateTime) {
    this.useFromToDateTime = useFromToDateTime;
    return this;
  }
}

export default ColumnConfig;
