import moment from "moment";
import { useParameterContext } from "../../../../context/ParameterContext";
import useMiddlewares from "../../../../middlewares/Middleware";
import usePermissionMiddleware from "../../../../middlewares/PermissionMiddleware";
import useRouteValidator from "../../RouteValidator";
import { useOrderRouteValidator } from "./OrderRouteValidator";
import useRoleTypeMiddleware from "../../../../middlewares/RoleTypeMiddleware";

/**
 * @returns {{ validate: (order) => boolean|{ to: string, replace: boolean }}}
 */
export function useOrderCreateRouteValidator() {
  const parameterContext = useParameterContext();
  const orderValidator = useOrderRouteValidator();
  const middlewaresValidation = useMiddlewares([
    usePermissionMiddleware("canteen.dishes.create"),
    useRoleTypeMiddleware(["superadmin", "user"], "or"),
  ]);

  return useRouteValidator((order) => {
    //Use the default order validator
    const orderValidation = orderValidator.validate(order);
    if (orderValidation !== true) {
      return orderValidation;
    }

    //Validate the order time
    const orderTimeParam = parameterContext.getParameter(
      "canteen.max.order.time"
    );
    if (
      orderTimeParam &&
      moment(orderTimeParam, "HH:mm").isBefore(
        moment(moment().format("HH:mm"), "HH:mm")
      )
    ) {
      return { to: "/dashboard/canteen/orders", replace: true };
    }

    //validate the middlewares
    return middlewaresValidation;
  });
}
