import { useLanguageContext } from "../../../../../context/LanguageContext";
import QuestionAnswerPage from "../../../../../pages/questionnaires/questionnaire/question/answer/QuestionAnswerPage";
import { useQuestionnaireEditRouteValidator } from "../../../../validators/questionnaires/QuestionnaireEditRouteValidator";
import { RouteConfig } from "../../../RouteConfig";
import useQuestionEditRouteConfig from "../QuestionEditRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useQuestionAnswerCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath(
      "/dashboard/questionnaires/:questionnaire/questions/:question/answers/create"
    )
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.question_answer"),
        },
      ])
    )
    .setValidator(useQuestionnaireEditRouteValidator())
    .setPage(<QuestionAnswerPage />)
    .setParent(useQuestionEditRouteConfig());
}

export default useQuestionAnswerCreateRouteConfig;
