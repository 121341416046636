import { useLanguageContext } from "../../../context/LanguageContext";
import DepartmentPage from "../../../pages/departments/department/DepartmentPage";
import { useDepartmentCreateRouteValidator } from "../../validators/departments/DepartmentCreateRouteValidator";
import { RouteConfig } from "../RouteConfig";
import useDepartmentsRouteConfig from "./DepartmentsRouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useDepartmentCreateRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/departments/create")
    .setLabel(
      translate("eva.main.entities.states.create", [
        {
          key: "entities",
          value: translate("eva.main.entities.department"),
        },
      ])
    )
    .setValidator(useDepartmentCreateRouteValidator())
    .setPage(<DepartmentPage />)
    .setParent(useDepartmentsRouteConfig());
}

export default useDepartmentCreateRouteConfig;
