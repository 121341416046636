import { useLanguageContext } from "../../../context/LanguageContext";
import RolesPage from "../../../pages/roles/index/RolesPage";
import { useRolesRouteValidator } from "../../validators/roles/RolesRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useRolesRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/roles")
    .setLabel(translate("eva.main.entities.roles"))
    .setValidator(useRolesRouteValidator())
    .setPage(<RolesPage />);
}

export default useRolesRouteConfig;
