import { useAuthContext } from "../../../../context/AuthContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import LocationSelectView from "./LocationSelectView";

function LocationSelect({ disabled }) {
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  /**
   * Handle the change
   * @param {any} value
   */
  function onChange(value) {
    authContext.updateLocation(value);
  }

  /**
   * Determine the options for this select
   * @returns {object[]}
   */
  function getOptions() {
    return [
      { label: translate("eva.main.general.all"), value: null },
      ...authContext.auth.user.company.locations.map((loc) => ({
        value: loc.id,
        label: loc.name,
      })),
    ];
  }

  return (
    authContext.auth.user?.company.type !== "superadmin" && (
      <LocationSelectView
        value={authContext.auth.location}
        options={getOptions()}
        onChange={onChange}
        disabled={authContext.auth.user.location ? true : disabled}
      />
    )
  );
}

export default LocationSelect;
