import InputWrapper from "../../../../../components/input/wrapper/InputWrapper";
import HiddenLoader from "../../../../../components/loader/HiddenLoader/HiddenLoader";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import {
  StyledAltButton,
  StyledButton,
} from "../../../../../styles/ButtonStyles";
import { StyledCardHeader } from "../../../../../styles/CardStyles";
import { StyledInput } from "../../../../../styles/InputStyles";
import {
  StyledForm,
  StyledFormButtons,
  StyledFormCard,
  StyledFormCardBody,
} from "./MainFormStyles";
import Hierarchy from "./hierarchy/Hierarchy";

function MainFormView({
  layout,
  layoutData,
  setLayoutData,
  onNameChange,
  onCancel,
  onSubmit,
}) {
  const { translate } = useLanguageContext();

  return (
    !layoutData.focused_item_id && (
      <StyledFormCard>
        <StyledCardHeader>
          <h2>
            {translate(
              `eva.main.entities.states.${layout ? "edit" : "create"}`,
              [
                {
                  key: "entities",
                  value: translate("eva.main.entities.layout"),
                },
              ]
            )}
          </h2>
        </StyledCardHeader>

        <StyledFormCardBody>
          <StyledForm>
            <InputWrapper
              label={translate("eva.main.validation.attributes.name")}
              required
            >
              <StyledInput
                type="text"
                value={layoutData.name}
                onChange={onNameChange}
                placeholder={translate("eva.main.validation.attributes.name")}
              />
            </InputWrapper>

            <StyledFormButtons>
              <StyledAltButton onClick={onCancel}>
                {translate("eva.main.general.cancel")}
              </StyledAltButton>

              <HiddenLoader groups={"layout_page_submit"}>
                <StyledButton onClick={onSubmit}>
                  {translate("eva.main.general.save")}
                </StyledButton>
              </HiddenLoader>
            </StyledFormButtons>
          </StyledForm>

          <Hierarchy layoutData={layoutData} setLayoutData={setLayoutData} />
        </StyledFormCardBody>
      </StyledFormCard>
    )
  );
}

export default MainFormView;
