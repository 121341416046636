import { useLanguageContext } from "../../../context/LanguageContext";
import ButtonsPage from "../../../pages/buttons/index/ButtonsPage";
import { useButtonsRouteValidator } from "../../validators/buttons/ButtonsRouteValidator";
import { RouteConfig } from "../RouteConfig";

/**
 * The route config which contains static information about the route
 * @returns {{ path, label, validator, getPage, getRouteElement }}
 */
function useButtonsRouteConfig() {
  const { translate } = useLanguageContext();

  return new RouteConfig()
    .setPath("/dashboard/buttons")
    .setLabel(translate("eva.main.entities.buttons"))
    .setValidator(useButtonsRouteValidator())
    .setPage(<ButtonsPage />);
}

export default useButtonsRouteConfig;
