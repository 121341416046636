import styled from "styled-components";

export const StyledStatisticsBar = styled.div`
  height: 15px;
  background-color: ${(props) => props.theme.colors.primary.dark};
  border-radius: ${(props) => props.theme.radius.default};
  margin-top: ${(props) => props.theme.spacing.normal};
  overflow: hidden;
`;

export const StyledStatisticsBarFilled = styled.div`
  background-color: ${(props) => props.theme.colors.accent.normal};
  transition: width 1s linear;
  height: 100%;
  width: ${(props) => props.percentage}%;
`; 