import HtmlSizeInputView from "./HtmlSizeInputView";

function HtmlSizeInput({
  value,
  onChange,
  types = ["px", "%"],
  withNullType = true,
}) {
  const type = value
    ? value.toString().replace(/[0-9.,]/g, "")
    : withNullType
    ? null
    : "auto";
  let size = value ? value.toString().replace(type, "") : "";
  size = size === "0" ? "" : size;

  /**
   * This method will handle the type change event
   * @param {string} value
   */
  function onTypeChange(value) {
    if (!onChange) return;

    if (value && value !== "auto") {
      onChange(getNewValue(size.length > 0 ? size : 0, value));
    } else {
      onChange(value);
    }
  }

  /**
   * This method will handle the size change event
   * @param {Event} e
   */
  function onSizeChange(e) {
    if (!onChange) return;

    if (e.target.value === "" || parseFloat(e.target.value) <= 0) {
      onChange(getNewValue(0, type));
    } else {
      onChange(getNewValue(e.target.value, type));
    }
  }

  /**
   * This method will return the new value based on the given size and type
   * @param {any} size
   * @param {string} type
   * @returns {string}
   */
  function getNewValue(size, type) {
    return size.toString() + type;
  }

  return (
    <HtmlSizeInputView
      size={size}
      type={type}
      types={types}
      withNullType={withNullType}
      onSizeChange={onSizeChange}
      onTypeChange={onTypeChange}
    />
  );
}

export default HtmlSizeInput;
