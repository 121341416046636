import { useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import LoginPageView from "./LoginPageView";
import useCustomEffect from "../../hooks/useCustomEffect";
import { useLanguageContext } from "../../context/LanguageContext";
import useEvaApi, { EvaApiCall } from "../../hooks/useEvaApi";

function LoginPage() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    tfaCode: "",
  });
  const [submitError, setSubmitError] = useState(null);
  const [tfaQRCodeURL, setTFAQRCodeURL] = useState(null);

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    fetchAnnouncements();
  });

  /**
   * This callback will get the active announcements from the API
   */
  async function fetchAnnouncements() {
    await callEvaApi(
      new EvaApiCall("announcements")
        .setParams({ active_only: true })
        .setLoadingGroup("login_page_announcements_fetch")
        .setOnSuccess((response) => {
          setAnnouncements(response.data.data.records);
        })
    );
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onEmailChange(e) {
    setLoginData({ ...loginData, email: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onPasswordChange(e) {
    setLoginData({ ...loginData, password: e.target.value });
  }

  /**
   * This method will update the email on input change
   * @param {event} e
   */
  function onTFACodeChange(e) {
    setLoginData({ ...loginData, tfaCode: e.target.value });
  }

  /**
   * This method will copy the QR Code secret to the clipboard
   */
  function onQRCodeClick() {
    navigator.clipboard.writeText(getQRCodeSecret());
    alert(translate("eva.main.general.copied_to_clipboard"));
  }

  /**
   * Gets the secret from the QR Code URL
   * @returns {string} The QR Code secret
   */
  function getQRCodeSecret() {
    const url = new URL(tfaQRCodeURL);
    return url.searchParams.get("secret");
  }

  /**
   * This method will submit the form to log the user in
   */
  async function onSubmit() {
    setSubmitError(null);

    authContext.login(
      loginData.email,
      loginData.password,
      loginData.tfaCode,
      null,
      (error) => {
        setTFAQRCodeURL(error.response?.data?.data?.tfa_qr_code_url);
        setSubmitError(error);
      }
    );
  }

  return (
    <LoginPageView
      loginData={loginData}
      announcements={announcements}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onTFACodeChange={onTFACodeChange}
      onQRCodeClick={onQRCodeClick}
      tfaQRCodeURL={tfaQRCodeURL}
      onSubmit={onSubmit}
      submitError={submitError}
      showAnnouncements={showAnnouncements}
      setShowAnnouncements={setShowAnnouncements}
    />
  );
}

export default LoginPage;
